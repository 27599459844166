import {
	cloneDeep,
	isArray,
	isEmpty,
	isPlainObject,
	keys,
	toInteger,
	toNumber,
} from 'lodash';
import { saveAs } from 'file-saver';
import { LIMIT_TYPES, TIME_UNITS } from './commonConstants';
import betsActions from '../redux/rtm/bets/actions';
import depositsActions from '../redux/rtm/deposits/actions';
import withdrawalsActions from '../redux/rtm/withdrawals/actions';
import casinoActions from '../redux/rtm/casino/actions';
import documentsActions from '../redux/rtm/documents/actions';
import riskActions from '../redux/rtm/riskManagement/actions';
import staffActions from '../redux/staff/filter/actions';
import { actions as partnersActions } from '../redux/partner/partners/filter/actions';
import roleActions from '../redux/staff/filterRoles/actions';
import usersActions from '../redux/users/filter/actions';
import bonusesActions from '../redux/loyalty/bonuses/bonuses/actions';
import freeSpinsActions from '../redux/loyalty/bonusingHistory/freeSpins/actions';
import welcomeActions from '../redux/loyalty/bonuses/welcomeBonus/actions';
import cashbackActions from '../redux/loyalty/bonuses/cashback/actions';
import massBonusActions from '../redux/loyalty/bonuses/massBonus/list/actions';
import userMassBonus from '../redux/loyalty/bonuses/userMassBonus/filter/actions';
import cashbackUserActions from '../redux/loyalty/bonusingHistory/cashbackUsers/actions';
import freeBetsActions from '../redux/loyalty/bonusingHistory/freeBets/actions';
import tournamentsActions from '../redux/loyalty/tournaments/actions';
import eventsActions from '../redux/sport/events/filter/actions';
import resultsActions from '../redux/sport/results/filter/actions';
import { liveStatisticTypesFilterActions } from '../redux/sport/liveStatisticTypes/filter/actions';
import marketTemplatesActions from '../redux/sport/markets/filter/actions';
import marketGroupsActions from '../redux/sport/markets/groups/actions';
import marketStatisticTypesActions from '../redux/settings/statisticTypes/actions';
import marketDisplayTypesActions from '../redux/sport/markets/displayTypes/actions';
import participantsActions from '../redux/sport/participants/filter/actions';
import periodsActions from '../redux/settings/scopes/actions';
import laddersActions from '../redux/sport/settings/ladders/actions';
import blockedIPsActions from '../redux/settings/security/blockedIps/list/actions';
import matchesOfDayActions from '../redux/cms/landingPage/matchesOfDay/filter/actions';
import promotionsActions from '../redux/cms/pages/promotions/list/actions';
import bannersActions from '../redux/cms/banners/list/actions';
import reportByPlayerActions from '../redux/reports/reportsByPlayer/actions';
import reportByGameActions from '../redux/reports/reportsByGame/actions';
import reportsByProviderActions from '../redux/reports/reportsByProvider/actions';
import reportsByIntegratorActions from '../redux/reports/reportsByIntegrator/actions';
import transactionsActions from '../redux/reports/transactions/actions';
import pushNotificationsActions from '../redux/notificationCenter/pushNotifications/usersList/actions';
import smsActions from '../redux/notificationCenter/sms/actions';
import emailsActions from '../redux/notificationCenter/emails/emails/list/actions';
import affiliatesActions from '../redux/affiliates/actions';
import gamesActions from '../redux/casino/games/actions';
import { inboxAcceptedFileTypes } from '../constants/fileTypes';
import { bonusTemplatesReducers } from '../redux/loyalty/templates/slice';

// Filters ---------------------------------------------------
export const filtersURL = {
	// RTM
	rtmBets   : 'rtm/bets',
	deposits  : 'rtm/deposits',
	withdrawal: 'rtm/withdrawals',
	casino    : 'rtm/casino',
	documents : 'rtm/documents',
	risk      : 'rtm/risk-alerts',

	// Sport Risk Management

	sportRiskManagement: 'riskManagement/bets',

	// Staff
	staffList           : 'staff/personal',
	roles               : 'staff/roles',
	// Partners
	partnersList        : 'partners/partners',
	// Customer
	usersList           : 'users/list',
	// Loyalty Bonuses Sistem
	bonuses             : 'loyalty/bonusingSystem/bonuses',
	welcome             : 'loyalty/bonusingSystem/welcome',
	casinoCashbacks     : 'loyalty/bonusingSystem/cashback',
	massBonus           : 'loyalty/bonusingSystem/massBonus',
	createMassBonus     : 'loyalty/bonusingSystem/massbonus',
	// Loyalty Bonuses History
	freeSpins           : 'loyalty/bonusingHistory/freeSpins',
	casinoUsers         : 'loyalty/bonusingHistory/usersWithCashback',
	freeBets            : 'loyalty/bonusingHistory/freeBets',
	// Loyalty Tournaments
	tournaments         : 'loyalty/tournaments',
	// Loyalty Tournaments
	bonusTemplatesList  : 'loyalty/bonusTemplates',
	// Casino
	gamesList           : 'casino/games',
	// Sport
	eventsList          : 'sport/events',
	resultsList         : 'sport/results',
	liveStatisticTypes  : 'sport/liveStatisticTypes',
	// Sport/markets
	marketTemplates     : 'sport/markets/templates',
	marketGroups        : 'sport/markets/groups',
	marketStatisticTypes: 'sport/markets/statisticTypes',
	displayTypes        : 'sport/markets/displayTypes',
	// Sport Participants
	participants        : 'sport/participants/list',
	// Sport Settings
	sportSettingsPeriods: 'sport/sportSettings/scopes',
	ladders             : 'sport/sportSettings/ladders',
	// Settings Security
	blockedIPs          : 'settings/security/blockedIps',
	//CMS Landing Page
	matchesOfTheDay     : 'cms/homeManage/matchesOfDay',
	// CMS Pages
	promotions          : 'cms/cmsPages/promotions',
	// CMS Baners
	banners             : 'cms/banners/list',
	// Reports
	reportsByPlayer     : 'reports/reportByPlayer',
	reportsByGame       : 'reports/reportByGame',
	reportsByProvider   : 'reports/reportByProvider',
	reportsByIntegrator : 'reports/reportByIntegrator',
	transactions        : 'reports/transactions',
	// Notifications Center
	notifications       : 'notificationCenter/pushNotifications',
	smsList             : 'notificationCenter/sms',
	emailsList          : 'notificationCenter/emails/list',
	// Affiliates
	affiliates          : 'affiliates',
};
export const filtersRESET = {
	'rtm/bets'                                 : betsActions.filterReset,
	'rtm/deposits'                             : depositsActions.filterReset,
	'rtm/withdrawals'                          : withdrawalsActions.filterReset,
	'rtm/casino'                               : casinoActions.filterReset,
	'rtm/documents'                            : documentsActions.filterReset,
	'rtm/risk-alerts'                          : riskActions.filterReset,
	// Staff
	'staff/personal'                           : staffActions.filterReset,
	'staff/roles'                              : roleActions.dataReset,
	// Partners
	'partners/partners'                        : partnersActions.filterReset,
	// Customer
	'users/list'                               : usersActions.filterReset,
	// Loyalty Bonuses Sistem
	'loyalty/bonusingSystem/bonuses'           : bonusesActions.filterReset,
	'loyalty/bonusingSystem/welcome'           : welcomeActions.filterReset,
	'loyalty/bonusingSystem/cashback'          : cashbackActions.filterReset,
	'loyalty/bonusingSystem/massBonus'         : massBonusActions.filterReset,
	'loyalty/bonusingSystem/massbonus'         : userMassBonus.filterReset,
	// Loyalty Bonuses History
	'loyalty/bonusingHistory/freeSpins'        : freeSpinsActions.filterReset,
	'loyalty/bonusingHistory/usersWithCashback': cashbackUserActions.filterReset,
	'loyalty/bonusingHistory/freeBets'         : freeBetsActions.filterReset,
	// Loyalty Tournaments
	'loyalty/tournaments'                      : tournamentsActions.filterReset,
	'casino/games'                             : gamesActions.filterReset ,

	//Loyalty Templates
	'loyalty/bonusTemplates': bonusTemplatesReducers.resetFilterData,

	// Sport
	'sport/events'                        : eventsActions.filterReset,
	'sport/results'                       : resultsActions.filterReset,
	'sport/liveStatisticTypes'            : liveStatisticTypesFilterActions.clearFilters,
	// Sport/markets
	'sport/markets/templates'             : marketTemplatesActions.filterReset,
	'sport/markets/groups'                : marketGroupsActions.filterReset,
	'sport/markets/statisticTypes'        : marketStatisticTypesActions.filterReset,
	'sport/markets/displayTypes'          : marketDisplayTypesActions.filterReset,
	// Sport Participants
	'sport/participants/list'             : participantsActions.filterReset,
	// Sport Settings
	'sport/sportSettings/scopes'          : periodsActions.filterReset,
	'sport/sportSettings/ladders'         : laddersActions.filterReset,
	// Settings Security
	'settings/security/blockedIps'        : blockedIPsActions.filterReset,
	//CMS Landing Page
	'cms/homeManage/matchesOfDay'         : matchesOfDayActions.resetValues,
	// CMS Pages
	'cms/cmsPages/promotions'             : promotionsActions.filterReset,
	// CMS Baners
	'cms/banners/list'                    : bannersActions.filterReset,
	// Reports
	'reports/reportByPlayer'              : reportByPlayerActions.filterReset,
	'reports/reportByGame'                : reportByGameActions.filterReset,
	'reports/reportByProvider'            : reportsByProviderActions.filterReset,
	'reports/reportByIntegrator'          : reportsByIntegratorActions.filterReset,
	'reports/transactions'                : transactionsActions.filterDataReset,
	// Notifications Center
	'notificationCenter/pushNotifications': pushNotificationsActions.filterReset,
	'notificationCenter/sms'              : smsActions.filterReset,
	'notificationCenter/emails/list'      : emailsActions.filterReset,
	// Affiliates
	'affiliates'                          : affiliatesActions.filterReset,
};

// ----------------------------------------------------------------

export function isValidTimeFormat(timeString) {
	const regex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // Regular expression for hh:mm:ss format
	return regex.test(timeString);
}

// check if value is ID: integer number > 0
export function isID(value) {
	return Boolean(toInteger(value));
}

export function isZeroOrID(value) {
	return Boolean(toInteger(value)) || (value === 0);
}

export function isEqualID(id01, id02) {
	return toInteger(id01) === toInteger(id02);
}

// calculate an absolute limit value
export function calculateAbsoluteLimit(
	parentLimit = 0, percent = 0, format = true) {
	const result = (toNumber(parentLimit) * toNumber(percent)) / 100;
	if (format) {
		return result.toLocaleString('en-US', { minimumFractionDigits: 2 });
	}

	return result;
}

export function calculateLimit({
	override,
	limit,
	limitTypeID,
	parentAbsolute,
}) {
	if (!override) {
		return 0;
	}

	const isPercent = toInteger(limitTypeID) === LIMIT_TYPES.percent;
	if (!isPercent) {
		return limit;
	}


	return calculateAbsoluteLimit(parentAbsolute, limit, false);
}

// filling object
export function fill(source, target, withCreatingKeys = false) {
	const sourceKeys = keys(source);
	const targetKeys = keys(target);
	const result     = cloneDeep(target);
	sourceKeys.forEach(key => {
		if (!targetKeys.includes(key) && !withCreatingKeys) {
			return;
		}
		result[key] = source[key];
	});
	return result;
}

// map enum
export function mapEnum(enumList) {
	const result = {};
	enumList.forEach(item => {
		const key   = item.id;
		result[key] = item.name;
	});

	return result;
}

//cut enum
export function cutEnum(enumList, IDs, field = 'id') {
	if (!isArray(enumList) || isEmpty(enumList)) {
		return [];
	}
	if (!isArray(IDs) || isEmpty(IDs)) {
		return enumList;
	}

	return enumList.filter(item => !IDs.includes(item[field]));
}

// entities map to list
export function entitiesToEnumList(entities = {}, nameField = null) {

	const IDs = Object.keys(entities);
	if (IDs.length === 0) {
		return [];
	}

	const enumList = IDs.map(ID => {
		const entity     = cloneDeep(entities[ID]);
		const entityName = entity.name;

		entity.id = toInteger(ID);
		if (!entityName && nameField) {
			entity.name = entity[nameField];
		}

		return entity;
	});

	return enumList;
}

// list convert to entities
export function listToEntities(list = [], nameKey = 'id') {
	if (!isArray(list) || isEmpty(list)) {
		return {};
	}

	const result = {};

	list.forEach(item => {
		const keyValue = item[nameKey];
		if (!keyValue) {
			return;
		}

		result[keyValue] = cloneDeep(item);
	});

	return result;
}

// create columns for Tables
export function createColumn(
	dataKey, title, render = null, sorter = null, width = null, className = null,
	key = null) {
	const column = {
		title,
		dataIndex: dataKey,
		key      : key || dataKey,
	};
	if (render) {
		column.render = (text, record) => render(record, dataKey);
	}
	if (sorter) {
		column.sorter = (a, b) => sorter(a, b);
	}
	if (width) {
		column.width = width;
	}
	if (className) {
		column.className = className;
	}

	return column;
}

export function createColumnNoSort(
	dataKey, title, render = null, withKey = null) {

	const column = withKey ? {
		title,
		dataIndex: dataKey,
		key      : dataKey,
		sorter   : true,
	} : {
		title,
		dataIndex: dataKey,
	};

	if (render) {
		column.render = (text, record) => render(record, dataKey);
	}

	return column;
}

export function adjustLanguages(list) {
	return list.map(({ text, dataBaseLangID }) => ({
		name: text,
		id  : dataBaseLangID,
	}));
}

export function isEmptyObject(obj = null) {
	if (!obj) {
		return true;
	}

	try {
		return (keys(obj) > 0);

	}
	catch (error) {
		return true;
	}
}

export function getHeadersTotalCount(headers = {}) {
	const targetName = 'x-total-count';
	const headerKeys = keys(headers);
	for (let i = 0; i < headerKeys.length; i++) {
		const key = headerKeys[i];
		if (String(key).toLowerCase() === targetName) {
			return toInteger(headers[key]);
		}
	}

	return 0;
}

// detect is JSON
export function isJSON(rawData) {
	try {
		const parsed = JSON.parse(rawData);
		return isPlainObject(parsed);
	}
	catch (err) {
		return false;
	}
}

//allows also dot numbers
export function allowNumberDot(value) {
	return value.replace(/[^0-9.]/g, '')
		.replace(/(\..*)\./g, '$1')
		.replace(/(?!^)-/g, '')
		.replace(/^0+(\d)/gm, '$1');
}

//allows only numbers
export function allowNumber(value) {
	return value.replace(/-[^0-9]/g, '');
}

/**
 * Creates debounced function with possibility to revoke last invocation
 * @param func
 * @param ms defaults to 500 ms
 */
export function debounce(func, ms = 500) {
	let timer = null;

	return function (...args) {
		const onComplete = () => {
			func.apply(this, args);
			timer = null;
		};

		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(onComplete, ms);
	};
}

export const autocompleteDebounce = (func, ms = 500) => {
	let timeoutId;
	return function (...args) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => func(...args), ms);
	};
};

export function createFilter(keyUrl, searchTypes, defaultValues = {}) {
	const filter = {
		...defaultValues,
	};
	const { pathname, search } = window.location;
	const path = pathname.replace('/dashboard/', '');

	if (!search || path !== keyUrl) return filter;
	return queryStringToObject(search, filter, searchTypes);

}

export function queryStringToObject(search, filter, searchTypes) {
	const deCodeSearch = decodeURIComponent(search);
	const searchPart = deCodeSearch.startsWith('?') ? deCodeSearch.slice(1) : deCodeSearch;

	const pairs = searchPart.split('&');
	pairs.forEach(pair => {
		const [key, value] = pair.split('=');
		if (searchTypes[key]) {
			const isValidValue = searchTypes[key](value);
			if (isValidValue) {
				filter[key] = isValidValue;
			}
		}
	});
	return filter;
}

export function downloadReport(data) {
	let filename      = '';
	const disposition = data.headers['content-disposition'];
	if (disposition && disposition.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches       = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	const blobData = new Blob([data.data], { type: 'text/csv' });
	saveAs(blobData, filename);
}

export function allowOnlyNumbers(val) {
	const reg = /^\d+$/;
	let value;
	if (val === undefined || val === null) {
		value = '';
	}
	else if (reg.test(val)) {
		value = val;
	}
	else if (!reg.test(val[0])) {
		value = val.substr(1);
	}
	else {
		value = val.slice(0, -1);
	}
	return value;
}

export function allowOnlyFloatNumbers(val) {
	const reg = /^-?\d*[.,]?\d*$/;
	let value;
	if (val === undefined || val === null) {
		value = '';
	}
	else if (reg.test(val)) {
		value = val;
	}
	else if (!reg.test(val[0])) {
		value = val.substr(1);
	}
	else {
		value = val.slice(0, -1);
	}
	return value;
}

export function alphaOnly(event) {
	const key = event.keyCode;
	return ((key >= 65 && key <= 90) || key === 8);
}

export function resetGamesInCategory(arr1, arr2) {
	if (arr1 && arr2) {
		const uniqueSet   = new Set([...arr1, ...arr2]);
		const uniqueArray = Array.from(uniqueSet);

		return uniqueArray.filter((value) => {
			return arr1.includes(value) !== arr2.includes(value);
		});
	}
	return [];
}

export function resetTheSameValue(arr1, arr2) {
	if (arr1 && arr2) {
		const uniqueSet = [...new Set([...arr1, ...arr2])];

		return uniqueSet.filter((value) => {
			return arr1.some(item => item.id === value.id) !==
        arr2.some(item => item.id === value.id);
		});
	}
	return [];
}

export function theSameValue(arr1, arr2) {
	if (arr1 && arr2) {
		const uniqueSet = arr1.length <= arr2.length ? arr1 : [...new Set(
			[...arr1, ...arr2]),
		];

		return uniqueSet.filter((value) => {
			return arr1.some(item => item.id === value.id) ===
        arr2.some(item => item.id === value.id);
		});
	}
	return [];
}

export function setAllCheckedCategories({
	bonusData,
	checkedAllGames,
	categoryList,
	gameIDs,
}) {
	const { categoryIDs } = bonusData;

	categoryIDs.map(item => checkedAllGames(
		{ categoryID: item, games: [], checkedAll: true, into: true })
	);

	if (bonusData.count.categories) {
		const ids                    = Object.keys(bonusData.count.categories);
		const checkedGamesByCategory = [];
		const readyGames             = {};

		categoryList.forEach(item => {
			return ids.forEach(itemId => {
				if (item.id === +itemId) {
					checkedGamesByCategory.push(
						{ games: item.games.map(it => it.id), categoryID: item.id });
				}
			});
		});

		checkedGamesByCategory.forEach(item => {
			item.games.forEach(gameID => gameIDs.forEach(it => {
				if (gameID === it) {
					readyGames[item.categoryID] = readyGames[item.categoryID]
						? [...readyGames[item.categoryID], gameID]
						: [gameID];
				}
			}));
		});

		Object.entries(readyGames)
			.forEach(([categoryID, games]) => {
				checkedAllGames({
					games,
					categoryID,
					checkedAll: categoryIDs.includes(+categoryID),
					into      : true,
				});
			});
	}
}

export function formatMillisecondsToDuration(date) {
	const items = [];

	items.push({ timeUnit: TIME_UNITS.days, value: date.days() });
	items.push({ timeUnit: TIME_UNITS.hours, value: date.hours() });
	items.push({ timeUnit: TIME_UNITS.minutes, value: date.minutes() });
	items.push({ timeUnit: TIME_UNITS.seconds, value: date.seconds() });

	return items.reduce((acc, { value, timeUnit }) => {
		if (acc.length === 0 && value === 0) {
			return acc;
		}

		acc.push({ value, timeUnit });

		return acc;
	}, []);
}

export const formatMilliseconds = (milliseconds) => {
	const msPerSecond = 1000;
	const msPerMinute = 60 * msPerSecond;
	const msPerHour   = 60 * msPerMinute;
	const msPerDay    = 24 * msPerHour;

	const days = Math.floor(milliseconds / msPerDay);
	milliseconds %= msPerDay;

	const hours = Math.floor(milliseconds / msPerHour);
	milliseconds %= msPerHour;

	const minutes = Math.floor(milliseconds / msPerMinute);
	milliseconds %= msPerMinute;

	const seconds = Math.floor(milliseconds / msPerSecond);
	milliseconds %= msPerSecond;

	let formattedTime = '';

	if (days > 0) {
		formattedTime += `${days.toString().padStart(2, '0')} `;
	}

	formattedTime += `${hours.toString().padStart(2, '0')}:${minutes.toString()
		.padStart(2, '0')}:${seconds.toString()
		.padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;

	return formattedTime;
};

export const formatAlias = (alias = '') => {
	return alias.replace(/[^\p{L}\p{N}]/gu, '_');
};

export function capitalizeFirstLetter(str = '') {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const createEntities = (data = [], key = 'id' ) => {
	return data.reduce((acc, item) => {
		acc[item[key]] = item;
		return acc;
	}, {});
};

export const formatFixedNumber = (number, fractionDigits) => {
	const fixedNumber = number.toFixed(fractionDigits);
	const zeroString = '0.' + '0'.repeat(fractionDigits);
	return fixedNumber === zeroString ? '0' : fixedNumber;
};

export const validateFiles = (files, maxFileSize) => {
	const MAX_TOTAL_SIZE = maxFileSize * 1024 * 1024;
	let totalSize = 0;
	let isValidType = true;

	for (const file of files) {
		isValidType = inboxAcceptedFileTypes.includes(file.type);
		if (!isValidType) return isValidType;

		totalSize += file.size;
	}

	return isValidType && totalSize <= MAX_TOTAL_SIZE;
};
