import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';

import { APP_TABS, DEFAULT_TABS } from '../../constants/appTabs';
import { storeAppTabs, restoreAppTabs } from '../../helpers/utility';
import { getSidebarTabsKey } from '../../helpers/locationUtils';

import actions from './actions';
import { defineActiveTab } from './utils';
import { filtersRESET } from '../../helpers/utils';

function getStoreData({ AppTabs }) {

	return {
		tabs       : cloneDeep(AppTabs.get('tabs')),
		activeTabID: AppTabs.get('activeTabID'),
	};
}

function* tabsReload() {
	yield takeEvery(actions.APP_TABS_RELOAD, function* () {
		let tabs = yield call(restoreAppTabs);

		if (!tabs) {
			tabs = cloneDeep(DEFAULT_TABS);

			yield call(storeAppTabs, tabs);
		}

		const activeTabID = yield call(getSidebarTabsKey);
		const activeTab   = yield call(defineActiveTab, activeTabID);

		yield put(actions.tabsRefresh(tabs));
		yield put(actions.openTab(activeTab));
	});
}

function* openTab() {
	yield takeEvery(actions.APP_TABS_OPEN_TAB, function* (action) {

		const { tabs } = yield select(getStoreData);
		const { tab } = action.data;
		const tabID       = tab.id;
		const existingTab = find(tabs, { id: tabID });

		if (existingTab) {
			yield put(actions.activeTabSet(tabID, tab.titleID, !!tab.isSearch));

			// Location and componentProps can be changed if we open existing tab but with new data (User Info, for example)
			const isChanges = (existingTab.location !== tab.location || !isEqual(existingTab.componentProps, tab.componentProps));
			if (isChanges) {
				existingTab.location       = tab.location;
				existingTab.componentProps = tab.componentProps;
				existingTab.titleID        = tab.titleID;

				yield call(storeAppTabs, tabs);
				yield put(actions.tabsRefresh(tabs));
			}

		} else {
			tabs.push(tab);
			yield call(storeAppTabs, tabs);
			yield put(actions.tabsRefresh(tabs));
			yield put(actions.activeTabSet(tabID, tab.titleID));
		}
	});
}

function* closeTab() {
	yield takeEvery(actions.APP_TABS_CLOSE_TAB, function* (action) {
		const { tabs, activeTabID } = yield select(getStoreData);
		const { tabID, closeContext }  = action.data;

		if (closeContext && !(tabID === activeTabID)) {
			const currentTab = tabs.find(tab => tab.id === activeTabID);
			yield put(actions.activeTabSet(currentTab.id, currentTab.titleID));
		} else {
			const previousIndex = (findIndex(tabs, { id: tabID }) - 1);
			if (previousIndex >= 0 && activeTabID === tabID) {
				const newTab = tabs[previousIndex];
				yield put(actions.activeTabSet(newTab.id, newTab.titleID));
			}
		}
		const newTabs = tabs.filter(tab => tab.id !== tabID);
		yield call(storeAppTabs, newTabs);
		yield put(actions.tabsRefresh(newTabs));
		filtersRESET[tabID] && (yield put(filtersRESET[tabID]()));
	});
}

function* closeOtherTabs() {
	yield takeEvery(actions.APP_TABS_CLOSE_OTHER_TABS, function* ({ data }) {

		const { tabs } = yield select(getStoreData);
		const chooseTabs = [];
		for (const tab of tabs) {
			const { id } = tab;
			if (id === data.tabID) {
				chooseTabs.push(tab);
			} else if (filtersRESET[id]){
				yield put(filtersRESET[id]()); 
			}
		}
		const deepCloneChooseTabs = cloneDeep(chooseTabs);

		chooseTabs.unshift(tabs[0]);
		const targetTab = deepCloneChooseTabs[0];

		yield put(actions.activeTabSet(targetTab.id, targetTab.titleID));
		yield call(storeAppTabs, chooseTabs);
		yield put(actions.tabsRefresh(chooseTabs));
	});
}

function* closeRightTabs() {
	yield takeEvery(actions.APP_TABS_CLOSE_RIGHT_TABS, function* ({ data }) {
		let currentIdx = 0;

		const { tabs, activeTabID } = yield select(getStoreData);
		const clonedTabs = cloneDeep(tabs);
		const foundIndex = clonedTabs.findIndex(tab => tab.id === data.tabID);
		clonedTabs.find((tab, idx) => {
			currentIdx = idx;
			return tab.id === activeTabID;
		});
		const nextTab = foundIndex + 1;
		const oldTabs = clonedTabs.slice(nextTab);

		for (const tab of oldTabs) {
			if (filtersRESET[tab.id]){
				yield put(filtersRESET[tab.id]()); 
			}
		}
		const newTabs = clonedTabs.slice(0, nextTab);
		if (currentIdx > foundIndex) {
			const targetTab = newTabs[newTabs.length - 1];
			yield put(actions.activeTabSet(targetTab.id, targetTab.titleID));
		}

		yield call(storeAppTabs, newTabs);
		yield put(actions.tabsRefresh(newTabs));
	});
}

function* closeAllTabs() {

	yield takeEvery(actions.APP_TABS_CLOSE_ALL, function* () {

		const { tabs } = yield select(getStoreData);
		const newTabs = yield call(makeResetAllOpenTabsFilter, tabs);
		yield call(storeAppTabs, newTabs);
		yield put(actions.activeTabSet(APP_TABS.dashboard, newTabs[0].titleID));
		yield put(actions.tabsRefresh(newTabs));
	});
}

export function* makeResetAllOpenTabsFilter(tabs = [], newTabs = []) {

	for (const tab of tabs) {
		const { id } = tab;
		if (filtersRESET[id]) {
			yield put(filtersRESET[id]()); // TODO reset All open tabs filter
		}
		if (id === APP_TABS.dashboard) {
			newTabs.push(tab);
		}
	}
	return newTabs;
}

export default function* appTabsSaga() {
	yield all([
		fork(tabsReload),
		fork(openTab),
		fork(closeTab),
		fork(closeAllTabs),
		fork(closeOtherTabs),
		fork(closeRightTabs),
	]);
}
