import React, { FC, MouseEvent } from 'react';
import { Col, Row } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import FormInputNumber from '../../../../../../components/FormControls/FormInputNumber';
import FormCheckbox from '../../../../../../components/FormControls/FormCheckbox';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import {
	userBonusUI,
	userBonusLineAndDenominationsRequired,
	userBonusData,
} from '../../../../../../selectors/bonuses';
import bonusesActions from '../../../../../../redux/loyalty/bonuses/userBonus/actions';
import { formItemLabel } from '../../../../../Partner/Websites/modals/Websites/TabIntegration/utils/utils';
import { lang } from '../../lang';
import { IPropsBetsAndDenomination } from './types';

const BetsAndDenominationUser: FC<IPropsBetsAndDenomination> = ({
	onChangeField,
	disabled,
}) => {
	const dispatch = useAppDispatch();
	const { showLineAndDenominationCheckbox, isCheckedMinLineAndBet } = useAppSelector(userBonusUI);
	const lineAndDenominationsRequired = useAppSelector(userBonusLineAndDenominationsRequired);
	const { requiredBetPerLine, requiredLines } = lineAndDenominationsRequired;
	const bonusData = useAppSelector(userBonusData);
	const { betPerLine, lines } = bonusData;

	const handleCheck = (e: MouseEvent<HTMLDivElement>) => {
		const target = e.target as HTMLInputElement;
		dispatch(bonusesActions.uiRefresh({ isCheckedMinLineAndBet: target.checked }));

		if (target.checked) {
			const clonedData = cloneDeep(bonusData);
			clonedData.betPerLine = null;
			clonedData.lines = null;
			dispatch(bonusesActions.dataRefresh(clonedData));
		}
		dispatch(bonusesActions.uiRefresh({ isChanged: true }));
	};

	return (
		<div>
			<Row>
				<Col span={24}>
					<FormInputNumber
						label={!showLineAndDenominationCheckbox ? formItemLabel(lang.betPerLine, requiredBetPerLine) : lang.betPerLine}
						value={betPerLine || null}
						min={0}
						step={1}
						disabled={disabled || isCheckedMinLineAndBet}
						onChange={(value: string) => onChangeField(value, 'betPerLine')}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<FormInputNumber
						label={!showLineAndDenominationCheckbox ? formItemLabel(lang.lines, requiredLines) : lang.lines}
						value={lines || null}
						min={0}
						step={1}
						disabled={disabled || isCheckedMinLineAndBet}
						onChange={(value: string) =>  onChangeField((`${value ? Math.floor(Number(value)) : ''}`), 'lines')}
					/>
				</Col>
			</Row>
			{showLineAndDenominationCheckbox && (
				<Row>
					<Col span={24}>
						<FormCheckbox
							label={lang.minPerLineAndMinLine}
							name="forced"
							checked={isCheckedMinLineAndBet}
							onChange={handleCheck}
							disabled={disabled}
						/>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default BetsAndDenominationUser;