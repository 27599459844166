import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import { uniqBy } from 'lodash';

const gamesList				= (state) => state.Casino.Games.get('gamesList');
const gamesListAutoComplete	= (state) => state.Casino.Games.get('gamesListAutoComplete');
const gamesListInModal		= (state) => state.Casino.Games.get('gamesListInModal');
const gamesListUI			= (state) => state.Casino.Games.get('UI');
const gamesListFilter		= (state) => state.Casino.Games.get('filter');
const gamesListPagination	= (state) => state.Casino.Games.get('pagination');
const gameCategoryList		= (state) => state.Casino.GameCategories.get('entities');
// bulk

const gameBulk = (state) => state.Casino.CasinoGameBulk.get('baseData');

// Simple Selectors -------------------------------------------------------------------------------
export const deriveGamesList = createSelector(
	[gamesList],
	(gamesList) => uniqBy(gamesList, 'id')
	// TODO  remove uniqby after BE duplicated game error fix
);
export const deriveGamesListAutoComplete = createSelector(
	[gamesListAutoComplete],
	(gamesListAutoComplete) => uniqBy(gamesListAutoComplete, 'id')
);

export const deriveGamesCategoriesList = createSelector(
	[gameCategoryList],
	(gameCategoryList) => {
		const gamesList = Object.values(gameCategoryList);
		gamesList.unshift({ id: 0, name: 'No Category' });
		return gamesList;
	}
);

export const deriveGamesInModal = createSelector(
	[gamesListInModal],
	(gamesListInModal) => gamesListInModal
);


export const deriveGamesListAlphabetical = createSelector(
	[gamesList],
	(gamesList) => sortBy(gamesList, ['name'])
);

export const deriveGamesEntities = createSelector(
	[gamesList],
	(gamesList) => gamesList.reduce((entities, item) => {
		const { id } = item;
		entities[id] = item;
		return entities;
	}, {})
);

export const deriveGamesListUI = createSelector(
	[gamesListUI],
	(UI) => UI
);

// bulk

export const deriveGamesIDsBulk = createSelector(
	[gameBulk],
	(gameBulk) => gameBulk.bulkGameIDs
);

export const deriveCasinoGamesListFilter = createSelector(
	[gamesListFilter],
	(filter) => filter
);

export const deriveCasinoGamesPagination = createSelector(
	[gamesListPagination],
	(pagination) => pagination
);

