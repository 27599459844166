import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toInteger from 'lodash/toInteger';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import FormSelect from '../../FormControls/FormSelect';

import currencyActions from '../../../redux/settings/currency/actions';
import { deriveCurrencyList } from '../../../selectors/currency';

import { Wrapper } from './CurrencySelect.style';

class CurrencySelect extends Component {

	static propTypes = {
		currencyID    : PropTypes.number,
		disabled      : PropTypes.bool,
		noAutocomplete: PropTypes.bool,
		dataSource    : PropTypes.array,
		onChange      : PropTypes.func,
		onAutoComplete: PropTypes.func,
		// Redux
		currencyList  : PropTypes.array,
		listReload    : PropTypes.func.isRequired,
	};

	static defaultProps = {
		currencyID    : null,
		dataSource    : null,
		disabled      : false,
		noAutocomplete: false,
		currencyList  : [],
		onChange      : () => {},
		onAutoComplete: () => {},
	};

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		const { currencyList, noAutocomplete, listReload, onAutoComplete } = this.props;
		if (!isArray(currencyList) || isEmpty(currencyList)) {
			listReload();

		} else {
			const currency = currencyList[0];
			if (!noAutocomplete) {
				onAutoComplete(toInteger(currency.id));
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { currencyList, noAutocomplete, onAutoComplete } = this.props;
		const prevCurrencyList = prevProps.currencyList;
		if (isEqual(currencyList, prevCurrencyList)) {
			return;
		}

		if (isArray(currencyList) && !isEmpty(currencyList)) {
			const currency = currencyList[0];
			if (!noAutocomplete) {
				onAutoComplete(toInteger(currency.id));
			}
		}
	}

	onChange(currencyID) {
		const { onChange } = this.props;
		onChange(toInteger(currencyID));
	}

	render() {
		const { currencyID, disabled, currencyList, dataSource } = this.props;
		const list = isArray(dataSource) ? dataSource : currencyList;

		return (
			<Wrapper>
				<FormSelect
					labelWidth={0}
					value={currencyID}
					disabled={disabled}
					optionsList={list}
					onChange={this.onChange}
				/>
			</Wrapper>
		);
	}
}

function mapStateToProps(state) {

	return {
		currencyList: deriveCurrencyList(state),
	};
}

export default connect(mapStateToProps, {
	listReload: currencyActions.currencyListReload,
})(CurrencySelect);
