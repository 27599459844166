import { combineReducers } from 'redux';

import Bonuses from './bonuses/reducer';
import UserBonus from './userBonus/reducer';
import FirstDeposit from './firstDeposit/reducer';
import WelcomeBonus from './welcomeBonus/reducer';
import Cashback from './cashback/reducer';
import MassBonus from './massBonus/reducer';

import UserCasinoBonuses from './userCasinoBonuses/reducer';
import UserCasinoBonusesFilter from './userCasinoBonuses/Filter/reducer';
import UserCasinoFreeSpins from './userCasinoFreeSpins/reducer';
import UserCasinoFreeSpinsFilter from './userCasinoFreeSpins/Filter/reducer';
import UserFreeBets from './userFreeBets/reducer';
import UserMassBonus from './userMassBonus/reducer';

export default combineReducers({
	Bonuses,
	UserBonus,
	FirstDeposit,
	WelcomeBonus,
	Cashback,
	MassBonus,

	UserCasinoBonuses,
	UserCasinoFreeSpins,
	UserFreeBets,
	UserMassBonus,
	
	UserCasinoBonusesFilter,
	UserCasinoFreeSpinsFilter,
});
