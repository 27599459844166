import React from 'react';
import * as PropTypes from 'prop-types';
import Utils from '../FilterUtils';
import FormInputNumberTextType from '../../../components/FormControls/FormInputNumberTextType';

const InputNumberTypeText = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, placeholder, defaultValue, disabled, validationParams } = filterItem;

	return (
		<FormInputNumberTextType
			name={name}
			validationParams={validationParams}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			defaultValue={defaultValue || null}
			value={value}
			disabled={disabled}
			placeholder={placeholder}
			onChange={onChange}
		/>
	);
};

InputNumberTypeText.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};


export default InputNumberTypeText;
