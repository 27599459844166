export const ACCEPTED_FILE_TYPES = {
	PDF : 'application/pdf',
	PDF2: '.pdf',
	JPEG: 'image/jpeg',
	JPG : 'image/jpg',
	PNG : 'image/png',
	DOC : 'application/msword',
	DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	XLS : 'application/vnd.ms-excel',
	XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	GIF : 'image/gif',
	ZIP : 'application/x-zip-compressed',
	TXT : 'text/plain',
};

export const inboxAcceptedFileTypes = [
	ACCEPTED_FILE_TYPES.PNG,
	ACCEPTED_FILE_TYPES.JPG,
	ACCEPTED_FILE_TYPES.JPEG,
	ACCEPTED_FILE_TYPES.PDF,
	ACCEPTED_FILE_TYPES.DOC,
	ACCEPTED_FILE_TYPES.DOCX,
	ACCEPTED_FILE_TYPES.XLS,
	ACCEPTED_FILE_TYPES.XLSX,
	ACCEPTED_FILE_TYPES.GIF,
	ACCEPTED_FILE_TYPES.ZIP,
	ACCEPTED_FILE_TYPES.TXT,
];