const actions = {

	SPORT_RISK_MANAGMENT_LOG_MODELS_DATA_RELOAD : 'SPORT_RISK_MANAGMENT_LOG_MODELS_DATA_RELOAD',
	SPORT_RISK_MANAGMENT_LOG_MODELS_DATA_REFRESH: 'SPORT_RISK_MANAGMENT_LOG_MODELS_DATA_REFRESH',
	SPORT_RISK_MANAGMENT_LOG_MODELS_UI_REFRESH  : 'SPORT_RISK_MANAGMENT_LOG_MODELS_UI_REFRESH',

	dataReload: (id, logModel) => ({
		type: actions.SPORT_RISK_MANAGMENT_LOG_MODELS_DATA_RELOAD,
		data: {
			id,
			logModel,
		},
	}),
	dataRefresh: (logData, logModel) => ({
		type: actions.SPORT_RISK_MANAGMENT_LOG_MODELS_DATA_REFRESH,
		data: {
			logData,
			logModel,
		},
	}),
	uiRefresh: data => ({
		type: actions.SPORT_RISK_MANAGMENT_LOG_MODELS_UI_REFRESH,
		data,
	}),
};

export default actions;
