
import {
	BookOutlined,
	CheckOutlined, CloseOutlined, CopyOutlined, CreditCardOutlined,
	DeleteOutlined, DoubleLeftOutlined, DoubleRightOutlined, DownloadOutlined,
	EditOutlined, ExceptionOutlined, EyeOutlined, InfoCircleOutlined, LeftOutlined,
	MinusCircleOutlined, NotificationOutlined,
	PlusOutlined, ReloadOutlined, RightOutlined, RollbackOutlined,
	SaveOutlined, SearchOutlined, SendOutlined, SettingOutlined, SwapOutlined,
} from '@ant-design/icons';

interface IIcons {
    check        : JSX.Element;
    edit         : JSX.Element;
    remove       : JSX.Element;
    plus         : JSX.Element;
    save         : JSX.Element;
    minusOutlined: JSX.Element;
    exception    : JSX.Element;
    settings     : JSX.Element;
    swap         : JSX.Element;
    doubleRight  : JSX.Element;
    right        : JSX.Element;
    left         : JSX.Element;
    doubleLeft   : JSX.Element;
    eye          : JSX.Element;
    copy         : JSX.Element;
    rollBack     : JSX.Element;
    close        : JSX.Element;
    notification : JSX.Element;
    reload       : JSX.Element;
    bookMark     : JSX.Element;
    send         : JSX.Element;
    creditCard   : JSX.Element;
    downLoad     : JSX.Element;
    search       : JSX.Element;
    info         : JSX.Element;
}

export const Icons: IIcons = {
	check        : <CheckOutlined/>,
	edit         : <EditOutlined/>,
	remove       : <DeleteOutlined/>,
	plus         : <PlusOutlined/>,
	minusOutlined: <MinusCircleOutlined/>,
	save         : <SaveOutlined/>,
	exception    : <ExceptionOutlined/>,
	settings     : <SettingOutlined/>,
	swap         : <SwapOutlined/>,
	doubleRight  : <DoubleRightOutlined/>,
	right        : <RightOutlined/>,
	left         : <LeftOutlined/>,
	doubleLeft   : <DoubleLeftOutlined/>,
	eye          : <EyeOutlined/>,
	copy         : <CopyOutlined/>,
	rollBack     : <RollbackOutlined/>,
	close        : <CloseOutlined/>,
	notification : <NotificationOutlined/>,
	reload       : <ReloadOutlined/>,
	bookMark     : <BookOutlined/>,
	send         : <SendOutlined/>,
	creditCard   : <CreditCardOutlined/>,
	downLoad     : <DownloadOutlined />,
	search       : <SearchOutlined />,
	info         : <InfoCircleOutlined />,
};
