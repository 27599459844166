import styled from 'styled-components';
import { Icon } from 'react-icons-kit';

export const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
  margin: 16px 0;
  & > .form-control {
    width: 30%;
		height: 32px;	

		.label {
				display: none;
		}	
			
		.searchGame {
			 padding: 8px 10px;
		}
  }
`;

export const Info = styled.span`
  font-size: 14px;
  border: 1px solid #FAAD14;
  padding: 2px 10px;
  border-radius: 4px;
  background: rgba(255 243 220 / 79%);
  display: flex;
  align-items: center;
  color: #595959;
`;

export const IconWarning = styled(Icon)`
  margin-right: 4px;

  & svg {
    width: 20px;
    height: 20px;
    color: #FAAD14;
  }
`;

export const SwitchContainer = styled.div`
    display: flex;
		align-items: center;
    flex-direction: row;
		gap: 1em;

    & > .ant-switch {
        background-color: rgb(239, 20, 20);
				width: 20px;
    }

    & > .ant-switch-checked {
        background-color: #4482FF;
    }
`;

export const InfiniteScrollWrapper = styled.div`
	overflow-y: auto;
	height: 30vh;
    margin-top: 10px;
    
  > div {
      display: inline-block;
      width: 100%;
  }
`;
