import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import actions from './actions';
import tabActions from '../../appTabs/actions';
import externalSaga from './externalSaga';

import { adaptMessagesList } from './utils';

import { messagesAPI } from '../../../helpers/api/messages';
import { socketsMessagesAPI } from '../../../helpers/sockets/api/messages';
import { createError } from '../../../helpers/sockets/socket';

// import { getHeadersTotalCount } from '../../../helpers/utils';
import notifications from '../../../helpers/notifications';
import { isValidEmail } from '../../../containers/Partner/Websites/modals/Websites/TabIntegration/utils/utils';
import { logger } from '../../../helpers/logger';

const prefix = 'notifcenter.messages';

const messages = {
	errorSocket           : `${prefix}.errorSocket`,
	errorListReload       : `${prefix}.errorListReload`,
	errorSubscribeUpdate  : `${prefix}.errorSubscribeUpdate`,
	errorSubscribeDelete  : `${prefix}.errorSubscribeDelete`,
	errorSubscribeCreate  : `${prefix}.errorSubscribeCreate`,
	errorUpdateMessage    : `${prefix}.errorUpdateMessage`,
	successUpdateMessage  : `${prefix}.successUpdateMessage`,
	successSubscribeUpdate: `${prefix}.successSubscribeUpdate`,
};

function getStoreData({ Messages, App }) {
	return {
		messagesIDs : Messages.get('messagesIDs'),
		entities    : Messages.get('entities'),
		pagination  : Messages.get('pagination'),
		newMessage  : Messages.get('newMessage'),
		selectedMail: Messages.get('selectedMail'),
		websiteID   : App.get('websiteID'),
	};
}

function* listReload() {

	yield takeEvery(actions.MESSAGES_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		let messagesIDs    = [];
		let entities       = {};
		let newMessagesIDs = [];
		let newEntities    = {};
		try {
			const response = yield call(messagesAPI.messagesRequests);
			if (response && response.status === 200) {
				const result = adaptMessagesList(response.data.data);
				messagesIDs    = result.messagesIDs;
				entities       = result.entities;
				newMessagesIDs = result.newMessagesIDs;
				newEntities    = result.newEntities;
			}
		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			logger.log(error);
		}

		yield put(actions.dataRefresh(messagesIDs, entities));
		yield put(actions.newMessagesListRefresh(newMessagesIDs, newEntities));
		yield put(actions.uiRefresh({ loading: false }));
		yield put(actions.subscribeToUpdate(messagesIDs));
	});
}

function* filterApply() {
	yield takeEvery(actions.MESSAGES_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

function* selectMail() {
	yield takeEvery(actions.MESSAGES_SELECTED_MAIL, function* () {
		const { selectedMail } = yield select(getStoreData);
		yield put(tabActions.openTabMessages(selectedMail));
	});
}

function* changeComposeMail() {
	yield takeEvery(actions.MESSAGES_COMPOSE_MAIL, function* (action) {
		yield put(tabActions.openTabMessages(action.composeMail));
	});
}

function* newMessageSend() {
	yield takeEvery(actions.MESSAGES_NEW_MESSAGE_SEND, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { newMessage, websiteID } = yield select(getStoreData);
		const { subject, to } = newMessage;

		try {
			if (isEmpty(to) || !subject) {
				notifications.showWarning('Message must have a subject and a body. Check it, please');
				return;
			}
			if (!isValidEmail(to)) {
				notifications.showError('Not valid mail');
			}

			const params = { return_info: true };
			const res = yield call(messagesAPI.sendRequest, { ...newMessage, website_id: websiteID }, params);

			if (res && res.status === 200) {
				notifications.showSuccess('Message has been sent');
				yield put(
					actions.newMessageMerge({
						to         : [],
						body       : '',
						subject    : '',
						bucket     : 'Sent',
						no_reply   : false,
						attachments: [],
					})
				);
				yield put(actions.changeComposeMail(false));
			}
		} catch (error) {
			notifications.showError('Sending message failed', error);
		}

		yield put(actions.listReload());
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* replyToMessage() {
	yield takeEvery(actions.MESSAGES_REPLY_TO_MESSAGE, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { newMessage, selectedMail } = yield select(getStoreData);

		try {
			const params = { return_info: true };
			const res = yield call(messagesAPI.sendMessage, selectedMail, newMessage, params);

			if (res && res.status === 200) {
				notifications.showSuccess('Message has been sent');
				yield put(
					actions.newMessageMerge({
						to         : [],
						body       : '',
						subject    : '',
						bucket     : 'Sent',
						no_reply   : false,
						attachments: [],
					})
				);
				yield put(actions.changeReplyMail(false));
			}
		} catch (error) {
			notifications.showError('Sending message failed', error);
		}

		yield put(actions.listReload());
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* messageDelete() {

	yield takeEvery(actions.MESSAGES_MESSAGE_DELETE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { selectedMail } = yield select(getStoreData);
		try {
			const res = yield call(messagesAPI.deleteMessage, selectedMail);
			if (res) {
				notifications.showSuccess('Message request has been deleted');
				yield put(actions.listReload());
			}

		} catch (error) {
			notifications.showError('Delete message failed', error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* subscribeToUpdate() {

	yield takeEvery(actions.MESSAGES_SOCKET_SUBSCRIBE_TO_UPDATE, function* (action) {

		const { messagesIDs } = action.data;
		try {
			const response = yield call(socketsMessagesAPI.subscribeToUpdate, messagesIDs);
			if (response) {
				if (response.isSuccess) {
					//notifications.showSuccess(messages.successSubscribeUpdate);
				} else {
					const error = createError(response);
					throw error;
				}
			}
		} catch (error) {
			notifications.showError(messages.errorSubscribeUpdate, error);
			logger.log(error);
		}
	});
}


export default function* messagesSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(newMessageSend),
		fork(replyToMessage),
		fork(selectMail),
		fork(messageDelete),
		fork(changeComposeMail),
		fork(subscribeToUpdate),

		fork(externalSaga),
	]);
}
