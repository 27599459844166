import styled from 'styled-components';
import WithDirection from '../../../config/withDirection';

const TimeRangeWrapper = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .ant-time-picker {
    width: auto;
  }

  .number-box {
    max-width: 50%;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
  .ant-time-picker-panel{
    width: fit-content;
  }
  .time-range .ant-time-picker-panel-combobox {
		margin-top: 22px;
	}

	.time-range .ant-time-picker-panel-addon {
		position: absolute;
		top: 35px;
		display: flex;
		justify-content: space-between;
    width: 100%;
    padding-left: 8px;
	}
`;

export default WithDirection(TimeRangeWrapper);
