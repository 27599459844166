import styled from 'styled-components';
import { palette } from 'styled-theme';

export const Wrapper = styled.div`

    .ant-table-row {
        td {
            padding-top: 8px;
            padding-bottom: 8px;

            a {
                color: ${palette('text', 1)};
                text-decoration: none;
            }
        }
    }
`;

export const Cell = styled.div`
	color: ${({ color }) => color};	
`;

export const WrapperCard = styled.div`
	margin-top:10px;	
`;
