const actions = {

	MASS_BONUS_LIST_RELOAD     	: 'MASS_BONUS_LIST_RELOAD',
	MASS_BONUS_LIST_REFRESH     : 'MASS_BONUS_LIST_REFRESH',
	MASS_BONUS_BASE_DATA_REFRESH: 'MASS_BONUS_BASE_DATA_REFRESH',
		
	MASS_BONUS_UI_REFRESH: 'MASS_BONUS_UI_REFRESH',
	MASS_BONUS_DATA_RESET: 'MASS_BONUS_DATA_RESET',
	
	MASS_BONUS_FILTER_REFRESH: 'MASS_BONUS_FILTER_REFRESH',
	MASS_BONUS_FILTER_RESET  : 'MASS_BONUS_FILTER_RESET',
	MASS_BONUS_GET_ITEM_BY_ID: 'MASS_BONUS_GET_ITEM_BY_ID',
	
	listReload: data => ({
		type: actions.MASS_BONUS_LIST_RELOAD,
		data,
	}),
	getItemByID: bonusID => ({
		type: actions.MASS_BONUS_GET_ITEM_BY_ID,
		bonusID,
	}),
	listRefresh: list => ({
		type: actions.MASS_BONUS_LIST_REFRESH,
		data: list,
	}),
	baseDataRefresh: data => ({
		type: actions.MASS_BONUS_BASE_DATA_REFRESH,
		data: data,
	}),
	uiRefresh: data => ({
		type: actions.MASS_BONUS_UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.MASS_BONUS_DATA_RESET,
	}),
	filterRefresh: filter => ({
		type: actions.MASS_BONUS_FILTER_REFRESH,
		filter,
	}),
	filterReset: () => ({
		type: actions.MASS_BONUS_FILTER_RESET,
	}),
};

export default actions;
