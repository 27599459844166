import EntityAdapter from '../../../helpers/entityAdapter';
import { WebsiteUserShowRequiredFields } from '../../partner/websites/website/types';

const fields = {
	email               : 'email',
	phone               : 'phone',
	currencyID          : 'currency_id',
	password            : 'password',
	nationalID          : 'national_id',
	userName            : 'username',
	firstName           : 'first_name',
	lastName            : 'last_name',
	gender              : 'gender',
	birth               : 'birth',
	statusID            : 'status_id',
	country             : 'country_id',
	languageID          : 'language_id',
	city                : 'city',
	address1            : 'address_1',
	address2            : 'address_2',
	affiliateReference  : 'affiliate_reference',
	passwordConfirmation: 'password_confirmation',

	// params

	websiteID: 'website_id',
};

const baseDataAdapter     = createBaseDataAdapter();
const paramsAdapter     = createParamsAdapter();

// Adapt ------------------------------------------------------------------------------------------


export function userCreatePrepare(rawData = {}, params) {
	baseDataAdapter.clearExcludes();
	const prepareData = baseDataAdapter.prepare(rawData);
	const data = Object.keys(prepareData).reduce((acc, item) => {
		if (prepareData[item]) {
			acc[item] = prepareData[item];
			return acc;
		}
		return acc;
	}, {});

	const paramsData = paramsAdapter.prepare(params);

	return {
		data,
		paramsData,
	};
}

// Adapters ---------------------------------------------------------------------------------------

function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.string, WebsiteUserShowRequiredFields.EMAIL, fields.email);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.PHONE, fields.phone);
	adapter.addField(rules.number, WebsiteUserShowRequiredFields.CURRENCY_ID, fields.currencyID);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.PASSWORD, fields.password);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.NATIONAL_ID, fields.nationalID);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.USERNAME, fields.userName);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.FIRST_NAME, fields.firstName);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.LAST_NAME, fields.lastName);
	adapter.addField(rules.number, WebsiteUserShowRequiredFields.GENDER, fields.gender);
	adapter.addField(rules.date, WebsiteUserShowRequiredFields.BIRTH, fields.birth);
	adapter.addField(rules.number, WebsiteUserShowRequiredFields.STATUS_ID, fields.statusID);
	adapter.addField(rules.number, WebsiteUserShowRequiredFields.COUNTRY, fields.country);
	adapter.addField(rules.number, WebsiteUserShowRequiredFields.LANGUAGE_ID, fields.languageID);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.CITY, fields.city);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.ADDRESS_1, fields.address1);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.ADDRESS_2, fields.address2);
	adapter.addField(rules.string, WebsiteUserShowRequiredFields.AFFILIATE_REFERENCE, fields.affiliateReference);

	return adapter;
}

function createParamsAdapter() {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.string, 'websiteID', fields.websiteID);

	return adapter;
}
