const actions = {

	CASINO_PROVIDERS_LIST_RELOAD : 'CASINO_PROVIDERS_LIST_RELOAD',
	CASINO_PROVIDERS_LIST_REFRESH: 'CASINO_PROVIDERS_LIST_REFRESH',

	CASINO_INTEGRATORS_PROVIDERS_LIST_RELOAD_GAMES : 'CASINO_INTEGRATORS_PROVIDERS_LIST_RELOAD_GAMES',
	CASINO_INTEGRATORS_PROVIDERS_LIST_REFRESH_GAMES: 'CASINO_INTEGRATORS_PROVIDERS_LIST_REFRESH_GAMES',

	CASINO_PROVIDERS_UI_REFRESH: 'CASINO_PROVIDERS_UI_REFRESH',

	listReload: (isPartnerCasinoList) => ({
		type: actions.CASINO_PROVIDERS_LIST_RELOAD,
		isPartnerCasinoList,
	}),
	listRefresh: entities => ({
		type: actions.CASINO_PROVIDERS_LIST_REFRESH,
		data: {
			entities,
		},
	}),

	listReloadGames: data => ({
		type: actions.CASINO_INTEGRATORS_PROVIDERS_LIST_RELOAD_GAMES,
		data,
	}),

	listRefreshGames: data => ({
		type: actions.CASINO_INTEGRATORS_PROVIDERS_LIST_REFRESH_GAMES,
		data,
	}),

	uiRefresh: data => ({
		type: actions.CASINO_PROVIDERS_UI_REFRESH,
		data,
	}),
};

export default actions;
