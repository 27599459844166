import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

const casinoList               = (state) => state.Casino.List.get('list');
const casinoUI               	= (state) => state.Casino.List.get('UI');
const casinoEntities           = (state) => state.Casino.List.get('entities');
const providersEntities        = (state) => state.Casino.Providers.get('entities');
const providersEntitiesGames   = (state) => state.Casino.Providers.get('entitiesGames');
const tagsEntities             = (state) => state.Casino.Tags.get('entities');
const integratorsEntities      = (state) => state.Casino.Integrators.get('entities');
const integratorsFilteredEntities = (state) => state.Casino.Integrators.get('filteredEntities');
const integratorsGamesEntities = (state) => state.Casino.Providers.get('gamesByProviders');
const customProvidersEntities  = (state) => state.Casino.CustomProviders.get('entities');

// Casino -----------------------------------------------------------------------------------------
export const deriveCasinoEntities = createSelector(
	[casinoEntities],
	(entities) => entities
);

export const deriveCasinoList = createSelector(
	[casinoList],
	(entities) => sortBy(values(entities), ['id'])
);

export const deriveCasinoUI = createSelector(
	[casinoUI],
	(entities) => entities
);


// Providers --------------------------------------------------------------------------------------
export const deriveCasinoProvidersEntities = createSelector(
	[providersEntities],
	(entities) => entities
);

export const deriveCasinoProvidersList = createSelector(
	[deriveCasinoProvidersEntities],
	(entities) => sortBy( values(entities), ['id'] )
);
// Providers Games--------------------------------------------------------------------------------------

export const deriveCasinoProvidersEntitiesGames = createSelector(
	[providersEntitiesGames],
	(entities) => entities
);

export const deriveCasinoProvidersListGames = createSelector(
	[deriveCasinoProvidersEntitiesGames],
	(entities) => sortBy( values(entities), ['id'] )
);

// Tags

export const deriveCasinoTagsEntities = createSelector(
	[tagsEntities],
	(entities) => entities
);

export const deriveCasinoTagsList = createSelector(
	[deriveCasinoTagsEntities],
	(entities) => sortBy( values(entities), ['id'] )
);

// Tags

export const deriveCasinoIntegratorsEntities = createSelector(
	[integratorsEntities],
	(entities) => entities
);

export const deriveCasinoIntegratorsFilteredEntities = createSelector(
	[integratorsFilteredEntities],
	(entities) => entities
);

export const deriveCasinoIntegratorsGamesEntities = createSelector(
	[integratorsGamesEntities],
	(games) => games
);

export const deriveCasinoIntegratorsList = createSelector(
	[deriveCasinoIntegratorsEntities],
	(entities) => sortBy( values(entities), ['id'] )
);

//CustomProviders
export const customProvidersList = createSelector(
	[customProvidersEntities],
	(entities) => sortBy( values(entities), ['orderNumber'] )
);
