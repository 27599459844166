import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

function checkMultiselect( multiSelect, value, preValue ) {
	if ( multiSelect ) return preValue ? value : [];
	if (isUndefined(value)) return null;
	return value;
}

function findFromList( list, key ) {

	return list.find( listItem => listItem.value === key )?.id;

}

function createPlaceholder( value, searchLength, loading, messages, shouldLoadOnClick ) {
	let foundContent;

	if ( value.length >= searchLength && !loading ) foundContent = messages.nothing;
	// eslint-disable-next-line  no-mixed-operators
	else if ( value.length >= searchLength || shouldLoadOnClick && value.length === 0 ) foundContent = messages.loading;
	else foundContent = messages.requiredLength(searchLength);

	return foundContent;
}

function ShowSpinnerOption({ loading = true, icon, message }) {
	return (
		<div style={{ width: '100%', display: loading ? 'flex' : 'none', justifyContent: 'center', marginTop: '8px' }}>
			{message}
			{icon}
		</div>
	);
}

// hook -------------------------------------------------------------------------------------------

function useOnScreen(ref) {

	const [isIntersecting, setIntersecting] = useState(false);

	const observer = new IntersectionObserver(
		([entry]) => setIntersecting(entry.isIntersecting)
	);

	useEffect(() => {
		observer.observe(ref.current);
		// Remove the observer as soon as the component is unmounted
		return () => { observer.disconnect(); };
	// eslint-disable-next-line
	}, []);

	return isIntersecting;
}

// const ------------------------------------------------------------------------------------------

const messages = {
	nothing       : 'There is nothing in database with your search',
	loading       : 'let\'s see what we can find',
	requiredLength: (letterLength) => (`Type at least ${letterLength} letters`),
};


ShowSpinnerOption.propTypes = {
	loading: PropTypes.bool.isRequired,
	message: PropTypes.string,
	icon   : PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

ShowSpinnerOption.defaultProps = {
	icon   : '',
	message: null,
};

export { checkMultiselect, findFromList, createPlaceholder, ShowSpinnerOption, useOnScreen, messages };
