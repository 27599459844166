import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import actions from './actions';
import externalSaga from './externalSaga';
import tableActions from '../../tables/actions';
import userWithdrawalsActions from '../userWithdrawals/actions';

import { adaptWithdrawalList, getListParams } from './utils';

import notifications from '../../../helpers/notifications';
import { withdrawalsAPI } from '../../../helpers/api/withdrawals';
import { withdrawalsAPI as socketsWithdrawalsAPI } from '../../../helpers/sockets/api/withdrawals';
import { getHeadersTotalCount } from '../../../helpers/utils';
import { createError } from '../../../helpers/sockets/socket';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import socketActions from '../../socket/actions';
import { logger } from '../../../helpers/logger';

const prefix = 'rtm.withdrawals';

const messages = {
	errorListReload       : `${prefix}.errorListReload`,
	errorAccept           : `${prefix}.errorAccept`,
	errorDecline          : `${prefix}.errorDecline`,
	errorSubscribeUpdate  : `${prefix}.errorSubscribeUpdate`,
	successAccept         : `${prefix}.successAccept`,
	successDecline        : `${prefix}.successDecline`,
	successSubscribeUpdate: `${prefix}.successSubscribeUpdate`,
	errorGetTable         : `${prefix}.errorGetTable`,
	errorPostTable        : `${prefix}.errorPostTable`,
};
const tableType           = TABLE_TYPES.withdrawRTM;

function getStoreData(state) {
	const { RTM, Tables, AppTabs } = state;
	return {
		filter                 : RTM.Withdrawals.get('filter'),
		sorting                : Tables.get(tableType).sorting,
		pagination             : deriveTablePagination(tableType, state),
		activeTabID            : AppTabs.get('activeTabID'),
		withdrawalsEntities    : RTM.Withdrawals.get('entities'),
		userWithdrawalsEntities: RTM.UserWithdrawals.get('entities'),
	};
}

function* listReload() {

	yield takeEvery(actions.RTM_WITHDRAWALS_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination, activeTabID } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		let withdrawalIDs    = [];
		let entities         = {};
		let withdrawalTotals = {};
		let totalCount       = 0;
		try {
			const response = yield call(withdrawalsAPI.rtmWithdrawalsList, params);
			if (response && response.status === 200) {
				const result     = adaptWithdrawalList(response.data.data);
				withdrawalIDs    = result.withdrawalIDs;    // eslint-disable-line prefer-destructuring
				entities         = result.entities;         // eslint-disable-line prefer-destructuring
				withdrawalTotals = result.withdrawalTotals; // eslint-disable-line prefer-destructuring
				totalCount       = getHeadersTotalCount(response.headers) || withdrawalIDs.length;
				yield put(actions.dataRefresh(withdrawalIDs, entities, withdrawalTotals));
				yield put(socketActions.rtmSubscribe(activeTabID, withdrawalIDs));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));

		} catch (error) {
			notifications.showError(messages.errorListReload, error);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* filterApply() {
	yield takeEvery(actions.RTM_WITHDRAWALS_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

function* subscribeToUpdate() {

	yield takeEvery(actions.RTM_WITHDRAWALS_SOCKET_SUBSCRIBE_TO_UPDATE, function* (action) {

		const { withdrawalIDs } = action.data;
		try {
			const response = yield call(
				socketsWithdrawalsAPI.subscribeToUpdate,
				withdrawalIDs
			);
			if (response) {
				if (response.isSuccess) {
					//notifications.showSuccess(messages.successSubscribeUpdate);
				} else {
					throw createError(response);
				}
			}
		} catch (error) {
			notifications.showError(messages.errorSubscribeUpdate, error);
			logger.log(error);
		}
	});
}

function* withdrawalApprove() {

	yield takeEvery(actions.RTM_WITHDRAWALS_ITEM_APPROVE, function* (action) {

		const { wdID, reason: approveReason, reasonForAdmin, reasonForUser, approved, userID } = action.data;
		const { withdrawalsEntities, userWithdrawalsEntities } = yield select(getStoreData);
		const withdrawalItem = userID ? userWithdrawalsEntities[wdID] : withdrawalsEntities[wdID];
		withdrawalItem.isLoading = true;

		if (userID) {
			yield put(userWithdrawalsActions.userWithdrawalItemRefresh(wdID, withdrawalItem));
		} else {
			yield put(actions.withdrawalItemRefresh(wdID, withdrawalItem));
		}

		const data = {
			withdrawal_reason      : approveReason || reasonForUser,
			approve                : approved,
			admin_withdrawal_reason: reasonForAdmin,
		};

		const errorMessage   = approved ? messages.errorAccept : messages.errorDecline;
		const successMessage = approved ? messages.successAccept : messages.successDecline;

		try {
			const response = yield call(withdrawalsAPI.withdrawApprove, wdID, data);

			if (response && response.status === 200) {
				notifications.showSuccess(successMessage);
				withdrawalItem.wdStatusID = response.data.data.status_id;
			}
		} catch (error) {
			notifications.showError(errorMessage);
		} finally {
			withdrawalItem.isLoading = false;
			withdrawalItem.wdDecisionReasonAdmin = reasonForAdmin;
			withdrawalItem.wdDecisionReasonUser = data.withdrawal_reason;

			if (userID) {
				yield put(userWithdrawalsActions.userWithdrawalItemRefresh(wdID, withdrawalItem));
			} else {
				yield put(actions.withdrawalItemRefresh(wdID, withdrawalItem));
			}
		}
	});
}

export default function* rtmWithdrawalsSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(subscribeToUpdate),
		fork(withdrawalApprove),

		fork(externalSaga),
	]);
}
