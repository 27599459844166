import React from 'react';
import IntlMessages from '../../../../../components/utility/intlMessages';

export const lang = {
	provider             : <IntlMessages id="bonuses.eligibility.provider" />,
	category             : <IntlMessages id="bonuses.eligibility.category" />,
	integration          : <IntlMessages id="bonuses.eligibility.integration" />,
	tags                 : <IntlMessages id="bonuses.eligibility.tags" />,
	integrators          : <IntlMessages id="bonuses.eligibility.integrators" />,
	otherIntegratorsTitle: <IntlMessages id="bonuses.eligibility.integrator.title" />,
	noGame               : <IntlMessages id="bonuses.eligibility.category.noGame" />,
	warning              : <IntlMessages id="bonuses.freeSpins.eligibility.info" />,
	gameCount            : <IntlMessages id="bonuses.freeSpins.eligibility.gameCount" />,
	currentList          : <IntlMessages id="partner.modal.selectAllGames.title" />,
	selected             : <IntlMessages id="partner.modal.selectAllGames.selectedGames" />,
	selectedIntegrators  : <IntlMessages id="partner.modal.selectedIntegrators" />,
	selectIntegrator     : <IntlMessages id="partner.modal.selectIntegrator" />,
	selectedProviders    : <IntlMessages id="partner.modal.selectedProviders" />,
	selectedCategory     : <IntlMessages id="bonuses.eligibility.selectedCategory" />,
	noTagGame            : <IntlMessages id="bonuses.eligibility.tags.noTagGame" />,
};
