import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import toNumber from 'lodash/toNumber';
import endsWith from 'lodash/endsWith';

import NumberRangeWrapper from './numberRange.style';
import InputNumber from '../InputNumber';

class NumberRange extends Component {

	static propTypes = {
		value       : PropTypes.array,
		defaultValue: PropTypes.array,
		separator   : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		min         : PropTypes.number,
		step        : PropTypes.number,
		name        : PropTypes.string,
		onChange    : PropTypes.func,
	};

	static defaultProps = {
		value       : [],
		defaultValue: [],
		separator   : '',
		min         : 0,
		step        : 0,
		onChange    : null,
		name        : '',

	};

	constructor(props) {
		super(props);
		this.convertToNumber = this.convertToNumber.bind(this);
		this.changeFormField = this.changeFormField.bind(this);
		// this.onBlur          = this.onBlur.bind(this);
		this.changeState     = this.changeState.bind(this);

		this.state = {
			valueFrom: 0,
			valueTo  : 0,
		};
	}

	componentDidMount() {
		const { value, defaultValue } = this.props;

		// defined Value
		if (isArray(value) && value.length === 2) {
			const valueFrom = this.convertToNumber(value[0]);
			const valueTo   = this.convertToNumber(value[1]);
			this.setState({ valueFrom, valueTo });

			// defined DefaultValue
		} else if (isArray(defaultValue) && defaultValue.length === 2) {
			const valueFrom = this.convertToNumber(defaultValue[0]);
			const valueTo   = this.convertToNumber(defaultValue[1]);
			this.setState({ valueFrom, valueTo });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { value } = nextProps;

		if (isArray(value) && value.length === 2) {
			const valueFrom = this.convertToNumber(value[0]);
			const valueTo   = this.convertToNumber(value[1]);
			this.setState({ valueFrom, valueTo });
		}
	}

	// Service --------------------------------------------------------------------------------------
	convertToNumber(rawValue) {
		const { min, step } = this.props;
		if (rawValue === null || rawValue === undefined) {
			return null;
		}

		let reg = (step === 1) ? /^[0-9]*/ : /^[0-9]*\.?[0-9]*/;
		if (min && min < 0) {
			reg = (step === 1) ? /^-?[0-9]*/ : /^-?[0-9]*\.?[0-9]*/;
		}

		const resValue = String(rawValue).replace(',', '.');
		const matches = reg.exec(resValue);
		if (!matches) {
			return null;
		}

		let result = matches[0];
		if (result === '' || endsWith(result, '.0') || endsWith(result, '0')) {
			result += '';

		} else if (result !== '-' && !endsWith(result, '.')) {
			result = toNumber(result);
		}

		return result;
	}

	// Events ---------------------------------------------------------------------------------------
	changeFormField(fieldName, value) {
		this.setState({ [fieldName]: this.convertToNumber(value) }, () => {
			this.changeState();
		});
	}

	// onBlur() {
	//   const { valueFrom, valueTo } = this.state;
	//
	//   if (valueFrom && valueTo) {
	//     if (toNumber(valueFrom) > toNumber(valueTo)) {
	//       this.setState({
	//         valueFrom,
	//         valueTo: valueFrom,
	//       }, () => {
	//         this.changeState(valueTo, valueFrom);
	//       });
	//     } else {
	//       this.changeState(valueFrom, valueTo);
	//     }
	//   } else {
	//     this.changeState(valueFrom, valueTo);
	//   }
	// }

	changeState() {
		const { onChange } = this.props;
		const { valueFrom, valueTo } = this.state;

		const values = [valueFrom, valueTo];
		if (onChange) {
			onChange(values);
		}
	}

	// Renders --------------------------------------------------------------------------------------
	render() {

		const { separator, name } = this.props;
		const { valueFrom, valueTo } = this.state;

		return (
			<NumberRangeWrapper>
				<InputNumber
					{...this.props}
					value={valueFrom}
					name={name}
					className="number-box"
					onChange={value => this.changeFormField('valueFrom', value)}
					// onBlur={this.onBlur}
				/>
				{separator && <div>{separator}</div>}
				<InputNumber
					{...this.props}
					value={valueTo}
					name={name}
					className="number-box"
					onChange={value => this.changeFormField('valueTo', value)}
					// onBlur={this.onBlur}
				/>
			</NumberRangeWrapper>
		);
	}
}

export default NumberRange;
