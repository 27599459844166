import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import ReportBar from '../../../../../../components/toolbars/ReportBar';
import listActions from '../../../../../../redux/loyalty/bonuses/userMassBonus/list/actions';

export const ReportToolbar = () => {
	const [unselectAll, setUnselectAll] = useState(false);
	const dispatch		= useAppDispatch();
	const reportFields	= useAppSelector(state => state.Loyalty.Bonuses.UserMassBonus.UserMassBonusList.get('reportFields'));

	const loading		= useAppSelector(state => state.Loyalty.Bonuses.UserMassBonus.UserMassBonusList.get('UI')).reportLoading;

	const onDownload = () => {
		dispatch(listActions.reportDataReload());
	};
	const onChangeFields = (reportFields) => {
		setUnselectAll(reportFields.every(field => !field.checked));
		dispatch(listActions.reportFieldsRefresh(reportFields));

	};

	return (
		<ReportBar
			id="reportsUsersListMassBonus"
			reportFields={reportFields}
			loading={loading}
			onDownload={onDownload}
			onChangeFields={onChangeFields}
			disabled={unselectAll}
		/>
	);
};


