import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, Empty, Spin } from 'antd';
import { ic_error_outline_twotone as warningIcon } from 'react-icons-kit/md/ic_error_outline_twotone';
import { lang } from './lang';

import { IconWarning, InfiniteScrollWrapper, Info, Wrapper } from './TabEligabilityStyle';

import Tabs, { TabPane } from '../../../../../components/uielements/tabs';
import TabIntegrators from './TabIntegrators';
import TabTags from './TabTags';
import TabCategories from './TabCategories';
import gamesActions from '../../../../../redux/casino/games/actions';
import { Label } from './Components/components';
import { Switcher } from './Switcher';
import cloneDeep from 'lodash/cloneDeep';
import { deriveGamesListUI } from '../../../../../selectors/casinoGames';
import categoryActions from '../../../../../redux/casino/gameCategories/actions';
import InfiniteScroll from 'react-infinite-scroller';
import { LayoutContentStyle } from '../../../../../components/connected/NotificationBadge/NotificationBadge.style';
import Spinner from '../../../../../components/Spinner/Spinner';
import { debounce } from 'lodash';
import FormInput from '../../../../../components/FormControls/FormInput';

class Eligability extends Component {

	static propTypes = {
		readOnly                      : PropTypes.bool.isRequired,
		gamesList                     : PropTypes.array.isRequired,
		gamesSearchList               : PropTypes.array.isRequired,
		checkedGames                  : PropTypes.array.isRequired,
		dataRefresh                   : PropTypes.func.isRequired,
		uiRefresh                     : PropTypes.func.isRequired,
		gamesSearchListRefresh        : PropTypes.func.isRequired,
		gamesListResetSearchPagination: PropTypes.func.isRequired,
		setSearchValue                : PropTypes.func.isRequired,
		searchValue                   : PropTypes.string.isRequired,
		bonusData                     : PropTypes.object.isRequired,
		UI                        				: PropTypes.object.isRequired,
		allow                         : PropTypes.bool,
		keyTab                        : PropTypes.string.isRequired,
		fromLoyaltySystemBonusTab     : PropTypes.bool,
		showWarningIcon               : PropTypes.bool,
		websiteID                     : PropTypes.number,
		gamesListReload               : PropTypes.func.isRequired,
		gamesSearchListReload         : PropTypes.func.isRequired,
		dataReset                     : PropTypes.func.isRequired,
		gamesDataReset                : PropTypes.func.isRequired,
	};

	static defaultProps  = {
		allow                    : false,
		fromLoyaltySystemBonusTab: false,
		showWarningIcon          : true,
		websiteID                : null,
	};

	constructor(props) {
		super(props);
		this.onChangeAllow  			= this.onChangeAllow.bind(this);
		this.onSearch       			= this.onSearch.bind(this);
		this.onChangeSearch 			= this.onChangeSearch.bind(this);
		this.renderSearchCheckAll = this.renderSearchCheckAll.bind(this);
		this.onCheckAllSearch 		= this.onCheckAllSearch.bind(this);
		this.renderRow 						= this.renderRow.bind(this);
		this.loadMore							= this.loadMore.bind(this);
		this.debouncedChange      = debounce(this.onSearch, 300);
	}

	componentWillUnmount() {
		const { dataReset, gamesDataReset, withReset = true } = this.props;
		if (withReset) {
			gamesDataReset();
		}
		dataReset();
	}

	onChangeAllow(checked) {
		const { dataRefresh, uiRefresh } = this.props;
		dataRefresh({
			allow: checked,
		});
		uiRefresh({ isChanged: true });
	}

	onSearch(value) {
		if (!value) return;

		const { websiteID, gamesSearchListReload, bonusData } = this.props;
		const byCurrency = !!bonusData.currencyID;
		gamesSearchListReload({ byCurrency, currency: bonusData.currencyID, name: value, websiteID });
	}

	onChangeSearch(event) {
		const { value } = event.target;
		const { gamesSearchListRefresh, gamesListResetSearchPagination, setSearchValue } = this.props;

		setSearchValue(value);
		gamesSearchListRefresh(null);
		gamesListResetSearchPagination();

		if (value.length >= 3) {
			this.debouncedChange(value);
		}
	}

	onCheckAllSearch ({ target }) {
		const { checked } = target;

		const { gamesSearchList, bonusData, dataRefresh, uiRefresh } = this.props;
		const clonedBonusData = cloneDeep(bonusData);
		const currentListIDs = gamesSearchList.map(game => game.id);
		if (checked) {
			clonedBonusData.gameIDs = clonedBonusData.gameIDs.concat(currentListIDs);
		} else {
			clonedBonusData.gameIDs = clonedBonusData.gameIDs.filter(gameID => !currentListIDs.includes(gameID) );
		}

		dataRefresh(clonedBonusData);
		uiRefresh({ isChanged: true });
	}
	renderSearchCheckAll () {
		const { bonusData, gamesSearchList } = this.props;
		const checkedAll = gamesSearchList.every(game => bonusData.gameIDs.includes(game.id));
		return (
			<Checkbox
				onChange={this.onCheckAllSearch}
				checked={checkedAll}
			/>
		);
	}
	renderRow (item) {
		const { dataRefresh, bonusData, uiRefresh, readOnly, singleGameMode } = this.props;
		const game = item;
		const clonedBonusData = cloneDeep(bonusData);
		const onCheckGame = ({ target }) => {
			const { checked } = target;
			if (checked) {
				if (!singleGameMode) {
					clonedBonusData.gameIDs.push(game.id);
				} else {
					clonedBonusData.gameName = game.name;
					clonedBonusData.casinoGameID = game.id;
				}
			} else {
				if (!singleGameMode) {
					clonedBonusData.gameIDs = clonedBonusData.gameIDs.filter(gameID => game.id !== gameID);
				} else {
					clonedBonusData.gameName = null;
					clonedBonusData.casinoGameID = null;
				}
			}

			dataRefresh(clonedBonusData, game.name);
			uiRefresh({ isChanged: true });

		};
		const checked = clonedBonusData.gameIDs.includes(game.id);

		return (
			<Label style={{}} key={game.id}>
				{(
					<Checkbox
						value={game.id}
						onChange={onCheckGame}
						checked={checked}
						disabled={readOnly}
					/>
				)}
				<span>{game.name}</span>
			</Label>
		);
	}

	loadMore() {
		const { gamesSearchListReload, UI: { loading }, bonusData } = this.props;
		!loading && gamesSearchListReload({ byCurrency: !!bonusData.currencyID, currency: bonusData.currencyID });
	}
	// Renders --------------------------------------------------------------------------------------
	render() {
		const { readOnly, allow, gamesSearchList, searchValue, dataRefresh, uiRefresh, UI, bonusData, keyTab, fromLoyaltySystemBonusTab, showWarningIcon, singleGameMode = false } = this.props;
		const showSearchResult = (searchValue.length >= 3);
		const isEligibility	= (keyTab === 'eligibility');
		const { hasMore, loading } = UI;
		return (
			<div>
				{showWarningIcon && (
					<Info>
						<IconWarning icon={warningIcon} />
						{lang.warning}
					</Info>
				)}
				<Wrapper>
					<FormInput
						placeholder="Search game"
						value={searchValue}
						className="searchGame"
						onChange={this.onChangeSearch}
						disabled={readOnly || loading}
					/>
					{!singleGameMode && <Switcher
						onChange={this.onChangeAllow}
						allow={allow}
						disabled={readOnly}
					/>}
				</Wrapper>

				{showSearchResult ? (
					<>
						{loading && <Spinner size='default' height='200px' />}

						{gamesSearchList && !gamesSearchList.length && <Empty />}

						{gamesSearchList && gamesSearchList.length > 0 && (
							<InfiniteScrollWrapper id="game-list-container">
								<InfiniteScroll
									useWindow={false}
									initialLoad={false}
									hasMore={hasMore}
									pageStart={1}
									loadMore={this.loadMore}
									threshold={150}
									loader={loading && <LayoutContentStyle> <Spin size="small" /> </LayoutContentStyle>}
									getScrollParent={() => document.getElementById('game-list-container')}
								>
									{gamesSearchList.map(item => this.renderRow(item))}
								</InfiniteScroll>
							</InfiniteScrollWrapper>
						)}
					</>
				) : (
					<Tabs defaultKey="category">
						<TabPane tab={lang.category} key="category">
							{isEligibility && (
								<TabCategories
									readOnly	= {readOnly}
									uiRefresh	= {uiRefresh}
									dataRefresh	= {dataRefresh}
									bonusData	= {bonusData}
									singleGameMode={singleGameMode}
								/>
							)
							}
						</TabPane>

						<TabPane tab={lang.tags} key="tags">
							{isEligibility && (
								<TabTags
									readOnly	= {readOnly}
									uiRefresh	= {uiRefresh}
									dataRefresh = {dataRefresh}
									bonusData   = {bonusData}
									singleGameMode={singleGameMode}
								/>
							)}
						</TabPane>

						<TabPane tab={lang.integrators} key="integrators">
							{isEligibility && (
								<TabIntegrators
									readOnly	= {readOnly}
									uiRefresh	= {uiRefresh}
									dataRefresh	= {dataRefresh}
									bonusData	= {bonusData}
									fromLoyaltySystemBonusTab={fromLoyaltySystemBonusTab}
									singleGameMode={singleGameMode}
								/>
							)}
						</TabPane>
					</Tabs>
				)
				}
			</div>
		);
	}
}

function mapStateToProps(state, props) {
	const { Casino } = state;
	const { bonusData, dataRefresh, uiRefresh } = props;
	const listUI = deriveGamesListUI(state);
	const UI = Casino.Games.get('UI');
	return {
		allow          : bonusData.allow,
		gamesList      : Casino.Games.get('gamesList'),
		gamesSearchList: Casino.Games.get('gamesSearchList'),
		searchValue    : Casino.Games.get('searchValue'),
		websiteID      : listUI.websiteID,
		dataRefresh,
		uiRefresh,
		bonusData,
		UI,
	};
}

const mapDispatchToProps = {
	gamesSearchListReload         : gamesActions.gamesSearchListReload,
	gamesSearchListRefresh        : gamesActions.gamesSearchListRefresh,
	setSearchValue                : gamesActions.setSearchvalue,
	gamesListReload               : gamesActions.casinoGameListReload,
	gamesListResetSearchPagination: gamesActions.gamesListResetSearchPagination,
	gamesDataReset                : gamesActions.dataReset,
	dataReset                     : categoryActions.dataReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Eligability);
