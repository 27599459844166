import styled from 'styled-components';
import { Button } from 'antd';

export const Wrapper = styled.div`
  display: flex;
	margin-bottom: 8px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    overflow: hidden;
    word-break: break-word;
  }

  .control {
		cursor: pointer;
    display: flex;
    align-items: center;
    width: 75%;
    flex: 1 1 0;
  }
`;

export const ButtonLink = styled(Button)`
    padding: 0;
`;
