import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    gap: 4px;

    .ant-picker {
        max-height: 32px;
        margin-right: 0.2em;
    }
`;
