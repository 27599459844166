
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { availableFields } from '../../../../redux/users/reportUsers/utils';
import TabMain from '../../../CustomerManagement/modals/UserBonus/FreeSpin/TabMain/TabMain';
import { validateFreeSpin } from '../../../CustomerManagement/modals/UserBonus/validator';
import TabEligability from '../Tabs/TabEligability';
import TabTitle from '../Tabs/TabTitle/TabTitle';
import UsersFilter from './Steps/UsersFilter';
import { lang as usersListLang } from '../../../CustomerManagement/UsersListToolbar/utils';
export const defaultReportFields = availableFields.map(fieldKey => {
	return {
		id     : fieldKey,
		intlID : usersListLang[fieldKey],
		checked: true,
	};
});

export const tableType = TABLE_TYPES.bonusesMassBonusList;

export enum stepsIDs {
	usersFilter	= 1,
	main		= 2,
	title		= 3,
	eligability	= 4
}

export const stepsData = [
	{
		Component: UsersFilter,
		key      : 'usersFilter',
		id       : stepsIDs.usersFilter,
	},
	{
		Component: TabMain,
		key      : 'main',
		id       : stepsIDs.main,
	},
	{
		Component: TabTitle,
		key      : 'title',
		id       : stepsIDs.title,
	},
	{
		Component: TabEligability,
		key      : 'eligability',
		id       : stepsIDs.eligability,
	},
];


export const disabledFilterStep = (filterData) => {
	return !filterData.currency || (filterData.currency && !filterData.currency.length);
};

export const disabledMainStep = (bonusData, limits, multilanguage, lineAndDenominationsRequired, isMassBonusCreate) => {
	const data = validateFreeSpin(bonusData, limits, multilanguage, lineAndDenominationsRequired, isMassBonusCreate).filter(item => item.startsWith('users.userBonus'));
	return data.length;
};

export const disabledTitlesStep = (bonusData) => {
	const hasTitle = bonusData.titles.some(item => item.title);
	return !hasTitle;
};

export const disabledSave = (filterData, bonusData, limits, multilanguage, lineAndDenominationsRequired, isMassBonusCreate) => {
	return disabledFilterStep(filterData) || disabledMainStep(bonusData, limits, multilanguage, lineAndDenominationsRequired, isMassBonusCreate) || disabledTitlesStep(bonusData);
};

export const disRightDetect = (filterData, bonusData, limits, step, multilanguage, lineAndDenominationsRequired, isMassBonusCreate) => {

	switch (step + 1) { // index + 1
		case stepsIDs.usersFilter: {
			return disabledFilterStep(filterData);
		}
		case stepsIDs.main: {
			return disabledMainStep(bonusData, limits, multilanguage, lineAndDenominationsRequired, isMassBonusCreate);
		}
		case stepsIDs.title: {
			return disabledTitlesStep(bonusData);
		}
			
		case stepsIDs.eligability: {
			break;
		}
			
		default:
			break;
	}
};