import { PERIODS } from './common';

export const periodsList = [
	{ id: PERIODS.today,     name: 'Today'      },
	{ id: PERIODS.yesterday, name: 'Yesterday'  },
	{ id: PERIODS.thisWeek,  name: 'This week'  },
	{ id: PERIODS.thisMonth, name: 'This month' },
	{ id: PERIODS.lastWeek,  name: 'Last week'  },
	{ id: PERIODS.lastMonth, name: 'Last month' },
];
