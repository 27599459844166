import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '../../uielements/FormControl';
import SelectList from '../../uielements/selectList';

const fullWidth = { width: '100%' };

class FormSelect extends Component {

	static propTypes = {
		label        : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth   : PropTypes.number,
		height       : PropTypes.string,
		value        : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		style        : PropTypes.object,
		name         : PropTypes.string,
		direction    : PropTypes.string,
		onPopupScroll: PropTypes.func,
		widthInput   : PropTypes.number,
	};

	static defaultProps = {
		label        : '',
		labelWidth   : 25, // in percent
		height       : '36px',
		value        : '',
		style        : {},
		name         : '',
		direction    : 'row',
		onPopupScroll: () => {},
		widthInput   : 0,
	};

	render() {
		const { label, labelWidth, height, value, style, name, onPopupScroll, direction, widthInput, labelRight, ...restProps } = this.props;
		const lw  = (Boolean(labelWidth) || labelWidth === 0) ? labelWidth : 25;
		const h   = height ? { height }     : {};
		const val = (Boolean(value) || value === 0) ? String(value) : null;
		const st  = style ? { ...style, ...fullWidth } : fullWidth;
		const mb = (labelWidth === 0) ? 0 : undefined;

		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				direction={direction}
				widthInput={widthInput}
				labelRight={labelRight}
				{...h}
				control={(
					<SelectList
						{...restProps}
						onPopupScroll={onPopupScroll}
						value={val}
						name={name}
						style={st}
					/>
				)}
			/>
		);
	}
}

export default FormSelect;
