import { makeActionCreator } from '../../../helpers';

const prefix = 'CASHBACK';

const TYPES = {
	LIST_RELOAD              : `${prefix}_LIST_RELOAD`,
	LIST_REFRESH             : `${prefix}_LIST_REFRESH`,
	SUBCASHBACK_LIST_RELOAD  : `${prefix}_SUBCASHBACK_LIST_RELOAD`,
	SUBCASHBACK_LIST_REFRESH : `${prefix}_SUBCASHBACK_LIST_REFRESH`,
	CASHBACK_ITEM_DATA_RELOAD: `${prefix}_CASHBACK_ITEM_DATA_RELOAD`,

	FILTER_REFRESH    : `${prefix}_FILTER_REFRESH`,
	FILTER_APPLY      : `${prefix}_FILTER_APPLY`,
	PAGINATION_REFRESH: `${prefix}_PAGINATION_REFRESH`,
	FILTER_RESET      : `${prefix}_FILTER_RESET`,
	BONUS_SAVE        : `${prefix}_BONUS_SAVE`,
	BONUS_DELETE      : `${prefix}_BONUS_DELETE`,
	BONUS_DATA_REFRESH: `${prefix}_BONUS_DATA_REFRESH`,
	BONUS_DATA_RESET  : `${prefix}_BONUS_DATA_RESET`,
	BONUS_UI_REFRESH  : `${prefix}_BONUS_UI_REFRESH`,

	CASHBACK_HISTORY_DATA_RELOAD          : `${prefix}_CASHBACK_HISTORY_DATA_RELOAD`,
	CASHBACK_GGRLIMITS_HISTORY_DATA_RELOAD: `${prefix}_CASHBACK_GGRLIMITS_HISTORY_DATA_RELOAD`,
	CASHBACK_HISTORY_DATA_REFRASH         : `${prefix}_CASHBACK_HISTORY_DATA_REFRASH`,
};

const actions = {
	...TYPES,

	listReload         : makeActionCreator(TYPES.LIST_RELOAD),
	listRefresh        : makeActionCreator(TYPES.LIST_REFRESH, 'entities'),
	getSubCashbacks    : makeActionCreator(TYPES.SUBCASHBACK_LIST_RELOAD, 'id'),
	subCashbacksRefresh: makeActionCreator(TYPES.SUBCASHBACK_LIST_REFRESH, 'list'),
	filterRefresh      : makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterApply        : makeActionCreator(TYPES.FILTER_APPLY),
	filterReset        : makeActionCreator(TYPES.FILTER_RESET),
	paginationRefresh  : makeActionCreator(TYPES.PAGINATION_REFRESH, 'pagination'),
	getCashback        : makeActionCreator(TYPES.CASHBACK_ITEM_DATA_RELOAD, 'id'),
	
	bonusSave  : makeActionCreator(TYPES.BONUS_SAVE),
	bonusDelete: makeActionCreator(TYPES.BONUS_DELETE, 'id'),
	dataRefresh: makeActionCreator(TYPES.BONUS_DATA_REFRESH, 'bonusData'),
	dataReset  : makeActionCreator(TYPES.BONUS_DATA_RESET),
	uiRefresh  : makeActionCreator(TYPES.BONUS_UI_REFRESH, 'UI'),

	historyDataReload         : makeActionCreator(TYPES.CASHBACK_HISTORY_DATA_RELOAD, 'cashbackID', 'logModel'),
	historyGGrLimitsDataReload: makeActionCreator(TYPES.CASHBACK_GGRLIMITS_HISTORY_DATA_RELOAD, 'cashbackID'),
	historyDataRefrash        : makeActionCreator(TYPES.CASHBACK_HISTORY_DATA_REFRASH, 'data'),
};

export default actions;
