import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { actions } from './slice';
import  sagaActions from './actions';
import { SPORT_RISK_MANAGMENT_LOG_MODELS } from '../../../../helpers/commonConstants';
import { historyAPI } from '../../../../helpers/api/history';
import { adaptData, sportRiskManagmentModels } from './utils';
import { IActionDataReload } from './types';
import { showError } from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';

const prefix = 'sportRiskManagment.history';

const messages = {
	loadData: `${prefix}.loadData`,
};

function* dataReload() {

	yield takeEvery(sagaActions.SPORT_RISK_MANAGMENT_LOG_MODELS_DATA_RELOAD, function* (action: IActionDataReload) {

		yield put(actions.uiRefresh({ loading: true }));

		
		const { id, logModel } = action.data;

		try {
			if (logModel === SPORT_RISK_MANAGMENT_LOG_MODELS.betslip) {
				const betslipRes = yield call(historyAPI.historyRiskManagmentBetslipLogs, id, sportRiskManagmentModels.BETSLIP);
				if (betslipRes && betslipRes.status === 200) {
					const adaptedData = adaptData(betslipRes.data.data);
					yield put(actions.setBetslipLogsData(adaptedData));
				}
			} else if (logModel === SPORT_RISK_MANAGMENT_LOG_MODELS.betslipDetails) {
				const betslipDetailsRes = yield call(historyAPI.historyRiskManagmentBetslipLogs, id, sportRiskManagmentModels.BETSLIP_DETAIL);
				if (betslipDetailsRes && betslipDetailsRes.status === 200) {
					const adaptedData = adaptData(betslipDetailsRes.data.data);
					yield put(actions.setBetslipDetailsLogsData(adaptedData));
				}
			} else if (logModel === SPORT_RISK_MANAGMENT_LOG_MODELS.eventMarkets) {
				const eventMarketsRes = yield call(historyAPI.historyRiskManagmentEventMarketsLogs, id);
				if (eventMarketsRes && eventMarketsRes.status === 200) {
					const adaptedData = adaptData(eventMarketsRes.data.data);
					yield put(actions.setEventMarketsLogsData(adaptedData));
				}
			} else if (logModel === SPORT_RISK_MANAGMENT_LOG_MODELS.eventSelections) {
				const eventSelections = yield call(historyAPI.historyRiskManagementEventSelectionsLogs, id);
				if (eventSelections && eventSelections.status === 200) {
					const adaptedData = adaptData(eventSelections.data.data);
					yield put(actions.setEventSelectionsLogsData(adaptedData));
				}
			}

		} catch (error) {
			showError(messages.loadData);
			logger.log(error);
		}
		
		// else if (logModel ===SPORT_RISK_MANAGMENT_LOG_MODELS.betslipDetails){
		// 	data = yield modelDataReload(userID, logModel);
		// 	yield put(actions.dataRefresh(data, logModel));
		// } else if (logModel === SPORT_RISK_MANAGMENT_LOG_MODELS.eventMarkets) {
		// 	data = yield modelDataReload(userID, logModel);
		// 	yield put(actions.dataRefresh(data, logModel));
		// }
		
		yield put(actions.uiRefresh({ loading: false }));
	});
}


export default function* sportRiskManagementHistorySaga() {
	yield all([
		fork(dataReload),
	]);
}
