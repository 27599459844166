import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import formatter from '../../../../helpers/formatter';
import { HistoryEnums } from '../../../sport/results/enums';
import { DiffMapper } from '../../../../helpers/diff';

export enum sportRiskManagmentModels {
	BETSLIP = 1,
    BETSLIP_DETAIL = 2,
}

export const fields = {
	// list
	betStake             : 'stake',
	betStakeUSD          : 'stake_usd',
	betPlacementTime     : 'place_date',
	betTotalPrice        : 'total_odd',
	maxPossiblePayoutUSD : 'won_amount_usd',
	maxPossiblePayout    : 'won_amount',
	maxPossibleWinningUSD: 'possible_won_amount_usd',
	maxPossibleWinning   : 'possible_won_amount',
	eventStartingTime    : 'event_start_date',
	oddPrice             : 'odd',
	repetitiveNumber     : 'repetitive_number',

	// filter
	betID                    : 'id',
	customerID               : 'user_id',
	betNumber                : 'bet_number',
	currencyID               : 'currency_id',
	eventName                : 'event_name',
	marketName               : 'market_name',
	sportName                : 'sport_name',
	selectionName            : 'selection_name',
	statusID                 : 'status_id',
	countryName              : 'country_name',
	leagueName               : 'league_name',
	stakeFrom                : 'stake_from',
	stakeTo                  : 'stake_to',
	eventID                  : 'event_id',
	gameType                 : 'game_type',
	betPlacementTimeFrom     : 'place_date_from',
	betPlacementTimeTo       : 'place_date_to',
	betTotalPriceFrom        : 'total_odd_from',
	betTotalPriceTo          : 'total_odd_to',
	betType                  : 'bet_type',
	maxPossiblePayoutUSDFrom : 'won_amount_usd_from',
	maxPossiblePayoutUSDTo   : 'won_amount_usd_to',
	maxPossiblePayoutFrom    : 'won_amount_from',
	maxPossiblePayoutTo      : 'won_amount_to',
	maxPossibleWinningUSDFrom: 'possible_won_amount_usd_from',
	maxPossibleWinningUSDTo  : 'possible_won_amount_usd_to',
	maxPossibleWinningFrom   : 'possible_won_amount_from',
	maxPossibleWinningTo     : 'possible_won_amount_to',
	priceProvider            : 'source_id',
	betStakeUSDFrom          : 'stake_usd_from',
	betStakeUSDTo            : 'stake_usd_to',
	eventStartingTimeFrom    : 'event_start_date_from',
	eventStartingTimeTo      : 'event_start_date_to',
	betTradingMode           : 'trading_mode',
	marketID                 : 'market_id',
	gameVariety              : 'game_variety',
	gamePeriod               : 'game_period',
	repetitiveNumberFrom     : 'repetitive_number_from',
	repetitiveNumberTo       : 'repetitive_number_to',
	currentMarginFrom        : 'margin_from',
	currentMarginTo          : 'margin_to',

	// event Markets
	eventMarkets : 'event_markets',
	reason       : 'reason',
	suspend      : 'suspend',
	initialMargin: 'initial_margin',
	currentMargin: 'current_margin',

	objectBefore: 'object_before',
	objectAfter : 'object_after',
	userID      : 'source_id',
	sourceIp    : 'source_ip',
	sourceTypeID: 'source_type',
	actionTypeID: 'action_type',
	date        : 'date',

	id: 'id',

	stake               : 'stake',
	stakeUsd            : 'stake_usd',
	placeDate           : 'place_date',
	wonAmount           : 'won_amount',
	wonAmountUsd        : 'won_amount_usd',
	possibleWonAmount   : 'possible_won_amount',
	possibleWonAmountUsd: 'possible_won_amount_usd',

	totalOdd   : 'total_odd',
	bettype    : 'bet_type',
	environment: 'environment',

};

// Adapting ---------------------------------------------------------------------------------------

export function adaptData(rawData = []) {

	if (!isArray(rawData) || isEmpty(rawData)) {
		return [];
	}
	return adaptBetslip(rawData);
}

// Adapters ---------------------------------------------------------------------------------------

function adaptBetslip(rawData) {

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.betID],
	};

	return adaptHistoryList(rawData, diffOptions);
}

// Common -----------------------------------------------------------------------------------------

export function adaptHistoryList(rawData, diffOptions, arrayFields = []) {

	const historyEnums = new HistoryEnums();

	const dataList = [];

	const mapper = new DiffMapper();
	mapper.init(diffOptions);

	rawData.forEach((historyItem, historyItemIndex) => {
		let itemDiffList = [];

		const objectBefore = historyItem[fields.objectBefore] || {};
		const objectAfter  = historyItem[fields.objectAfter]  || {};

		// main data
		const diffMap  = mapper.map(objectBefore, objectAfter);
		const diffList = mapper.toList(diffMap);

		itemDiffList = itemDiffList.concat(diffList);

		// arrays
		arrayFields.forEach(fieldName => {
			const diffList = mapper.mapList(
				objectBefore,
				objectAfter,
				fieldName
			);
			itemDiffList = itemDiffList.concat(diffList);
		});

		if (isEmpty(itemDiffList)) {
			return;
		}

		// adding id for detail records
		itemDiffList.forEach((item, index) => {
			item.id = index;
		});

		// prepare result item
		const userID       = toInteger(historyItem[fields.userID]);
		const sourceIp     = historyItem[fields.sourceIp];
		const sourceTypeID = toInteger(historyItem[fields.sourceTypeID]);
		const actionTypeID = toInteger(historyItem[fields.actionTypeID]);

		const dataItem = {
			userID,
			sourceIp,
			id         : historyItemIndex,
			date       : formatter.fullDateTime(historyItem[fields.date]),
			sourceType : historyEnums.sourceType(sourceTypeID),
			actionType : historyEnums.actionType(actionTypeID),
			detailsList: itemDiffList,
		};
		dataList.push(dataItem);
	});

	return dataList;
}