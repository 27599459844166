import React from 'react';
import * as PropTypes from 'prop-types';

import FormControl from '../../../components/uielements/FormControl';
import Utils from '../FilterUtils';

const CustomComponent = ({ filterItem }) => {
	const { label, showLabel, component, name } = filterItem;

	return (
		<FormControl
			label={label}
			name={name}
			labelWidth={Utils.getLabelWidth(showLabel)}
			control={component}
		/>
	);
};

CustomComponent.propTypes = {
	filterItem: PropTypes.object.isRequired,
};

export default CustomComponent;
