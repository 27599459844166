import { all, fork } from 'redux-saga/effects';

import singleSaga from './single/saga';
import multipleSaga from './multiple/saga';

export default function* imagesSaga() {
	yield all([
		fork(singleSaga),
		fork(multipleSaga),
	]);
}
