const createUserActions = {
	CREATE_USER: 'CREATE_USER',

	createUser: () =>{
		return {
			type: createUserActions.CREATE_USER,
		};
	},
};

export default createUserActions;