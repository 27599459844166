import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius, boxShadow } from '../../../../config/style-util';

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: -8px -16px;
  /*width: 360px;*/
  min-width: 160px;
  flex-shrink: 0;
  ${borderRadius('5px')};
  ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
  ${transition()};

  @media only screen and (max-width: 767px) {
    width: 310px;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 0px;
  padding: 8px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 500;
  color: ${palette('text', 0)};
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`;

export const Body = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  background-color: ${palette('grayscale', 6)};
  padding: 4px 8px;

  a {
    text-decoration: none;
  }

  .ant-table-thead {
    th {
      padding: 8px;
    }
  }

  .ant-table-row {
		td {
			padding: 4px;
		}
	}
`;

export const Item = styled.div`
  padding: 4px 4px;
`;

export const IconWrapper = styled.div`
  margin-right: 16px;
  &:has(:disabled) {
		pointer-events: none;
    cursor: not-allowed;
		background: rgba(0, 0, 0, 0.04);
	}
  i {
    font-size: 20px;
    cursor: pointer;
  }
`;

export const LinksBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Link = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${palette('text', 2)};
  padding: 8px 24px;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  ${transition()};

  &:hover {
    color: ${palette('primary', 0)};
  }
`;
