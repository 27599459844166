import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import { isID } from '../../../../helpers/utils';
import { fields, createListAdapter, createDescAdapter } from '../list/utils';
import { createEntities } from '../../../utility';

const listAdapter = createListAdapter();
const descAdapter = createDescAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptBaseData(rawData = {}) {

	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);

	return adaptedData;
}

export function adaptDescData(rawData = [], contentLanguageList) {

	descAdapter.clearExcludes();
	const result = {};
	if ((!isArray(rawData) || isEmpty(rawData)) || (!isArray(contentLanguageList) || isEmpty(contentLanguageList))) {
		return descAdapter.adapt({});
	}

	const adaptedData = descAdapter.adaptList(rawData);
	adaptedData.forEach(item => {
		const dataPerLang = adaptedData.filter(data =>data.langID === item.langID);
		if (!result[item.langID]) {
			result[item.langID] = createEntities(dataPerLang, 'channelID');
		}
	});
	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareBaseData(baseData) {

	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('viewCount');
	listAdapter.addExcludeField('startDate');
	listAdapter.addExcludeField('endDate');

	const preparedData = listAdapter.prepare(baseData);

	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}

	if (baseData.startDate) {
		preparedData[fields.startDate] = new Date(baseData.startDate).toISOString();
	}
	if (baseData.endDate) {
		preparedData[fields.endDate] = new Date(baseData.endDate).toISOString();
	}

	return preparedData;
}

export function prepareDescData(descData, websiteID, bannerID) {

	descAdapter.clearExcludes();

	const flattenData = [];

	for (const key1 in descData) {
		// eslint-disable-next-line no-prototype-builtins
		if (descData.hasOwnProperty(key1)) {
			for (const key2 in descData[key1]) {
			// eslint-disable-next-line no-prototype-builtins
				if (descData[key1].hasOwnProperty(key2)) {
					flattenData.push({
						parentKey1: key1,
						parentKey2: key2,
						bannerID,
						...descData[key1][key2],
					});
				}
			}
		}
	}
	const preparedData = descAdapter.prepareList(flattenData.map(item => {
		return {
			...item,
			websiteID,
		};
	}));

	return preparedData;
}