import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../uielements/button';
import Dropdown, { DropdownMenu, MenuItem } from '../../../uielements/dropdown';
import { Wrapper } from './SelectPeriod.style';
import { DownOutlined } from '@ant-design/icons';

const SelectPeriod = ({ periods, height, onSelect }) => {

	const menu = (
		<DropdownMenu onClick={onSelect}>
			{periods.map(item => <MenuItem key={item.id}>{item.name}</MenuItem>)}
		</DropdownMenu>
	);

	return (
		<Dropdown placement={'bottom'} overlay={menu} trigger={['click']}>
			<Wrapper>
				<Button className="select-period" style={height}>
					<DownOutlined />
				</Button>
			</Wrapper>
		</Dropdown>
	);
};

SelectPeriod.propTypes = {
	periods: PropTypes.arrayOf(PropTypes.shape({
		id  : PropTypes.string.isRequired,
		name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	})).isRequired,
	onSelect: PropTypes.func.isRequired,
	height  : PropTypes.object,
};

SelectPeriod.defaultProps = {
	height: {},
};

export default SelectPeriod;
