import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormControl from '../../uielements/FormControl';
import SingleUploader from '../../../containers/Uploaders/SingleUploader';

class FormSingleUploader extends Component {

	static propTypes = {
		label           : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth      : PropTypes.number,
		height          : PropTypes.string,
		minImageWidth   : PropTypes.string,
		minImageHeight  : PropTypes.string,
		disabled        : PropTypes.bool,
		disabledCloseBtn: PropTypes.bool,
	};

	static defaultProps = {
		label         : '',
		labelWidth    : 25, // in percent
		height        : '36px',
		minImageWidth : '',
		minImageHeight: '',
		disabled      : false,
	};

	render() {
		const { label, labelWidth, height, disabled, disabledCloseBtn, ...restProps } = this.props;

		const isLabel = (Boolean(labelWidth) || labelWidth === 0);

		const lw = isLabel ? labelWidth : 25;
		const mb = (labelWidth === 0) ? 0 : undefined;
		const h  = height ? { height } : {};

		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				disabled={disabled}
				{...h}
				control={<SingleUploader disabledCloseBtn={disabledCloseBtn} disabled={disabled} {...restProps} />}
			/>
		);
	}
}

export default FormSingleUploader;
