import { PERMISSIONS } from '../../../../../helpers/permissions/constants';
import { superAdminPermissions } from '../../../../../helpers/permissions/utils';
import IntlMessages from '../../../../../components/utility/intlMessages';

export const DATE_FORMATS = [
	{ id: 1, name: 'DD.MM.YYYY HH:mm:ss' },
	{ id: 2, name: 'MM/DD/YYYY hh:mm:ss A' },
	{ id: 3, name: 'YYYY-MM-DD HH:mm:ss' },
	{ id: 4, name: 'YYYY/MM/DD HH:mm:ss' },
	{ id: 5, name: 'HH:mm DD MMM YYYY' },
];

export const lang = {
	modalTitle           : <IntlMessages id="websites.modal.title"/>,
	websiteInner         : <IntlMessages id="websiteInner.title"/>,
	tabMain              : <IntlMessages id="websites.modal.tabMain.title"/>,
	tabPayments          : <IntlMessages id="websites.modal.tabPayments.title"/>,
	tabProvider          : <IntlMessages id="websites.modal.tabProvider.title"/>,
	tabCurrency          : <IntlMessages id="websites.modal.tabCurrency.title"/>,
	tabScripts           : <IntlMessages id="websites.modal.tabScripts.title"/>,
	autoWithdrawal       : <IntlMessages id="websites.modal.tabAutoWithdrawal.title"/>,
	tabIntegration       : <IntlMessages id="websites.modal.tabIntegration.title"/>,
	tabUserConfigurations: <IntlMessages id="websites.modal.UserConfigurations.title"/>,
	smsGayteways         : <IntlMessages id="websites.modal.tabSmsGateways.title"/>,
	riskManagement       : <IntlMessages id="websites.modal.riskManagement.title"/>,
	countries            : <IntlMessages id="websites.modal.tabCountries.title"/>,
	languages            : <IntlMessages id="websites.modal.tabLanguages.title"/>,
	seo                  : <IntlMessages id="casino.modal.tabSeo.title"/>,
	responsibleGambling  : <IntlMessages id="userInfo.tabResponsibleGambling.title"/>,
	update               : <IntlMessages id="partner.websites.website.websiteInner.update"/>,
};

export const canReadTabs = (permissions) => {
	const superAdmin = permissions.super_admin;
	const showTabPayments       = (superAdmin || permissions[PERMISSIONS.accessWebsitePaymentsTab]?.read);
	const showTabProvider       = (superAdmin || permissions[PERMISSIONS.accessWebsiteProviderTab]?.read);
	const showTabCurrency       = (superAdmin || permissions[PERMISSIONS.accessWebsiteCurrencyTab]?.read);
	const showTabScripts        = (superAdmin || permissions[PERMISSIONS.accessWebsiteScriptsTab]?.read);
	const showTabAutoWithdraw   = (superAdmin || permissions[PERMISSIONS.accessWebsiteAutoWithdrawTab]?.read);
	const showTabSmsGateway     = (superAdmin || permissions[PERMISSIONS.accessWebsiteSmsGatewayTab]?.read);
	const showTabRiskManagement = (superAdmin || permissions[PERMISSIONS.accessWebsiteRiskManagementTab]?.read);
	const showTabCountry        = (superAdmin || permissions[PERMISSIONS.accessWebsiteCountryTab]?.read);
	const showTabLanguages      = (superAdmin || permissions[PERMISSIONS.accessWebsiteLanguagesTab]?.read);

	return {
		showTabs: {
			showTabPayments,
			showTabProvider,
			showTabCurrency,
			showTabScripts,
			showTabAutoWithdraw,
			showTabSmsGateway,
			showTabRiskManagement,
			showTabCountry,
			showTabLanguages,
		},
		tabsCRUD: {
			crudTabPayments      : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsitePaymentsTab]        || {},
			crudTabProvider      : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteProviderTab]        || {},
			crudTabCurrency      : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteCurrencyTab]        || {},
			crudTabScripts       : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteScriptsTab]         || {},
			crudTabAutoWithdraw  : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteAutoWithdrawTab]    || {},
			crudTabSmsGateway    : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteSmsGatewayTab]      || {},
			crudTabRiskManagement: superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteRiskManagementTab]  || {},
			crudTabCountry       : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteCountryTab]         || {},
			crudTabLanguages     : superAdmin ? superAdminPermissions : permissions[PERMISSIONS.accessWebsiteLanguagesTab]       || {},
		},
	};
};
