import { Collapse } from 'antd';
import styled from 'styled-components';

const { Panel } = Collapse;


export const PanelAntd = styled(Panel)`
  & .ant-collapse-content-active {
    max-height:60vh;
  }
  & .ant-collapse-content-active .ant-collapse-content-box {
    padding: 16px 16px;
  }
`;

export const ExtraDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px
`;
