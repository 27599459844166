import { Col, Row } from 'antd';
import { Wrapper, Cell } from '../../../../../CustomerManagement/UsersList.style';
import UserFilter from './Filter';
import { TABLE_TYPES } from '../../../../../../constants/tableTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';

import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/icomoon/facebook';
import { linkedin2 } from 'react-icons-kit/icomoon/linkedin2';
import { google } from 'react-icons-kit/fa/google';
import { vk } from 'react-icons-kit/fa/vk';
import { odnoklassniki } from 'react-icons-kit/fa/odnoklassniki';
import { email } from 'react-icons-kit/entypo/email';

import listActions from '../../../../../../redux/loyalty/bonuses/userMassBonus/list/actions';
// import filterActions from '../../../../../../redux/users/filter/actions';
import tableActions from '../../../../../../redux/tables/actions';
import ListTable from '../../../../../../components/connected/ListTable';
import UserLink from '../../../../../AppTabsManager/components/UserLink';

import Formatter from '../../../../../../helpers/formatter';
import { toInteger } from 'lodash';
import { lang } from '../../lang';
import { mapEnum, genders } from '../../../../../CustomerManagement/enums';
import { createEntities } from '../../../../../../redux/utility';
import { useState } from 'react';
import ReportToolbar from '../UsersReport';

const tableType             = TABLE_TYPES.customersSearch;
const userStatusMap         = mapEnum('userStatusList');
const gamingStatusMap       = mapEnum('gamingStatusList');
const verificationStatusMap = mapEnum('verificationStatusList');
const profitabilityGradeMap = mapEnum('profitabilityGradeList');

export const UsersFilter = () => {
	const dispatch  = useAppDispatch();
	const usersList = useAppSelector(state => state.Loyalty.Bonuses.UserMassBonus.UserMassBonusList.get('list'));
	const loading   = useAppSelector(state => state.Users.List.get('UI').loading);
    
	const currencyMap       = useAppSelector(state => state.Settings.CurrencyModule.get('entities'));
	const riskGroupEntities = createEntities(useAppSelector(state => state.Common.get('riskGroupList')));

	const [showUsersList, setShowUsersList] = useState(false);

	const usersListReload   = () => dispatch(listActions.listReload());
	const onApplyFilter     = () => {
		setShowUsersList(true);
		// dispatch(filterActions.dataStore());
		dispatch(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		usersListReload();
	};
    
	const renderCell = (record, colName) => {
		const { id, riskGroup, firstName, lastName, customerID } = record;
		const value = record[colName];
		const group = riskGroupEntities[riskGroup] || null;
		const color = group ? group.color : 'inherit';
		let result  = value;
    
		switch (colName) {
			case 'statusID': {
				result = userStatusMap[toInteger(value)] || '';
				break;
			}
			case 'customerID': {
				result = customerID || '';
				break;
			}
			case 'gamingStatusID': {
				result = gamingStatusMap[toInteger(value)] || '';
				break;
			}
			case 'created': {
				result = Formatter.date(value);
				break;
			}
			case 'email':
			case 'userName': {
				result =  (
					<UserLink userID={id}>
						{value}
					</UserLink>
				);
				break;
			}
			case 'balance':
			case 'betsAmount':
			case 'lastBetAmount':
			case 'depositsAmount':
			case 'withdrawalsAmount':
			case 'averageStake':
			case 'turnover': {
				result = Formatter.sum(value);
				break;
			}
			case 'currencyID': {
				const currency = currencyMap[toInteger(value)] || {};
				result = currency.code;
				break;
			}
			case 'riskGroup': {
				result = group ? group.name : lang.noData;
				break;
			}
			case 'verificationStatusID': {
				result = verificationStatusMap[toInteger(value)] || '';
				break;
			}
    
			case 'profitabilityID': {
				result = profitabilityGradeMap[toInteger(value)] || '';
				break;
			}
    
			case 'genderID': {
				result = value ? genders[value] : '';
				break;
			}
    
			case 'emailVerified':
			case 'ageVerified':
			case 'phoneVerified':
			case 'creditCardVerified':
			case 'passportVerified': {
				result = value ? 'Verified' : 'Not verified';
				break;
			}
    
			case 'socialNetworks': {
				const fb = record.facebookID ? <Icon icon={facebook} size={12} /> : '';
				const ln = record.linkedID ? <Icon icon={linkedin2} size={12} /> : '';
				const googleIcon = record.googleID ? <Icon icon={google} size={12} /> : '';
				const vkontakte = record.vkID ? <Icon icon={vk} size={12} /> : '';
				const ok = record.okID ? <Icon icon={odnoklassniki} size={12} /> : '';
				const mailru = record.mailruID ? <Icon icon={email} size={12} /> : '';
				result = (
					<span className="icons">
						{fb}
						{ln}
						{googleIcon}
						{vkontakte}
						{ok}
						{mailru}
					</span>
				);
				break;
			}
    
			case 'verificationMethods': {
				const emailVerified = record.emailVerified ? 'E-mail ' : '';
				const ageVerified = record.ageVerified ? 'Age' : '';
				const phoneVerified = record.phoneVerified ? 'Phone ' : '';
				const creditCardVerified = record.creditCardVerified ? 'Card ' : '';
				const passportVerified = record.passportVerified ? 'Passport' : '';
				result = (
					<span className="icons">
						{emailVerified}
						{ageVerified}
						{phoneVerified}
						{creditCardVerified}
						{passportVerified}
					</span>
				);
				break;
			}
    
			case 'name': {
				result = `${firstName} ${lastName}`;
				break;
			}
			case 'isOnline': {
				result = value ? 'Online' : 'Offline';
				break;
			}
			default:
		}
    
		return (
			<Cell color={color}>{result}</Cell>
		);
        
	};
	const initColumns = () => {
		const cols = [
			{ id: 'id',                   render: renderCell },
			{ id: 'customerID',           render: renderCell },
			{ id: 'email',                render: renderCell },
			{ id: 'statusID',             render: renderCell },
			{ id: 'gamingStatusID',       render: renderCell },
			{ id: 'riskGroup',            render: renderCell },
			{ id: 'created',              render: renderCell },
			{ id: 'balance',              render: renderCell },
			{ id: 'currencyID',           render: renderCell },
			{ id: 'age',                  render: renderCell },
			{ id: 'affiliateReference',   render: renderCell },
			{ id: 'socialNetworks',       render: renderCell },
			{ id: 'verificationMethods',  render: renderCell },
			{ id: 'verificationStatusID', render: renderCell },
			{ id: 'domain',               render: renderCell },
			{ id: 'userName',             render: renderCell },
			{ id: 'betsAmount',           render: renderCell },
			{ id: 'lastBetAmount',        render: renderCell },
			{ id: 'depositsAmount',       render: renderCell },
			{ id: 'withdrawalsAmount',    render: renderCell },
			{ id: 'minStake',             render: renderCell },
			{ id: 'maxStake',             render: renderCell },
			{ id: 'lastLoginDate',        render: renderCell },
			{ id: 'lastBetDate',          render: renderCell },
			{ id: 'countryID',            render: renderCell },
			{ id: 'profitabilityID',      render: renderCell },
			{ id: 'averageStake',         render: renderCell },
			{ id: 'turnover',             render: renderCell },
			{ id: 'name',                 render: renderCell },
			{ id: 'isOnline',             render: renderCell },
			{ id: 'withdrawalsCount',     render: renderCell },
			{ id: 'depositsCount',        render: renderCell },
			{ id: 'betsCount',            render: renderCell },
			{ id: 'genderID',             render: renderCell },
			{ id: 'birth',                render: renderCell },
			{ id: 'firstDeposit',         render: renderCell },
			{ id: 'firstWithdrawal',      render: renderCell },
			{ id: 'registrationIP',       render: renderCell },
		];

		return {
			cols,
			lang,
		};
	};
	const columns = initColumns();
	const getSortableColumns = () => columns.cols.map(column => column.id);

	const sortableColumns = columns ? getSortableColumns() : null;

	const renderTable = showUsersList && (
		<ListTable
			tableType={tableType}
			rowKey="id"
			columns={columns}
			dataSource={usersList}
			pagination={false}
			filterApply={onApplyFilter}
			listReload={usersListReload}
			sortableColumns={sortableColumns}
			loading={loading}
		/>
	);
	return (
		<Wrapper>
			<Row>
				<Col span={24}>
					<ReportToolbar />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<UserFilter onApplyFilter={onApplyFilter} />
				</Col>
			</Row>
			<Row>
				<Col span={24}>{renderTable}</Col>
			</Row>
		</Wrapper>
	);
};