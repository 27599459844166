import styled from 'styled-components';

export const Wrapper = styled.div`
  //.ant-btn.btn-select-period {
  //  padding: 0 8px;
  //}
  .select-period {
    min-height: 100%;
    padding: 0 8px ;

  }
`;
