import { availableFields } from '../../../redux/users/reportUsers/utils';

export const lang = {
	id                  : 'usersList.columns.id',
	email               : 'usersList.columns.email',
	userName            : 'usersList.columns.userName',
	firstName           : 'usersList.columns.firstName',
	lastName            : 'usersList.columns.lastName',
	genderID            : 'usersList.columns.genderID',
	created             : 'usersList.columns.created',
	balance             : 'usersList.columns.balance',
	currencyID          : 'usersList.columns.currencyCode',
	statusID            : 'usersList.columns.statusID',
	langID              : 'usersList.columns.langID',
	countryID           : 'usersList.columns.countryID',
	zipCode             : 'usersList.columns.zipCode',
	city                : 'usersList.columns.city',
	address_1           : 'usersList.columns.address_1',
	address_2           : 'usersList.columns.address_2',
	phone               : 'usersList.columns.phone',
	birth               : 'usersList.columns.birth',
	timeZone            : 'usersList.columns.timeZone',
	utm                 : 'usersList.columns.utm',
	gamingStatusID      : 'usersList.columns.gamingStatusID',
	oddsFormatID        : 'usersList.columns.oddsFormatID',
	referrer            : 'usersList.columns.referrer',
	state               : 'usersList.columns.state',
	stateID             : 'usersList.columns.stateID',
	stateIDissueDate    : 'usersList.columns.stateIDissueDate',
	verificationStatusID: 'usersList.columns.verificationStatusID',
	isOnline            : 'usersList.columns.isOnline',
	emailContact        : 'usersList.columns.emailContact',
	phoneContact        : 'usersList.columns.phoneContact',
	receiveNewsletters  : 'usersList.columns.receiveNewsletters',
	description         : 'usersList.columns.description',
	avatarURL           : 'usersList.columns.avatarURL',
	verificationPhotoURL: 'usersList.columns.verificationPhotoURL',
	profitabilityID     : 'usersList.columns.profitabilityID',
	age                 : 'usersList.columns.age',
	affiliateReference  : 'usersList.columns.affiliateReference',
	facebookID          : 'usersList.columns.facebookID',
	linkedID            : 'usersList.columns.linkedID',
	googleID            : 'usersList.columns.googleID',
	vkID                : 'usersList.columns.vkID',
	okID                : 'usersList.columns.okID',
	mailruID            : 'usersList.columns.mailruID',
	emailVerified       : 'usersList.columns.emailVerified',
	ageVerified         : 'usersList.columns.ageVerified',
	phoneVerified       : 'usersList.columns.phoneVerified',
	creditCardVerified  : 'usersList.columns.creditCardVerified',
	passportVerified    : 'usersList.columns.passportVerified',
	domain              : 'usersList.columns.domain',
	depositsCount       : 'usersList.columns.depositsCount',
	withdrawalsCount    : 'usersList.columns.withdrawalsCount',
	betsCount           : 'usersList.filter.betsCount',
	customerID          : 'usersList.filter.userID',
};

export const defaultReportFields = availableFields.map(fieldKey => ({
	id     : fieldKey,
	intlID : lang[fieldKey],
	checked: true,
}));
