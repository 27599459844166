const actions = {

	USER_BALANCE_DATA_RESET  : 'USER_BALANCE_DATA_RESET',
	USER_BALANCE_DATA_REFRESH: 'USER_BALANCE_DATA_REFRESH',
	USER_BALANCE_UI_REFRESH  : 'USER_BALANCE_UI_REFRESH',
	USER_BALANCE_SAVE        : 'USER_BALANCE_SAVE',

	USER_BALANCE_TEMPLATE_DATA_REFRESH: 'USER_BALANCE_TEMPLATE_DATA_REFRESH',
	USER_BALANCE_TEMPLATE_DATA_SAVE   : 'USER_BALANCE_TEMPLATE_DATA_SAVE',
	
	dataReset: () => ({
		type: actions.USER_BALANCE_DATA_RESET,
	}),
	dataRefresh: data => ({
		type: actions.USER_BALANCE_DATA_REFRESH,
		data,
	}),
	bonusMoneySaveWithTemplate: () => ({
		type: actions.USER_BALANCE_TEMPLATE_DATA_SAVE,
	}),
	templateDataRefresh: data => ({
		type: actions.USER_BALANCE_TEMPLATE_DATA_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.USER_BALANCE_UI_REFRESH,
		data,
	}),
	balanceSave: () => ({
		type: actions.USER_BALANCE_SAVE,
	}),
};

export default actions;
