import { makeActionCreator } from '../../../helpers';

const prefix = 'USER_BONUS';

const TYPES = {
	DATA_SAVE   : `${prefix}_DATA_SAVE`,
	DATA_REFRESH: `${prefix}_DATA_REFRESH`,
	DATA_RESET  : `${prefix}_DATA_RESET`,
	UI_REFRESH  : `${prefix}_UI_REFRESH`,
	GET_BY_ID   : `${prefix}_GET_BY_ID`,

	GAME_LIMITS_RELOAD                   : `${prefix}_GAME_LIMITS_RELOAD`,
	BETS_REFRESH                         : `${prefix}_BETS_REFRESH`,
	DENOMINATIONS_REFRESH                : `${prefix}_DENOMINATIONS_REFRESH`,
	CANCEL_USER_BONUS_DATA               : `${prefix}_CANCEL_USER_BONUS_DATA`,
	BONUS_DATA_RESET                 	   : `${prefix}_BONUS_DATA_RESET`,
	PROVIDERS_LIST_REFRESH               : `${prefix}_PROVIDERS_LIST_REFRESH`,
	LINE_DENOMINATION_REQUIRED_REFRESH   : `${prefix}_LINE_DENOMINATION_REQUIRED_REFRESH`,
	RESET_CHECKED_PROVIDER_BY_INTEGRATION: `${prefix}_RESET_CHECKED_PROVIDER_BY_INTEGRATION`,
	TEMPLATE_DATA_RELOAD                 : `${prefix}_TEMPLATE_DATA_RELOAD`,
	TEMPLATE_DATA_REFRESH                : `${prefix}_TEMPLATE_DATA_REFRESH`,
	TEMPLATE_DATA_LIST_RELOAD            : `${prefix}_TEMPLATE_DATA_LIST_RELOAD`,
	FREE_SPIN_SAVE_WITH_TEMPLATE         : `${prefix}_FREE_SPIN_SAVE_WITH_TEMPLATE`,
	TEMPLATE_DATA_RESET                  : `${prefix}_TEMPLATE_DATA_RESET`,
};

const actions = {
	...TYPES,

	dataSave           : makeActionCreator(TYPES.DATA_SAVE, 'isMassBonusPath'),
	dataRefresh        : makeActionCreator(TYPES.DATA_REFRESH, 'bonusData'),
	providersRefresh   : makeActionCreator(TYPES.PROVIDERS_LIST_REFRESH, 'checkedProvidersByIntegrator'),
	dataReset          : makeActionCreator(TYPES.DATA_RESET),
	bonusDataReset     : makeActionCreator(TYPES.BONUS_DATA_RESET),
	uiRefresh          : makeActionCreator(TYPES.UI_REFRESH, 'UI'),
	templateDataRefresh: makeActionCreator(TYPES.TEMPLATE_DATA_REFRESH, 'userBonusTemplatesData'),
	templateDataReset  : makeActionCreator(TYPES.TEMPLATE_DATA_RESET),
	getByID            : makeActionCreator(TYPES.GET_BY_ID, 'userID', 'bonusID', 'typeID'),


	gameLimitsReload             : makeActionCreator(TYPES.GAME_LIMITS_RELOAD, 'casinoGameID', 'userID', 'currencyCode', 'isMassBonus'),
	betsRefresh                  : makeActionCreator(TYPES.BETS_REFRESH, 'bets'),
	denominationsRefresh         : makeActionCreator(TYPES.DENOMINATIONS_REFRESH, 'denominations'),
	denominationsAndLineRefresh  : makeActionCreator(TYPES.LINE_DENOMINATION_REQUIRED_REFRESH, 'denominationsAndLine'),
	cancelUserBonusData          : makeActionCreator(TYPES.CANCEL_USER_BONUS_DATA),
	resetCheckedData             : makeActionCreator(TYPES.RESET_CHECKED_PROVIDER_BY_INTEGRATION),
	userBonusTemplatesData       : makeActionCreator(TYPES.TEMPLATE_DATA_RELOAD),
	userBonusTemplatesDataList   : makeActionCreator(TYPES.TEMPLATE_DATA_LIST_RELOAD, 'data' ),
	bonusFreeSpinSaveWithTemplate: makeActionCreator(TYPES.FREE_SPIN_SAVE_WITH_TEMPLATE ),
};

export default actions;
