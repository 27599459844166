import { EBonusTemplateTypeIDs } from '../../../redux/loyalty/templates/types';

export enum EBonusTemplateCols {
    ID = 'id',
    NAME = 'name',
    TYPE = 'typeID',
    TEMPLATE_TYPE = 'templateType',
    STATUS = 'statusID',
    CREATED_DATE = 'createDate',
    EXPIRE_DATE = 'templateExpireDate',
    CURRENCY = 'currencies',
    WAGER_TURNOVER = 'wageringTurnover',
    CASINO_GAME = 'casinoGame',
    CASINO_GAME_ID = 'casinoGameID',
    AFFILIATE_REFERENCE = 'affiliateReference',
    ACTIONS = 'actions',
}

export enum EBonusTemplateTypes {
    MANUAL_FREE_SPINS = 'manual_free_spins',
    MANUAL_BONUS_MONEY = 'manual_bonus_money',
}

export const enumBonusTemplateReverse = {
	[EBonusTemplateTypes.MANUAL_FREE_SPINS] : EBonusTemplateTypeIDs.MANUAL_FREE_SPINS,
	[EBonusTemplateTypes.MANUAL_BONUS_MONEY]: EBonusTemplateTypeIDs.MANUAL_BONUS_MONEY,
};

export enum EBonusTemplateTypesStatus {
    MANUAL_FREE_SPINS = 2,
    MANUAL_BONUS_MONEY = 1,
}


export const bonusTemplateTypes = [
	EBonusTemplateTypes.MANUAL_FREE_SPINS,
	EBonusTemplateTypes.MANUAL_BONUS_MONEY,
];

