
import FormInputNumber from '../../../components/FormControls/FormInputNumber';
import Utils from '../FilterUtils';

const InputNumber = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, defaultValue, disabled, minValue, maxValue, step, isId } = filterItem;
	const transformValue = (name, value) => {
		const reg = /^\d+$/;
		let rawValue = (value || (!value && typeof value === 'number')) && value.toString();
		if (value === undefined || value === null) {
			rawValue = '';
		} else if (!isId) {
			const ind = rawValue.indexOf('.');
			rawValue = reg.test() ? rawValue : (rawValue.slice(0, ind + rawValue.length + 1));
		}
		onChange(name, rawValue);
	};
	return (
		<FormInputNumber
			name={name}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			defaultValue={defaultValue || null}
			value={value}
			min={minValue || 0}
			max={maxValue || Number.MAX_SAFE_INTEGER}
			step={step || 1}
			className="single-number-box"
			disabled={disabled}
			onChange={value => transformValue(name, value)}
		/>
	);
};

export default InputNumber;
