import { Map } from 'immutable';
import { fill } from '../../../../helpers/utils';
import actions from './actions';

const initState = new Map({

	balanceData: {
		amount          : '',
		comment         : '',
		userComment     : '',
		wageringTurnover: '',
		daysToExpire    : '',
		forced          : '',
		logoURL         : '',

		titles: [],
		count : {
			providers : {},
			categories: {},
		},
		integratorIDs: [],
		providerIDs  : [],
		categoryIDs  : [],
		tagIDs       : [],
	},
	templateData: {
		amount      : null,
		noteForAdmin: null,
	},

	UI: {
		userID       : null,
		visible      : false,
		loading      : false,
		closeModal   : false,
		isChanged    : false,
		langID       : 0,
		balanceTypeID: 0,
	},
});

export default function userBalanceReducer(state = initState, action) {

	switch (action.type) {

		case actions.USER_BALANCE_DATA_RESET: {
			return initState;
		}

		case actions.USER_BALANCE_DATA_REFRESH: {
			const target = state.get('balanceData');
			const result = fill(action.data, target, true);
			return state.set('balanceData', result);
		}

		case actions.USER_BALANCE_TEMPLATE_DATA_REFRESH: {
			const target = state.get('templateData');
			const result = fill(action.data, target, true);
			return state.set('templateData', result);
		}

		case actions.USER_BALANCE_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}

		default:
			return state;
	}
}
