import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import tabsActions from '../../redux/appTabs/actions';
import socketActions from '../../redux/socket/actions';
import userActions from '../../redux/users/user/actions';
import notifActions from '../../redux/notifications/actions';

import { deriveActiveTabID, deriveActiveTabIsSearch, deriveActiveUserID, deriveAppTabs } from '../../selectors/appTabs';
import { deriveSubscribedTypeRiskManagementBets, deriveSubscribedTypeRiskManagementMarketIDs, deriveSubscribedTypeRTM } from '../../selectors/socket';
import { APP_TABS } from '../../constants/appTabs';
import { CloseIcon, Wrapper } from './AppTabsManager.style';
import { deriveUserUI } from '../../selectors/users';
import { initTabWidths, activeTabIDs } from './utils';
import { deriveNotifBadgeUI } from '../../selectors/notifications';
import { withRouter } from '../../components/hoc/WithRouter/withRouter.tsx';
import { useNavigate } from 'react-router';
import { getSidebarTabsKey } from '../../helpers/locationUtils';
import { restoreAppTabs } from '../../helpers/utility';
import TabTitle from './components/TabTitle';
import { defineActiveTab } from '../../redux/appTabs/utils';

const AppTabsManager = (props) => {
	const {
		tabs,
		activeTabID,
		activeUserID,
		// activeTabIsSearch,
		rtmSubscribedType,
		riskManagementBetsSubscribedType,
		riskManagementBetsUnsubscribe,
		riskManagementBetsTypeRefresh,
		userInnerCurrentTab,
		notifBadgeVisible,
		activeTabSet,
		closeTab,
		closeOtherTabs,
		closeRightTabs,
		closeAllTabs,
		rtmUnsubscribe,
		rtmSubscribedTypeRefresh,
		notifBadgeUiRefresh,
		openTab,
		riskManagementMarketIDsUnsubscribe,
		riskManagementMarketIDsSubscribedType,
		riskManagementMarketIDsTypeRefresh,
	} = props;

	const navigate = useNavigate();
	const myComponentRef = useRef(null);
	const [tabWidth, setTabWidth] = useState(initTabWidths.max);
	const activeTabIDRef = useRef(activeTabID);
	const [syncTabsFinished, setSyncTabsFinished] = useState(false);

	const onChange = (tabKey) => {
		const tab = find(tabs, { id: tabKey });
		activeTabSet(tabKey, tab.titleID);

		// navigate(`${tab.location}${tab.search}`); TODO CHECK MNACAKAN
	};

	const onEdit = (tabKey, action, closeContext) => {
		if (action === 'add') {
			onAddTab(tabKey);
		} else if (action === 'remove') {
			onRemoveTab(tabKey, closeContext);
		}
	};

	const onAddTab = (tabKey) => {
		const tab = find(tabs, { id: tabKey });
		if (!tab) {
			return;
		}
		activeTabSet(tabKey);
		navigate(`${tab.location}`);
	};

	const onRemoveTab = (tabKey, closeContext) => {
		// activeTabIDRef.current = activeTabID; TODO CHECK MNACAKAN
		// if (syncTabsFinished) {
		// 	const existingTab = tabs.find(tab => tab.id === activeTabID);

		// 	if (existingTab) {
		// 		const search = existingTab.search ? existingTab.search : '';
		// 		navigate(`${existingTab.location}${search}`);
		// 	}
		// }
		closeTab(tabKey, closeContext);
	};

	const onCloseAllTabs = () => {
		closeAllTabs();
		navigate(`/${APP_TABS.dashboard}`);
	};

	useEffect(() => {
		const activeTabIDbyPath = getSidebarTabsKey();
		onAddTab(activeTabIDbyPath);
		const handlePopstate = () => {
			const activeTabIDbyPath = getSidebarTabsKey();

			if (activeTabIDRef.current !== activeTabIDbyPath) {
				const activeTab = defineActiveTab(activeTabIDbyPath);
				openTab(activeTab);
			}
		};

		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		};
		// eslint-disable-next-line
	}, [syncTabsFinished]);

	useEffect(() => {
		const getWidth = () => {
			const width = myComponentRef.current.getBoundingClientRect().width;
			const tabWidth = width/tabs.length;
			setTabWidth(tabWidth);
		};

		getWidth();
		window.addEventListener('resize', getWidth);

		const appTabs = restoreAppTabs();
		if (tabs.length === appTabs?.length) {
			setSyncTabsFinished(true);
		}

		return () => {
			window.removeEventListener('resize', getWidth);
		};
	}, [tabs.length]);

	useEffect(() => { 
		activeTabIDRef.current = activeTabID;
		if (syncTabsFinished) {
			const existingTab = tabs.find(tab => tab.id === activeTabID);
			if (existingTab) {
				const search = existingTab.search ? existingTab.search : '';
				navigate(`${existingTab.location}${search}`); 
			}
		}
	}, [activeTabID, activeUserID]);

	useEffect(() => {
		if (notifBadgeVisible) {
			notifBadgeUiRefresh({ visible: !notifBadgeVisible });
		}
		// eslint-disable-next-line
	}, [userInnerCurrentTab]);

	useEffect(() => {
		const isActiveTab = activeTabIDs.includes(activeTabID);
		const wasSubscribed = Boolean(rtmSubscribedType);

		if (isActiveTab && wasSubscribed && activeTabID !== rtmSubscribedType) {
			rtmUnsubscribe(rtmSubscribedType);
		} else if (!isActiveTab && wasSubscribed) {
			rtmUnsubscribe(rtmSubscribedType);
			rtmSubscribedTypeRefresh(null);
		}
		if (!isActiveTab && riskManagementBetsSubscribedType) {
			riskManagementBetsUnsubscribe(riskManagementBetsSubscribedType);
			riskManagementBetsTypeRefresh(null);

			riskManagementMarketIDsUnsubscribe(riskManagementMarketIDsSubscribedType);
			riskManagementMarketIDsTypeRefresh(null);
		}
		// eslint-disable-next-line
	}, [activeTabID, rtmSubscribedType, rtmUnsubscribe, rtmSubscribedTypeRefresh]);

	const renderPanes = () => {
		return tabs.map((tab) => ({
			label: (
				<TabTitle
					tabs={tabs}
					id={tab.id}
					titleID={tab.titleID}
					closeTab={onEdit}
					closeOtherTabs={closeOtherTabs}
					closeRightTabs={closeRightTabs}
				/>
			),
			key     : tab.id,
			closable: tab.id !== APP_TABS.dashboard,
		}));
	};

	const renderButtonClose = () => (
		<CloseIcon onClick={onCloseAllTabs} title="Close all">
			<CloseOutlined />
		</CloseIcon>
	);

	const panes = renderPanes();
	const btnClose = renderButtonClose();
	const width = (tabWidth >= initTabWidths.max) ? initTabWidths.max : (tabWidth <= initTabWidths.min) ? initTabWidths.min : tabWidth;
	return (
		<Wrapper ref={myComponentRef} tabWidth={width} className='app tabs manager'>
			<Tabs
				type="editable-card"
				onChange={onChange}
				activeKey={activeTabID}
				onEdit={onEdit}
				items={panes}
				tabBarExtraContent={btnClose}
				hideAdd
			/>
		</Wrapper>
	);
};

AppTabsManager.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id            : PropTypes.string.isRequired,
			titleID       : PropTypes.string.isRequired,
			componentName : PropTypes.string.isRequired,
			componentProps: PropTypes.object,
			location      : PropTypes.string.isRequired,
		})
	).isRequired,
	userInnerCurrentTab             : PropTypes.string.isRequired,
	activeTabSet                    : PropTypes.func.isRequired,
	closeTab                        : PropTypes.func.isRequired,
	closeAllTabs                    : PropTypes.func.isRequired,
	rtmUnsubscribe                  : PropTypes.func.isRequired,
	closeOtherTabs                  : PropTypes.func.isRequired,
	closeRightTabs                  : PropTypes.func.isRequired,
	rtmSubscribedTypeRefresh        : PropTypes.func.isRequired,
	riskManagementBetsUnsubscribe   : PropTypes.func.isRequired,
	riskManagementBetsTypeRefresh   : PropTypes.func.isRequired,
	changeUserInnerTabs             : PropTypes.func.isRequired,
	notifBadgeUiRefresh             : PropTypes.func.isRequired,
	notifBadgeVisible               : PropTypes.bool.isRequired,
	activeTabID                     : PropTypes.string,
	activeUserID                    : PropTypes.string,
	activeTabIsSearch               : PropTypes.bool,
	rtmSubscribedType               : PropTypes.string,
	riskManagementBetsSubscribedType: PropTypes.string,
	location                        : PropTypes.object.isRequired,
	openTab                         : PropTypes.func.isRequired,
};

AppTabsManager.defaultProps = {
	activeTabID      : APP_TABS.dashboard,
	activeTabIsSearch: false,
	rtmSubscribedType: null,
};

function mapStateToProps(state) {
	return {
		tabs                                 : deriveAppTabs(state),
		activeTabID                          : deriveActiveTabID(state),
		activeUserID                         : deriveActiveUserID(state),
		activeTabIsSearch                    : deriveActiveTabIsSearch(state),
		rtmSubscribedType                    : deriveSubscribedTypeRTM(state),
		riskManagementBetsSubscribedType     : deriveSubscribedTypeRiskManagementBets(state),
		riskManagementMarketIDsSubscribedType: deriveSubscribedTypeRiskManagementMarketIDs(state),
		userInnerCurrentTab                  : deriveUserUI(state).currentTab,
		notifBadgeVisible                    : deriveNotifBadgeUI(state).visible,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		activeTabSet  : tabsActions.activeTabSet,
		closeTab      : tabsActions.closeTab,
		closeOtherTabs: tabsActions.closeOtherTabs,
		closeRightTabs: tabsActions.closeRightTabs,
		closeAllTabs  : tabsActions.closeAllTabs,
		rtmUnsubscribe: socketActions.rtmUnsubscribe,

		riskManagementBetsUnsubscribe: socketActions.riskManagementBetsUnsubscribe,
		riskManagementBetsTypeRefresh: socketActions.riskManagementBetsTypeRefresh,

		riskManagementMarketIDsUnsubscribe: socketActions.riskManagementMarketIDsUnsubscribe,
		riskManagementMarketIDsTypeRefresh: socketActions.riskManagementMarketIDsTypeRefresh,
		
		rtmSubscribedTypeRefresh: socketActions.rtmSubscribedTypeRefresh,
		changeUserInnerTabs     : userActions.setValueUI,
		notifBadgeUiRefresh     : notifActions.uiRefresh,
		openTab                 : tabsActions.openTab,
	})(AppTabsManager)
);
