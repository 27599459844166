import styled from 'styled-components';
import WithDirection from '../../../config/withDirection';

const NumberRangeWrapper = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .number-box {
    max-width: 50%;

    &:first-child {
      margin-left: 0px;
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
      margin-right: 0px;
    }
  }
`;

export default WithDirection(NumberRangeWrapper);
