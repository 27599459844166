import { all, takeEvery, put, fork, select, call, takeLatest } from 'redux-saga/effects';
import actions from './actions';

import defaultSidebar from '../../config/defaultSidebar';
import notifications from '../../helpers/notifications';
import { sidebarAPI } from '../../helpers/api/sidebar';

import { adaptSidebar, prepareSidebar, createDefaultMenu } from './utils';
import { additionalKeys, createSidebarKeys } from '../../constants/appTabs';
import { storeTabIDS } from '../../helpers/utility';
import { checkPathName, returnObject } from '../../components/utility/searchSidebarData';
import { logger } from '../../helpers/logger';

export const prefix = 'sidebar';
export const messages = {
	errorSettingsLoad  : `${prefix}.errorSettingsLoad`,
	errorSettingsSave  : `${prefix}.errorSettingsSave`,
	successSettingsSave: `${prefix}.successSettingsSave`,
};

function getStoreData({ Auth, Sidebar }) {

	return {
		user       : Auth.get('user'),
		permissions: Auth.get('permissions'),
		menu       : Sidebar.get('menu'),
		editMenu   : Sidebar.get('editMenu'),
	};
}

// function* sidebarReload() {

//   yield takeEvery(actions.SIDEBAR_SETTINGS_RELOAD, function* () {

//     yield put(actions.uiRefresh({ loading: true }));

//     const { user, permissions } = yield select(getStoreData);
//     let menu = [];
//     let isError = false;
//     try {
//       const res = yield call(sidebarAPI.sidebarSettingsLoad, user.id);
//       if (res && res.status === 200) {
//         menu = adaptSidebar(res.data.data, user, permissions);
//       }
//       const sidebarKeys = createSidebarKeys(defaultSidebar, permissions, [], {});
//       const AVAILABLE_TABS_IDS = additionalKeys.concat( sidebarKeys );

//       yield put(actions.sidebarKeysRefresh(sidebarKeys));
//       yield storeTabIDS(AVAILABLE_TABS_IDS);

//     } catch (error) {
//       isError = true;
//       notifications.showWarning(messages.errorSettingsLoad);
//       console.error(error);
//     }

//     // TODO: Attention!!! Default sidebar, non-secure in part of permissions
//     if (!menu.length && isError) {
//       console.error('ALARM!!! Sidebar is set by default because of loading error');
//       menu = defaultSidebar;
//     }

//     yield put(actions.sidebarRefresh(menu));
//     yield put(actions.uiRefresh({ loading: false }));
//   });
// }
function* sidebarReload() {

	yield takeLatest(actions.SIDEBAR_SETTINGS_RELOAD, function* () {
		const { user, permissions } = yield select(getStoreData);

		let menu = [];
		let currentMenu = defaultSidebar;
		try {
			const res = yield call(sidebarAPI.sidebarSettingsLoad, user.id);
			if (res && res.status === 200) {
				currentMenu = res.data.data;
			}

		} catch (error) {
			notifications.showWarning(messages.errorSettingsLoad);
			logger.error(error);
		}

		menu = adaptSidebar(currentMenu, user, permissions);

		const sidebarKeys = createSidebarKeys(defaultSidebar, permissions, [], {});
		const AVAILABLE_TABS_IDS = additionalKeys.concat( sidebarKeys );

		const resSidebarSearch = returnObject(menu);
		const dataSidebarSearch = checkPathName(resSidebarSearch);
		yield put(actions.sidebarKeysRefresh(sidebarKeys));
		yield storeTabIDS(AVAILABLE_TABS_IDS);
		yield put(actions.sidebarRefresh(menu));
		yield put(actions.addDataInSearchMenu(dataSidebarSearch));
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* sidebarSave() {

	yield takeEvery(actions.SIDEBAR_SETTINGS_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { user, editMenu } = yield select(getStoreData);
		const userID = user.id;

		const data = prepareSidebar(editMenu, userID);

		try {
			const res = yield call(sidebarAPI.sidebarSettingsSave, userID, data);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.succesSettingsSave);
			}
		} catch (error) {
			notifications.showWarning(messages.errorSettingsSave);
			logger.error(error);
		}

		yield put(actions.sidebarReload(userID, true));
	});
}

function* sidebarReset() {

	yield takeEvery(actions.SIDEBAR_SETTINGS_RESET, function* () {

		const { editMenu, user } = yield select(getStoreData);
		const defaultMenu = createDefaultMenu(editMenu, user);

		yield put(actions.sidebarEditRefresh(defaultMenu));
		yield put(actions.sidebarSave());
	});
}

export default function* sidebarSaga() {
	yield all([
		fork(sidebarReload),
		fork(sidebarSave),
		fork(sidebarReset),
	]);
}
