import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import { usersAPI } from '../../../../helpers/api/users';
import { showError, showSuccess } from '../../../../helpers/notifications';
import balanceAdjustmentsActions from '../../balanceAdjustments/actions';
import bonusActions from '../../../loyalty/bonuses/userBonus/actions';
import { logger } from '../../../../helpers/logger';
import { USER_INFO_VIEW_TABS } from '../../../../helpers/commonConstants';
import { BONUS_TABS } from '../../../loyalty/bonuses/userBonus/utils';
import userCasinoBonusesActions from '../../../loyalty/bonuses//userCasinoBonuses/actions';
import { ErrorCodes } from '../../../../errors/ErrorCodes';

const prefix = 'users.userBalance';

const messages = {
	errorBalanceSave  : `${prefix}.errorBalanceSave`,
	successBalanceSave: `${prefix}.successBalanceSave`,

	successManualBonusSaveTemplate   : `${prefix}.successManualBonusSaveTemplate`,
	errorManualBonusSaveTemplate     : `${prefix}.errorManualBonusSaveTemplate`,
	errorLimitManualBonusSaveTemplate: `${prefix}.errorLimitManualBonusSaveTemplate`,
};

function getStoreData({ Users, Loyalty, App }) {
	return {
		closeModal          : Users.Adjustment.UserBalance.get('UI').closeModal,
		balanceData         : Users.Adjustment.UserBalance.get('balanceData'),
		templateData        : Users.Adjustment.UserBalance.get('templateData'),
		userID              : Users.User.get('baseData').id,
		templateID          : Loyalty.Bonuses.UserBonus.get('UI').selectedTemplateID,
		websiteID           : App.get('websiteID'),
		innerMainCurrentTab : Users.User.get('UI').currentTab,
		innerBonusCuttentTab: Loyalty.Bonuses.UserBonus.get('UI').currentTab,
	};
}


function* manualBonusSave() {
	yield takeEvery(actions.USER_BALANCE_TEMPLATE_DATA_SAVE, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { userID, templateData, websiteID, templateID, innerMainCurrentTab, innerBonusCuttentTab } = yield select(getStoreData);
		const { amount, noteForAdmin } = templateData;
		const preparedData = {
			template_id: templateID,
			website_id : websiteID,
			comment    : noteForAdmin,
			amount,
		};

		try {
			const res = yield call(usersAPI.userManualBonusTemplateSave, userID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successManualBonusSaveTemplate);
				yield put(bonusActions.uiRefresh({ visible: false }));
				yield put(actions.templateDataRefresh({ amount: null, noteForAdmin: null }));
				if ((innerMainCurrentTab === USER_INFO_VIEW_TABS.bonus) && (innerBonusCuttentTab === BONUS_TABS.bonuses)) {
					yield put(userCasinoBonusesActions.listReload(userID));
				}
			}
		} catch (error) {
			if (error.response.data.text_code === ErrorCodes.TOTAL_AMOUNT_LIMIT) {
				showError(messages.errorLimitManualBonusSaveTemplate);
			} else {
				showError(messages.errorManualBonusSaveTemplate, error); //here
			}
			logger.log(error);
		}

		yield put(actions.uiRefresh({
			loading  : false,
			isChanged: false,
		}));
	});
}
function* balanceSave() {
	yield takeEvery(actions.USER_BALANCE_SAVE, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		const { closeModal } = yield select(getStoreData);
		const { userID, balanceData } = yield select(getStoreData);
		const { amount, comment, userComment, wageringTurnover, daysToExpire, forced, logoURL, titles } = balanceData;

		const preparedData = {
			user_id          : userID,
			user_comment     : userComment,
			wagering_turnover: wageringTurnover,
			days_to_expire   : daysToExpire,
			amount,
			comment,
		};
		if (wageringTurnover) {
			preparedData.forced   = forced;
			preparedData.logo_url = logoURL;
			preparedData.titles   = titles;
		}

		let isError = false;
		try {
			const res = yield call(usersAPI.userBalanceUpdate, userID, preparedData);
			if (res && res.status === 200) {
				showSuccess(messages.successBalanceSave);
			}
		} catch (error) {
			isError = true;
			showError(messages.errorBalanceSave, error);
			logger.log(error);
		}

		yield put(balanceAdjustmentsActions.listReload(userID));
		yield put(
			actions.uiRefresh({
				loading  : false,
				isChanged: false,
			})
		);
		if (closeModal && !isError) {
			yield put(actions.dataReset());
		}
	});
}

export default function* userBalanceSaga() {
	yield all([
		fork(balanceSave),
		fork(manualBonusSave),
	]);
}
