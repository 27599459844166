import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';

import notifications from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../helpers/utils';

import { getListParams, adaptFreeSpinsList, updateFreeSpinList } from './utils';
import { logger } from '../../../../helpers/logger';
import { BONUS_STATUSES } from '../../../../constants/bonuses';
import { formatDate } from '../../../../helpers/utility';

const messages = {
	errorListLoad: 'CANCEL_FREE_SPIN_ERROR',
};

function getStoreData({ Loyalty: { Bonuses }, App, Auth }) {
	const { UserCasinoFreeSpins, UserCasinoFreeSpinsFilter } = Bonuses;

	return {
		entities  : UserCasinoFreeSpins.get('entities'),
		filter    : UserCasinoFreeSpinsFilter.get('filter'),
		pagination: UserCasinoFreeSpins.get('pagination'),
		websiteID : App.get('websiteID'),
		user      : Auth.get('user'),
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* (action) {

		yield put(actions.uiMerge({ loading: true }));

		const { pagination, filter } = yield select(getStoreData);
		const { userID } = action.data;
		const params = getListParams(pagination, filter);

		let entities   = {};
		let totalCount = 0;
		try {
			const res = yield call(bonusesAPI.casinoBonusSpins, userID, params);
			if (res && res.status === 200) {
				entities   = adaptFreeSpinsList(res.data.data, res.headers);
				totalCount = getHeadersTotalCount(res.headers);
			}

		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.listRefresh(entities));
		yield put(actions.paginationRefresh({ totalCount }));
		yield put(actions.uiMerge({ loading: false }));
	});
}

function* cancelFreeSpin() {

	yield takeEvery(actions.CANCEL_FREE_SPIN, function* (action) {
		const { websiteID, entities, user } = yield select(getStoreData);
		const { id, userID } = action.data.data;
		const params = {
			id,
			user_id   : userID,
			website_id: +websiteID,
		};

		let clonedEntities   = {};
		clonedEntities = updateFreeSpinList({ entities, spinID: id, loading: true });
		yield put(actions.listRefresh(clonedEntities));

		try {
			const res = yield call(bonusesAPI.freeSpinCancel, params);
			if (res && res.status === 200) {
				const updateOptions = {
					entities,
					spinID    : id,
					loading   : false,
					statusID  : BONUS_STATUSES.CANCELED,
					adminID   : user.id,
					cancelDate: formatDate(new Date()),
				};
				
				clonedEntities = updateFreeSpinList(updateOptions);
				yield put(actions.listRefresh(clonedEntities));
			}

		} catch (error) {
			clonedEntities = updateFreeSpinList({ entities, spinID: id, loading: false });
			yield put(actions.listRefresh(clonedEntities));
			notifications.showError(messages.errorListLoad, error);
			logger.log(error);
		}
	});
}

function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(actions.paginationRefresh({ currentPage: 1 }));
		yield put(actions.listReload());
	});
}

export default function* userCasinoFreeSpinsSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(cancelFreeSpin),
	]);
}
