import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';

import Select, { SelectOption } from '../select';
import Option from './Option';
import SelectAll from './SelectAll';
import { Wrapper } from './MultiSelect.style';

class MultiSelect extends PureComponent {

	static propTypes = {
		options: PropTypes.arrayOf(PropTypes.shape({
			id  : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		})),
		name        : PropTypes.string,
		placeholder : PropTypes.string,
		disabled    : PropTypes.bool,
		defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		])),
		value: PropTypes.arrayOf(PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		])),
		onChange: PropTypes.func,
		dataId  : PropTypes.string,
	};

	static defaultProps = {
		options     : [],
		placeholder : '',
		name        : '',
		disabled    : false,
		defaultValue: [],
		value       : [],
		onChange    : () => [],
		dataId      : '',
	};

	constructor(props) {
		super(props);
		this.getPopupContainer = this.getPopupContainer.bind(this);
		this.onFilterOption    = this.onFilterOption.bind(this);
		this.onSelect          = this.onSelect.bind(this);
		this.onDeselect        = this.onDeselect.bind(this);
		this.onChangeLocal     = this.onChangeLocal.bind(this);
		this.onSelectAll       = this.onSelectAll.bind(this);
		this.renderOptions     = this.renderOptions.bind(this);

		this.selectID = uuid();
	}

	// Service --------------------------------------------------------------------------------------
	getPopupContainer() {
		return document.getElementById(this.selectID);
	}

	onFilterOption(input, option) {
		if (typeof option.props.children.props.name === 'string') {
			return  option.props.children.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		}
		return false;
	}

	// Events ---------------------------------------------------------------------------------------
	onSelect(selectedID) {
		const { value, onChange } = this.props;
		if (selectedID === this.selectID) {
			this.onSelectAll();
			return;
		}

		const resValue = isArray(value) ? cloneDeep(value) : [];
		if (!resValue.includes(selectedID)) {
			resValue.push(selectedID);
		}

		onChange(resValue);
	}

	onDeselect(selectedID) {
		const { value, onChange } = this.props;
		if (selectedID === this.selectID) {
			this.onSelectAll();
			return;
		}

		let resValue = isArray(value) ? cloneDeep(value) : [];
		resValue = resValue.filter(item => item !== selectedID);

		onChange(resValue);
	}

	onChangeLocal(selectedIDs) {
		const { onChange } = this.props;
		if (isArray(selectedIDs) && selectedIDs.length > 0) {
			return;
		}

		onChange([]);
	}

	onSelectAll() {
		const { options, value, onChange } = this.props;
		const allSelected = (isArray(options) && isArray(value) && options.length === value.length);

		if (allSelected) {
			onChange([]);
		} else {
			const IDs = options.map(item => String(item.id));
			onChange(IDs);
		}
	}

	// Renders --------------------------------------------------------------------------------------
	renderOptions() {
		const { options, value } = this.props;
		if (!isArray(options)) {
			return null;
		}
		const allSelected = (isArray(options) && isArray(value) && options.length === value.length);

		const renderOptions = options.map( item => {
			return (
				<SelectOption value={String(item.id)} key={(item.id) + item.name}>
					<Option name={item.name} />
				</SelectOption>
			);
		});

		if (options.length > 0) {
			renderOptions.unshift((
				<SelectOption value={this.selectID} key={this.selectID}>
					<SelectAll
						name={allSelected ? 'Unselect all' : 'Select all'}
						selected={allSelected}
					/>
				</SelectOption>
			));
		}

		return renderOptions;
	}

	render() {
		const { placeholder, defaultValue, value, disabled, name, dataId } = this.props;
		const renderOptions = this.renderOptions();
		return (
			<Wrapper id={this.selectID} name={name}>
				<Select
					data-qa-id = {dataId}
					allowClear
					mode="multiple"
					tokenSeparators={[',']}
					placeholder={placeholder}
					defaultValue={defaultValue}
					value={value}
					style={{ width: '100%', overflowY: 'auto' }}
					disabled={disabled}
					getPopupContainer={this.getPopupContainer}
					onChange={this.onChangeLocal}
					onSelect={this.onSelect}
					onDeselect={this.onDeselect}
					filterOption={this.onFilterOption}
				>
					{renderOptions}
				</Select>
			</Wrapper>
		);
	}
}

export default MultiSelect;
