import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 0 8px 0;
  width: 100%;
  margin-bottom: 10px;

  .single-number-box {
    max-width: 100%;
    margin-right: 0;

    .ant-input-number-input-wrap {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 8px 0 8px 18px;
        display:flex;
        align-items:center;

        .ant-collapse-arrow {
          left: 5px;
          font-size: 8px;
        }
      }
      .ant-collapse-content {
        padding: 0 4px;
        overflow:visible;
        
        .ant-collapse-content-box {
          .ant-input-number {
						height: 32px!important;

            .ant-input-number-input {
              height: 30px!important;
						}
					}
					
					.ant-row {
						margin-bottom: 8px;
					}
					
					.form-control {
						min-height: 32px;
						margin-bottom: 0;
						
						.control {
              .ant-select {
								vertical-align: top;
							}
						}
					}

          .ant-select-selector input {
            cursor: pointer!important;
						height: 28px;
          }

          .ant-select-dropdown {
            top: 100% !important;
						min-height: max-content;
						right: 0;
          }

          .ant-btn {
						height: 32px!important;
					}
        }
      }
    }
  }
`;
