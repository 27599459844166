import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import notifications, { showError } from '../../../../../helpers/notifications';
import { usersAPI } from '../../../../../helpers/api/users';
import { downloadReport, getHeadersTotalCount } from '../../../../../helpers/utils';

import actions from './actions';
import tableActions from '../../../../tables/actions';
import { getListParams, adaptUsersList, createReportParams } from './utils';
import { deriveTablePagination } from '../../../../../selectors/tables';
import { TABLE_TYPES } from '../../../../../constants/tableTypes';
import { logger } from '../../../../../helpers/logger';
import { availableFields } from '../../../../users/reportUsers/utils';

const tableType       = TABLE_TYPES.customersSearch;

const messages = {
	errorLoadList: 'loyalty.bonuses.massBonus.create.usersList.reload.error',
	errorDataLoad: 'loyalty.bonuses.massBonus.create.usersReport.reload.error',
};

function getStoreData(state) {
	const { Tables, Loyalty: { Bonuses } } = state;
	return {
		filter      : Bonuses.UserMassBonus.UserMassBonusFilter.get('filter'),
		reportFields: Bonuses.UserMassBonus.UserMassBonusList.get('reportFields'),
		pagination  : deriveTablePagination(tableType, state),
		sorting     : Tables.get(tableType).sorting,
		massBonus   : Bonuses.UserBonus.get('UI').massBonus,
	};
}

function* listReload() {

	yield takeEvery(actions.USERS_MASS_BONUS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));

		let list = [];
		let totalCount = 0;

		const { filter, sorting, pagination, massBonus } = yield select(getStoreData);

		const params = getListParams(filter, sorting, pagination, massBonus);
		try {
			const res = yield call(usersAPI.usersList, null, params);
			if (res && res.status === 200) {
				list = adaptUsersList(res.data.data);
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(list));
			}

			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			notifications.showError(messages.errorLoadList, error);
			yield put(actions.uiRefresh({ loading: true }));
			yield put(actions.listRefresh([]));

			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* reportDataReload() {

	yield takeEvery(actions.USERS_MASS_BONUS_REPORT_DATA_RELOAD, function* () {

		yield put(actions.uiRefresh({ reportLoading: true }));
		// yield put(actions.dataRefresh('')); // TODO check

		const { filter, sorting, reportFields } = yield select(getStoreData);
		const params = createReportParams(filter, sorting, reportFields, availableFields);

		try {
			const res = yield call(usersAPI.usersCSVLoad, params);
			if (res) {
				downloadReport(res);
			}

		} catch (error) {
			showError(messages.errorDataLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ reportLoading: false }));
	});
}

export default function* listSaga() {
	yield all([
		fork(listReload),
		fork(reportDataReload),
	]);
}
