import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toInteger from 'lodash/toInteger';
import { connect } from 'react-redux';
import Select, { SelectOption } from '../select';
import { getStoredDataBaseLangID } from '../../../helpers/utility';
import Wrapper from './index.style';
import { langList } from '../../../helpers/commonEnums';

class LanguageSwitcher extends Component {

	static propTypes = {
		label              : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		langID             : PropTypes.number,
		disabled           : PropTypes.bool,
		onChangeLanguage   : PropTypes.func,
		contentLanguageList: PropTypes.array,
		optionsList        : PropTypes.array,
		isMainLangList     : PropTypes.bool,
		loading            : PropTypes.bool,
		// allLanguageList     : PropTypes.array,
		customStyle        : PropTypes.object,
		className          : PropTypes.string,
	};

	static defaultProps = {
		label              : '',
		langID             : getStoredDataBaseLangID(),
		disabled           : false,
		onChangeLanguage   : null,
		contentLanguageList: [],
		optionsList        : null,
		isMainLangList     : false,
		loading        				: false,
		// allLanguageList     : [],
		customStyle        : {},
		className          : '',
	};

	constructor(props) {
		super(props);
		this.onChange            = this.onChange.bind(this);
		this.renderOptions       = this.renderOptions.bind(this);
		this.renderGlobalOptions = this.renderGlobalOptions.bind(this);
	}


	// Events ---------------------------------------------------------------------------------------

	onChange(value) {
		const { onChangeLanguage } = this.props;
		const langID = toInteger(value);
		if (onChangeLanguage) {
			onChangeLanguage(langID);
		}
	}

	// Renders --------------------------------------------------------------------------------------

	/**
   * is used to render languages in any scope (Partner, website).
   * @returns {*}
   */
	renderOptions() {
		const { contentLanguageList, isMainLangList } = this.props;
		const currentLangList = isMainLangList ? langList : contentLanguageList;
		return currentLangList.map(item => <SelectOption key={item.id}>{item.name}</SelectOption>);
	}

	/**
   * is Used to render global languages
   * @returns {*}
   */
	renderGlobalOptions() {
		const { optionsList } = this.props;
		return optionsList.map(item => <SelectOption key={item.id}>{item.name}</SelectOption>);

	}

	render() {
		const { label, langID, disabled, customStyle, optionsList, loading, className } = this.props;
		const isLabel = Boolean(label);

		return (
			<Wrapper className={className} style={customStyle}>
				{isLabel && <div className="label">{label}</div>}
				<div className="language-switcher">
					<Select
						value={String(langID)}
						disabled={disabled}
						loading={loading}
						style={{ width: '100%' }}
						onChange={this.onChange}
					>
						{optionsList ? this.renderGlobalOptions() : this.renderOptions()}
					</Select>
				</div>
			</Wrapper>
		);
	}
}
//
export default connect(({ App }) => ({
	contentLanguageList: App.get('websiteAttachedLanguages'),
	// allLanguageList     : App.get('allLanguageList'),
}))(LanguageSwitcher);
