import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .download {
    margin-right: 8px;
  }

  .recalculate {
    margin-right: 16px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkHolder = styled.div`
  margin-left: 8px;
`;
