import toInteger from 'lodash/toInteger';
import cloneDeep from 'lodash/cloneDeep';
import { isUndefined, find } from 'lodash';
import { isID } from '../../../../helpers/utils';
import {
	fields,
	createListAdapter,
	createCasinoListAdapter,
	createCurrencyListAdapter,
	createLanguageListAdapter,
	createCountryListAdapter,
	createAttachedLanguageListAdapter,
	createAttachedCountryListAdapter,
	createCountryListPreparer,
	createSeoAdapter,
} from '../list/utils';
import { websiteDomainTypes } from '../../../../constants/website';
import { createEntities } from '../../../utility';
import { WebsiteUserShowRequiredFields } from './types';
import { langToString } from '../../../../languageProvider';

const lang = {
	email      : 'website.registration_fields.email',
	password   : 'website.registration_fields.password',
	passConfirm: 'website.registration_fields.password_confirmation',
	username   : 'website.registration_fields.username',
	firstName  : 'website.registration_fields.first_name',
	lastName   : 'website.registration_fields.last_name',
	gender     : 'website.registration_fields.gender',
	currency   : 'website.registration_fields.currency',
	birth      : 'website.registration_fields.birth',
	phone      : 'website.registration_fields.phone',
	country    : 'website.registration_fields.country',
	city       : 'website.registration_fields.city',
	address1   : 'website.registration_fields.address1',
	address2   : 'website.registration_fields.address2',
	nationalID : 'website.registration_fields.national_id',
};

export const websiteConfigsFields = {
	email               : 'email',
	phone               : 'phone',
	currencyID          : 'currency_id',
	password            : 'password',
	nationalID          : 'national_id',
	userName            : 'username',
	firstName           : 'first_name',
	lastName            : 'last_name',
	gender              : 'gender',
	birth               : 'birth',
	statusID            : 'status_id',
	country             : 'country',
	languageID          : 'language_id',
	city                : 'city',
	address1            : 'address_1',
	address2            : 'address_2',
	affiliateReference  : 'affiliate_reference',
	passwordConfirmation: 'password_confirmation',
};

const listAdapter = createListAdapter();

const casinoListAdapter = createCasinoListAdapter();
const currencyListAdapter = createCurrencyListAdapter();
const languageListAdapter = createLanguageListAdapter();
const attachedLanguageListAdapter = createAttachedLanguageListAdapter();
const countryListAdapter = createCountryListAdapter();
const countryListPreparer = createCountryListPreparer();
const attachedCountryListAdapter = createAttachedCountryListAdapter();
const seoAdapter = createSeoAdapter();


// Adapt ------------------------------------------------------------------------------------------

export function adaptWebsite(rawData = {}) {
	listAdapter.clearExcludes();
	const adaptedData = listAdapter.adapt(rawData);
	const fields = reverseFields(cloneDeep(websiteConfigsFields));
	if (adaptedData.userShowFields) {
		adaptedData.userShowFields = adaptedData.userShowFields.map(item => fields[item]);
	}
	if (adaptedData.userRequiredFields) {
		adaptedData.userRequiredFields = adaptedData.userRequiredFields.map(item => fields[item]);
	}
	return adaptedData;
}

export function adaptCasino(rawData = []) {

	casinoListAdapter.clearExcludes();
	const adaptedData = casinoListAdapter.adaptList(rawData);

	return createEntities(adaptedData);
}

export function adaptAdditionalDomains(rawData = [], baseDataAdditionalDomains, create) {

	const entities = {};
	const allreadyCreatedDomains = [];

	if (create) {

		Object.values(baseDataAdditionalDomains).forEach(domain => {
			if (!isUndefined(domain.id)) {
				allreadyCreatedDomains.push(domain);
			}
		});
	}

	[...allreadyCreatedDomains, ...rawData].forEach(item => {

		entities[item.id] = {
			id       : item.id,
			websiteID: item.website_id,
			domain   : item.domain,
			redirect : item.domain_type === websiteDomainTypes.redirect || item.redirect,
		};

	});
	return entities;
}

export function adaptNewDomainsForSend(rawData = {}, websiteID, withoutArray) {

	let adaptedData = [];

	if (withoutArray) {
		adaptedData = {
			id         : rawData.id,
			website_id : rawData.websiteID,
			domain     : rawData.domain,
			domain_type: rawData.redirect ? 1 : 2,
		};

	} else {

		Object.values(rawData).forEach(domain => {
			if (isUndefined(domain.id)) {
				adaptedData.push({
					website_id : websiteID,
					domain     : domain.domain,
					domain_type: domain.redirect ? 1 : 2,
				});
			}
		});
	}

	return adaptedData;

}

export function adaptCurrencies(rawData = [], currencyList = []) {

	currencyListAdapter.clearExcludes();
	const adaptedList = currencyListAdapter.adaptList(rawData);
	const adaptedData = currencyList.map(currencyItem => {

		const currencyID = currencyItem.id;
		const adaptedItem = find(adaptedList, { currencyID }) || {};

		return {
			...adaptedItem,
			currencyID,
			name   : `${currencyItem.description || currencyItem.name} (${currencyItem.code})`,
			isExist: Boolean(adaptedItem.currencyID),
		};
	});

	return createEntities(adaptedData, 'currencyID');
}

// Prepare ----------------------------------------------------------------------------------------


export function adaptLanguages(rawData, attachedData, attachedLangCount = 0) {
	languageListAdapter.clearExcludes();
	attachedLanguageListAdapter.clearExcludes();
	const adaptedList = languageListAdapter.adaptList(rawData).map(item => ({
		...item,
		name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
	}));
	const adaptedAttachedList = attachedLanguageListAdapter.adaptList(attachedData);
	const adaptedData = adaptedList.map(languageItem => {

		const attachedValue = find(adaptedAttachedList, { languageID: languageItem.languageID });
		if (attachedValue) {
			attachedLangCount += 1;
			return {
				...languageItem,
				orderID: attachedValue.orderID,
				isExist: Boolean(attachedValue),
			};
		}
		return languageItem;

	});

	const entities = createEntities(adaptedData, 'languageID');

	return {
		entities,
		adaptedData,
		attachedLangCount,
	};
}

export function prepareLanguages(rawData) {
	attachedLanguageListAdapter.clearExcludes();
	const prepared = attachedLanguageListAdapter.prepare(rawData);
	return prepared;
}

export function prepareCountryData(rawData) {
	countryListPreparer.clearExcludes();
	const prepared = countryListPreparer.prepare(rawData);
	return prepared;
}


export function adaptCountries(rawData, attachedData) {
	countryListAdapter.addExcludeField('website_id');
	attachedCountryListAdapter.clearExcludes();
	const adaptedList = countryListAdapter.adaptList(rawData);
	const adaptedAttachedData = attachedCountryListAdapter.adaptList(attachedData);

	const adaptedData = adaptedList.map(countryItem => {
		const attachedValue = find(adaptedAttachedData, { countryID: countryItem.countryID });

		if (attachedValue) {
			return {
				...countryItem,
				isExist: Boolean(attachedValue),
			};
		}

		return countryItem;
	});

	const entities = createEntities(adaptedData, 'countryID');

	return {
		entities,
		adaptedData,
	};
}


export function prepareWebsite(baseData, changedFields, currentTab, activeTab) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('responsibleDBStatus');

	if (changedFields.suspendPrematch && changedFields.suspendInplay) {
		listAdapter.addExcludeField('suspendAll');
	} else if (changedFields.suspendPrematch && changedFields.suspendAll) {
		listAdapter.addExcludeField('suspendInplay');
	} else if (changedFields.suspendInplay && changedFields.suspendAll) {
		listAdapter.addExcludeField('suspendPrematch');
	} else if (changedFields.suspendAll) {
		if (baseData.suspendPrematchByPlatform || baseData.suspendPrematchInPartner) {
			listAdapter.addExcludeField('suspendPrematch');
		}
		if (baseData.suspendInPlayByPlatform || baseData.suspendInPlayInPartner) {

			listAdapter.addExcludeField('suspendInplay');
		}
	} else if (changedFields.suspendPrematch) {
		listAdapter.addExcludeField('suspendAll');
		listAdapter.addExcludeField('suspendInplay');
	} else if (changedFields.suspendInplay) {
		listAdapter.addExcludeField('suspendAll');
		listAdapter.addExcludeField('suspendPrematch');
	} else if (!changedFields.suspendAll && !changedFields.suspendPrematch && !changedFields.suspendInplay) {
		listAdapter.addExcludeField('suspendAll');
		listAdapter.addExcludeField('suspendPrematch');
		listAdapter.addExcludeField('suspendInplay');
	}

	listAdapter.addExcludeField('suspendPrematchInPartner');
	listAdapter.addExcludeField('suspendInPlayInPartner');

	listAdapter.addExcludeField('suspendAllByPlatform');
	listAdapter.addExcludeField('suspendInPlayByPlatform');
	listAdapter.addExcludeField('suspendPrematchByPlatform');
	listAdapter.addExcludeField('changedStatusByPlatform');

	activeTab !== 'integration' && listAdapter.addExcludeField('website_mailing');

	const preparedData = listAdapter.prepare(baseData);

	if (preparedData.user_show_fields) {
		preparedData.user_show_fields = preparedData.user_show_fields.map(item => {
			item = websiteConfigsFields[item];
			return item;
		});
	}

	if (preparedData.user_required_fields) {
		preparedData.user_required_fields = preparedData.user_required_fields.map(item => {
			item = websiteConfigsFields[item];
			return item;
		});
	}

	if (preparedData.website_cdn) {
		preparedData.website_cdn = Object.values(preparedData.website_cdn);
	}
	if (isID(baseData.id)) {
		preparedData.id = toInteger(baseData.id);
	}
	return preparedData;
}

export function prepareCasino(rawData = {}) {
	casinoListAdapter.clearExcludes();
	casinoListAdapter.addExcludeField('id');
	casinoListAdapter.addExcludeField('websiteID');

	const preparedData = casinoListAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}

	return preparedData;
}

export function prepareCurrency(rawData = {}) {

	currencyListAdapter.clearExcludes();
	currencyListAdapter.addExcludeField('id');
	currencyListAdapter.addExcludeField('code');

	const preparedData = currencyListAdapter.prepare(rawData);
	if (isID(rawData.id)) {
		preparedData.id = toInteger(rawData.id);
	}
	delete preparedData.description;
	return [preparedData];
}

export function prepareSeoData(rawData = {}, casinoGameID) {
	const langIDs = Object.keys(rawData);
	seoAdapter.clearExcludes();

	return langIDs.reduce((acc, langID) => {
		const data = Object.assign({}, rawData[langID]);
		data[fields.langID] = langID;
		data[fields.casinoGameID] = casinoGameID;
		const adapted = seoAdapter.prepare(data);

		acc.push(adapted);

		return acc;
	}, []);

}

export const createCurrenciesList = (currenciesList) => {
	return currenciesList.map(currency => ({
		...currency,
		name: `${currency.description} (${currency.code})`,
	}));
};

export const usersConfigurationsList = [
	{
		id      : 1,
		key     : WebsiteUserShowRequiredFields.EMAIL,
		name    : langToString(lang.email),
		show    : false,
		required: false,
		disabled: true,
	},
	{
		id      : 2,
		key     : WebsiteUserShowRequiredFields.PASSWORD,
		name    : langToString(lang.password),
		show    : false,
		required: false,
		disabled: true,
	},
	{
		id      : 3,
		key     : WebsiteUserShowRequiredFields.PASSWORD_CONFIRMATION,
		name    : langToString(lang.passConfirm),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 4,
		key     : WebsiteUserShowRequiredFields.USERNAME,
		name    : langToString(lang.username),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 5,
		key     : WebsiteUserShowRequiredFields.FIRST_NAME,
		name    : langToString(lang.firstName),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 6,
		key     : WebsiteUserShowRequiredFields.LAST_NAME,
		name    : langToString(lang.lastName),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 7,
		key     : WebsiteUserShowRequiredFields.GENDER,
		name    : langToString(lang.gender),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 8,
		key     : WebsiteUserShowRequiredFields.CURRENCY_ID,
		name    : langToString(lang.currency),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 9,
		key     : WebsiteUserShowRequiredFields.BIRTH,
		name    : langToString(lang.birth),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 10,
		key     : WebsiteUserShowRequiredFields.PHONE,
		name    : langToString(lang.phone),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 11,
		key     : WebsiteUserShowRequiredFields.COUNTRY,
		name    : langToString(lang.country),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 12,
		key     : WebsiteUserShowRequiredFields.CITY,
		name    : langToString(lang.city),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 13,
		key     : WebsiteUserShowRequiredFields.ADDRESS_1,
		name    : langToString(lang.address1),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 14,
		key     : WebsiteUserShowRequiredFields.ADDRESS_2,
		name    : langToString(lang.address2),
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 15,
		key     : WebsiteUserShowRequiredFields.NATIONAL_ID,
		name    : langToString(lang.nationalID),
		show    : false,
		required: false,
		disabled: false,
	},
];


export const userConfigurationsListForCreateUser = [
	{
		id      : 16,
		key     : WebsiteUserShowRequiredFields.LANGUAGE_ID,
		name    : 'Language ID',
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 17,
		key     : WebsiteUserShowRequiredFields.STATUS_ID,
		name    : 'Status ID',
		show    : false,
		required: false,
		disabled: false,
	},
	{
		id      : 18,
		key     : WebsiteUserShowRequiredFields.AFFILIATE_REFERENCE,
		name    : 'Status ID',
		show    : false,
		required: false,
		disabled: false,
	},
];


export const userRequiredFields = ['email', 'password'];
export const userShowFields = ['email', 'password'];

export function makeConfigs(showList = userShowFields, requiredList = userRequiredFields, list = usersConfigurationsList ) {
	return list.map(item => {
		item.show = showList.includes(item.key);
		item.required = requiredList.includes(item.key);
		return item;
	});
}

export function reverseFields(list) {
	const reversFields = Object.entries(list).map(([key, value]) => [value, key]);
	return reversFields.reduce((acc, item) => {
		const [key, value] = item;
		acc[key] = value;
		return acc;
	}, {});


}
