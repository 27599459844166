const actions = {

	USERS_MASS_BONUS_FILTER_SET_VALUE              : 'USERS_MASS_BONUS_FILTER_SET_VALUE',
	USERS_MASS_BONUS_FILTER_REFRESH                : 'USERS_MASS_BONUS_FILTER_REFRESH',
	USERS_MASS_BONUS_FILTER_RISK_GROUP_LIST_RELOAD : 'USERS_MASS_BONUS_FILTER_RISK_GROUP_LIST_RELOAD',
	USERS_MASS_BONUS_FILTER_RISK_GROUP_LIST_REFRESH: 'USERS_MASS_BONUS_FILTER_RISK_GROUP_LIST_REFRESH',

	USERS_MASS_BONUS_FILTER_DATA_SOURCE_AUTOCOMPLETE_RELOAD      : 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_AUTOCOMPLETE_RELOAD',
	USERS_MASS_BONUS_FILTER_DATA_SOURCE_AUTOCOMPLETE_MAIL_RELOAD : 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_AUTOCOMPLETE_MAIL_RELOAD',
	USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_NAME_REFRESH        : 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_NAME_REFRESH',
	USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_REFRESH       : 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_REFRESH',
	USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_SCROLL_REFRESH: 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_SCROLL_REFRESH',
	USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_SCROLL_RESET  : 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_SCROLL_RESET',
	USERS_MASS_BONUS_FILTER_DATA_SOURCE_FIRST_NAME_REFRESH       : 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_FIRST_NAME_REFRESH',
	USERS_MASS_BONUS_FILTER_DATA_SOURCE_LAST_NAME_REFRESH        : 'USERS_MASS_BONUS_FILTER_DATA_SOURCE_LAST_NAME_REFRESH',

	USERS_MASS_BONUS_DATA_HASMORE: 'USERS_MASS_BONUS_DATA_HASMORE',
	USERS_MASS_BONUS_FILTER_RESET: 'USERS_MASS_BONUS_FILTER_DATA_RESET',

	filterReset: () => ({
		type: actions.USERS_MASS_BONUS_FILTER_RESET,
	}),
	filterSetValue: (valueName, value) => ({
		type: actions.USERS_MASS_BONUS_FILTER_SET_VALUE,
		data: {
			valueName,
			value,
		},
	}),
	filterRefresh: data => ({
		type: actions.USERS_MASS_BONUS_FILTER_REFRESH,
		data,
	}),
	riskGoupsReload: () => ({
		type: actions.USERS_MASS_BONUS_FILTER_RISK_GROUP_LIST_RELOAD,
	}),
	riskGoupsRefresh: list => ({
		type: actions.USERS_MASS_BONUS_FILTER_RISK_GROUP_LIST_REFRESH,
		data: list,
	}),

	dataSourceReloadMail: ({ value, type, pagination, search, limit }) => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_SOURCE_AUTOCOMPLETE_MAIL_RELOAD,
		data: {
			value,
			type,
			pagination,
			search,
			limit,
		},
	}),

	dataSourceReload: (value, type) => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_SOURCE_AUTOCOMPLETE_RELOAD,
		data: {
			value,
			type,
		},
	}),

	dataSourceUserNameRefresh: data => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_NAME_REFRESH,
		data,
	}),
	dataSourceUserEmailRefresh: data => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_REFRESH,
		data,
	}),
	dataSourceUserEmailRefreshScroll: data => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_SOURCE_USER_EMAIL_SCROLL_REFRESH,
		data,
	}),
	dataSourceFirstNameRefresh: data => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_SOURCE_FIRST_NAME_REFRESH,
		data,
	}),
	dataSourceLastNameRefresh: data => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_SOURCE_LAST_NAME_REFRESH,
		data,
	}),

	dataHasMore: (data) => ({
		type: actions.USERS_MASS_BONUS_DATA_HASMORE,
		data,
	}),

	dataStore: () => ({
		type: actions.USERS_MASS_BONUS_FILTER_DATA_STORE,
	}),
};

export default actions;
