import styled from 'styled-components';
import { Row } from 'antd/es/grid';


export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: baseline;

    &.span {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 6px;
    padding-bottom: 12px;
  }
`;

export const RowWrapper = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
