import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './Option.style';

const Option = ({ name }) => {

	return (
		<Wrapper>
			{name}
		</Wrapper>
	);
};

Option.propTypes = {
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Option.defaultProps = {
	name: '',
};

export default Option;
