import { MAIN_BONUSES_TABS } from '../../containers/LoyaltySystem/BonusingSystem/Bonuses/modals/Bonus/utils';
import { CRUD_OPTIONS } from '../../redux/staff/permissions/utils';
import { getPermissions } from '../utility';
import { API_PERMISSIONS, PERMISSIONS, SIDEBAR_PERMISSIONS } from './constants';

// Logic ------------------------------------------------------------------------------------------

export function canUse(roleName, user, permissions) {

	const isSA = isSuperAdmin(user, permissions);
	if (isSA) {
		return isSA;
	}
	return hasRole(roleName, user, permissions);
}

export function canUseSidebar(sidebarKey, user, permissions) {
	const roleName = SIDEBAR_PERMISSIONS[sidebarKey];
	if (!roleName) {
		return false;
	}
	return canUse(roleName, user, permissions);
}

export function canWriteMapping(user) {
	return canUse(PERMISSIONS.writeMapping, user);
}

// Service ----------------------------------------------------------------------------------------

export function hasRole(roleName, user, permissions) {
	try {
		// permissions.rootSidebar['admin_loyalty_bonus-engine_bonuses'] = 'admin_loyalty_bonus-engine_bonuses';
		return permissions.rootSidebar[roleName];
	} catch (error) {
		return false;
	}
}

export function isSuperAdmin() {
	const permissions = getPermissions();
	return Boolean(permissions && permissions.rootSidebar[PERMISSIONS.superAdmin]);
}

export function isAdmin(user) {

	return hasRole(PERMISSIONS.admin, user);
}

export function isUser(user) {

	return hasRole(PERMISSIONS.user, user);
}

const hasPermittedChild = (item, patternDepartment) => (item.department.match(patternDepartment)[1] === PERMISSIONS.accessCustomerLimits || item.department.match(patternDepartment)[1] === PERMISSIONS.accessNextDeposit) && CRUD_OPTIONS.some(crud => item[crud]);

export const canRead      = (tabName, canReadTab)                       => canReadTab.isSA || canReadTab[tabName];
export const canReadSome  = (tabName, canReadTab, canReadTabList = [])  => canReadTab.isSA || canReadTabList.some(tab => tab.name.startsWith(tabName));
export const hasInner     = (tabName, canReadTab, innerTabs)            => canReadTab.isSA || innerTabs.length;
export const canReadInner = (tabName, canReadTab, innerTabs = [])       => canReadTab.isSA || innerTabs.some(tab => tabName === tab.name);

export const toCamelCase  = (str) => str.replace(/-([a-z])/g, (m, nextWord) => nextWord.toUpperCase());

export const canReadTabs  = (department, permissionData = {}, /*default arguments-->*/ canReadTabList = [], innerTabs = [], mainBonusesTabs = [],  toolbarBonus = null, toolbarAdjustments = null) => {
	const { superAdmin, platformAdmin, accessBalanceAdjustment, accessBonusFreeBet, accessBonusSpin, accessManualDeposit } = PERMISSIONS;
	const isSA = permissionData[superAdmin] || permissionData[platformAdmin];

	if (isSA) {
		const canReadTab = { isSA: true };
		return { canReadTab, toolbarBonus: true, toolbarAdjustments: true };
	}

	const patternDepartment = /^([\w-]+)_(.*)$/;
	const mainDepartment = department.match(patternDepartment)[1];

	const canReadTab = Object.values(permissionData).reduce((acc, item) => {

		if (item.department.startsWith(mainDepartment) && CRUD_OPTIONS.some(crud => item[crud])) {
			if (MAIN_BONUSES_TABS[item.department]) {
				mainBonusesTabs.push(item);
			}
			const tabName = item.department.match(patternDepartment)[2];
			const key = toCamelCase(tabName);
			item.name = key;

			if (hasPermittedChild(item, patternDepartment)) {
				innerTabs.push(item);
			}
			acc[key] = true;
			canReadTabList.push(item);
		}
		if ((item.department === accessBalanceAdjustment || item.department === accessManualDeposit) && item.create) {
			toolbarAdjustments = item.create;
		}
		if ((item.department === accessBonusSpin || item.department === accessBonusFreeBet) && item.create) {
			const tabName = item.department.match(patternDepartment)[2];
			const key = toCamelCase(tabName);
			toolbarBonus = {
				...toolbarBonus,
				[key]: item.create,
			};
		}
		return acc;
	}, {});

	return {
		canReadTab,
		canReadTabList,
		toolbarBonus,
		toolbarAdjustments,
		innerTabs,
		mainBonusesTabs,
	};
};
export const superAdminPermissions = {
	read  : true,
	create: true,
	update: true,
	delete: true,
};
export const isNotAnyPermissions = {
	department: 'admin_ ',
	read      : true,
	create    : true,
	update    : true,
	delete    : true,
};

export const canRequest = (userPermissions = [], key) => {

	return userPermissions.includes(PERMISSIONS.superAdmin) ||  userPermissions.includes(PERMISSIONS.platformAdmin) ||  userPermissions.includes(API_PERMISSIONS[key]);
};

export const canReadRTM = (tabName, permissionsData, superAdmin) => {
	return superAdmin || tabName in permissionsData;
};

export const canCancelFreeSpin = () => {
	const permissions = getPermissions();
	return Boolean(permissions && (permissions.rootSidebar[PERMISSIONS.superAdmin] || permissions.rootSidebar[PERMISSIONS.platformAdmin] || permissions.projectPermissions[PERMISSIONS.accessBonusSpin].update));
};