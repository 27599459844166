import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import { difference, isArray, isUndefined, uniqBy } from 'lodash';
import actions from '../../../../../../redux/loyalty/bonuses/bonuses/actions';
import providersActions from '../../../../../../redux/casino/providers/actions';
import gameCategoriesActions from '../../../../../../redux/casino/gameCategories/actions';
import { resetGamesInCategory } from '../../../../../../helpers/utils';

class CheckAll extends Component {

	static propTypes = {
		gameIDs            : PropTypes.array.isRequired,
		categoryIDs        : PropTypes.array.isRequired,
		tagIDs             : PropTypes.array.isRequired,
		dataRefresh        : PropTypes.func.isRequired,
		uiRefresh          : PropTypes.func.isRequired,
		data               : PropTypes.object.isRequired,
		allChecked         : PropTypes.object.isRequired,
		oldDataChecked     : PropTypes.object.isRequired,
		outsideCheck       : PropTypes.bool.isRequired,
		providersRefresh   : PropTypes.func.isRequired,
		sourceID           : PropTypes.number.isRequired,
		integratorID       : PropTypes.number.isRequired,
		checkedProviders   : PropTypes.object.isRequired,
		// bonusData          : PropTypes.object.isRequired,
		checkedAllGames    : PropTypes.func.isRequired,
		// bonusDataRefresh   : PropTypes.func.isRequired,
		showCheckboxAllInto: PropTypes.bool.isRequired,
		showAllCheckedInto : PropTypes.bool.isRequired,
		disabled           : PropTypes.bool.isRequired,
		checkAllInto       : PropTypes.bool,
		isTagPage          : PropTypes.bool,
	};

	static defaultProps = {
		checkAllInto: false,
		isTagPage   : false,
	};

	constructor(props) {
		super(props);
		this.onCheckAllChange = this.onCheckAllChange.bind(this);
		this.onClick = this.onClick.bind(this);
	}


	componentDidUpdate(prevProps) {

		const { outsideCheck:checked, gameIDs, uiRefresh, data, providersRefresh, checkedProviders, sourceID, integratorID } = this.props;

		if (providersRefresh) {
			const gameListIDs = data && isArray(data) ? data.map(item => item.id) : [];
			const anotherChecked = gameListIDs.length && difference(gameListIDs, gameIDs).length === 0;

			if (!isUndefined(checked) && prevProps?.outsideCheck !== checked ) {

				if (checked) {
					// dataRefresh({ gameIDs : [...clonedIDs, ...newListIDs] });
				} else if ( gameListIDs.length && difference(gameListIDs, gameIDs).length === 0 && !checked ) {
					// const newArray = clonedIDs.filter(item => newListIDs.indexOf(item) === -1);
					// dataRefresh({ gameIDs : newArray });
				}
				return uiRefresh({ isChanged: true });

			}
			if ( prevProps?.gameIDs?.length !== gameIDs?.length ) {
				providersRefresh({ [integratorID]: { ...checkedProviders, [sourceID]: anotherChecked } });
			}
		}

	}

	onCheckAllChange(e) {
		const { checkAllInto } = this.props;
		const { checked } = e.target;
		const {
			dataRefresh,
			gameIDs,
			categoryIDs,
			uiRefresh,
			data,
			providersRefresh,
			checkedProviders,
			sourceID,
			integratorID,
			checkedAllGames,
			allChecked,
			isTagPage,
			oldDataChecked,
			tagIDs,
			// bonusDataRefresh,
			// bonusData,
		} = this.props;
		const gamesData = data.games || [];
		const newListIDs = gamesData.map(item => item.id);
		const clonedIDs = categoryIDs || [];
		const clonedGameIDs = gameIDs || [];
		const gameIDWithoutCategory = resetGamesInCategory(gameIDs, allChecked[sourceID] ? allChecked[sourceID].games : []);
		const gameIDsInto = [...clonedGameIDs, ...newListIDs];
		const oldGames = oldDataChecked[sourceID] ? oldDataChecked[sourceID].games : [];

		const cloneTagIDs = tagIDs || [];
		const tagIDUniqBaseData = resetGamesInCategory([sourceID], tagIDs || []);

		if (checked) {
			const tempSetter	= !checkAllInto ? gameIDWithoutCategory : uniqBy(gameIDsInto);
			const categoryIDs	= !checkAllInto ? [...clonedIDs, sourceID] : clonedIDs;
			isTagPage
				? dataRefresh({ tagIDs: [...tagIDUniqBaseData] })
				: dataRefresh({
					categoryIDs: uniqBy(categoryIDs),
					gameIDs    : [...tempSetter, ...oldGames],
				});

			checkedAllGames({
				categoryID: sourceID,
				games   		: [...newListIDs],
				checkedAll: checkAllInto ? null : checked,
				into    		: true,
			});

			if (providersRefresh) {
				providersRefresh({ [integratorID]: {
					...checkedProviders,
					[sourceID]: checked },
				});
			}
		} else {
			const newArray = clonedIDs.filter(item => item !== sourceID);

			!checkAllInto
				? !isTagPage && dataRefresh({ categoryIDs: newArray })
				: dataRefresh({ gameIDs: [] /*uniqBy(gamesOffCheck)*/ }); // TODO i dont undersend for what this logic , meybe for Tags tab, bit it is will be like that

			checkedAllGames({
				categoryID: sourceID,
				games   		: oldGames,
				checkedAll: checkAllInto ? null : checked,
				into    		: true,
			});

			const newArrayTags = cloneTagIDs.filter(item => item !== sourceID);
			isTagPage && dataRefresh({ tagIDs: newArrayTags });

			if (providersRefresh) {
				providersRefresh({ [integratorID]: {
					...checkedProviders,
					[sourceID]: checked } });
			}
		}

		// const clonedBonusData = cloneDeep(bonusData); // TODO check provider games
		// bonusDataRefresh(clonedBonusData);
		return uiRefresh({ isChanged: true });
	}

	onClick(e) {
		e.stopPropagation();
		// const { checkAllInto, showCheckboxAllInto } = this.props;
		// showCheckboxAllInto(!!checkAllInto);
	}

	onChecked(into, showAllCheckedInto, checkedInto) {
		if (into && showAllCheckedInto) {
			return checkedInto;
		}
		return false;
	}

	render() {
		const { data, disabled, checkAllInto, showAllCheckedInto, categoryIDs, sourceID, allChecked, isTagPage, tagIDs } = this.props;

		const gameListIDs		    = data && isArray(data) ? data.map(item => item.id) : [];
		const gameCheckAllInto	= allChecked[sourceID] ? allChecked[sourceID].games : [];
		const checkedInto		= gameListIDs.length && difference(gameListIDs, gameCheckAllInto).length === 0;
		const checked			= categoryIDs ? categoryIDs.includes(sourceID) : false;
		const checkedAll		= this.onChecked(checkAllInto, showAllCheckedInto, checkedInto);
		const checkTag			= tagIDs ? tagIDs.includes(sourceID) : false;

		return (
			<Checkbox
				onChange = {this.onCheckAllChange}
				onClick  = {this.onClick}
				checked  = {!isTagPage ? checkedAll || checked : checkTag}
				value    = {data.id}
				disabled = {checkAllInto && disabled} // TODO NOT true implementation
			/>
		);
	}
}

function mapStateToProps(state, props) {
	const { App, Casino, Loyalty : { Bonuses } } = state;
	const { bonusData } = props;
	const selectedSearchGamesIDs = Bonuses.UserBonus.get('bonusData').gameIDs;
	return {
		gameIDs           : bonusData.gameIDs,
		categoryIDs       : bonusData.categoryIDs,
		tagIDs            : bonusData.tagIDs,
		websiteID         : App.get('websiteID'),
		gamesList         : Casino.Games.get('gamesList'),
		showAllCheckedInto: Casino.GameCategories.get('showCheckboxInto'),
		allChecked        : Casino.GameCategories.get('allCheckedCategories'),
		oldDataChecked    : Casino.GameCategories.get('oldDataChecked'),
		selectedSearchGamesIDs,
	};
}
const mapDispatchToProps = {
	providersListReload: providersActions.listReload,
	checkedAllGames    : gameCategoriesActions.checkedCategoryGamesActon,
	showCheckboxAllInto: gameCategoriesActions.showCheckboxAllInto,
	bonusDataRefresh   : actions.dataRefresh,
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckAll);
