import styled from 'styled-components';
import { Col } from 'antd';

export const RightCol = styled(Col)`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
`;

export const LeftCol = styled(Col)`
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`;
