import IntlMessages from '../../../../components/utility/intlMessages';

export const prefixSteps      = 'loyalty.bonuses.massBonus.create.steps';

export const lang = {
	//steps
	usersFilter: <IntlMessages id={`${prefixSteps}.usersFilter`} />,
	main       : <IntlMessages id={`${prefixSteps}.main`} />,
	title      : <IntlMessages id={`${prefixSteps}.title`} />,
	eligability: <IntlMessages id={`${prefixSteps}.eligability`} />,

	showUsersFilter: <IntlMessages id={`${prefixSteps}.showUsersList`} />,
	createPageTitle: <IntlMessages id="createMassBonus.title" />,
	viewPageTitle  : <IntlMessages id="viewMassBonus.title" />,

	pageTitle           : <IntlMessages id="users.title" />,
	id                  : <IntlMessages id="usersList.columns.id" />,
	customerID          : <IntlMessages id="usersList.filter.userID" />,
	email               : <IntlMessages id="usersList.columns.email" />,
	statusID            : <IntlMessages id="usersList.columns.statusID" />,
	gamingStatusID      : <IntlMessages id="usersList.columns.gamingStatusID" />,
	riskGroup           : <IntlMessages id="usersList.columns.riskGroupName" />,
	created             : <IntlMessages id="usersList.columns.created" />,
	balance             : <IntlMessages id="usersList.columns.balance" />,
	currencyID          : <IntlMessages id="usersList.columns.currencyID" />,
	age                 : <IntlMessages id="usersList.columns.age" />,
	affiliateReference  : <IntlMessages id="usersList.columns.affiliateReference" />,
	verificationStatusID: <IntlMessages id="usersList.columns.verificationStatusID" />,
	domain              : <IntlMessages id="usersList.columns.domain" />,
	averageStake        : <IntlMessages id="usersList.columns.averageStake" />,
	turnover            : <IntlMessages id="usersList.columns.turnover" />,
	userName            : <IntlMessages id="usersList.columns.userName" />,
	withdrawalsAmount   : <IntlMessages id="usersList.filter.withdrawalsAmount" />,
	depositsAmount      : <IntlMessages id="usersList.filter.depositsAmount" />,
	betsCount           : <IntlMessages id="usersList.filter.betsCount" />,
	lastBetAmount       : <IntlMessages id="usersList.filter.lastBetAmount" />,
	betsAmount          : <IntlMessages id="usersList.filter.betsAmount" />,
	minStake            : <IntlMessages id="usersList.filter.minStake" />,
	maxStake            : <IntlMessages id="usersList.filter.maxStake" />,
	lastBetDate         : <IntlMessages id="usersList.filter.lastBetDate" />,
	lastLoginDate       : <IntlMessages id="usersList.filter.lastLoginDate" />,
	profitabilityID     : <IntlMessages id="usersList.columns.profitabilityID" />,
	countryID           : <IntlMessages id="usersList.columns.countryID" />,
	verificationMethods : <IntlMessages id="usersList.columns.verificationMethods" />,
	socialNetworks      : <IntlMessages id="usersList.columns.socialNetworks" />,
	name                : <IntlMessages id="usersList.columns.name" />,
	isOnline            : <IntlMessages id="usersList.columns.isOnline" />,
	withdrawalsCount    : <IntlMessages id="usersList.columns.withdrawalsCount" />,
	depositsCount       : <IntlMessages id="usersList.columns.depositsCount" />,
	genderID            : <IntlMessages id="usersList.filter.gender" />,
	birth               : <IntlMessages id="usersList.filter.birthday" />,
	firstDeposit        : <IntlMessages id="usersList.filter.firstDeposit" />,
	firstWithdrawal     : <IntlMessages id="usersList.filter.firstWithdrawal" />,
	registrationIP      : <IntlMessages id="usersList.filter.registrationIP" />,
	notFound            : <IntlMessages id="usersList.notFound" />,
	noData              : <IntlMessages id="common.noData" />,
};

