import PropTypes from 'prop-types';
import { List } from 'react-virtualized';
import { Checkbox } from 'antd';
import { RowWrapper } from './containers';
import { connect } from 'react-redux';


const LargeList = (props) => {
	const { renderRow, list, width, height, rowHeight, onCheck, disabled, withCheckbox, deletedIntegrator, selectedSearchGamesIDs } = props;

	const calculatedListHeight = list?.length * height;
	const listHeight = Math.min(calculatedListHeight, 300);

	const rowRenderer = ({ key, index, style }) => {
		const { isChecked, gameID, name, isChanged } = list[index];
		const checkAll = selectedSearchGamesIDs && (selectedSearchGamesIDs.length >= list.length);

		return (
			<RowWrapper key={key} style={style}>
				{withCheckbox && (
					<Checkbox
						onChange={(e) => onCheck(e.target.checked, gameID)}
						checked={isChecked || checkAll}
						disabled={disabled || (deletedIntegrator && !isChecked && !isChanged)}
					/>
				)}
				<span>{name}</span>
			</RowWrapper>
		);
	};

	const renderer = (typeof renderRow === 'function') ? renderRow : rowRenderer;
	return (
		<List
			width={width}
			containerStyle={{
				width   : '100%',
				maxWidth: '100%',
			}}
			style={{ width: '100%' }}
			height={listHeight}
			rowCount={list.length}
			rowHeight={rowHeight}
			rowRenderer={renderer}
		/>
	);
};

LargeList.propTypes = {
	renderRow: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.any,
	]),
	list: PropTypes.arrayOf(PropTypes.shape({
		name     : PropTypes.string.isRequired,
		isChecked: PropTypes.bool.isRequired,
		gameID   : PropTypes.number.isRequired,
		isChanged: PropTypes.bool,
	})).isRequired,

	width            : PropTypes.number,
	height           : PropTypes.number,
	rowHeight        : PropTypes.number,
	withCheckbox     : PropTypes.bool,
	disabled         : PropTypes.bool,
	deletedIntegrator: PropTypes.bool,

	onCheck: PropTypes.func,

};

LargeList.defaultProps = {
	width            : 600,
	height           : 20,
	rowHeight        : 20,
	renderRow        : null,
	withCheckbox     : true,
	disabled         : false,
	onCheck          : () => {},
	deletedIntegrator: false,
};

export default connect(({ Loyalty : { Bonuses } }) => ({
	selectedSearchGamesIDs: Bonuses.UserBonus.get('bonusData').gameIDs,
}))(LargeList);
