import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../../helpers/utils';
import actions from './actions';
import { initFilterTypes, titlesEligibilityDefault } from './utils';
import { LANGUAGES } from '../../../../helpers/commonConstants';

const initState = new Map({

	entities       : {},
	subCashbackList: [],
	filter         : createFilter(filtersURL.casinoCashbacks, initFilterTypes),

	pagination: {
		currentPage : 1,
		totalCount  : 0,
		itemsPerPage: 100,
	},

	history: [],

	bonusData: {
		settlementDelay: 0,
		...titlesEligibilityDefault,
	},
	UI: {
		bonusID           : null,
		visible           : false,
		loading           : false,
		editMode          : false,
		isMainChanged     : false,
		isSubChanged      : false,
		subCashbacksNumber: 0,
		expandingID       : null,
		showGGRLimitTab   : false,
		showMinGGRTab     : false,
		modalLoad       		: false,
		langID            : LANGUAGES.en,			
	},
});

export default function casinoCashbackReducer(state = initState, action) {

	switch (action.type) {

		case actions.LIST_REFRESH: {
			const { entities } = action.data;
			return state.set('entities', entities);
		}

		case actions.CASHBACK_HISTORY_DATA_REFRASH: {
			const { data } = action.data;
			return state.set('history', data);
		}

		case actions.SUBCASHBACK_LIST_REFRESH: {
			const { list } = action.data;
			return state.set('subCashbackList', list);
		}

		case actions.FILTER_REFRESH: {
			const { filter } = action.data;
			const target = state.get('filter');
			const result = fill(filter, target, true);
			return state.set('filter', result);
		}

		case actions.PAGINATION_REFRESH: {
			const { pagination } = action.data;
			const target = state.get('pagination');
			const result = fill(pagination, target);
			return state.set('pagination', result);
		}

		case actions.BONUS_DATA_REFRESH: {
			const { bonusData } = action.data;
			const target = state.get('bonusData');
			const result = fill(bonusData, target, true);
			return state.set('bonusData', result);
		}

		case actions.BONUS_UI_REFRESH: {
			const { UI } = action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}

		case actions.BONUS_DATA_RESET: {
			const bonusData = initState.get('bonusData');
			const UI = initState.get('UI');

			return state
				.set('bonusData', bonusData)
				.set('UI', UI);
		}
		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
