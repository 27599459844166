import chunk from 'lodash/chunk';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import { EMPTY_VALUES } from '../../constants/filters';
import { FORMATS } from '../../helpers/commonConstants';
import { storeAppTabs } from '../../helpers/utility';

class FilterUtils {

	checkUseAdvanced(settings) {
		const { advancedElements } = settings;
		return (isArray(advancedElements) && advancedElements.length > 0);
	}

	isSettingsChanged(prevSettings, curSettings) {

		const prevPrimary = prevSettings.elements.map(item => item.name);
		const curPrimary  = curSettings.elements.map(item => item.name);
		if (!isEqual(prevPrimary, curPrimary)) {
			return true;
		}

		const isPrevAdvanced = isArray(prevSettings.advancedElements);
		const isCurAdvanced  = isArray(curSettings.advancedElements);

		const prevAdvanced = isPrevAdvanced
			? prevSettings.advancedElements.map(item => item.name)
			: [];

		const curAdvanced = isCurAdvanced
			? curSettings.advancedElements.map(item => item.name)
			: [];

		if (!isEqual(prevAdvanced, curAdvanced)) {
			return true;
		}

		const prevColsPrimary = prevSettings.columnsCount;
		const curColsPrimary  = curSettings.columnsCount;
		if (prevColsPrimary !== curColsPrimary) {
			return true;
		}

		const prevColsAdvanced = prevSettings.advancedColumnsCount || 0;
		const curColsAdvanced  = curSettings.advancedColumnsCount || 0;
		return prevColsAdvanced !== curColsAdvanced;


	}

	isValidRange(range) {
		return isArray(range);
	}

	createGrid(elements, columnsCount) {
		const names = elements.map(item => item.name);
		const grid = chunk(names, columnsCount);
		grid.forEach(row => {
			if (row.length < columnsCount) {
				const toAddCount = columnsCount - row.length;
				for (let i = 1; i <= toAddCount; i++) {
					row.push(null);
				}
			}
		});

		return grid;
	}

	createGrids(settings) {
		const { elements, advancedElements, columnsCount, advancedColumnsCount } = settings;
		const useAdvanced = this.checkUseAdvanced(settings);
		const gridElements = this.createGrid(elements, columnsCount);
		const gridAdvancedElements = useAdvanced
			? this.createGrid(advancedElements, advancedColumnsCount)
			: [];

		return {
			gridElements,
			gridAdvancedElements,
		};
	}

	collectFilter(settings) {
		const { elements, advancedElements } = settings;
		const useAdvanced = this.checkUseAdvanced(settings);
		const filter = {};
		elements.forEach(item => {
			const { name, type, value, defaultValue } = item;
			filter[name] = value || defaultValue || EMPTY_VALUES[type];
		});

		if (useAdvanced) {
			advancedElements.forEach(item => {
				const { name, type, value, defaultValue } = item;
				filter[name] = value || defaultValue || EMPTY_VALUES[type];
			});
		}

		return filter;
	}
	
	reBuildTabs(tabs, pathname, search) {
		const tabKey = pathname.replace('/dashboard/', '');
		tabs.forEach(tab => {
			if (tab.id === tabKey) {
				tab.search = search;
			}
		});
		storeAppTabs(tabs);
		return tabs;
	}

	buildUrl(searchParams, filter) {
		this.createQueryString(searchParams, filter);
		const queryString = searchParams.toString();
		if (searchParams.size) {
			window.history.pushState({}, '', `?${queryString}`);
		}
		return `?${queryString}`;
	}
	
	createQueryString(searchParams, filter) {
		return Object.entries(filter).forEach(([key, value]) => {
			const isArray = Array.isArray(value);
			if (!value || (isArray && (!value.length || !value.some(v => v)))) return;
			const formattedValue = isArray ? value : encodeURIComponent(value);
			searchParams.set(key, formattedValue);
		});
	}

	getFilterItem(settings, name) {
		const { elements, advancedElements } = settings;
		let filterItem = find(elements, { name });
		if (!filterItem) {
			filterItem = find(advancedElements, { name });
		}

		return filterItem;
	}

	getLabelWidth(showLabel, widthLabel = 0) {
		return showLabel ? widthLabel || 24 : 0;
	}

	getDateRangeFormat(dateFormat, timeFormat) {
		const originDateFormat = dateFormat || FORMATS.date;
		const originTimeFormat = timeFormat || FORMATS.time;
		const resFormat = originTimeFormat ? `${originDateFormat} ${originTimeFormat}` : originDateFormat;
		const showTime  = originTimeFormat ? { format: originTimeFormat } : false;

		return {
			format: resFormat,
			showTime,
		};
	}

	onFilterOptios(input, option) {
		let returnValue = true;
		if (option.props.children) {
			returnValue = option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		}

		return returnValue;
	}
}

export default new FilterUtils();
