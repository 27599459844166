import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import FormControl from '../../uielements/FormControl';
import { DateRangePicker } from '../../uielements/datePicker';
import { isValidFilterArray } from '../../../helpers/utility';
import { periodsList } from '../../../constants/common.enum';

import SelectPeriod from './SelectPeriod';
import { Wrapper } from './FormDateRangePicker.style';

const fullWidth = { width: '100%' };

class FormDateRangePicker extends Component {

	static propTypes = {
		value         : PropTypes.array,
		label         : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth    : PropTypes.number,
		height        : PropTypes.string,
		wrapperID     : PropTypes.string,
		style         : PropTypes.object,
		noLabel       : PropTypes.bool,
		noMargin      : PropTypes.bool,
		showPeriods   : PropTypes.bool,
		skippedPeriods: PropTypes.arrayOf(PropTypes.string),
		name          : PropTypes.string,
		onChange      : PropTypes.func.isRequired,
	};

	static defaultProps = {
		value         : [],
		label         : '',
		labelWidth    : 25, // in percent
		height        : '36px',
		style         : {},
		noLabel       : false,
		noMargin      : false,
		showPeriods   : false,
		skippedPeriods: [],
		wrapperID     : '',
		name          : '',
	};

	constructor(props) {
		super(props);
		this.onChangeValue  = this.onChangeValue.bind(this);
		this.onSelectPeriod = this.onSelectPeriod.bind(this);
		this.createPeriods  = this.createPeriods.bind(this);
		this.onCalendarChange  = this.onCalendarChange.bind(this);
	}
	onCalendarChange(dates) {
		if (dates && dates[1]) {
			const dateTo = dates[1];
			if (dateTo.hour() === 0 && dateTo.minute() === 0 && dateTo.second() === 0 ) {
				const updatedDateTo = dayjs(dateTo)
					.set('hour', 23)
					.set('minute', 59)
					.set('second', 59);
				this.onChangeValue([dates[0], updatedDateTo]);
			}
		}
	}

	onChangeValue(value) {
		
		const { onChange, value: oldValue } = this.props;
		const needFormatValueFrom = (value?.[0] && oldValue?.[0] === null);
		const needFormatValueTo   = (value?.[1] && oldValue?.[1] === null);

		let valueFrom = value?.[0] || null;
		let valueTo   = value?.[1] || null;

		if (needFormatValueFrom) { valueFrom = dayjs(valueFrom).startOf('day'); }
		if (needFormatValueTo)   { valueTo   = dayjs(valueTo).endOf('day'); }

		const resValue = compact([valueFrom, valueTo]);
		onChange(resValue);
	}

	onSelectPeriod({ key }) {
		let valueFrom = dayjs().startOf('day');
		let valueTo   = dayjs().endOf('day');

		switch (key) {
			case 'yesterday': {
				valueFrom = dayjs().startOf('day').subtract(1, 'day');
				valueTo   = valueFrom.clone().endOf('day');
				break;
			}
			case 'thisWeek': {
				valueFrom = dayjs().startOf('week');
				valueTo   = dayjs().endOf('week');
				break;
			}
			case 'thisMonth': {
				valueFrom = dayjs().startOf('month');
				valueTo   = dayjs().endOf('month');
				break;
			}
			case 'lastWeek': {
				valueFrom = dayjs().startOf('week').subtract(1, 'week');
				valueTo   = valueFrom.clone().endOf('week');
				break;
			}
			case 'lastMonth': {
				valueFrom = dayjs().startOf('month').subtract(1, 'month');
				valueTo   = valueFrom.clone().endOf('month');
				break;
			}
			default: // Today
		}
		this.onChangeValue([valueFrom, valueTo]);
	}

	createPeriods(showPeriods, skippedPeriods) {
		if (!showPeriods) {
			return [];
		}
		if (!isArray(skippedPeriods) || isEmpty(skippedPeriods)) {
			return periodsList;
		}

		return periodsList.filter(item => !skippedPeriods.includes(item.id));
	}

	render() {
		const { label, name, labelWidth, height, value, style, noLabel, noMargin, showPeriods, skippedPeriods, wrapperID = '', ...restProps } = this.props;

		const lw  = noLabel ? 0 : labelWidth;
		const h   = height ? { height } : {};
		const val = createValue(value);
		const st  = style ? { ...style, ...fullWidth } : fullWidth;
		const mb  = (noMargin || lw === 0) ? 0 : undefined;

		const periods = this.createPeriods(showPeriods, skippedPeriods);
		const getRangeContainer = wrapperID ? { getCalendarContainer: () => document.getElementById(wrapperID) } : '';

		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				{...h}
				control={(
					<Wrapper id={wrapperID} name={name}>
						<DateRangePicker
							{...restProps}
							value={val}
							style={st}
							onChange={this.onChangeValue}
							onCalendarChange={this.onCalendarChange}
							{...getRangeContainer}
						/>
						{showPeriods && (
							<SelectPeriod
								periods={periods}
								height={h}
								onSelect={this.onSelectPeriod}
							/>
						)}
					</Wrapper>
				)}
			/>
		);
	}
}

export default FormDateRangePicker;

function createValue(rawValue = null) {
	if (!isValidFilterArray(rawValue)) {
		return [null, null];
	}

	const valueFrom = rawValue[0] ? dayjs(rawValue[0]) : null;
	const valueTo   = rawValue[1] ? dayjs(rawValue[1]) : null;

	return [valueFrom, valueTo];
}
