import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';


import FormControl from '../../uielements/FormControl';
import SelectList from '../../uielements/selectList';

const fullWidth = { width: '100%' };

class FormMultiSelect extends Component {

	static propTypes = {
		label                  : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth             : PropTypes.number,
		height                 : PropTypes.string,
		value                  : PropTypes.array,
		style                  : PropTypes.object,
		name                   : PropTypes.string,
		direction              : PropTypes.string,
		styleFormControl       : PropTypes.object,
		styleFormControlWrapper: PropTypes.object,
		styleLabelControl      : PropTypes.object,
	};

	static defaultProps = {
		label                  : '',
		labelWidth             : 25, // in percent
		height                 : '36px',
		value                  : [],
		style                  : {},
		name                   : '',
		direction              : 'row',
		styleFormControl       : {},
		styleFormControlWrapper: {},
		styleLabelControl      : {},
	};

	render() {
		const { label, labelWidth, height, value, style, styleFormControl, styleFormControlWrapper, name, direction, styleLabelControl, labelRight, ...restProps } = this.props;

		const lw  = (Boolean(labelWidth) || labelWidth === 0) ? labelWidth : 25;
		const h   = height ? { height }     : {};
		const st  = style ? { ...style, ...fullWidth } : fullWidth;
		const mb = (labelWidth === 0) ? 0 : undefined;
		const val = isArray(value)
			? value.map( item => String(item) )
			: [];

		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				direction={direction}
				styleFormControl = {styleFormControl}
				styleFormControlWrapper = {styleFormControlWrapper}
				styleLabelControl={styleLabelControl}
				labelRight={labelRight}
				{...h}
				control={(
					<SelectList
						{...restProps}
						mode="multiple"
						tokenSeparators={[',']}
						value={val}
						name={name}
						style={st}
					/>
				)}
			/>
		);
	}
}

export default FormMultiSelect;
