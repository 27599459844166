import { apiRequest } from './index';

function messagesRequests(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/requests',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function messagesList(requestID) {

	const req = {
		method       : 'GET',
		url          : `/request/${requestID}`,
		withoutLangID: true,
	};

	return apiRequest(req);
}

function getMessage(requestID, messageID) {

	const req = {
		method       : 'GET',
		url          : `/request/${requestID}/message/${messageID}`,
		withoutLangID: true,
	};

	return apiRequest(req);
}

function sendRequest(request, params) {

	const req = {
		method: 'POST',
		url   : '/request',
		data  : request,
		params,
	};

	return apiRequest(req);
}

function sendMessage(requestID, message, params) {

	const req = {
		method: 'POST',
		url   : `/request/${requestID}/message`,
		data  : message,
		params,
	};

	return apiRequest(req);
}

function deleteMessage(requestID) {

	const req = {
		method: 'DELETE',
		url   : `/request/${requestID}`,
	};

	return apiRequest(req);
}

function updateRequest(requestID, data) {

	const req = {
		method: 'PUT',
		url   : `/request/${requestID}`,
		data,
	};

	return apiRequest(req);
}

function updateMessage(requestID, messageID, data) {

	const req = {
		method: 'PUT',
		url   : `/request/${requestID}/message/${messageID}`,
		data,
	};

	return apiRequest(req);
}

export const messagesAPI = {
	messagesRequests,
	messagesList,
	getMessage,
	sendRequest,
	sendMessage,
	deleteMessage,
	updateRequest,
	updateMessage,
};
