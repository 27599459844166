import { combineReducers } from 'redux';

import Bonuses from './bonuses/reducer';
import BonusingHistory from './bonusingHistory/reducer';
import Tournaments from './tournaments/reducer';
import BonusTemplates from './templates/slice';

export default combineReducers({
	Bonuses,
	BonusingHistory,
	Tournaments,
	BonusTemplates,
});
