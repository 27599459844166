export const TABLE_TYPES = {
	homepageMatchOfTheDay      : 'HOMEPAGE_MATCH_OF_THE_DAY',
	events                     : 'EVENTS',
	bannersGroups              : 'BANNERS_GROUPS',
	banners                    : 'BANNERS',
	customersSearch            : 'CUSTOMERS_SEARCH',
	transactions               : 'TRANSACTIONS',
	withdrawRTM                : 'WITHDRAW_RTM',
	withdrawProfile            : 'WITHDRAW_PROFILE',
	depositProfile             : 'DEPOSIT_PROFILE',
	casinoProfile              : 'CASINO_PROFILE',
	betProfile                 : 'BET_PROFILE',
	depositRTM                 : 'DEPOSIT_RTM',
	casinoRTM                  : 'CASINO_RTM',
	betRTM                     : 'BET_RTM',
	sportRiskManagement        : 'SPORT_RISK_MANAGEMENT',
	results                    : 'RESULTS',
	reportByPlayer             : 'REPORT_BY_PLAYER',
	reportByGame               : 'REPORT_BY_GAME',
	reportByProvider           : 'REPORT_BY_PROVIDER',
	reportByIntegrator         : 'REPORT_BY_INTEGRATOR',
	personal                   : 'PERSONAL',
	infoPages                  : 'INFO_PAGES',
	news                       : 'NEWS',
	homepageUpcomingEvents     : 'HOMEPAGE_UPCOMING_EVENTS',
	emailsList                 : 'EMAILS_LIST',
	emailTemplates             : 'EMAIL_TEMPLATES',
	bonusesWelcomeDeposit      : 'BONUSES_WELCOME_DEPOSIT',
	bonusesRegistrationFreeSpin: 'BONUSES_REGISTRATION_FREE_SPIN',
	bonusesFreeSpins           : 'BONUSES_FREE_SPINS',
	bonusesFreeBets            : 'BONUSES_FREE_BETS',
	bonusesRirstDeposit        : 'BONUSES_FIRST_DEPOSIT',
	bonusesTemplates           : 'BONUSES_TEMPLATES',
	cashbackUsers              : 'CASHBACK_USERS',
	cashback                   : 'CASHBACK',
	websites                   : 'WEBSITES',
	homepageSportsPriorities   : 'HOMEPAGE_SPORTS_PRIORITIES',
	customerGroups             : 'CUSTOMERS_GROUPS',
	homepageSpecialOffers      : 'HOMEPAGE_SPECIAL_OFFERS',
	partners                   : 'PARTNERS',
	notification               : 'NOTIFICATION',
	game                       : 'GAME',
	documentsRTM               : 'DOCUMENTS_RTM',
	riskManagementRTM          : 'RISK_MANAGEMENT_RTM',
	smsListing                 : 'SMS_LISTING',
	tournaments                : 'TOURNAMENTS',
	userAllTransactions        : 'USER_ALL_TRANSACTION',
	bonusesMassBonusList       : 'MASS_BONUSES',
};
