import dayjs from 'dayjs';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormControl from '../../uielements/FormControl';
import DatePicker from '../../uielements/datePicker';

const fullWidth = {
	width: '100%',
};

class FormDatePicker extends Component {

	static propTypes = {
		label       : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth  : PropTypes.number,
		height      : PropTypes.string,
		value       : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		style       : PropTypes.object,
		name        : PropTypes.string,
		disabled    : PropTypes.bool,
		disabledDate: PropTypes.func,
	};

	static defaultProps = {
		label       : '',
		labelWidth  : 25, // in percent
		height      : '36px',
		value       : 0,
		style       : {},
		disabledDate: null,
		disabled    : null,
		name        : '',
	};

	render() {
		const { label, labelWidth, height, value, style, disabledDate, name, disabled, ...restProps } = this.props;

		const lw = (Boolean(labelWidth) || labelWidth === 0) ? labelWidth : 25;
		const h   = height ? { height }     : {};
		const val = value ? dayjs(value)   : null;
		const st  = style ? { ...style, ...fullWidth } : fullWidth;
		const mb = (labelWidth === 0) ? 0 : undefined;

		return (
			<FormControl
				disabled={disabled}
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				{...h}
				control={(
					<DatePicker
						className={disabled ? 'disabled' : ''}
						disabled={disabled}
						{...restProps}
						name={name}
						value={val}
						style={st}
						disabledDate={disabledDate}
					/>
				)}
			/>
		);
	}
}

export default FormDatePicker;
