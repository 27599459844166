import React from 'react';
import { Icon } from 'react-icons-kit';
import PropTypes from 'prop-types';
import { ic_arrow_drop_up as arrowUp } from 'react-icons-kit/md/ic_arrow_drop_up';
import { ic_arrow_drop_down as arrowDown } from 'react-icons-kit/md/ic_arrow_drop_down';


const SortArrow = ({ sorting, id }) => {
	const { sortOrder, sortBy } = sorting;
	const activeArrowUp         = sortOrder === 'asc';
	const activeArrowDown       = sortOrder === 'desc';
	const showArrow             = (
		id === sortBy
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<span style={{ color: showArrow && activeArrowUp ? '#4382ff' : '#c6c6c6', marginBottom: '-6px' }}>
				<Icon icon={arrowUp} size={20} />
			</span>
			<span style={{ color: showArrow && activeArrowDown ? '#4382ff' : '#c6c6c6', marginTop: '-6px' }}>
				<Icon icon={arrowDown} size={20} />
			</span>
		</div>
	);
};


SortArrow.propTypes = {
	sorting: PropTypes.shape({
		sortOrder: PropTypes.string.isRequired,
		sortBy   : PropTypes.string.isRequired,
	}).isRequired,
	id: PropTypes.string.isRequired,
};


export { SortArrow };
