import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'antd';


export const CheckProvider = (props) => {
	const { checked, onChange, disabled } = props;

	return (
		<Checkbox
			checked={checked}
			onClick={e => {
				e.stopPropagation();
			}}
			onChange={e => {
				e.stopPropagation();
				onChange(e.target.checked);
			}}
			disabled={disabled}
		/>
	);
};

CheckProvider.propTypes = {
	checked : PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};
