import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Wrapper, Title } from './HeaderCell.style';
import { SortArrow } from './SortArrow';

class HeaderCell extends Component {

	static propTypes = {
		id                : PropTypes.string.isRequired,
		type              : PropTypes.string.isRequired,
		title             : PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
		onClick           : PropTypes.func.isRequired, // function(sorting)
		// Redux
		sorting           : PropTypes.object.isRequired,
		nonSortableColumns: PropTypes.arrayOf(PropTypes.string),
	};

	static defaultProps = {
		nonSortableColumns: [],
	};

	constructor(props) {
		super(props);

		this.onCellClick = this.onCellClick.bind(this);
	}

	// Events ---------------------------------------------------------------------------------------
	onCellClick() {
		const { id, sorting, onClick, nonSortableColumns } = this.props;
		if (id === 'action' || id === 'actions' || nonSortableColumns.includes(id)) return;

		let newOrder = 'asc';
		if (id === sorting.sortBy && sorting.sortOrder === 'asc') {
			newOrder = 'desc';
		}

		const newSorting = {
			sortBy   : id,
			sortOrder: newOrder,
		};


		onClick(newSorting);
	}

	// Renders --------------------------------------------------------------------------------------
	render() {
		const { id, title, sorting, nonSortableColumns } = this.props;
		const showArrow = (id === sorting.sortBy);
		const sortable = !nonSortableColumns.includes(id);
		return (
			<Wrapper onClick={this.onCellClick}>
				<Title color={showArrow ? '#4382ff' : '#c6c6c6'}>{title}</Title>
				{ sortable && <SortArrow sorting={sorting} id={id} />}
			</Wrapper>
		);
	}
}

function mapStateToProps(state, props) {

	const { Sport: { Participants }, Tables } = state;
	const table = Tables.get(props.type);

	let sorting = {};
	switch (props.type) {
		case 'participants':
			sorting = Participants.List.get('sorting');
			break;
		default:
			sorting = table.sorting; // eslint-disable-line prefer-destructuring
	}

	return {
		sorting,
	};
}

export default connect(mapStateToProps)(HeaderCell);
