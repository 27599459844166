import { Component } from 'react';
import { cloneDeep, isNull, isUndefined, toInteger } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Checkbox } from 'antd';
import LargeList from '../../../../../../components/LargeList/LargeList';
import { lang } from '../lang';
import { Label } from './components';
import gameCategoriesActions from '../../../../../../redux/casino/gameCategories/actions';
import CheckAllGames from './CheckAllGamesbox';

class GamesList extends Component {

	static propTypes = {
		gamesSearchList  : PropTypes.array,
		uiRefresh        : PropTypes.func.isRequired,
		dataRefresh      : PropTypes.func.isRequired,
		sourceID         : PropTypes.number.isRequired,
		checkedAllGames  : PropTypes.func.isRequired,
		checkedOldGames  : PropTypes.func.isRequired,
		renderRow        : PropTypes.func,
		gameIDs          : PropTypes.object.isRequired,
		bonusData        : PropTypes.object.isRequired,
		data             : PropTypes.object,
		indeterminate    : PropTypes.bool.isRequired,
		allChecked       : PropTypes.bool.isRequired,
		isTagPage        : PropTypes.bool,
		checkAll         : PropTypes.element,
		deletedIntegrator: PropTypes.bool,
	};

	static defaultProps = {
		data             : {},
		gamesSearchList  : [],
		isTagPage        : false,
		renderRow        : null,
		checkAll         : null,
		deletedIntegrator: false,
	};

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	// Local Events ---------------------------------------------------------------------------------

	onChange(e, gameID, providerID, gameName) {
		const { checked } = e.target;
		const { dataRefresh, gameIDs, uiRefresh, checkedAllGames, sourceID, checkedOldGames, bonusData, singleGameMode } = this.props;

		const clonedBonusData = cloneDeep(bonusData);
		const providerCount = !singleGameMode && clonedBonusData.count.providers[providerID];
		const intGameID = toInteger(gameID);

		if (isUndefined(providerCount) || isNull(providerCount)) {
			clonedBonusData.count.providers[providerID] = 0;
		}
		let clonedList = [...gameIDs];
		if (!checked && !singleGameMode) {
			clonedList = clonedList.filter(item => toInteger(item) !== intGameID);
			clonedBonusData.count.providers[providerID]--;
		} else if (!singleGameMode) {
			clonedList.push(intGameID);
			clonedBonusData.count.providers[providerID]++;
		}
		clonedBonusData.gameIDs = clonedList;
		
		if (singleGameMode) {
			if (checked) { 
				clonedBonusData.gameName = gameName;
				clonedBonusData.casinoGameID = intGameID;
			} else {
				clonedBonusData.gameName = null;
				clonedBonusData.casinoGameID = null;
			}
		}
		
		uiRefresh({ isChanged: true });
		dataRefresh(clonedBonusData, gameName);
		checkedAllGames({ categoryID: sourceID, games: [gameID], checked });
		checkedOldGames({ categoryID: sourceID, games: [gameID], checked }, gameID);

	}

	showCheckbox() {
		const { allChecked, sourceID, isTagPage, singleGameMode } = this.props;
		if (singleGameMode) return true;
		if (isTagPage)  return false;
		if (!allChecked[sourceID]) return true;
		return !!(allChecked[sourceID] && !allChecked[sourceID].allChecked);
	}

	renderRow({ props, list }) {
		const { index, key, style } = props;
		const { allChecked, sourceID, deletedIntegrator, disabled, gameIDs, singleGameMode } = this.props;
		const { id, name, providerID } = list[index];
		const defaultChecked = allChecked[sourceID]?.games.includes(id);
		const checked =  singleGameMode ? gameIDs.includes(id) : allChecked[sourceID]?.games.includes(id) || gameIDs.includes(id);
		return (
			<Label style={style} key={key}>
				{this.showCheckbox() && (
					<Checkbox
						value={id}
						onChange={(e) => this.onChange(e, id, providerID, name)}
						checked={checked}
						disabled={disabled || (deletedIntegrator && !defaultChecked)}
					/>
				)}
				<span>{name}</span>
			</Label>
		);
	}

	rowRenderer({ props: rowProps, list }) {
		const { renderRow, disabled } = this.props;
		const props = {
			...rowProps,
			disabled,
		};
		if (renderRow && typeof renderRow === 'function') {
			return renderRow({ props, list });
		}
		return this.renderRow({ props, list });
	}

	// Renders --------------------------------------------------------------------------------------

	render() {
		const { gameIDs, data = [], gamesSearchList, bonusData, sourceID, uiRefresh, dataRefresh, indeterminate, isTagPage, checkAll, singleGameMode, disabled/* , checkAllInto */ } = this.props;

		const allData = data.games ? gamesSearchList.concat(data.games) : gamesSearchList;

		if (isEmpty(allData)) return <div>{lang.noTagGame}</div>;

		const list = gamesSearchList.length ? gamesSearchList : data.games;

		return (
			<div>
				{!singleGameMode && !isTagPage && (
					<Label style={{ marginBottom: '6px' }}>
						{ checkAll || (
							<CheckAllGames
								data={list}
								uiRefresh={uiRefresh}
								dataRefresh={dataRefresh}
								bonusData={bonusData}
								sourceID={sourceID}
								indeterminate={indeterminate}
								disabled={disabled || !this.showCheckbox()}
								checkAllInto // NOT truty hard code implementation 
							/>
						)}
						<span>{lang.currentList}</span>
					</Label>
				)}
				<LargeList 
					disabled={disabled} 
					isTagPage={isTagPage} 
					list={list} 
					renderRow={(props) => this.rowRenderer({ props, list, gameIDs })} 
				/>
			</div>
		);
	}
}
function mapSateToProps(state, props) {
	const { Casino } = state;
	const { bonusData } = props;
	return ({
		gameIDs   : bonusData.gameIDs,
		gamesList : Casino.Games.get('gamesList'),
		allChecked: Casino.GameCategories.get('allCheckedCategories'),
		bonusData,
	});
}

const mapDispatchToProps = {
	checkedAllGames: gameCategoriesActions.checkedCategoryGamesActon,
	checkedOldGames: gameCategoriesActions.oldCheckedDataList,
};


export default connect(mapSateToProps, mapDispatchToProps)(GamesList);
