import styled from 'styled-components';

export const Wrapper = styled.div`
  
  width: 100%;

  .ant-input-number {
    max-width: 100%;
    box-sizing: border-box;
  }
`;
