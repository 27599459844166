import styled from 'styled-components';
import { Collapse } from 'antd';

export const NameTitle = styled.span`
  color: ${(props) => props.deleted ? '#fd0000' : '#000000'};
  font-style: ${(props) => props.deleted ? 'italic' : 'normal'};
`;

export const CollapseWrapper = styled(Collapse)`
    .ant-collapse-item > .ant-collapse-header {
        align-items: baseline;
    }
`;


