import React from 'react';
import * as PropTypes from 'prop-types';

import FormCheckbox from '../../../components/FormControls/FormCheckbox';
import Utils from '../FilterUtils';

const Checkbox = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, disabled } = filterItem;

	return (
		<FormCheckbox
			name={name}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			checked={value}
			disabled={disabled}
			onChange={({ target }) => onChange(name, target.checked)}
		/>
	);
};

Checkbox.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default Checkbox;
