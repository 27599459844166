import { apiRequest } from './index';
import { RESULT_SELECTION_LOG_MODELS } from '../commonConstants';

function historyCategoryList(categoryID, additionalParams = {}) {

	const params = {
		...additionalParams,
		model_id         : categoryID,
		include_ext_table: true,
	};

	const req = {
		method: 'GET',
		url   : '/history/category',
		params,
	};

	return apiRequest(req);
}

function historyCategoryLogs(categoryID, logModel, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/category',
		params: {
			model_id  : categoryID,
			model_name: logModel,
			...additionalParams,
		},
	};

	return apiRequest(req);
}

function historyMarketLogs(marketID, logModel, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/markets',
		params: {
			model_id  : marketID,
			model_name: logModel,
			...additionalParams,
		},
	};

	return apiRequest(req);
}

function historySelectionLogs(selectionID, logModel, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/marketSelections',
		params: {
			model_id  : selectionID,
			model_name: logModel,
			...additionalParams,
		},
	};

	return apiRequest(req);
}

function historyResultSelectionLogs(eventSelectionID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/results',
		params: {
			model_id  : eventSelectionID,
			model_name: RESULT_SELECTION_LOG_MODELS.eventSelections,
			...additionalParams,
		},
	};

	return apiRequest(req);
}

function historyGamblingLogs(additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/history/gambling',
		params: {
			...additionalParams,
		},
	};

	return apiRequest(req);
}

function historyUserLogs(userID, logModel, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/users',
		params: {
			model_id  : userID,
			model_name: logModel,
			...additionalParams,
		},
	};

	return apiRequest(req);
}

function historyCashbackLogs(params) {

	const req = {
		method: 'GET',
		url   : '/history/cashback',
		params,
	};

	return apiRequest(req);
}

function historyCashbackGGRlimitsLogs(params) {

	const req = {
		method: 'GET',
		url   : '/history/ggrlimits',
		params,
	};

	return apiRequest(req);
}

function historyCurrencyLogs(currencyID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/currency',
		params: {
			...additionalParams,
			model_id: currencyID,
		},
	};

	return apiRequest(req);
}

function historyRegistrationFreeSpinLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/registrationFreeSpin',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyWelcomeBonusLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/welcome',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyDepositFreeSpinLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/depositFreeSpin',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyPackNextLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/next_pack',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyNextDepositFreeSpinLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/next_deposit_free_spin',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyNextDepositBonusLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/next_deposit_bonus',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyDepositsLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/deposit',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyPacksLogs(bonusID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/bonuses/pack',
		params: {
			...additionalParams,
			model_id: bonusID,
		},
	};

	return apiRequest(req);
}

function historyParticipantTranslations(participantID, params = {}) {

	const req = {
		method: 'GET',
		url   : '/history/participant/translation',
		params: {
			...params,
			model_id: participantID,
		},
	};

	return apiRequest(req);
}

function historyResponsibleGamblingLogs(userID, additionalParams = {}) {

	const req = {
		method: 'GET',
		url   : '/history/users/responsibleGambling',
		params: {
			...additionalParams,
			model_id: userID,
		},
	};

	return apiRequest(req);
}

function historyRiskManagmentBetslipLogs(betID, modelName, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/history/risk/betslip',
		params: {
			...additionalParams,
			model_id  : betID,
			model_name: modelName,
		},
	};
	return apiRequest(req);
}

function historyRiskManagmentEventMarketsLogs(id, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : '/history/risk/event_markets',
		params: {
			...additionalParams,
			model_id: id,
		},
	};
	return apiRequest(req);
}

function historyRiskManagementEventSelectionsLogs(id) {
	const req = {
		method: 'GET',
		url   : '/history/risk/event_selections',
		params: {
			model_id: id,
		},
	};
	return apiRequest(req);
}


export const historyAPI = {
	historyCategoryList,
	historyCategoryLogs,
	historyMarketLogs,
	historySelectionLogs,
	historyResultSelectionLogs,
	historyUserLogs,
	historyGamblingLogs,
	historyCashbackLogs,
	historyCashbackGGRlimitsLogs,
	historyCurrencyLogs,
	historyRegistrationFreeSpinLogs,
	historyWelcomeBonusLogs,
	historyDepositFreeSpinLogs,
	historyDepositsLogs,
	historyNextDepositBonusLogs,
	historyNextDepositFreeSpinLogs,
	historyPackNextLogs,
	historyPacksLogs,
	historyParticipantTranslations,
	historyResponsibleGamblingLogs,

	historyRiskManagmentBetslipLogs,
	historyRiskManagmentEventMarketsLogs,
	historyRiskManagementEventSelectionsLogs,
};
