import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  width: 100%;

  .ant-select-dropdown.ant-select-dropdown--multiple {
    .ant-select-dropdown-menu.ant-select-dropdown-menu-vertical {
      .ant-select-dropdown-menu-item {
        padding-left: 24px;
        &:hover {
          &::after {
            left: 8px;
          }
        }
      }
      .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
        &::after {
          left: 8px;
        }
      }
    }
  }
`;
