import { PureComponent, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import compact from 'lodash/compact';
import { Row, Col, Collapse } from 'antd';
import Button from '../../components/uielements/button';
import FormControl from '../../components/uielements/FormControl';
import tabsActions from '../../redux/appTabs/actions';
import { ELEMENT_TYPES } from '../../constants/filters';

import {
	Checkbox,
	CustomComponent,
	Date,
	DateRange,
	Input,
	InputAutoComplete,
	InputNumber,
	Multiselect,
	NumberRange,
	Select,
	SelectSearch,
	TimeRange,
	InputNumberTypeText,
} from './components';
import Utils from './FilterUtils';
import { Wrapper } from './FilterBlock.style';
import { IconButton } from '../../components/uielements/styles/button.style';
import { Icons } from '../../components/uielements/AntIcons/AntIcons';
import { restoreAppTabs } from '../../helpers/utility';
import { connect } from 'react-redux';

const { Panel } = Collapse;

const rowStyle = {
	marginLeft : 0,
	marginRight: 0,
	display    : 'flex',
};

const columnsCounts = {
	columnMidleCount: 2,
	columnLargeCount: 3,
};

const btnContainerStyle = { display: 'flex' };
const btnResetStyle     = { marginRight: 8, height: 32 };

class FilterBlock extends PureComponent {

	static propTypes = {
		loading : PropTypes.bool,
		settings: PropTypes.shape({

			onClickSearch: PropTypes.func.isRequired,

			elements: PropTypes.arrayOf(PropTypes.shape({
				type: PropTypes.oneOf([
					ELEMENT_TYPES.Input,
					ELEMENT_TYPES.InputNumberTypeText,
					ELEMENT_TYPES.InputNumber,
					ELEMENT_TYPES.Select,
					ELEMENT_TYPES.SelectSearch,
					ELEMENT_TYPES.Multiselect,
					ELEMENT_TYPES.Date,
					ELEMENT_TYPES.DateRange,
					ELEMENT_TYPES.NumberRange,
					ELEMENT_TYPES.Checkbox,
					ELEMENT_TYPES.TimeRange,
					ELEMENT_TYPES.InputAutoComplete,
					ELEMENT_TYPES.Component,
				]).isRequired,

				name : PropTypes.string.isRequired,
				label: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.element,
				]),

				loading     : PropTypes.bool,
				showLabel   : PropTypes.bool,
				placeholder : PropTypes.string,
				defaultValue: PropTypes.any,
				value       : PropTypes.any,     // Only for 'controlled' elements
				minValue    : PropTypes.number,  // Only for 'InputNumber'
				maxValue    : PropTypes.number,  // Only for 'InputNumber'
				step        : PropTypes.number,  // Only for 'InputNumber'
				dateFormat  : PropTypes.string,  // Only for 'Date' and 'DateRange'
				timeFormat  : PropTypes.string,  // Only for 'Date', 'DateRange' and 'TimeRange'
				separator   : PropTypes.string,  // Only for 'NumberRange'
				dataSource  : PropTypes.arrayOf(PropTypes.string), // Only for InputAutoComplete
				component   : PropTypes.element, // Only for 'Component'

				optionList: PropTypes.arrayOf(PropTypes.shape({
					id  : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
					name: PropTypes.string,
				})), // Array of objects { id: 1, name: 'One' }. Only for 'Select' and 'Multiselect'

				onChange: PropTypes.func, // invoke after changing value
			})).isRequired,

			advancedElements: PropTypes.arrayOf(PropTypes.object), // Array of filter elements. Defines using 'Advanced filters' panel

			onClickReset        : PropTypes.func,
			columnsCount        : PropTypes.number,
			advancedColumnsCount: PropTypes.number,

			blockTitle          : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
			buttonSearchTitle   : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
			advancedFiltersTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
			buttonResetTitle    : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		}),

		// Only for DataRange: allow to remove periods from dropdown
		skippedPeriods: PropTypes.arrayOf(PropTypes.string),
	};

	static defaultProps = {
		settings: {
			columnsCount        : null,
			advancedColumnsCount: null,
			advancedElements    : [],
			blockTitle          : 'Filters',
			buttonSearchTitle   : 'Search',
			advancedFiltersTitle: 'Advansed Filters',
			buttonResetTitle    : 'Reset',
			onClickReset        : () => {},
		},

		loading: false,

		skippedPeriods: [],
	};

	constructor(props) {
		super(props);
		const { columnLargeCount } = columnsCounts;
		const { gridElements, gridAdvancedElements } = Utils.createGrids(props.settings);

		this.state = {
			gridElements,
			gridAdvancedElements,
			location                 : window.location,
			history                  : window.history,
			tabs                     : restoreAppTabs(),
			stateColumnsCount        : columnLargeCount,
			stateAdvancedColumnsCount: columnLargeCount,
			prevWidth                : window.innerWidth,
		};

		this.updateDataAfterChange      = this.updateDataAfterChange.bind(this);

		this.onFormSubmit               = this.onFormSubmit.bind(this);
		this.onClickSearch              = this.onClickSearch.bind(this);
		this.onClickReset               = this.onClickReset.bind(this);
		this.onChangeFormField          = this.onChangeFormField.bind(this);
		this.onChangeFormFieldDate      = this.onChangeFormFieldDate.bind(this);
		this.onChangeFormFieldDateRange = this.onChangeFormFieldDateRange.bind(this);
		this.onChangeFormFieldTimeRange = this.onChangeFormFieldTimeRange.bind(this);

		this.renderRows                 = this.renderRows.bind(this);
		this.renderElements             = this.renderElements.bind(this);
		this.renderButtons              = this.renderButtons.bind(this);
		this.renderFilterElement        = this.renderFilterElement.bind(this);
	}

	componentDidMount() {
		const { settings } = this.props;
		const { columnMidleCount } = columnsCounts;
		const filter		= Utils.collectFilter(settings);
		const searchParams	= new URLSearchParams();
		Utils.createQueryString(searchParams, filter);
		const queryString	= decodeURIComponent(searchParams.toString());

		window.addEventListener('resize', this.handleResize);
		if (window.innerWidth < 1200) {
			this.setState({ 
				stateColumnsCount        : columnMidleCount,
				stateAdvancedColumnsCount: columnMidleCount, 
				prevWidth                : window.innerWidth,
			});
		}

		if (searchParams.size) {
			window.history.pushState({}, '', `?${queryString}`);
		}
	}


	componentDidUpdate(prevProps, prevState) {

		const { settings } = this.props;
		const { stateColumnsCount, stateAdvancedColumnsCount } = this.state;
		const { settings: prevSettings } = prevProps;
		
		if (prevState.prevWidth !== window.innerWidth) {
			this.handleResize();
		}

		const needRebuild = Utils.isSettingsChanged(prevSettings, settings) || prevState.stateColumnsCount !== stateColumnsCount;
		if (needRebuild) {

			settings.columnsCount			= stateColumnsCount;
			settings.advancedColumnsCount	= stateAdvancedColumnsCount;

			const { gridElements, gridAdvancedElements } = Utils.createGrids(settings);
			this.setState({
				gridElements,
				gridAdvancedElements,
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	// Service --------------------------------------------------------------------------------------
	updateDataAfterChange(name, value) {
		const { settings } = this.props;
		const filterItem = Utils.getFilterItem(settings, name);

		if (filterItem.onChange) {
			filterItem.onChange(name, value);
		}
	}

	// Events ---------------------------------------------------------------------------------------
	onFormSubmit(event) {
		event.preventDefault();
		this.onClickSearch();
	}

	handleResize = () => {
		const { columnMidleCount, columnLargeCount } = columnsCounts;
		
		if (window.innerWidth < 1200) {
			this.setState({ 
				stateColumnsCount        : columnMidleCount,
				stateAdvancedColumnsCount: columnMidleCount, 
				prevWidth                : window.innerWidth,
			});
		} else {
			this.setState({ 
				stateColumnsCount        : columnLargeCount,
				stateAdvancedColumnsCount: columnLargeCount, 
				prevWidth                : window.innerWidth,
			});
		}
	};

	onClickSearch() {
		const { settings, tabsRefresh } = this.props;
		const { location : { pathname }, tabs } = this.state;
		const searchParams		= new URLSearchParams();
		const filter			= Utils.collectFilter(settings);
		const search			= Utils.buildUrl(searchParams, filter);
		const tabsWithSearch	= Utils.reBuildTabs(tabs, pathname, search);

		tabsRefresh(tabsWithSearch);
		settings.onClickSearch(filter);
	}

	onClickReset() {
		const { settings, tabsRefresh } = this.props;
		const { location : { href, pathname }, history, tabs } = this.state;
		const baseUrl	= href.split('?')[0];
		const tabsWithSearch = Utils.reBuildTabs(tabs, pathname, '');

		tabsRefresh(tabsWithSearch);
		history.pushState({}, '', baseUrl);
		settings.onClickReset();
	}

	onChangeFormField(name, value) {
		let currentValue = value;

		if (name === 'amount' && typeof value === 'string' && !value) {
			currentValue = null;
		}

		this.updateDataAfterChange(name, currentValue);
	}

	onChangeFormFieldDate(name, value, dateFormat) {
		const filterValue = value ? value.format(dateFormat) : '';
		this.updateDataAfterChange(name, filterValue);
	}

	onChangeFormFieldDateRange(name, value, format) {
		let valueFrom = null;
		let valueTo   = null;
		if (value.length) {
			valueFrom = value[0] ? value[0].format(format) : null;
			valueTo   = value[1] ? value[1]/* .set({ hour:'23', minute: '59', second:'59' }) */.format(format) : null;
		} else {
			valueFrom = value[0] ? value[0].set({ hour: '00', minute: '00', second: '00' }).format(format) : null;
			valueTo   = value[1] ? value[1].set({ hour: '23', minute: '59', second: '59' }).format(format) : null;
		}

		const filterValue = compact([valueFrom, valueTo]);

		this.updateDataAfterChange(name, filterValue);
	}

	onChangeFormFieldTimeRange(name, value) {
		const valueFrom   = value[0] ? value[0] : null;
		const valueTo     = value[1] ? value[1] : null;
		const filterValue = compact([valueFrom, valueTo]);

		this.updateDataAfterChange(name, filterValue);
	}

	// Renders --------------------------------------------------------------------------------------
	renderRows(grid, count, tryToRenderButtons = false) {

		const { settings } = this.props;
		const lastRowIndex = grid.length - 1;
		const lastColIndex = count - 1;

		const lastElementExists = Boolean(grid[lastRowIndex][lastColIndex]);
		const colSize = count > 0 ? Math.floor(24 / count) : 8;

		let buttonsRendered = false;

		const rows = grid.map((row, rowIndex) => {
			const cols = row.map((name, colIndex) => {
				const filterItem = Utils.getFilterItem(settings, name);

				// In the last cell we can render Search button.
				// Conditions:
				// - this is primary filters render
				// - last element doesn't exists
				// - we doesn't use advanced filters
				const isLast = (rowIndex === lastRowIndex && colIndex === lastColIndex);
				if (tryToRenderButtons && isLast && !lastElementExists) {
					buttonsRendered = true;
					const key = `col-${colIndex}`;
					return (
						<Col span={colSize} key={key}>
							{this.renderButtons()}
						</Col>
					);
				}

				// render regular filter element
				const key = `col-${colIndex}`;
				return (
					<Col span={colSize} key={key}>
						{this.renderFilterElement(filterItem)}
					</Col>
				);
			});

			const key = `row-${rowIndex}`;
			return (
				<Row gutter={16} style={rowStyle} key={key}>
					{cols}
				</Row>
			);
		});

		return {
			rows,
			buttonsRendered,
		};
	}

	renderElements() {
		const { settings } = this.props;
		const { stateColumnsCount, stateAdvancedColumnsCount, gridElements, gridAdvancedElements } = this.state;
		const { columnsCount, advancedColumnsCount, advancedFiltersTitle } = settings;
		const useAdvanced = Utils.checkUseAdvanced(settings);
		
		const colCount			= columnsCount || stateColumnsCount;
		const advancedColCount	= advancedColumnsCount || stateAdvancedColumnsCount;

		const { rows: primaryRows, buttonsRendered } = this.renderRows(gridElements, colCount, !useAdvanced);
		const { rows: advancedRows } = useAdvanced
			? this.renderRows(gridAdvancedElements, advancedColCount, false)
			: { rows: null };

		return (
			<Fragment>
				{primaryRows}
				{advancedRows && (
					<Wrapper>
						<Row gutter={16} style={rowStyle}>
							<Col span={24}>
								<Collapse>
									<Panel header={advancedFiltersTitle} key="advancedFilters">
										{advancedRows}
									</Panel>
								</Collapse>
							</Col>
						</Row>
					</Wrapper>
				)}
				{!buttonsRendered && (
					<Row gutter={16} style={{ ...rowStyle, marginTop: 8 }} key={uuid()}>
						<Col md={24}>
							{this.renderButtons()}
						</Col>
					</Row>
				)}
			</Fragment>
		);
	}

	renderButtons() {
		const { settings, loading } = this.props;
		const { buttonSearchTitle, buttonResetTitle, disabled, separateDisabledButtons } = settings;

		const resetDisabled		= separateDisabledButtons.includes('reset')	|| disabled;
		const searchDisabled	= separateDisabledButtons.includes('search') || disabled;

		const buttons = (
			<div style={btnContainerStyle}>
				<Button 
					disabled={resetDisabled} 
					style={btnResetStyle} 
					onClick={this.onClickReset}
				>
					{buttonResetTitle}
				</Button>
				<IconButton
					disabled={searchDisabled} 
					type="primary" 
					icon={Icons.search} 
					loading={loading} 
					onClick={this.onClickSearch}
				>
					{buttonSearchTitle}
				</IconButton>
			</div>
		);
		return (
			<FormControl control={buttons} controlRight />
		);
	}

	renderFilterElement(filterItem) {
		const { skippedPeriods } = this.props;
		const isExists = Boolean(filterItem);
		if (!isExists) {
			return null;
		}

		switch (filterItem.type) {
			case ELEMENT_TYPES.Input:
				return (
					<Input
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.InputNumberTypeText:
				return (
					<InputNumberTypeText
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.InputNumber:
				return (
					<InputNumber
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.Select:
				return (
					<Select
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.SelectSearch:
				return (
					<SelectSearch
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.Multiselect:
				return (
					<Multiselect
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.Date:
				return (
					<Date
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormFieldDate}
					/>
				);
			case ELEMENT_TYPES.DateRange:
				return (
					<DateRange
						filterItem={filterItem}
						name={filterItem.name}
						skippedPeriods={skippedPeriods}
						onChange={this.onChangeFormFieldDateRange}
					/>
				);
			case ELEMENT_TYPES.NumberRange:
				return (
					<NumberRange
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.Checkbox:
				return (
					<Checkbox
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.TimeRange:
				return (
					<TimeRange
						filterItem={filterItem}
						name={filterItem.name}
						onChange={this.onChangeFormFieldTimeRange}
					/>
				);
			case ELEMENT_TYPES.InputAutoComplete:
				return (
					<InputAutoComplete
						filterItem={filterItem}
						name={filterItem.name}
						value={filterItem.value}
						onChange={this.onChangeFormField}
					/>
				);
			case ELEMENT_TYPES.Component:
				return (
					<CustomComponent
						showLabel
						filterItem={filterItem}
						name={filterItem.name}
					/>
				);
			default:
				return null;
		}
	}

	render() {
		const { settings } = this.props;
		const { blockTitle } = settings;

		const elements = this.renderElements();

		return (
			<Wrapper>
				<Collapse defaultActiveKey="filterBlock">
					<Panel header={blockTitle} key="filterBlock">
						<form autoComplete="off" onSubmit={this.onFormSubmit}>
							<input autoComplete="off" name="hidden" type="text" style={{ display: 'none' }} />
							{elements}
						</form>
					</Panel>
				</Collapse>
			</Wrapper>
		);
	}
}

export default connect(null, { tabsRefresh: tabsActions.tabsRefresh })(FilterBlock);
