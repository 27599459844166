import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { eventsAPI } from '../../../../helpers/api/events';
import notifications from '../../../../helpers/notifications';

import actions from './actions';
import eventActions from '../event/actions';

import {
	// adaptDynamiLimits,
	prepareDynamicLimits,
	adaptSettings,
	prepareSettings,
	OWNERS,
} from '../utils';
import { prepareBaseData, adaptHistoryData } from './utils';
import { logger } from '../../../../helpers/logger';

const prefix = 'sport.events.selection';

const messages = {
	errorCancelSelection        : `${prefix}.errorCancelSelection`,
	errorUpdateSelection        : `${prefix}.errorUpdateSelection`,
	errorLoadSelectionSettings  : `${prefix}.errorLoadSelectionSettings`,
	errorUpdateSelectionOdd     : `${prefix}.errorUpdateSelectionOdd`,
	errorUpdateSelectionStatus  : `${prefix}.errorUpdateSelectionStatus`,
	errorLoadHistory            : `${prefix}.errorLoadHistory`,
	successSelectionCancel      : `${prefix}.successSelectionCancel`,
	successSelectionUpdate      : `${prefix}.successSelectionUpdate`,
	successSelectionOddUpdate   : `${prefix}.successSelectionOddUpdate`,
	successSelectionStatusUpdate: `${prefix}.successSelectionStatusUpdate`,
};

function getStoreData({ Sport: { Events } }) {

	return {
		eventID          : Events.Event.get('baseData').id,
		baseData         : Events.Selection.get('baseData'),
		settingsData     : Events.Selection.get('settingsData'),
		dynamicLimitsData: Events.Selection.get('dynamicLimitsData'),
		commonData       : Events.Selection.get('commonData'),
		UI               : Events.Selection.get('UI'),
	};
}

function* selectionCancel() {
	const takeAction = actions.EVENT_MARKET_SELECTION_CANCEL;

	yield takeEvery(takeAction, function* (action) {
		const { eventID, selectionID } = action.data;
		try {
			const response = yield call(eventsAPI.eventMarketSelectionCancel, selectionID);
			if (response && response.status === 200) {
				notifications.showSuccess(messages.successSelectionCancel);
				yield put(eventActions.oddsDataReload(eventID));
			}
		} catch (error) {
			notifications.showError(messages.errorCancelSelection, error);
			logger.log(error);
		}
	});
}

function* selectionUpdate() {
	const takeAction = actions.EVENT_MARKET_SELECTION_UPDATE;

	yield takeEvery(takeAction, function* (action) {
		const { selectionID, closeModal } = action.data;
		const {
			eventID,
			baseData,
			settingsData,
			dynamicLimitsData,
			commonData,
			UI,
		} = yield select(getStoreData);

		const preparedData = prepareBaseData(baseData);
		let isError = false;
		try {
			// Base data
			yield call(eventsAPI.eventMarketSelectionUpdate, selectionID, preparedData);

			// Settings
			if (UI.isChangedSettings) {
				const { marketTradingModeID } = commonData;
				const preparedSettings = prepareSettings(settingsData, OWNERS.eventSelection, marketTradingModeID);

				yield call(eventsAPI.eventMarketSelectionSettingsUpdate, selectionID, preparedSettings);
				yield put(actions.setValueUI('isChangedSettings', false));
			}

			// Dynamic Limits
			if (UI.isChangedDynamicLimits) {
				const preparedLimits = prepareDynamicLimits(dynamicLimitsData, OWNERS.eventSelection, selectionID);
				yield call(eventsAPI.eventMarketSelectionDynamicLimitsUpdate, selectionID, preparedLimits);
				yield put(actions.setValueUI('isChangedDynamicLimits', false));
			}

			notifications.showSuccess(messages.successSelectionUpdate);

		} catch (error) {
			isError = true;
			notifications.showError(messages.errorUpdateSelection, error);
			logger.log(error);
		}

		if (!isError) {
			yield put(eventActions.oddsDataReload(eventID));
			if (!closeModal) {
				if (UI.isChangedSettings) {
					yield put(actions.settingsDataReload(selectionID));
				}
				if (UI.isChangedDynamicLimits) {
					yield put(actions.dynamicLimitsDataReload(selectionID));
				}
			}
		}

		yield put(actions.setValueUI('loading', false));
		if (closeModal && !isError) {
			yield put(actions.dataReset());
		}
	});
}

function* settingsDataReload() {
	const takeAction = actions.EVENT_MARKET_SELECTION_SETTINGS_DATA_RELOAD;

	yield takeEvery(takeAction, function* (action) {
		const { selectionID } = action.data;
		let settings = adaptSettings({}, OWNERS.eventSelection);
		const params = { include_parent_limit: true };

		try {
			const response = yield call(eventsAPI.eventMarketSelectionSettingsList, selectionID, params);
			if (response && response.status === 200) {
				settings = adaptSettings(response.data.data, OWNERS.eventSelection);
			}
		} catch (error) {
			notifications.showError(messages.errorLoadSelectionSettings, error);
			logger.log(error);
		}

		yield put(actions.settingsDataRefresh(settings));
	});
}

function* selectionOddUpdate() {

	yield takeEvery(actions.EVENT_MARKET_SELECTION_ODD_UPDATE, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const { selectionID, odd, onSuccess } = action.data;
		const preparedData = {
			id: selectionID,
			odd,
		};

		try {
			const res = yield call(eventsAPI.eventMarketSelectionUpdate, selectionID, preparedData);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successSelectionOddUpdate);
				if (onSuccess) {
					onSuccess(odd);
				}
			}

		} catch (error) {
			notifications.showError(messages.errorUpdateSelectionOdd, error);
			logger.log(error);
		}

		yield put(actions.setValueUI('loading', false));
	});
}

function* selectionStatusUpdate() {

	yield takeEvery(actions.EVENT_MARKET_SELECTION_STATUS_UPDATE, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const { selectionID, statusID, onSuccess } = action.data;
		const preparedData = {
			id       : selectionID,
			status_id: statusID,
		};

		try {
			const res = yield call(eventsAPI.eventMarketSelectionUpdate, selectionID, preparedData);
			if (res && res.status === 200) {
				notifications.showSuccess(messages.successSelectionStatusUpdate);
				if (onSuccess) {
					onSuccess();
				}
			}

		} catch (error) {
			notifications.showError(messages.errorUpdateSelectionStatus, error);
			logger.log(error);
		}

		yield put(actions.setValueUI('loading', false));
	});
}

function* selectionHistoryReload() {

	yield takeEvery(actions.EVENT_MARKET_SELECTION_HISTORY_RELOAD, function* (action) {
		yield put(actions.setHistoryValueUI(true));
		const { selectionID } = action.data;
		try {
			const res = yield call(eventsAPI.eventMarketSelectionHistory, selectionID);
			if (res && res.status === 200) {
				const { data } = res.data;
				const adaptedData = adaptHistoryData(data);
				yield put(actions.historyRefresh(adaptedData));
			}

		} catch (error) {
			notifications.showError(messages.errorLoadHistory, error);
			logger.log(error);
		}
		yield put(actions.setHistoryValueUI(false));
	});
}

export default function* eventsSelectionsSaga() {
	yield all([
		fork(selectionCancel),
		fork(selectionUpdate),
		fork(settingsDataReload),
		fork(selectionOddUpdate),
		fork(selectionStatusUpdate),
		// fork(dynamicLimitsDataReload),
		fork(selectionHistoryReload),
	]);
}
