import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { fill } from '../../../../helpers/utils';
import { IInitSportRiskLogs, IRecordDetails, ISportRiskLogsUI } from './types';


const initialState: IInitSportRiskLogs = {
	betslip        : [],
	betslipDetails : [],
	eventMarkets   : [],
	eventSelections: [],
	
	UI: {
		loading: false,

	},
};

export const riskSportBetsListhistorySlice = createSlice({
	name    : 'riskSportBetsHistory',
	initialState,
	reducers: {
		setBetslipLogsData: (state: Draft<IInitSportRiskLogs>, action: PayloadAction<IRecordDetails[]>) => {
			state.betslip = action.payload;
		},
		setBetslipDetailsLogsData: (state: Draft<IInitSportRiskLogs>, action: PayloadAction<IRecordDetails[]>) => {
			state.betslipDetails = action.payload;
		},
		setEventMarketsLogsData: (state: Draft<IInitSportRiskLogs>, action: PayloadAction<IRecordDetails[]>) => {
			state.eventMarkets = action.payload;
		},
		setEventSelectionsLogsData: (state: Draft<IInitSportRiskLogs>, action: PayloadAction<IRecordDetails[]>) => {
			state.eventSelections = action.payload;
		},
		uiRefresh: (state: Draft<IInitSportRiskLogs>, action: PayloadAction<ISportRiskLogsUI>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},

	},
});


export const actions = {
	...riskSportBetsListhistorySlice.actions,
};

export default riskSportBetsListhistorySlice.reducer;

