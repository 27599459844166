import { SwitchContainer } from './TabEligabilityStyle';
import React from 'react';
import { ISwitcherProps } from './types';
import IntlMessages from '../../../../../components/utility/intlMessages';
import Radio from '../../../../../components/uielements/radio';


const lang = {
	allow : <IntlMessages id="bonuses.eligibility.allow"/>,
	reject: <IntlMessages id="bonuses.eligibility.reject"/>,
};

const options = [
	{
		label: lang.allow,
		value: true,
	},
	{
		label: lang.reject,
		value: false,
	},

];
export const Switcher: React.FC<ISwitcherProps> = (props) => {
	const { onChange, allow, disabled } = props;

	return (
		<SwitchContainer className="switch-containnnn">
			<Radio.Group
				value={allow}
				onChange={(e: { target: { value: boolean } }) => onChange(e.target.value)}
				options={options}
				optionType="button"
				disabled={disabled}
			/>
		</SwitchContainer>
	);
};
