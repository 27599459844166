import { countries } from 'country-data';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { mapEnum } from './utils';

export const allCountries = countries.all;

const rawCountryList = allCountries
	.filter(item => {
		return item.alpha2 !== '';
	})
	.map( item => {
		return {
			id  : item.alpha2,
			name: item.name,
		};
	});

export const countryList = sortBy( uniqBy(rawCountryList, 'id'), ['name']);

export const countryMap = mapEnum(countryList);
