import toInteger from 'lodash/toInteger';
import { isNumber } from 'lodash';
import { ADDITIONAL_TABS } from '../../constants/appTabs';
import { COMPONENTS } from '../../constants/components';
import { Routes, routes } from '../../routes/routesData';

const actions = {

	APP_TABS_RELOAD  	: 'APP_TABS_RELOAD',
	APP_TABS_REFRESH 	: 'APP_TABS_REFRESH',
	APP_TABS_OPEN_TAB	: 'APP_TABS_OPEN_TAB',
	APP_TABS_CLOSE_TAB: 'APP_TABS_CLOSE_TAB',
	APP_TABS_CLOSE_ALL: 'APP_TABS_CLOSE_ALL',

	APP_TABS_CLOSE_OTHER_TABS: 'APP_TABS_CLOSE_OTHER_TABS',
	APP_TABS_CLOSE_RIGHT_TABS: 'APP_TABS_CLOSE_RIGHT_TABS',

	APP_TABS_ACTIVE_TAB_SET: 'APP_TABS_ACTIVE_TAB_SET',

	APP_TABS_ACTIVE_USER_ID_SET: 'APP_TABS_ACTIVE_USER_ID_SET',

	tabsReload: () => ({
		type: actions.APP_TABS_RELOAD,
	}),
	tabsRefresh: tabs => ({
		type: actions.APP_TABS_REFRESH,
		data: { tabs },
	}),
	activeTabSet: (activeTabID, activeTabTitle, isSearch) => {

		return {
			type: actions.APP_TABS_ACTIVE_TAB_SET,
			data: {
				activeTabID,
				activeTabTitle,
				isSearch,
			},
		};
	},
	openTab: (tab, usePush = true ) => {

		return {
			type: actions.APP_TABS_OPEN_TAB,
			data: {
				tab,
				usePush,
			},
		};
	},
	closeTab: (tabID, closeContext) => ({
		type: actions.APP_TABS_CLOSE_TAB,
		data: {
			tabID,
			closeContext,
		},
	}),
	closeOtherTabs: tabID => ({
		type: actions.APP_TABS_CLOSE_OTHER_TABS,
		data: {
			tabID,
		},
	}),
	closeRightTabs: tabID => ({
		type: actions.APP_TABS_CLOSE_RIGHT_TABS,
		data: {
			tabID,
		},
	}),
	setTabUserId: userID => ({
		type: actions.APP_TABS_ACTIVE_USER_ID_SET,
		data: {
			userID,
		},
	}),
	closeAllTabs: () => ({
		type: actions.APP_TABS_CLOSE_ALL,
	}),

	// Helpers
	openTabUsers: () => {
		const tab = {
			id            : 'users/list',
			titleID       : 'sidebar.usersSearch',
			componentName : COMPONENTS.usersList,
			componentProps: {},
			location      : '/dashboard/users/list',
		};

		return actions.openTab(tab);
	},
	openTabUsersGroups: () => {
		const tab = {
			id            : 'users/groups',
			titleID       : 'sidebar.userGroups',
			componentName : COMPONENTS.usersGroups,
			componentProps: {},
			location      : '/dashboard/users/groups',
		};

		return actions.openTab(tab);
	},
	openTabUsersRoles: () => {
		const tab = {
			id            : 'users/roles',
			titleID       : 'sidebar.userRoles',
			componentName : COMPONENTS.usersRoles,
			componentProps: {},
			location      : '/dashboard/users/roles',
		};

		return actions.openTab(tab);
	},

	openTabWebsiteInner: (websiteID) => {
		const tab = {
			id            : 'partners/websitesInfo',
			titleID       : 'websiteInner.title',
			componentName : COMPONENTS.websiteInner,
			componentProps: { websiteID },
			location      : `/dashboard/partners/websitesInfo/${websiteID}`,
		};

		return actions.openTab(tab);
	},

	openTabCreateMassBonus: (massBonusID) => {
		const bonusID = toInteger(massBonusID);
		const location	= `/dashboard/loyalty/bonusingSystem/massbonus/${bonusID > 0 ? massBonusID : 'create'}`;
		const titleID	= bonusID > 0 ? 'viewMassBonus.title' : 'createMassBonus.title';

		const tab = {
			id            : 'loyalty/bonusingSystem/massbonus',
			titleID,
			location,
			componentName : COMPONENTS.createMassBonus,
			componentProps: { bonusID },
		};
		return actions.openTab(tab);
	},

	openTabMassBonusList: () => {

		const tab = {
			id            : 'loyalty/bonusingSystem/massBonus',
			titleID       : 'sidebar.bonuses.massBonus',
			location      : '/dashboard/loyalty/bonusingSystem/massBonus',
			componentName : COMPONENTS.createMassBonus,
			componentProps: { },
		};
		return actions.openTab(tab);
	},

	openTabUserInfo: (userID, itemID) => {
		const htmlId = `itemID_${isNumber(itemID) ? itemID : ''}`;
		const tempTab = itemID ? ADDITIONAL_TABS.userInfo : ADDITIONAL_TABS.personalInfo;
		const location = itemID ? `/dashboard/users/info/${userID}#${htmlId}` : `/dashboard/personal/info/${userID}`;

		const tab = {
			...tempTab,
			componentProps: { userID: toInteger(userID) },
			location,
		};
		if (itemID) {
			routes.navigate(location);
		}

		return actions.openTab(tab);
	},

	openTabUser( userID ) {
		const tab = {
			id            : 'users/info',
			titleID       : 'sidebar.usersInfo',
			componentName : COMPONENTS.userInfo,
			componentProps: { userID },
			location      : `/dashboard/users/info/${userID}`,
		};

		return actions.openTab(tab);
	},

	openTabInfoPage: pageID => {
		const tab = {
			...ADDITIONAL_TABS.infoPage,
			componentProps: { pageID: toInteger(pageID) },
			location      : `/dashboard/content/page/${toInteger(pageID) > 0 ? pageID : 'add'}`,
			titleID       : toInteger(pageID) > 0 ? 'infoPages.editTitle' : 'infoPages.newTitle',
		};

		return actions.openTab(tab);
	},
	openPromotionPage: promotionID => {
		const tab = {
			...ADDITIONAL_TABS.promotions,
			componentProps: { promotionID: toInteger(promotionID) },
			location      : `/dashboard/content/newPromotion/${toInteger(promotionID) > 0 ? promotionID : 'add'}`,
			titleID       : toInteger(promotionID) > 0 ? 'promotion.editTitle' : 'promotion.newTitle',
		};

		return actions.openTab(tab);
	},
	openTabNewsArticlePage: articleID => {
		const tab = {
			...ADDITIONAL_TABS.newsArticlePage,
			componentProps: { articleID: toInteger(articleID) },
			location      : `/dashboard/content/news/article/${toInteger(articleID) > 0 ? articleID : 'add'}`,
			titleID       : toInteger(articleID) > 0 ? 'news.article.editTitle' : 'news.article.newTitle',
		};

		return actions.openTab(tab);
	},
	openTabPayways: paymentID => {
		const tab = {
			...ADDITIONAL_TABS.paymentPayways,
			componentProps: { paymentID: toInteger(paymentID) },
			location      : `/dashboard/payment/payways/${paymentID}`,
		};

		return actions.openTab(tab);
	},

	openRTMSubModules: (address, componentName) => {
		const tab = {
			id            : `rtm/${address}`,
			titleID       : `sidebar.rtm.${address}`,
			componentName : COMPONENTS[componentName],
			componentProps: {},
			location      : `/dashboard/rtm/${address}`,
		};

		return actions.openTab(tab);
	},

	openTabMessages: () => {
		const tab = {
			id            : 'notificationCenter/messages',
			titleID       : 'sidebar.messages',
			componentName : COMPONENTS.messages,
			componentProps: {},
			location      : '/dashboard/notificationCenter/messages',
		};

		return actions.openTab(tab);
	},

	openTabBonusTemplateCreate: (templateType) => {
		const tab = {
			id            : 'loyalty/templates/create',
			titleID       : 'loyalty.templates.createTitle',
			// componentName : COMPONENTS.bonusTemplate,
			componentProps: { },
			location      : `/dashboard/loyalty/templates/create/${templateType}`,
		};

		return actions.openTab(tab);
	},
	openTabConditionalBonusCreate: (bonusType) => {
		const type = isNaN(Number(bonusType)) ? 'create' : 'edit';
		const tab = {
			id            : 'loyalty/bonusingSystem/bonuses/create',
			titleID       : 'loyalty/bonusingSystem/bonuses.createTitle',
			componentProps: { },
			location      : `/dashboard/loyalty/bonusingSystem/bonuses/${type}/${bonusType}`,
		};

		return actions.openTab(tab);
	},

	openTabConditionalBonusEdit: (bonusType, bonusID) => {
		const tab = {
			id            : 'loyalty/bonusingSystem/bonuses/create',
			titleID       : 'loyalty/bonusingSystem/bonuses.editTitle',
			componentProps: { },
			location      : `/dashboard/loyalty/bonusingSystem/bonuses/edit/${bonusType}/${bonusID}`,
		};

		return actions.openTab(tab);
	},

	openTabWelcomeBonusCreate: () => {
		const tab = {
			id            : 'loyalty/bonusingSystem/welcome/create',
			titleID       : 'loyalty/bonusingSystem/bonuses.createWelcomeTitle',
			componentProps: { },
			location      : Routes.welcomeBonusCreate,
		};

		return actions.openTab(tab);
	},

	openTabWelcomeBonusEdit: bonusID => {
		const tab = {
			id            : 'loyalty/bonusingSystem/welcome/create',
			titleID       : 'loyalty/bonusingSystem/bonuses.editWelcomeTitle',
			componentProps: { bonusID },
			location      : `/dashboard/loyalty/bonusingSystem/welcome/edit/${bonusID}`,
		};

		return actions.openTab(tab);
	},

	openTabBonusTemplates: () => {
		const tab = {
			id            : 'loyalty/bonusTemplates',
			titleID       : 'loyalty.templates.title',
			componentProps: {},
			location      : Routes.bonusTemplates,
		};

		return actions.openTab(tab);
	},

	openTabBonusesList: () => {
		const tab = {
			id            : 'loyalty/bonusingSystem/bonuses',
			titleID       : 'sidebar.bonuses.bonuses',
			componentProps: {},
			location      : Routes.bonuses,
		};

		return actions.openTab(tab);
	},
	openTabWelcomeBonusesList: () => {
		const tab = {
			id            : 'loyalty/bonusingSystem/welcome',
			titleID       : 'sidebar.bonuses.welcome',
			componentProps: {},
			location      : Routes.welcome,
		};

		return actions.openTab(tab);
	},

	openTabBonusTemplateEdit: (templateID) => {
		const tab = {
			id            : 'loyalty/templates/view',
			titleID       : 'loyalty.templates.viewTitle',
			// componentName : COMPONENTS.bonusTemplate,
			componentProps: {},
			location      : `/dashboard/loyalty/templates/view/${templateID}`,
		};

		return actions.openTab(tab);
	},
};

export default actions;
