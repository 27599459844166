import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash/isEqual';
import Select, { SelectOption } from './select';
import { Wrapper } from './styles/selectList.style';

/**
 * Additional props:
 *
 * optionsList - array of string or number.
 * keyID - key of Object for getting ID value. Default: 'id'
 * keyName - key of Object for getting Name value. Default: 'name'
 * dataName - name of element Select
 *
 * onChange() invoke function from props & pass to it values:
 *
 * value - selected value. String
 * dataName - name of element Select. String
 * dataref - selected object from optionsList. String
 * dataindex - index of selected object from optionsList. Number
 * option - selected Option. React-Component
 */

class SelectListTags extends Component {

	static propTypes = {
		optionsList: PropTypes.array,

		keyID            : PropTypes.string,
		title            : PropTypes.string,
		name             : PropTypes.string,
		value            : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		keyName          : PropTypes.string,
		dataName         : PropTypes.string,
		wrapperClass     : PropTypes.string,
		wrapperID        : PropTypes.string,
		onChange         : PropTypes.func,
		onSelect         : PropTypes.func,
		usePopupContainer: PropTypes.bool,
		showSearch   		  : PropTypes.bool,
		onSearch     		  : PropTypes.func,
	};

	static defaultProps = {
		onSearch     		  : () => {},
		optionsList      : [],
		keyID            : 'id',
		title            : '',
		keyName          : 'name',
		dataName         : 'selectList',
		wrapperClass     : null,
		wrapperID        : null,
		onChange         : null,
		onSelect         : null,
		value            : '',
		name             : '',
		usePopupContainer: true,
		showSearch   				: true,
	};

	constructor(props) {
		super(props);
		this.renderOptions     = this.renderOptions.bind(this);
		this.handleChange      = this.handleChange.bind(this);
		this.getPopupContainer = this.getPopupContainer.bind(this);

		this.state = {
			lastValue : null,
			lastOption: null,
		};

		this.uid = uuid();
	}

	renderOptions() {
		const { optionsList, title } = this.props;
		const result = optionsList.map((item, index) => {
			const key  = `${item}${index}`;
			const name = item;
			let optionDisabled = false;
			if ( item.optionDisabled ) {
				optionDisabled = true;
			}
			return (
				<SelectOption disabled={optionDisabled} value={item} key={key} dataref={item} dataindex={index} title={title}>
					{ name }
				</SelectOption>
			);
		});
		return result;
	}

	handleChange(value, option) {
		const { dataName, onChange, onSelect } = this.props;
		if (!value) {
			return;
		}
		if (!onChange && !onSelect) {
			return;
		}

		// because event 'onChange' rises two time in this component
		const { lastValue, lastOption } = this.state;
		if (isEqual(lastValue, value) && isEqual(lastOption, option)) {
			return;
		}

		const { dataref, dataindex } = option.props;

		if (onChange) {

			this.setState({
				lastValue: value,
				lastOption,
			}, () => {
				onChange(value, dataName, dataref, dataindex, option);
			});

		} else if (onSelect) {

			this.setState({
				lastValue: value,
				lastOption,
			}, () => {
				onSelect(value, dataName, dataref, dataindex, option);
			});
		}
	}

	getPopupContainer() {
		const { wrapperID } = this.props;
		const id = wrapperID || this.uid;

		return document.getElementById(id);
	}

	render() {
		const { wrapperClass, wrapperID, usePopupContainer, name, showSearch, ...restProps } = this.props;
		const options = this.renderOptions();

		return (
			<Wrapper className={wrapperClass} name={name} id={wrapperID || this.uid}>
				<Select
					{...restProps}
					showSearch={showSearch}
					onSelect={this.handleChange}
					onChange={() => null}
					filterOption={(input, option) => {
						if (input.length >= 3) {
							return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
						}
						return option;
					}

					}
					getPopupContainer={usePopupContainer ? this.getPopupContainer : null}
				>
					{options}
				</Select>
			</Wrapper>
		);
	}
}

export default SelectListTags;
