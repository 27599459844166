import last from 'lodash/last';
import { APP_TABS } from '../constants/appTabs';
import { getTabIDs } from './utility';

export function getSidebarTabsKey() {
	const pathname = window.location.pathname.replace('/dashboard', '').replace(/^\/{1}/, '');
	if (!pathname) {
		return APP_TABS.dashboard;
	}
	const AVAILABLE_TABS_IDS = getTabIDs() || [];
	for (let i = 0; i < AVAILABLE_TABS_IDS.length; i++) {
		const key = AVAILABLE_TABS_IDS[i];
		if (pathname.includes(key)) {
			return key;
		}
	}

	return APP_TABS.dashboard;
}

export function getLastParameterID() {
	const pathname = window.location.pathname.replace('/dashboard', '').replace(/^\/{1}/, '');

	if (!pathname) {
		return 0;
	}
	const parts  = pathname.split('/');
	const lastID = last(parts);

	return lastID; // TODO check why toInteger
}
export function getLastMultiParameterIDs(rootLocationID) {
	const pathname = window.location.pathname.replace('/dashboard', '').replace(/^\/{1}/, '');

	if (!pathname) {
		return 0;
	}
	const parts  = pathname.split('/');
	const params = [];
	parts.forEach(path => {
		if (!rootLocationID.includes(path)) {
			params.push(path);
		}
	});
	return params;
	
}
