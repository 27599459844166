import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { bonusesAPI } from '../../../../../helpers/api/bonuses';
import { getHeadersTotalCount } from '../../../../../helpers/utils';

import actions from './actions';
import userMassBonusFilterActions from '../../userMassBonus/filter/actions';
import userBonusActions from '../../userBonus/actions';
import tableActions from '../../../../tables/actions';
import appTabsActons from '../../../../appTabs/actions';
import bonusesActions from '../../bonuses/actions';
import { getListParams, adaptList } from './utils';
import { deriveTablePagination } from '../../../../../selectors/tables';
import { TABLE_TYPES } from '../../../../../constants/tableTypes';
import { logger } from '../../../../../helpers/logger';
import { showError } from '../../../../../helpers/notifications';
import { LANGUAGES } from '../../../../../helpers/commonConstants';

const tableType       = TABLE_TYPES.bonusesMassBonusList;

const messages = {
	errorLoadList: 'Loading list failed',
};

function getStoreData(state) {
	const { Partner: { Websites }, Tables, Loyalty: { Bonuses }, Casino, App } = state;
	const websiteID		= App.get('websiteID');
	const websiteData	= Websites.List.get('entities')[+websiteID];
	const langID = websiteData ? websiteData.langID : LANGUAGES.en;
	return {
		filter         : Bonuses.MassBonus.MassBonusList.get('filter'),
		pagination     : deriveTablePagination(tableType, state),
		sorting        : Tables.get(tableType).sorting,
		entities       : Bonuses.MassBonus.MassBonusList.get('entities'),
		casinoCamesList: Casino.Games.get('gamesList'),
		websiteID,
		langID,
	};
}

function* listReload() {

	yield takeEvery(actions.MASS_BONUS_LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);

		try {
			const res = yield call(bonusesAPI.massBonusList, null, params);
			if (res && res.status === 200) {
				const list			= adaptList(res.data.data);
				const totalCount	= getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(list));
				
				yield put(tableActions.paginationRefresh(tableType, { totalCount }));
			}
		} catch (error) {
			showError(messages.errorLoadList, error);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* getItemByID() {

	yield takeEvery(actions.MASS_BONUS_GET_ITEM_BY_ID, function* ({ bonusID }) {
		yield put(actions.uiRefresh({ editMode: true }));
		const { casinoCamesList, websiteID, langID } = yield select(getStoreData);
		try {
			const res = yield call(bonusesAPI.massBonusList, bonusID);
			if (res && res.status === 200) {
				const [massBonusEntity] = adaptList([res.data.data]);
				if (massBonusEntity) {
					if (!casinoCamesList.length) {
						yield put(bonusesActions.gameEntityReload(massBonusEntity.casinoGameID, null, websiteID, langID, true));
					}
					if (!massBonusEntity.count.providers) massBonusEntity.count.providers = {};
					if (!massBonusEntity.count.categories) massBonusEntity.count.categories = {};
					yield put(actions.baseDataRefresh(massBonusEntity));
					yield put(userMassBonusFilterActions.filterRefresh(massBonusEntity.userFilter));
					yield put(userBonusActions.dataRefresh(massBonusEntity));
					yield put(userBonusActions.uiRefresh({
						denominationKey  : massBonusEntity.denomination,
						denominationValue: massBonusEntity.denominationValue,
					}));
					
				}
				yield put(appTabsActons.openTabCreateMassBonus(bonusID));
			}
		} catch (error) {
			showError(messages.errorLoadList, error);
			logger.log(error);
		}

	});
}

export default function* listSaga() {
	yield all([ 
		fork(listReload),
		fork(getItemByID),
	]);
}
