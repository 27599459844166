import React from 'react';
import IntlMessages from '../../../../components/utility/intlMessages';

export const menuKeys = {
	close         : 1,
	closeOtherTabs: 2,
	closeRightTabs: 3,
};

export const lang = {
	close         : <IntlMessages id = "appTabs.ContextMenu.close" />,
	closeOtherTabs: <IntlMessages id = "appTabs.ContextMenu.closeOtherTabs" />,
	closeRightTabs: <IntlMessages id = "appTabs.ContextMenu.closeRightTabs" />,
};
