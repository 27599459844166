import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormControl from '../../uielements/FormControl';
import InputNumber from '../../uielements/InputNumber';
import { Wrapper } from './FormInputNumber.style';

class FormInputNumber extends Component {

	static propTypes = {
		label     : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth: PropTypes.number,
		height    : PropTypes.string,
	};

	static defaultProps = {
		label     : '',
		labelWidth: 25, // in percent
		height    : '36px',
	};

	render() {
		const { label, labelWidth, height, labelRight, ...restProps } = this.props;

		const lw = (Boolean(labelWidth) || labelWidth === 0) ? labelWidth : 25;
		const h  = height ? { height } : {};
		const mb = (labelWidth === 0) ? 0 : undefined;

		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				labelRight={labelRight}
				{...h}
				control={(
					<Wrapper>
						<InputNumber {...restProps} />
					</Wrapper>
				)}
			/>
		);
	}
}

export default FormInputNumber;
