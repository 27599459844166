import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > .ant-radio-group {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content:space-between;
  }
  & label {
   width: 200px; 
  };  
`;
