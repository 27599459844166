const actions = {

	USERS_MASS_BONUS_LIST_RELOAD               : 'USERS_MASS_BONUS_LIST_RELOAD',
	USERS_MASS_BONUS_LIST_REFRESH              : 'USERS_MASS_BONUS_LIST_REFRESH',
	USERS_MASS_BONUS_LIST_SET_VALUE_UI         : 'USERS_MASS_BONUS_LIST_SET_VALUE_UI',
	USERS_MASS_BONUS_LIST_SORTING_REFRESH      : 'USERS_MASS_BONUS_LIST_SORTING_REFRESH',
	USERS_MASS_BONUS_LIST_UI_REFRESH         		: 'USERS_MASS_BONUS_LIST_UI_REFRESH',
	USERS_MASS_BONUS_REPORT_DATA_RELOAD        : 'USERS_MASS_BONUS_REPORT_DATA_RELOAD',
	USERS_MASS_BONUS_REPORT_DATA_FIELDS_REFRESH: 'USERS_MASS_BONUS_REPORT_DATA_FIELDS_REFRESH',
	
	listReload: data => ({
		type: actions.USERS_MASS_BONUS_LIST_RELOAD,
		data,
	}),
	listRefresh: list => ({
		type: actions.USERS_MASS_BONUS_LIST_REFRESH,
		data: list,
	}),
	setValueUI: (valueName, value) => ({
		type: actions.USERS_MASS_BONUS_LIST_SET_VALUE_UI,
		data: {
			valueName,
			value,
		},
	}),
	sortingRefresh: data => ({
		type: actions.USERS_MASS_BONUS_LIST_SORTING_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.USERS_MASS_BONUS_LIST_UI_REFRESH,
		data,
	}),

	reportDataReload: () => ({
		type: actions.USERS_MASS_BONUS_REPORT_DATA_RELOAD,
	}),
	reportFieldsRefresh: reportFields => ({
		type: actions.USERS_MASS_BONUS_REPORT_DATA_FIELDS_REFRESH,
		reportFields,
	}),
};

export default actions;
