import { apiRequest } from './index';

// get list of users
export function findUsers(params) {
	if (!params) params = {};
	if (params.page === undefined) params.page = 1;
	if (params.limit === undefined) params.limit = 10;

	const req = {
		method: 'GET',
		url   : '/users',
		params,
	};

	return apiRequest(req);
}

// get list of admin/profile [modal]
export function profileList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/admin/profile',
		params,
	};

	return apiRequest(req);
}

// get list of users without restrictions
export function usersList(type, params = {}, id) {
	// eslint-disable-next-line no-nested-ternary
	const url = type && !id  ? `/${type}` : type && id ? `/${type}/${id}` : '/users';
	const req = {
		method: 'GET',
		url,
		params,
	};

	return apiRequest(req);
}

export function usersListByIP(ip, params = {}) {
	const req = {
		method       : 'GET',
		url          : `/ip/users/${ip}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

export function usersListByCardNumber(cardNumber, params = {}) {
	const req = {
		method       : 'GET',
		url          : `/users/card_number/${cardNumber}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

// get Admins List
export function adminsList(params = {}) {

	const userRoles = (
		params && params.user_roles
	) ? params.user_roles : 'admin';

	const req = {
		method: 'GET',
		url   : '/admins',
		params: {
			...params,
			user_roles: userRoles,
		},
	};

	return apiRequest(req);
}

// create Admin
export function adminCreate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/admins',
		data,
		params,
	};

	return apiRequest(req);
}

// get data for autocomplete filters
export function getDataAutocoplete(params) {
	if (!params) params = {};

	const req = {
		method: 'GET',
		url   : '/getUsersDataAutocomplete',
		params,
	};

	return apiRequest(req);
}

export function getAdminDataAutocomplete(params) {
	if (!params) params = {};

	const req = {
		method: 'GET',
		url   : '/getAdminsDataAutocomplete',
		params,
	};

	return apiRequest(req);
}

// get user info
export function getUserInfo(userID) {
	const req = {
		method: 'GET',
		url   : `/users/${userID}/info`,
	};

	return apiRequest(req);
}

// update user data
function updateUserData(userID, data, type) {

	const req = {
		method: 'POST',
		url   : `/${type}/${userID}`,
		data,
	};

	return apiRequest(req);
}

function updateAdminData(adminID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/admins/${adminID}`,
		params: { ...additionalParams },
		data,
	};

	return apiRequest(req);
}


// get bets statistics
export function getUserBetsStatistic(data) {
	const req = {
		method: 'GET',
		url   : '/betslips',
		params: {
			user_id: data.id,
			page   : data.page,
			limit  : data.limit,
		},
		withoutLangID: true,
	};

	return apiRequest(req);
}

// get user statistic
export function getUserStatistic(data) {


	const req = {
		method: 'GET',
		url   : `/users/${data.id}/statistics`,
		params: {
			date_from: data.date_from,
			date_to  : data.date_to,
		},
		withoutLangID: true,
	};

	return apiRequest(req);
}

// get user sport statistic
export function getUserSportStatistic(data) {


	const req = {
		method: 'GET',
		url   : `/users/${data.id}/sportStatistics`,
		params: {
			start_date: data.start_date,
			end_date  : data.end_date,
		},
		withoutLangID: true,
	};

	return apiRequest(req);
}

// get user casino statistic
export function getUserCasinoStatistic(data) {


	const req = {
		method: 'GET',
		url   : `/users/${data.id}/casinoStatistics`,
		params: {
			start_date: data.start_date,
			end_date  : data.end_date,
		},
		withoutLangID: true,
	};

	return apiRequest(req);
}

// get user actions
export function getActionsList(typeUrl, data) {
	const req = {
		method: 'GET',
		url   : `/${typeUrl}/${data.id}/actions`,
		params: {
			page : data.page,
			limit: data.limit,
		},
	};

	return apiRequest(req);
}

// get user info for admin
export function getUserAdminInfo(userID, apiType) {
	const req = {
		method: 'GET',
		url   : `/${apiType}/${userID}/adminInfo`,
	};

	return apiRequest(req);
}

// get user logins list
export function getLoginsList(typeUrl, data) {

	const req = {
		method: 'GET',
		url   : `/${typeUrl}/${data.id}/logins`,
		params: {
			page : data.page,
			limit: data.limit,
		},
	};

	return apiRequest(req);
}

// get user risk groups list
export function getUserRiskGroupList(userID) {
	const req = {
		method: 'GET',
		url   : `/users/${userID}/riskGroup`,
	};

	return apiRequest(req);
}

// get list of risk-groups
export function getRiskGroupsList() {
	const req = {
		method: 'GET',
		url   : '/riskGroup',
	};

	return apiRequest(req);
}

// get financial info about user
export function getUserFinance(userID) {
	const req = {
		method: 'GET',
		url   : `/users/${userID}/financial`,
	};

	return apiRequest(req);
}

// add user to risk-group
export function addUserToRiskGroup(userID, riskGroupID) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/riskGroup/${riskGroupID}`,
	};

	return apiRequest(req);
}

// deleting user from all risk-group
export function clearUserRiskGroup(userID) {
	const req = {
		method: 'DELETE',
		url   : `/users/${userID}/riskGroup`,
	};

	return apiRequest(req);
}

// add risk-group
export function addRiskGroup(data) {
	/* Examle
   data: {
   "name": "Test group",
   "reason": "Test Reason",
   "bet_factor": 1,
   "in_play_bet_delay": 10,
   "max_stake": 1000,
   "max_won": 10000,
   "min_comb": 100,
   "max_comb": 20000,
   "overask": "false",
   "bonuses": 2
   }
   */

	const req = {
		method: 'POST',
		url   : '/riskGroup',
		data,
	};

	return apiRequest(req);
}

// edit risk-group
export function editRiskGroup(data) {
	/* Examle
   data: {
   "id": 1
   "name": "Test group",
   "reason": "Test Reason",
   "bet_factor": 1,
   "in_play_bet_delay": 10,
   "max_stake": 1000,
   "max_won": 10000,
   "min_comb": 100,
   "max_comb": 20000,
   "overask": "false",
   "bonuses": 2
   }
   */

	const req = {
		method: 'POST',
		url   : `/riskGroup/${data.id}`,
		data,
	};

	return apiRequest(req);
}

// delete risk-group
export function deleteRiskGroup(groupID) {
	const req = {
		method: 'DELETE',
		url   : `/riskGroup/${groupID}`,
	};

	return apiRequest(req);
}

// update user permissions
export function userPermissionsUpdate(userID, permissionsList = [], params = {}) {

	const req = {
		method: 'POST',
		url   : `/users/${userID}/permissions`,
		data  : permissionsList,
		params,
	};

	return apiRequest(req);
}

// user documents: List
function userDocumentsList(userID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/users/${userID}/documents`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

// user document: Update
function userDocumentUpdate(userID, documentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/users/${userID}/documents/${documentID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function userDocumentUpload(userID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/users/${userID}/documents/upload`,
		data,
		params,
	};

	return apiRequest(req);
}

function updateDocument(id, data) {

	const req = {
		method: 'POST',
		url   : `/documents/${id}`,
		data,
	};

	return apiRequest(req);
}

// get user casino limits by limit type
export const userLimitsCasinoSelect = (data) => {
	const {
		userID,
		typeID,
	} = data;

	const req = {
		method: 'GET',
		url   : `/users/${userID}/casinoLimits/${typeID}`,
	};
	return apiRequest(req);
};

export function userLimitsCasinoUpdate(data, ids) {
	const {
		userID,
		type,
	}   = ids;
	const req = {
		method: 'POST',
		url   : `/users/${userID}/casinoLimits/${type}`,
		data,
	};
	return apiRequest(req);
}

// get user limits  payments list

export function getUserLimitsPaymentsDepositList() {
	const req = {
		method: 'GET',
		url   : '/profile/deposit/payways',
	};
	return apiRequest(req);
}

export function getUserLimitsPaymentsWithdrawalList() {
	const req = {
		method: 'GET',
		url   : '/profile/withdraw/payways',
	};

	return apiRequest(req);
}

export function LimitsPaymentsUpdate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/userPaymentLimits',
		data,
		params,
	};
	return apiRequest(req);
}

export function getUserLimits(params = {}) {
	const req = {
		method: 'GET',
		url   : '/userPaymentsLimits',
		params,
	};
	return apiRequest(req);
}

export function getUserSportLimits(userID, params = {}) {
	const req = {
		method: 'GET',
		url   : `/users/${userID}/limits`,
		params,
	};
	return apiRequest(req);
}

export function saveUserSportLimits(userID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/limits`,
		data,
		params,
	};
	return apiRequest(req);
}

//
function userBalanceUpdate(userID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/users/${userID}/balance`,
		data,
		params,
	};

	return apiRequest(req);
}

function userManualDepositSave(userID, paymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/users/${userID}/adjustment/${paymentID}/deposit`,
		data,
		params,
	};

	return apiRequest(req);
}

export function getWebsiteDepositList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/currency_payway',
		params,
	};
	return apiRequest(req);
}

function usersCSVLoad(params = {}) {

	const req = {
		method: 'GET',
		url   : '/export/users',
		params,
	};

	return apiRequest(req);
}

function userResetPassword(data,  admin = false, params = {}) {
	const url = admin ? '/admin/forgetPassword' : '/forgetPassword';
	const req = {
		method: 'POST',
		url,
		data,
		params,
	};

	return apiRequest(req);
}

function adminNotificationsCount(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/notifications/count',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function adminNotificationsList(typeID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/notifications/type/${typeID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

// TODO check117
function documentsList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/rtm/documents',
		withoutLangID: true,
		params,
	};
	return apiRequest(req);
}


function adminNotificationsMarkAll(typeID, params = {}) {

	const req = {
		method       : 'PUT',
		url          : `/notifications/type/${typeID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function adminNotificationsMarkOne(id, params = {}) {

	const req = {
		method       : 'PUT',
		url          : `/notifications/${id}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

// Roles ------------------------------------------------------------------------------------------

function availablePermissionsList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/permissions',
		params,
	};

	return apiRequest(req);
}

function availableUserRolesList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/userroles',
		params,
	};

	return apiRequest(req);
}

function availableRoleCreate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/userroles',
		data,
		params,
	};

	return apiRequest(req);
}

function availableRoleUpdate(roleID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/userroles/${roleID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function availableRoleDelete(roleID, data) {
	const req = {
		method: 'DELETE',
		url   : `/userroles/${roleID}`,
		data,
	};

	return apiRequest(req);
}

function userRolesUpdate(userID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/roles`,
		data,
		params,
	};

	return apiRequest(req);
}

function userChangePassword(oldPassword, newPassword, params = {}) {
	const req = {
		method: 'POST',
		url   : '/admin/changePassword',
		data  : {
			old_password: oldPassword,
			new_password: newPassword,
		},
		params,
	};

	return apiRequest(req);
}

function getUserNotes(userID) {

	const req = {
		method: 'GET',
		url   : `/users/${userID}/notes`,
	};

	return apiRequest(req);
}

function updateUserNotes(userID, data) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/notes`,
		data,
	};

	return apiRequest(req);
}

function deleteUserNote(userID, noteID) {
	const req = {
		method: 'DELETE',
		url   : `/users/${userID}/notes/${noteID}`,
	};

	return apiRequest(req);
}

function getUserSMSList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/usersms',
		params,
	};

	return apiRequest(req);
}

function sendSMS(data = {}, params = {}) {

	const req = {
		method: 'POST',
		url   : '/usersms',
		data,
		params,
	};

	return apiRequest(req);
}

function getAllTransactionsList(params, userID) {

	const req = {
		method: 'GET',
		url   : `/users/${userID}/transactions`,
		params,
	};

	return apiRequest(req);
}

function userManualBonusTemplateSave(userID, data, params = {}) {
	const req = {
		method: 'POST',
		url   : `/users/${userID}/manual_bonus_template`,
		data,
		params,
	};
	return apiRequest(req);
}

function createUserSave(data, params) {
	const req = {
		method: 'POST',
		url   : '/registerUsers',
		data,
		params,
	};
	return apiRequest(req);
}

export const usersAPI = {
	findUsers,
	profileList,
	usersList,
	usersListByIP,
	usersListByCardNumber,
	adminsList,
	adminCreate,
	updateUserData,
	updateAdminData,
	getDataAutocoplete,
	getAdminDataAutocomplete,
	getUserInfo,
	getUserBetsStatistic,
	getUserStatistic,
	getUserSportStatistic,
	getUserCasinoStatistic,
	getActionsList,
	getUserAdminInfo,
	getLoginsList,
	getUserRiskGroupList,
	getRiskGroupsList,

	userLimitsCasinoSelect,
	userLimitsCasinoUpdate,

	getUserLimitsPaymentsDepositList,
	getUserLimitsPaymentsWithdrawalList,
	getUserLimits,
	LimitsPaymentsUpdate,
	getUserSportLimits,
	saveUserSportLimits,

	addUserToRiskGroup,
	clearUserRiskGroup,
	addRiskGroup,
	editRiskGroup,
	deleteRiskGroup,
	userPermissionsUpdate,
	getUserFinance,

	userDocumentsList,
	userDocumentUpdate,
	userDocumentUpload,
	updateDocument,

	userBalanceUpdate,
	userManualDepositSave,
	getWebsiteDepositList,
	usersCSVLoad,
	userResetPassword,

	adminNotificationsCount,
	adminNotificationsList,
	documentsList,
	adminNotificationsMarkAll,
	adminNotificationsMarkOne,

	availablePermissionsList,
	availableUserRolesList,
	availableRoleCreate,
	availableRoleUpdate,
	availableRoleDelete,
	userRolesUpdate,
	userChangePassword,
	getUserNotes,
	updateUserNotes,
	deleteUserNote,

	getUserSMSList,
	sendSMS,

	getAllTransactionsList,
	userManualBonusTemplateSave,

	createUserSave,
};
