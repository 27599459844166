import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, Checkbox } from 'antd';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';

import IntlMessages from '../../../utility/intlMessages';

import { DropdownContainer, Header, Body, Item, IconWrapper, LinksBlock, Link } from './SettingsControl.style';

const lang = {
	title      : <IntlMessages id = "reportToolbal.fields" />,
	selectAll  : <IntlMessages id = "reportToolbal.selectAll" />,
	unselectAll: <IntlMessages id = "reportToolbal.unselectAll" />,
};

class SettingsControl extends Component {

	static propTypes = {
		id          : PropTypes.string.isRequired,
		reportFields: PropTypes.arrayOf(PropTypes.shape({
			id     : PropTypes.string,
			intlID : PropTypes.string,
			checked: PropTypes.bool,
		})),
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		reportFields: [],
	};

	constructor(props) {
		super(props);

		this.getPopupContainer     = this.getPopupContainer.bind(this);
		this.onVisibleChange       = this.onVisibleChange.bind(this);
		this.hide                  = this.hide.bind(this);
		this.onChangeCheckbox      = this.onChangeCheckbox.bind(this);
		this.onSelectAll           = this.onSelectAll.bind(this);
		this.onUnselectAll         = this.onUnselectAll.bind(this);

		this.renderItems           = this.renderItems.bind(this);
		this.renderDropdownContent = this.renderDropdownContent.bind(this);

		this.state = {
			dropdownVisible: false,
		};
	}

	getPopupContainer() {
		const { id } = this.props;
		return document.getElementById(`toolbarReport-${id}`);
	}

	// Local events ---------------------------------------------------------------------------------

	hide() {
		this.setState({ dropdownVisible: false });
	}

	onVisibleChange(visible) {
		this.setState({ dropdownVisible: visible });
	}

	onChangeCheckbox(id, checked) {
		const { reportFields, onChange } = this.props;

		const newReportFields = cloneDeep(reportFields);
		const fieldItem = find(newReportFields, { id });
		if (!fieldItem) {
			return;
		}
		fieldItem.checked = checked;

		onChange(newReportFields);
	}

	onSelectAll() {
		const { reportFields, onChange } = this.props;

		const newReportFields = cloneDeep(reportFields);
		newReportFields.forEach(field => {
			field.checked = true;
		});

		onChange(newReportFields);
	}

	onUnselectAll() {
		const { reportFields, onChange } = this.props;

		const newReportFields = cloneDeep(reportFields);
		newReportFields.forEach(field => {
			field.checked = false;
		});

		onChange(newReportFields);
	}

	// Renders --------------------------------------------------------------------------------------

	renderItems() {
		const { reportFields } = this.props;
		return reportFields.map(field => {

			const { id, intlID, checked } = field;

			return (
				<Item key={id}>
					<Checkbox
						checked={checked}
						onChange={event => this.onChangeCheckbox(id, event.target.checked)}
					>
						<IntlMessages id={intlID} />
					</Checkbox>
				</Item>
			);
		});
	}

	renderDropdownContent() {
		const items = this.renderItems();

		return (
			<DropdownContainer>
				<Header>{lang.title}</Header>
				<LinksBlock>
					<Link onClick={this.onSelectAll}>{lang.selectAll}</Link>
					<Link onClick={this.onUnselectAll}>{lang.unselectAll}</Link>
				</LinksBlock>
				<Body>
					{items}
				</Body>
			</DropdownContainer>
		);
	}

	render() {
		const { id, disabled } = this.props;
		const { dropdownVisible } = this.state;
		const dropdownContent = this.renderDropdownContent();

		return (
			<Popover
				content={dropdownContent}
				trigger={!disabled ? 'hover' : ''}
				visible={dropdownVisible}
				onVisibleChange={this.onVisibleChange}
				placement="bottom"
				getPopupContainer={this.getPopupContainer}
			>
				<IconWrapper disabled={disabled} id={`toolbarReport-${id}`}>
					<i className="ion-android-settings" title="Report Fields" />
				</IconWrapper>
			</Popover>
		);
	}
}

export default SettingsControl;
