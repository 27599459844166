const actions = {

	MESSAGES_LIST_RELOAD              : 'MESSAGES_LIST_RELOAD',
	MESSAGES_DATA_REFRESH             : 'MESSAGES_DATA_REFRESH',
	MESSAGES_NEW_MESSAGES_LIST_REFRESH: 'MESSAGES_NEW_MESSAGES_LIST_REFRESH',

	MESSAGES_FILTER_APPLY: 'MESSAGES_FILTER_APPLY',

	MESSAGES_UI_REFRESH: 'MESSAGES_UI_REFRESH',

	MESSAGES_SOCKET_SUBSCRIBE_TO_UPDATE   : 'MESSAGES_SOCKET_SUBSCRIBE_TO_UPDATE',
	MESSAGES_SOCKET_NOTIFY_CREATE_MESSAGES: 'MESSAGES_SOCKET_NOTIFY_CREATE_MESSAGES',
	MESSAGES_SOCKET_NOTIFY_UPDATE_MESSAGES: 'MESSAGES_SOCKET_NOTIFY_UPDATE_MESSAGES',

	MESSAGES_FILTER_ATTRIBUTE: 'MESSAGES_FILTER_ATTRIBUTE',
	MESSAGES_SELECTED_MAIL   : 'MESSAGES_SELECTED_MAIL',
	MESSAGES_COMPOSE_MAIL    : 'MESSAGES_COMPOSE_MAIL',
	MESSAGES_REPLY_MAIL      : 'MESSAGES_REPLY_MAIL',
	MESSAGES_SEARCH_STRING   : 'MESSAGES_SEARCH_STRING',

	MESSAGES_NEW_MESSAGE_MERGE          : 'MESSAGES_NEW_MESSAGE_MERGE',
	MESSAGES_NEW_MESSAGE_ATTACHMENTS_SET: 'MESSAGES_NEW_MESSAGE_ATTACHMENTS_SET',
	MESSAGES_NEW_MESSAGE_SEND           : 'MESSAGES_NEW_MESSAGE_SEND',
	MESSAGES_MESSAGE_DELETE             : 'MESSAGES_MESSAGE_DELETE',
	MESSAGES_REPLY_TO_MESSAGE           : 'MESSAGES_REPLY_TO_MESSAGE',
	MESSAGES_NEW_MESSAGE_RESET          : 'MESSAGES_NEW_MESSAGE_RESET',
	
	listReload: () => ({
		type: actions.MESSAGES_LIST_RELOAD,
	}),
	dataRefresh: (messagesIDs, entities) => ({
		type: actions.MESSAGES_DATA_REFRESH,
		data: {
			messagesIDs,
			entities,
		},
	}),
	newMessagesListRefresh: (newMessagesIDs, newMessagesEntities) => ({
		type: actions.MESSAGES_NEW_MESSAGES_LIST_REFRESH,
		data: {
			newMessagesIDs,
			newMessagesEntities,
		},
	}),
	newMessageReset: () => ({
		type: actions.MESSAGES_NEW_MESSAGE_RESET,
	}),
	filterApply: () => ({
		type: actions.MESSAGES_FILTER_APPLY,
	}),

	uiRefresh: data => ({
		type: actions.MESSAGES_UI_REFRESH,
		data,
	}),
	notifyCreateMessages: data => ({
		type: actions.MESSAGES_SOCKET_NOTIFY_CREATE_MESSAGES,
		data,
	}),
	notifyUpdateMessages: data => ({
		type: actions.MESSAGES_SOCKET_NOTIFY_UPDATE_MESSAGES,
		data,
	}),
	subscribeToUpdate: (MessagesIDs) => ({
		type: actions.MESSAGES_SOCKET_SUBSCRIBE_TO_UPDATE,
		data: {
			MessagesIDs,
		},
	}),

	filterAction: (data) => ({
		type: actions.MESSAGES_FILTER_ATTRIBUTE,
		data,
	}),
	selectMail: (data) => ({
		type: actions.MESSAGES_SELECTED_MAIL,
		data,
	}),
	changeComposeMail: (composeMail) => ({
		type: actions.MESSAGES_COMPOSE_MAIL,
		composeMail,
	}),
	changeReplyMail: (replyMail) => ({
		type: actions.MESSAGES_REPLY_MAIL,
		replyMail,
	}),
	changeSearchString: (searchString) => ({
		type: actions.MESSAGES_SEARCH_STRING,
		searchString,
	}),

	newMessageSend: (messageID = null) => ({
		type: actions.MESSAGES_NEW_MESSAGE_SEND,
		data: {
			messageID,
		},
	}),

	newMessageMerge: data => ({
		type: actions.MESSAGES_NEW_MESSAGE_MERGE,
		data,
	}),

	newMessageSet: data => ({
		type: actions.MESSAGES_NEW_MESSAGE_ATTACHMENTS_SET,
		data,
	}),

	messageDelete: () => ({
		type: actions.MESSAGES_MESSAGE_DELETE,
	}),

	replyToMessage: () => ({
		type: actions.MESSAGES_REPLY_TO_MESSAGE,
	}),
};

export default actions;
