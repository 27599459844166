import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Collapse, Checkbox } from 'antd';
import { cloneDeep } from 'lodash';
import actions from '../../../../../../redux/casino/gameCategories/actions';
import integrationsAction from '../../../../../../redux/casino/integrators/actions';
import providersAction from '../../../../../../redux/casino/providers/actions';
import bonusUserBonusAction from '../../../../../../redux/loyalty/bonuses/userBonus/actions';
import { RowContainer } from '../../../../../Partner/Partners/Components/modal/TabIntegrator/Components/Provider.style';
import { lang } from '../lang';

import CreateGamesList from './CreateGameList';

import {
	deriveCasinoIntegratorsFilteredEntities,
	deriveCasinoIntegratorsGamesEntities,
} from '../../../../../../selectors/casino';
import { NameTitle } from '../../../../../Partner/Partners/Components/modal/TabIntegrator/TabIntegrator.style';
import { statusCode } from '../../../../../../redux/casino/integrators/utils';
import { CollapseWrapper } from './TabIntegrators.style';
import Spinner from '../../../../../../components/Spinner/Spinner';

const { Panel } = Collapse;

class TabIntegrators extends Component {

	static propTypes = {
		integratorsList                  : PropTypes.array.isRequired,
		integratorsListReload            : PropTypes.func.isRequired,
		uiRefresh                        : PropTypes.func.isRequired,
		dataRefresh                      : PropTypes.func.isRequired,
		bonusData                        : PropTypes.object.isRequired,
		listReloadGames                  : PropTypes.func.isRequired,
		providersRefresh                 : PropTypes.func.isRequired,
		checkedProvidersByIntegrator     : PropTypes.func.isRequired,
		categoryGameCheck                : PropTypes.func.isRequired,
		gamesListbyIntegratorWithProvider: PropTypes.object.isRequired,
		loading                          : PropTypes.bool.isRequired,
		loadingIntegrators               : PropTypes.bool.isRequired,
		fromLoyaltySystemBonusTab        : PropTypes.bool.isRequired,
		categoryGameChecked              : PropTypes.shape(
			{ games: PropTypes.array.isRequired }
		).isRequired,
	};

	constructor(props) {
		super(props);

		this.renderIntegrators  = this.renderIntegrators.bind(this);
		this.integratorDeleted  = this.integratorDeleted.bind(this);
		this.onCheckDeletedIntegrators  = this.onCheckDeletedIntegrators.bind(this);
		this.integratorPanelExtraVisibility  = this.integratorPanelExtraVisibility.bind(this);
		this.setChangedIntegratorCheckboxValue  = this.setChangedIntegratorCheckboxValue.bind(this);

		this.state = {
			changedIntegratorCheckbox: false,
		};
	}

	componentDidMount() {
		const { integratorsListReload, bonusData, singleGameMode } = this.props;
		const { currencyID, typeID } = bonusData;
		const type = singleGameMode && typeID;
		integratorsListReload({ withProvider: true, currencyID, type });
	}

	componentDidUpdate() {
		const { loading } = this.props;
		const { changedIntegratorCheckbox } = this.state;

		if (loading && changedIntegratorCheckbox) {
			this.setChangedIntegratorCheckboxValue(false);
		}
	}

	integratorDeleted(status) {
		return status && +status === statusCode.deleted;
	}

	setChangedIntegratorCheckboxValue(changedIntegratorCheckbox) {
		this.setState({ changedIntegratorCheckbox });
	}

	onCheckDeletedIntegrators({ name, status }) {
		if (name) {
			return <NameTitle deleted={this.integratorDeleted(status)}>{name}</NameTitle>;
		}

		return lang.otherIntegratorsTitle;
	}

	integratorPanelExtraVisibility({ name, status }, integratorChecked) {
		const { changedIntegratorCheckbox } = this.state;

		return name
			&& (!this.integratorDeleted(status)
				|| (
					(this.integratorDeleted(status) && integratorChecked)
					|| (this.integratorDeleted(status) && !integratorChecked && changedIntegratorCheckbox)
				));
	}

	// Renders --------------------------------------------------------------------------------------

	renderIntegrators() {
		const {
			integratorsList,
			uiRefresh,
			dataRefresh,
			bonusData,
			listReloadGames,
			gamesListbyIntegratorWithProvider,
			providersRefresh,
			checkedProvidersByIntegrator,
			categoryGameCheck,
			categoryGameChecked,
			loading,
			fromLoyaltySystemBonusTab,
			loadingIntegrators,
			readOnly,
			singleGameMode,
		} = this.props;

		const { count: { providers: providerCount }, integratorIDs, providerIDs } = bonusData;

		if (loadingIntegrators) return <Spinner size='default' height='200px' />;

		if (integratorsList[0]?.providers) {
			return integratorsList.map(integrator => {
				const integratorChecked = integratorIDs.includes(integrator.id);

				const notCheckedProviders = integrator.providers.filter(item => !providerIDs.includes(item.id));
				const indeterminate = !integratorChecked && (integrator.providers.length - notCheckedProviders.length) > 0;
				const integratorCount = !singleGameMode && notCheckedProviders.reduce((sum, item) => {
					if (providerCount[item.id]) {  // It cannot be 0, so checking 0 value is redundant.
						sum += providerCount[item.id] || 0;
					} else {
						if (Object.isExtensible(providerCount)) {
							providerCount[item.id] = 0;
						}
					}
					return sum;
				}, 0);

				return (
					<Panel
						header={this.onCheckDeletedIntegrators(integrator)}
						key={integrator.id}
						extra={!singleGameMode && this.integratorPanelExtraVisibility(integrator, integratorChecked) && (
							<RowContainer>
								{
									integratorCount
										? (integratorChecked ? lang.selectedIntegrators : <> {lang.selected} {integratorCount} </>)
										: (integratorChecked ? lang.selectedIntegrators : lang.selectIntegrator)
								}
								<Checkbox
									onChange={(e) => {
										const { checked } = e.target;
										const clonedData = cloneDeep(bonusData);
										if (checked) {
											clonedData.integratorIDs.push(integrator.id);
										} else {
											clonedData.integratorIDs = clonedData.integratorIDs.filter(item => item !== integrator.id);
										}
										dataRefresh(clonedData);
										uiRefresh({ isChanged: true });
										this.setChangedIntegratorCheckboxValue(true);
										// const providersIDs = {};
										// integrator.providers.forEach( provider => providersIDs[provider.id] = checked );
										// providersRefresh({ [integrator.id] : providersIDs });
									}}
									onClick={e => e.stopPropagation()}
									checked={integratorChecked}
									indeterminate={indeterminate}
									disabled={readOnly}
								/>
							</RowContainer>
						)}
					>
						<div>
							<CreateGamesList
								readOnly={readOnly}
								integratorID={integrator.id}
								providers={integrator.providers}
								bonusData={bonusData}
								dataRefresh={dataRefresh}
								uiRefresh={uiRefresh}
								listReloadGames={listReloadGames}
								gamesListbyIntegratorWithProvider={gamesListbyIntegratorWithProvider}
								checkedProvidersByIntegrator={checkedProvidersByIntegrator}
								providersRefresh={providersRefresh}
								providerCount={providerCount}
								categoryGameCheck={categoryGameCheck}
								categoryGameChecked={categoryGameChecked}
								integratorChecked={integratorChecked}
								deletedIntegrator={this.integratorDeleted(integrator.status)}
								bonusDataLading={loading}
								fromLoyaltySystemBonusTab={fromLoyaltySystemBonusTab}
								singleGameMode={singleGameMode}
							/>
						</div>
					</Panel>
				);
			});
		}
	}

	render() {
		const IntegratorPanels = this.renderIntegrators();
		return (
			<CollapseWrapper>
				{IntegratorPanels}
			</CollapseWrapper>
		);
	}
}

export default connect(state => ({
	checkedProvidersByIntegrator     : state.Loyalty.Bonuses.UserBonus.get('checkedProvidersByIntegrator'),
	integratorsList                  : deriveCasinoIntegratorsFilteredEntities(state),
	gamesListbyIntegratorWithProvider: deriveCasinoIntegratorsGamesEntities(state),
	categoryGameChecked              : state.Casino.GameCategories.get('oldDataChecked'),
	loading                          : state.Loyalty.Bonuses.Bonuses.get('UI').loading,
	loadingIntegrators               : state.Casino.Integrators.get('UI').loading,
}),
{
	listReloadGames      : providersAction.listReloadGames,
	integratorsListReload: integrationsAction.listReload,
	providersRefresh     : bonusUserBonusAction.providersRefresh,
	categoryGameCheck    : actions.checkedAll,
})(TabIntegrators);
