import { all, fork } from 'redux-saga/effects';

import listSaga from './list/saga';
import filterSaga from './filter/saga';
import userSaga from './user/saga';
import loginsSaga from './logins/saga';
import activitySaga from './activity/saga';
import statisticSaga from './statistic/saga';
import sportStatisticSaga from './sportStatistic/saga';
import casinoStatisticSaga from './casinoStatistic/saga';
import groupsSaga from './groups/saga';
import documentsSaga from './documents/saga';
import balanceAdjustmentsSaga from './balanceAdjustments/saga';
import historySaga from './history/saga';
import reportUsersSaga from './reportUsers/saga';
// import rolesSaga from './roles/saga';
import userGroupSaga from './userGroup/saga';
import userInfoSaga from './userInfo/saga';
import responsibleGamblingSaga from './responsibleGambling/saga';
import adjustmentSaga from './userBalance/sagas';
import createUserSaga from './createUser/saga';

export default function* usersRootSaga() {
	yield all([
		fork(listSaga),
		fork(filterSaga),
		fork(userSaga),
		fork(loginsSaga),
		fork(activitySaga),
		fork(statisticSaga),
		fork(groupsSaga),
		fork(documentsSaga),
		fork(adjustmentSaga),
		fork(balanceAdjustmentsSaga),
		fork(historySaga),
		fork(reportUsersSaga),
		// fork(rolesSaga),
		fork(userGroupSaga),
		fork(sportStatisticSaga),
		fork(casinoStatisticSaga),
		fork(userInfoSaga),
		fork(responsibleGamblingSaga),
		fork(createUserSaga),
	]);
}
