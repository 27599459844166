import { Map } from 'immutable';
import { fill } from '../../../helpers/utils';
import actions from './actions';

const initState = new Map({
	uploaders: {},
});

function initUploader(files = []) {

	return {
		files,
		UI: {
			loading: false,
		},
	};
}

export default function imagesMultipleReducer(state = initState, action) {

	switch (action.type) {

		case actions.IMAGES_MULTIPLE_INIT: {
			const { id, files } = action.data;
			const uploaders = { ...state.get('uploaders') };
			uploaders[id] = initUploader(files);

			return state.set('uploaders', uploaders);
		}

		case actions.IMAGES_MULTIPLE_SET_FILES: {
			const { id, files } = action.data;

			const uploaders = { ...state.get('uploaders') };
			if (uploaders[id]) {

				uploaders[id].files = files;
			}

			return state.set('uploaders', uploaders);
		}

		case actions.IMAGES_MULTIPLE_UI_REFRESH: {
			const { id, uiData } = action.data;
			const uploaders = { ...state.get('uploaders') };

			const uploader = uploaders[id];
			uploader.UI    = fill(uiData, uploader.UI);

			return state.set('uploaders', uploaders);
		}

		default:
			return state;
	}
}
