import { makeActionCreator } from '../../../helpers';

const prefix = 'WELCOME_BONUS';

const TYPES = {
	LIST_RELOAD   : `${prefix}_LIST_RELOAD`,
	LIST_REFRESH  : `${prefix}_LIST_REFRESH`,
	FILTER_REFRESH: `${prefix}_FILTER_REFRESH`,
	FILTER_APPLY  : `${prefix}_FILTER_APPLY`,
	FILTER_RESET  : `${prefix}_FILTER_RESET`,
	
	BONUS_SAVE        : `${prefix}_BONUS_SAVE`,
	BONUS_DATA_REFRESH: `${prefix}_BONUS_DATA_REFRESH`,
	BONUS_DATA_RESET  : `${prefix}_BONUS_DATA_RESET`,
	BONUS_UI_REFRESH  : `${prefix}_BONUS_UI_REFRESH`,
	GET_BONUS_BY_ID   : `${prefix}_GET_BONUS_BY_ID`,

	HISTORY_LOGS_RELOAD : `${prefix}_HISTORY_LOGS_RELOAD`,
	HISTORY_LOGS_REFRESH: `${prefix}_HISTORY_LOGS_REFRESH`,
};

const actions = {
	...TYPES,

	listReload   : makeActionCreator(TYPES.LIST_RELOAD),
	listRefresh  : makeActionCreator(TYPES.LIST_REFRESH, 'entities'),
	filterRefresh: makeActionCreator(TYPES.FILTER_REFRESH, 'filter'),
	filterApply  : makeActionCreator(TYPES.FILTER_APPLY),

	bonusSave   : makeActionCreator(TYPES.BONUS_SAVE),
	dataRefresh : makeActionCreator(TYPES.BONUS_DATA_REFRESH, 'bonusData'),
	dataReset   : makeActionCreator(TYPES.BONUS_DATA_RESET),
	uiRefresh   : makeActionCreator(TYPES.BONUS_UI_REFRESH, 'UI'),
	filterReset : makeActionCreator(TYPES.FILTER_RESET),
	getBonusByID: makeActionCreator(TYPES.GET_BONUS_BY_ID, 'bonusID', 'bonusType'),

	historyLogsReload : makeActionCreator(TYPES.HISTORY_LOGS_RELOAD, 'bonusID'),
	historyLogsRefresh: makeActionCreator(TYPES.HISTORY_LOGS_REFRESH, 'historyLogs'),
};

export default actions;
