import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

const entities     = (state) => state.Casino.GameCategories.get('entities');
const entitiesGames     = (state) => state.Casino.GameCategories.get('entitiesGames');
const categoriesUI = (state) => state.Casino.GameCategories.get('UI');

// Simple Selectors -------------------------------------------------------------------------------
export const deriveGameCategoriesEntities = createSelector(
	[entities],
	(entities) => entities
);

export const deriveGameCategoriesEntitiesGames = createSelector(
	[entitiesGames],
	(entities) => entities
);

export const deriveGameCategoriesList = createSelector(
	[deriveGameCategoriesEntities],
	(entities) => sortBy(values(entities), ['name'])
);

export const deriveGameCategoriesListGames = createSelector(
	[deriveGameCategoriesEntitiesGames],
	(entities) => values(entities)
);

export const deriveGameCategoriesListWithDefault = createSelector(
	[deriveGameCategoriesList],
	(list) => {
		return list;
	}
);

export const deriveGameCategoriesUI = createSelector(
	[categoriesUI],
	(UI) => UI
);
