import { ITitles } from './types';
import { IWebsiteAttachedLanguages } from '../../../../../redux/app/types';
import { BONUS_DESCRIPTION_MAX_LENGTH, BONUS_TITLE_MAX_LENGTH } from '../../../../../constants/common';
import { defaultWebsiteID } from '../../../../../config';

/*
*  When language is multiple title is required for the English.
*  if website does not have English language title is required for the
*  first element in the websiteLanguages Array
*
* */


export function validateTitle(titles: Array<ITitles> = [], websiteLanguages: Array<IWebsiteAttachedLanguages> = []) {
	let errors = '';

	if (titles && titles.length) {
		const dataForDefaultLanguage = titles.find(item => Number(item.langID) === defaultWebsiteID);
		titles.map(item => {
			if (item.title && item.title.length > BONUS_TITLE_MAX_LENGTH) {
				errors = 'bonuses.modals.title.long';
			}
		});
		if (websiteLanguages.length) {
			const hasDefaultLang = !!websiteLanguages.find(item => Number(item.id) === defaultWebsiteID);
			if (hasDefaultLang) {
				if (!dataForDefaultLanguage || !dataForDefaultLanguage?.title) {
					errors = 'bonuses.modals.title.english.empty';
				}
			} else {
				const requireLangID = Number(websiteLanguages[0].id);
				const requireTitle = titles.find(item => Number(item.langID) === requireLangID)?.title;
				if (!requireTitle) {
					errors = 'bonuses.modals.title.empty';
				}
			}
		}
	} else {
		errors = 'bonuses.modals.title.empty';
	}
	return errors;
}


export function validateDescription(titles: Array<ITitles> = []) {
	if (titles && titles.length) {
		titles.map(item => {
			if (item.description && item.description.length > BONUS_DESCRIPTION_MAX_LENGTH) {
				return 'bonuses.modals.description.long';
			}
		});
	}
}
