import isArray from 'lodash/isArray';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../../utility';

const { isValidID, isValidDateRange, isValidArray } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	active      : isValidID,
	groupIDs    : isValidArray,
	langIDs     : isValidArray,
	userStatuses: isValidArray,
	startTime   : isValidDateRange,
	endTime     : isValidDateRange,
};


export const fields = {
	id          : 'id',
	bannerID    : 'banner_id',
	name        : 'name',
	statusID    : 'status_id',
	groupIDs    : 'groups_id',
	imageURL    : 'image_url',
	link        : 'link',
	linkText    : 'link_text',
	title       : 'title',
	subTitle    : 'sub_title',
	scriptCode  : 'script_code',
	maxViewCount: 'max_view_count',
	viewCount   : 'view_count',
	startDate   : 'start_date',
	endDate     : 'end_date',
	websiteID   : 'website_id',
	langID      : 'lang_id',
	orderID     : 'order_id',
	description : 'description',
	channelID   : 'channel_id',
	rotationTime: 'rotation_time',
	userStatusID: 'user_status_id',
	userStatuses: 'user_statuses',

	// filter
	langIDs      : 'lang_ids',
	startTimeFrom: 'start_time_from',
	startTimeTo  : 'start_time_to',
	endTimeFrom  : 'end_time_from',
	endTimeTo    : 'end_time_to',
	active       : 'status_id',
};

const listAdapter = createListAdapter();

export function getListParams(filter, pagination, sorting = null) {

	const builder = new ParamsBuilder();
	const rules   = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);

	builder.addField(rules.isArrayID, 'groupIDs', fields.groupIDs);
	builder.addField(rules.isArrayID, 'langIDs', fields.langIDs);
	builder.addField(rules.isArrayID, 'userStatuses', fields.userStatuses);

	builder.addField(rules.isID, 'websiteID', fields.websiteID);
	builder.addField(rules.isString, 'imageURL', fields.imageURL);
	builder.addField(rules.isString, 'name', fields.name);

	builder.addField(rules.isNumber, 'statusID', fields.statusID);
	builder.addField(rules.isNumber, 'active', fields.active);

	builder.addRangeField(rules.isDateTimeRange, filter.startTime, [
		fields.startTimeFrom,
		fields.startTimeTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.endTime, [
		fields.endTimeFrom,
		fields.endTimeTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptBannersList(rawData = [], responseHeaders) {
	if (!isArray(rawData)) {
		return {
			entities  : {},
			totalCount: 0,
		};
	}

	listAdapter.clearExcludes();
	const adaptedData	= listAdapter.adaptList(rawData);

	const entities		= createEntities(adaptedData);
	const totalCount	= getHeadersTotalCount(responseHeaders) || adaptedData.length;
	return {
		entities,
		totalCount,
	};
}

// Adapters ---------------------------------------------------------------------------------------

export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	// adapter.addField(rules.id, 'userStatusID', fields.userStatusID);
	adapter.addField(rules.id, 'orderID', fields.orderID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.string, 'scriptCode', fields.scriptCode);
	adapter.addField(rules.string, 'name', fields.name);

	adapter.addField(rules.arrayID, 'groupIDs', fields.groupIDs);

	adapter.addField(rules.positiveNumber, 'maxViewCount', fields.maxViewCount);
	adapter.addField(rules.positiveNumber, 'viewCount', fields.viewCount);
	adapter.addField(rules.positiveNumber, 'rotationTime', fields.rotationTime);

	adapter.addField(rules.fullDate, 'startDate', fields.startDate);
	adapter.addField(rules.fullDate, 'endDate', fields.endDate);
	adapter.addField(rules.arrayID, 'userStatuses', fields.userStatuses);

	return adapter;
}

export function createDescAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'bannerID', fields.bannerID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);

	adapter.addField(rules.string, 'imageURL', fields.imageURL);
	adapter.addField(rules.string, 'link', fields.link);
	adapter.addField(rules.string, 'linkText', fields.linkText);
	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'subTitle', fields.subTitle);
	adapter.addField(rules.string, 'description', fields.description);

	return adapter;
}
