import React from 'react';
import { InputNumber } from 'antd';
import toNumber from 'lodash/toNumber';
import toInteger from 'lodash/toInteger';
import PropTypes from 'prop-types';
import { allowNumber, allowNumberDot } from '../../helpers/utils';
import AntInputNumber from './styles/inputNumber.style';
import WithDirection from '../../config/withDirection';


const StyledInputNumber = AntInputNumber(InputNumber);
const WDInputnumber = WithDirection(StyledInputNumber);

const AdminInputNumber = ({ step, ...restProps }) => {
	const parser = toNumber(step) !== toInteger(step) ? allowNumberDot : allowNumber;

	return (
		<WDInputnumber
			step={step}
			parser={parser}
			{...restProps}
		/>
	);
};

AdminInputNumber.propTypes = {
	step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
AdminInputNumber.defaultProps = {
	step: 1,
};
export default AdminInputNumber;
