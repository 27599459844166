import { cloneDeep } from 'lodash';


export const bulkCheckGames = (categoryGameChecked, games, checked) => {

	const initialData = cloneDeep(categoryGameChecked);

	return  games.reduce((acc, { id, categoryID }) => {
		let categoryGames = initialData[categoryID] ? initialData[categoryID].games : [];

		if (checked) {
			categoryGames.push(id);
		} else {
			categoryGames = categoryGames.filter(item => item.id !== id);
		}
		const categoryData = {
			[categoryID]: {
				allChecked: false, // TODO change to dynamic value.
				games     : categoryGames,
			},
		};

		if (initialData[categoryID]) {
			initialData[categoryID].games = categoryGames;
		} else {
			initialData[categoryID] = {};
			initialData[categoryID].games = [];
		}

		return {
			...acc,
			...categoryData,
		};
	}, {});

};
