import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toNumber from 'lodash/toNumber';

import RadioBox, { RadioGroup } from '../../../../../../components/uielements/radio';

import bonusActions from '../../../../../../redux/loyalty/bonuses/userBonus/actions';
import { deriveUserBonusDenominations, deriveUserBonusUI } from '../../../../../../selectors/bonuses';
import { Wrapper } from './DenominationRadios.style';

class DenominationRadios extends Component {

	static propTypes = {
		denominationKey  : PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		denominationsList: PropTypes.array,
		selectedValue    : PropTypes.string,
		uiRefresh        : PropTypes.func.isRequired,
	};

	static defaultProps = {
		denominationsList: [],
		selectedValue    : '',
	};

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(key) {
		const { denominationKey, uiRefresh } = this.props;
		const denominationValue = key.split('/').reverse()[0];

		uiRefresh({
			denominationKey  : toNumber(denominationKey),
			denominationValue: toNumber(denominationValue),
			isChanged        : true,
		});
	}

	render() {
		const { denominationKey, denominationsList, selectedValue, readOnly } = this.props;

		const radios = denominationsList.map(denomValue => {
			const key = `${denominationKey}/${denomValue}`;
			const checked = (key === selectedValue);

			return (
				<RadioBox
					key={key}
					value={key}
					checked={checked}
					disabled={readOnly}
				>
					{denomValue}
				</RadioBox>
			);
		});

		return (
			<Wrapper>
				<RadioGroup
					disabled={readOnly}
					value={selectedValue}
					onChange={({ target }) => this.onChange(target.value)}
				>
					{radios}
				</RadioGroup>
			</Wrapper>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { denominationKey } = props;
	const denominations       = deriveUserBonusDenominations(state);
	const UI                  = deriveUserBonusUI(state);
	const selectedValue       = `${UI.denominationKey}/${UI.denominationValue}`;

	return {
		denominationsList: denominations[denominationKey],
		selectedValue,
	};
};

const mapDispatchToProps = {
	uiRefresh: bonusActions.uiRefresh,
};

const connected = connect(
	mapStateToProps,
	mapDispatchToProps
)(DenominationRadios);

connected.defaultProps = DenominationRadios.defaultProps;

export default connected;
