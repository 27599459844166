import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { fields } from '../list/utils';
import { IRiskBetsItem } from '../list/types';
import { IUpdateRiskBetsDataParams } from './types';

export function getUpdateDataAdapt(data: IRiskBetsItem): IUpdateRiskBetsDataParams {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addField(rules.isID, 'statusID', fields.statusID);

	return builder.biuldParams(data);
}

export function cleanObject<T>(raw: T): T {
	const obj = { ...raw };

	Object.keys(obj).forEach((key) => {
		if (obj[key] === undefined || obj[key] === null) {
			delete obj[key];
		}
	});

	return obj as T;
}