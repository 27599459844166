import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { fill } from '../../../../helpers/utils';
import { IDepositMethodsEntities, IInitialState, IManualBonusData, IUiManualDeposit } from './types';


const initialState: IInitialState = {
	manualBonusData: {
		transactionID  : '',
		amount         : null,
		paymentMethod  : null,
		commentForAdmin: '',
	},
	depositMethodsEntities: {},
	UI                    : {
		loading: false,
	},
};

export const manualDepositSlice = createSlice({
	name    : 'manualDepositSlice',
	initialState,
	reducers: {
		setManualBonusData: (state: Draft<IInitialState>, action: PayloadAction<IManualBonusData>) => {
			state.manualBonusData = action.payload;
		},
		setDepositMethodsEntities: (state: Draft<IInitialState>, action: PayloadAction<IDepositMethodsEntities>) => {
			state.depositMethodsEntities = action.payload;
		},
		uiRefresh: (state: Draft<IInitialState>, action: PayloadAction<IUiManualDeposit>) => {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},
		resetManualBonusData: (state: Draft<IInitialState>) => {
			state.manualBonusData = initialState.manualBonusData;
		},
	},
});


export const actions = {
	...manualDepositSlice.actions,
};

export default manualDepositSlice.reducer;

