
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import PaginationRow from '../../components/uielements/PaginationRow';
import { derivePagination } from '../../selectors/listPagination';

const sizeOptions = ['10', '20', '50', '100', '500', '1000'];

class ListPagination extends Component {

	static propTypes = {
		/**
     * Path to get pagination values from Redux Store
     * For example, path 'CustomerManagement.List.pagination' will be transformed to state.CustomerManagement.List.get('pagination')
     * Last part of path will always get by Immutable method 'get()'. Be careful with it.
     */
		reduxPath   : PropTypes.string,
		/**
     * Pagination parameters names to derive from Redux Store (optional)
     */
		namePageSize: PropTypes.string,
		nameCurrent : PropTypes.string,
		nameTotal   : PropTypes.string,
		/**
     * Pagination object: derived from Redux Store in according to 'reduxPath'
     */
		pagination  : PropTypes.shape({
			itemsPerPage: PropTypes.number.isRequired,
			currentPage : PropTypes.number.isRequired,
			totalCount  : PropTypes.number.isRequired,
		}),

		derivedPagination: PropTypes.shape({
			itemsPerPage: PropTypes.number.isRequired,
			currentPage : PropTypes.number.isRequired,
			totalCount  : PropTypes.number.isRequired,
		}),
		/**
     * Function which will be executed on change page or limit
     * It must be an action: paginationRefresh(data) from appropriated reducer
     * It makes changes in Redux Store
     */
		onChange    : PropTypes.func.isRequired,
		/**
     * Callbacks to perform after changing current page or limit (optional)
     */
		onChangePage: PropTypes.func,
		onChangeSize: PropTypes.func,
		/**
     * Precontent: will be displayed in left side of row (optional)
     */
		preContent  : PropTypes.element,
		/**
     * We can scroll to top after pagination change. Optional, but True by default
     */
		useScrollTop: PropTypes.bool,
	};

	static defaultProps = {
		namePageSize     : 'itemsPerPage',
		nameCurrent      : 'currentPage',
		nameTotal        : 'totalCount',
		onChangePage     : null,
		onChangeSize     : null,
		preContent       : null,
		useScrollTop     : true,
		reduxPath        : '',
		pagination       : null,
		derivedPagination: null,
	};

	constructor(props) {
		super(props);
		this.scrollTop    = this.scrollTop.bind(this);
		this.onChangePageHandle = this.onChangePageHandle.bind(this);
		this.onChangeSizeHandle = this.onChangeSizeHandle.bind(this);
	}

	// Service --------------------------------------------------------------------------------------
	scrollTop() {
		const { useScrollTop } = this.props;
		if (!useScrollTop) {
			return;
		}
		const container = document.querySelector('.ant-layout-menu > div > .ant-tabs > .ant-tabs-menu');
		if (!container) {
			return;
		}
		container.scroll({ top: 0, behavior: 'smooth' });
	}

	// Events ---------------------------------------------------------------------------------------
	onChangePageHandle(page, size) {
		const { pagination, derivedPagination, nameCurrent, namePageSize, onChange, onChangePage } = this.props;
		const actualPagination = pagination || derivedPagination;

		if (size !== actualPagination.itemsPerPage) {
			onChange({
				[nameCurrent] : 1,
				[namePageSize]: size,
			});
		} else {
			if (actualPagination.currentPage !== page) {
				onChange({
					[nameCurrent] : page,
					[namePageSize]: size,
				});
			}
		}

		if (onChangePage) {
			const resPagination = cloneDeep(actualPagination);
			resPagination[nameCurrent] = page;
			resPagination[namePageSize] = size;
			onChangePage();
		}

		this.scrollTop();
	}

	onChangeSizeHandle(_page, size) {
		const { pagination, derivedPagination, nameCurrent, namePageSize, onChange, onChangeSize } = this.props;
		const actualPagination = pagination || derivedPagination;

		const result = {
			[namePageSize]: size,
			[nameCurrent] : 1,
		};

		onChange(result);

		if (onChangeSize) {
			const resPagination = cloneDeep(actualPagination);
			resPagination[namePageSize] = size;
		}

		this.scrollTop();
	}

	// Renders --------------------------------------------------------------------------------------
	render() {
		const { pagination, preContent, derivedPagination } = this.props;
		const actualPagination = pagination || derivedPagination;
		const { itemsPerPage, currentPage, totalCount } = actualPagination;

		return (
			<PaginationRow
				showSizeChanger
				pageSizeOptions={sizeOptions}
				pageSize={itemsPerPage}
				current={currentPage}
				total={totalCount}
				preContent={preContent}
				onChange={this.onChangePageHandle}
				onShowSizeChange={this.onChangeSizeHandle}
			/>
		);
	}
}

function mapStateToProps(state, props) {

	return {
		derivedPagination: derivePagination(state, props),
	};
}

/**
 * Because of defaultProps aren't applied to original component,
 * we need to define them here after 'connect()'.
 * More info: https://github.com/reduxjs/react-redux/issues/633
 */
const ConnectedListPagination = connect(mapStateToProps)(ListPagination);

ConnectedListPagination.defaultProps = ListPagination.defaultProps;

export default ConnectedListPagination;
