import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormControl from '../../uielements/FormControl';
import { Wrapper } from './FormStatic.style';

class FormStatic extends Component {
	static propTypes = {
		label     : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth: PropTypes.number,
		height    : PropTypes.string,
		value     : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.element]),
		style     : PropTypes.object,
		bold      : PropTypes.bool,
		className : PropTypes.string,
	};

	static defaultProps = {
		label     : '',
		labelWidth: 25, // in percent
		height    : '36px',
		value     : 0,
		style     : {},
		bold      : false,
		className : '',
	};

	render() {
		const { label, labelWidth, height, bold, className, value, labelRight, style } = this.props;

		const lw = Boolean(labelWidth) || labelWidth === 0 ? labelWidth : 25;
		const h = height ? { height } : {};
		const mb = labelWidth === 0 ? 0 : undefined;

		const originClassName = className || '';
		const resultClassName = originClassName + (bold ? ' bold' : '');
		const resultStyle = style || null;

		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				style={resultStyle}
				labelRight={labelRight}
				{...h}
				control={<Wrapper className={resultClassName}>{value}</Wrapper>}
			/>
		);
	}
}

export default FormStatic;
