import isArray from 'lodash/isArray';
import EntityAdapter from '../../../helpers/entityAdapter';

const fields = {
	id                     : 'id',
	name                   : 'name',
	reason                 : 'reason',
	bonusesID              : 'bonuses',
	betFactor              : 'bet_factor',
	inPlayBetDelay         : 'in_play_bet_delay',
	minComb                : 'min_comb',
	maxComb                : 'max_comb',
	maxStake               : 'max_stake',
	maxWon                 : 'max_won',
	overask                : 'overask',
	default                : 'default',
	color                  : 'color',
	note                   : 'note',
	websiteID              : 'website_id',
	autoWithdrawalSettingID: 'auto_withdrawal_setting_id',

	countPerDay  : 'count_per_day',
	countPerWeek : 'count_per_week',
	countPerMount: 'count_per_month',

	// currencies
	currencies    : 'currencies',
	amountPerDay  : 'amount_per_day',
	amountPerWeek : 'amount_per_week',
	amountPerMount: 'amount_per_month',
	currencyID    : 'currency_id',
};
const currenyAdapter = createBaseDataCurrenyAdapter();

export function adaptGroupsList(rawData = []) {
	if (!isArray(rawData)) {
		return [];
	}
	const adapter = createBaseDataAdapter();

	const result = rawData.map(item => {
		const adaptedData = adapter.adapt(item);
		adaptedData.currencies = currenyAdapter.adaptList(item.currencies);
		return adaptedData;
	});

	return result;
}

export function adaptBaseData(rawData = {}) {
	const adapter = createBaseDataAdapter();
	const result = adapter.adapt(rawData);
	result.currencies = currenyAdapter.adaptList(rawData.currencies);
	return result;
}

export function prepareBaseData(rawData = {}, websiteID = null) {
	const adapter = createBaseDataAdapter();
	const result = adapter.prepare(rawData);
	result.currencies = currenyAdapter.prepareList(result.currencies);
	result[fields.websiteID] = websiteID;

	return result;
}

function createBaseDataCurrenyAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'currencyID', fields.currencyID);

	adapter.addField(rules.positiveNumber, 'amountPerDay', fields.amountPerDay);
	adapter.addField(rules.positiveNumber, 'amountPerWeek', fields.amountPerWeek);
	adapter.addField(rules.positiveNumber, 'amountPerMount', fields.amountPerMount);

	return adapter;
}

function createBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'bonusesID', fields.bonusesID);
	adapter.addField(rules.id, 'autoWithdrawalSettingID', fields.autoWithdrawalSettingID);

	adapter.addField(rules.bool, 'overask', fields.overask);
	adapter.addField(rules.bool, 'default', fields.default);

	adapter.addField(rules.positiveNumber, 'betFactor', fields.betFactor);
	adapter.addField(rules.positiveNumber, 'inPlayBetDelay', fields.inPlayBetDelay);
	adapter.addField(rules.positiveNumber, 'minComb', fields.minComb);
	adapter.addField(rules.positiveNumber, 'maxComb', fields.maxComb);
	adapter.addField(rules.positiveNumber, 'maxStake', fields.maxStake);
	adapter.addField(rules.positiveNumber, 'maxWon', fields.maxWon);

	adapter.addField(rules.positiveNumber, 'perDay', fields.countPerDay);
	adapter.addField(rules.positiveNumber, 'perWeek', fields.countPerWeek);
	adapter.addField(rules.positiveNumber, 'perMount', fields.countPerMount);

	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.string, 'reason', fields.reason);
	adapter.addField(rules.string, 'color', fields.color);
	adapter.addField(rules.string, 'note', fields.note);
	adapter.addField(rules.arrayObject, 'currencies', fields.currencies);

	return adapter;
}
