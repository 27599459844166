import React from 'react';
import * as PropTypes from 'prop-types';

import FormTimeRange from '../../../components/uielements/TimeRange';
import FormControl from '../../../components/uielements/FormControl';
import { FORMATS } from '../../../helpers/commonConstants';
import Utils from '../FilterUtils';

const TimeRange = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, disabled, timeFormat, separator } = filterItem;

	const resTimeFormat = timeFormat || FORMATS.time;

	let resValue = [null, null];
	if (Utils.isValidRange(value)) {
		const valueFrom = value[0] || null;
		const valueTo   = value[1] || null;
		resValue = [valueFrom, valueTo];
	}

	const control = (
		<FormTimeRange
			format={resTimeFormat}
			name={name}
			value={resValue}
			separator={separator || null}
			disabled={disabled}
			onChange={value => onChange(name, value)}
		/>
	);

	return (
		<FormControl
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			control={control}
		/>
	);
};

TimeRange.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default TimeRange;
