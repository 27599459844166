import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { fill } from '../../../helpers/utils';
import {
	IBaseData,
	ICreateUserState,
	ICreateUserUI,
} from './types';

const initState:ICreateUserState = {
	baseData: {
		affiliateReference: [],
	},
	UI: {
		visibleModal: false,
		loading     : false,
	},
};


export const creteUserSlice = createSlice({
	name        : 'creteUser',
	initialState: initState,
	reducers    : {
		setBaseData(state: Draft<ICreateUserState>, action: PayloadAction<Partial<IBaseData>>) {
			state.baseData = action.payload;
		},
		setCreateUserUI(state: Draft<ICreateUserState>, action: PayloadAction<Partial<ICreateUserUI>>) {
			const target = state.UI;
			state.UI = fill(action.payload, target, true);
		},
		resetData() {
			return initState;
		},
	},
});

export const createUserReducers = {
	...creteUserSlice.actions,
};

export default creteUserSlice.reducer;