import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { CRUD_OPTIONS } from '../../../../../../redux/staff/permissions/utils';
import IntlMessages from '../../../../../../components/utility/intlMessages';
import { InfoCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import { BONUS_STATUS, WAY_STATUS } from '../../../../../../constants/bonuses';
import Formatter from '../../../../../../helpers/formatter';
import { PERMISSIONS } from '../../../../../../helpers/permissions/constants';

export const MAIN_BONUSES_TABS = {
	'admin_loyalty_bonus-engine_deposit-free-spin': 'admin_loyalty_bonus-engine_deposit-free-spin',
	'admin_loyalty_bonus-engine_deposit'          : 'admin_loyalty_bonus-engine_deposit',
	'admin_loyalty_bonus-engine_free-bet'         : 'admin_loyalty_bonus-engine_free-bet',
	'admin_loyalty_bonus-engine_free-spins'       : 'admin_loyalty_bonus-engine_free-spins',
	'admin_loyalty_bonus-engine_pack'             : 'admin_loyalty_bonus-engine_pack',
	'admin_loyalty_bonus-engine_registration'     : 'admin_loyalty_bonus-engine_registration',
};
export const isSuperAdmin = (permissions) => {
	return !!(permissions[PERMISSIONS.superAdmin] || permissions[PERMISSIONS.platformAdmin]);
};

export const defaultBonusData = {
	
	statusID  : BONUS_STATUS.active,
	way       : WAY_STATUS.firstWay,
	startDate : Formatter.fullDateTime(new Date().setHours(0, 0)),
	endDate   : Formatter.fullDateTime(new Date().setHours(23, 59)),
	gameIDs   : [],
	allow     : false,
	titles    : [],
	currencies: [],
	currencyID: [],
	count     : {
		providers : {},
		categories: {},
	},
};

export const createFreeSpinBonusTypes = (permissions, canReadTabList, innerTabs, bonusType, editMode, depositFreeSpin, nextDepositFreeSpin, registrationFreeSpin) => {

	const registration      = canReadTabList && canReadTabList.find(item => item.name === 'registration');
	const deposit           = canReadTabList && canReadTabList.find(item => item.name === 'depositFreeSpin');
	const nextDeposit       = canReadTabList && innerTabs.find(item => item.name === 'freeSpins');

	// showTabFreeSpins ֊ էս էն պայմանն ա , երբ free Spin֊ին tab-ը պտի երևա կախված permissions֊ից ֊֊» registrationFreeSpin֊ի, depositFreeSpin֊ի, nextDepositFreeSpin֊ի գոնե մեկի 'create', 'delete', 'read', 'update' -ի դեպքում
	const showTabFreeSpins  = (permissions.isSA || [
		(registration       && CRUD_OPTIONS.some(crud => registration[crud])),
		(deposit            && CRUD_OPTIONS.some(crud => deposit[crud])),
		(nextDeposit        && CRUD_OPTIONS.some(crud => nextDeposit[crud])),
	].some(bool => bool)) && (!editMode || (bonusType === depositFreeSpin || bonusType === registrationFreeSpin || bonusType === nextDepositFreeSpin));

	//freeSpinBonusTypes ֊ էս  free Spin tab-ում bonus type select֊ի համար ա , թե որ option-ները երևան կախված permissions֊ի update-ից , եթե չկա update permissions ոչ  մեկի համար, կվերադարձնենք null select֊ի desable համար
	const freeSpinBonusTypes = (permissions.isSA || registration?.create || deposit?.create || nextDeposit?.create)
		? {
			registrationFreeSpin: permissions.isSA || registration?.create,
			depositFreeSpin     : permissions.isSA || nextDeposit?.create,
			nextDepositFreeSpin : permissions.isSA || nextDeposit?.create,
		}
		: null;

	return {
		freeSpinBonusTypes,
		showTabFreeSpins,
	};
};

export const createPackPermissions = (permissions, innerTabs) => {
	if (permissions.isSA) {
		return permissions;
	}
	return innerTabs.find(item => item.name === 'pack');
};
// const showTabFreeSpins      = (permissions.isSA || (freeSpinBonusTypes.depositFreeSpin || freeSpinBonusTypes.nextDepositFreeSpin || freeSpinBonusTypes.registrationFreeSpin)) && (!editMode || (bonusType === depositFreeSpin || bonusType === registrationFreeSpin || bonusType === nextDepositFreeSpin));

export const wayInfo = <IntlMessages id = "bonuses.nextDeposit.wayInfo" />;

export const IconLabel = ({ name, icon, text, placement }) => {
	return (
		<Tooltip title={text} placement={placement}>
			<div>{name}</div>
			<div style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}>
				{icon}
			</div>
		</Tooltip>
	);
};

IconLabel.propTypes = {
	name     : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	icon     : PropTypes.element,
	text     : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	placement: PropTypes.string,
};

IconLabel.defaultProps = {
	name     : '',
	text     : '',
	placement: 'top',
	icon     : <InfoCircleTwoTone />,
};


export function checkValueForZero(value) {
	return value === 0 ? value.toString() : '';
}

export function checkIsEndDateBeforeStartDate(startDate, endDate) {
	if (startDate && endDate) {
		const formattedStartDate = dayjs(startDate);
		const formattedEndDate = dayjs(endDate);

		return formattedEndDate.isBefore(formattedStartDate);
	}

	return false;
}
export function checkIsSame(startDate, endDate) {
	if (startDate && endDate) {
		const formattedStartDate = dayjs(startDate);
		const formattedEndDate = dayjs(endDate);

		return formattedStartDate.isSame(formattedEndDate, 'day');
	}

	return false;
}


export function disabledEndTime(bonusData) {
	const { startDate, endDate } = bonusData;
	let disabledHours = [];
	let disabledMinutes = [];
	let disabledSeconds = [];
	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	const isSameEndDateAndStartDate = checkIsSame(startDate, endDate);

	if (!disabledHours.length && !disabledMinutes.length && !disabledSeconds.length && startDate && endDate && isSameEndDateAndStartDate) {
		const date = dayjs(startDate);
		const hour = date.hour();
		const minute = date.minute();
		const second = date.second();

		disabledHours = range(0, hour);
		disabledMinutes = range(0, minute);
		disabledSeconds = range(0, second);
	}

	return {
		disabledHours  : () => disabledHours,
		disabledMinutes: () => disabledMinutes,
		disabledSeconds: () => disabledSeconds,
	};
}

export function setEndDateByStartDate(resData, dataRefresh, startDate, endDate) {
	if (!endDate) {
		resData['endDate'] = startDate;
		dataRefresh(resData);
	} else {
		const isEndDateBeforeStartDate = checkIsEndDateBeforeStartDate(startDate, endDate);

		if (isEndDateBeforeStartDate) {
			resData['endDate'] = startDate;
			dataRefresh(resData);
		}
	}
}

export function checkBonusLinesAndDenominations(bonusData, limits, lineAndDenominationsRequired, errors, messages) {
	if (!limits.showLineAndDenominationInputs) {
		if (!limits.denominationKey || !limits.denominationValue) {
			errors.push(messages.atLeastOneDenom);
		}
	} else {
		if (!bonusData.betPerLine && lineAndDenominationsRequired?.requiredBetPerLine) {
			errors.push(messages.errorBetPerLine);
		}
		if (!bonusData.lines && lineAndDenominationsRequired?.requiredLines) { // TODO CHECK Mher thanks )
			errors.push(messages.errorLines);
		}
	}
}
