import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GamesListInfinite from '../../../../../../components/InfinitiveDropDown/GameListInfiite';
import casinoGamesActions from '../../../../../../redux/casino/games/actions';
import {
	deriveCasinoGamesListFilter,
	deriveCasinoGamesPagination,
	deriveGamesListAlphabetical,
} from '../../../../../../selectors/casinoGames';
import { Wrapper } from './TabMain.style';


const GameListSelect = ({
	gamesList,
	gamesListReloadLimited,
	gamesListRefreshPagination,
	onSelect,
	hasMore,
	value,
	currentPage,
	label,
	disabled,
	filterRefresh,
	filter,
	isMassBonus,
	gameListReset,
	gameEntity,
	pageReset = false,
	uiRefresh,
}) => {
	const [loading, setLoading] = useState(null);

	const onChangeGame = (value) => onSelect && onSelect(value);

	const gamesReset = () => {
		if (!isMassBonus) {

			gamesListRefreshPagination({ currentPage: 1, totalCount: 0 });
			gameListReset();
		}
	};

	useEffect(() => {
		return () => {
			if (!isMassBonus) {
				gameListReset();
			}
		};
	}, []);

	return (
		<Wrapper>
			<div className="label">
				{label}
			</div>
			<div className="control">
				<GamesListInfinite
					onRefreshSelected={(selectedList) => {
						if (selectedList) {
							onChangeGame(selectedList[0]);
						} else if (gamesList.length > 0){
							gamesListRefreshPagination({ currentPage: 1 });
							gamesListReloadLimited('',  1);
						}
					}}
					limit={100}
					onLoadData={(name, page) => {
						if (page !== currentPage || filter?.name !== name) {
							filterRefresh({ name: name || '' });
							gamesListRefreshPagination({ currentPage: page });
							gamesListReloadLimited(name, page);
						} else {
							setLoading(false);
						}
					}}
					list={gamesList}
					multiSelect={false}
					style={{ width: '100%' }}
					outerVal={value}
					searchLength={3}
					returnId
					shouldLoadOnClick
					hasMore={hasMore}
					disabled={disabled}
					loading={loading}
					isMassBonus={isMassBonus}
					onBlur={gamesReset}
					gameEntity={gameEntity}
					pageReset={pageReset}
					uiRefresh={uiRefresh}
				/>
			</div>
		</Wrapper>
	);
};

const mapStateToProps = (state) => {
	const gamesList = deriveGamesListAlphabetical(state);
	const { totalCount, currentPage } = deriveCasinoGamesPagination(state);
	const hasMore = (totalCount - gamesList.length) > 0;
	const filter = deriveCasinoGamesListFilter(state);

	return {
		gamesList,
		hasMore,
		currentPage,
		filter,
	};
};

const mapDispatchToProps = {
	gamesListReloadLimited    : casinoGamesActions.gamesListReloadLimited,
	gamesListRefreshLimited   : casinoGamesActions.gamesListRefreshLimited,
	gamesListRefreshPagination: casinoGamesActions.gamesListRefreshPagination,
	filterRefresh             : casinoGamesActions.filterRefresh,
	gameListReset             : casinoGamesActions.gameListReset,
};

GameListSelect.propTypes = {
	gamesList: PropTypes.array.isRequired,
	filter   : PropTypes.shape({
		casinoGameID: PropTypes.number.isRequired,
	}).isRequired,

	gamesListReloadLimited: PropTypes.func.isRequired,
	onSelect              : PropTypes.func.isRequired,
	filterRefresh         : PropTypes.func.isRequired,
	gameListReset         : PropTypes.func.isRequired,

	hasMore : PropTypes.bool.isRequired,
	label   : PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};
GameListSelect.defaultProps = {
	disabled: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameListSelect);
