import { Map } from 'immutable';
import { fill } from '../../../../../helpers/utils';
import actions from './actions';
import { defaultReportFields } from '../../../../../containers/LoyaltySystem/BonusingSystem/MassBonus/utils';

const initState = new Map({

	list        : null,
	reportFields: defaultReportFields,
	
	sorting: {
		sortBy   : 'id',
		sortOrder: 'desc', // 'asc'
	},

	UI: { 
		loading      : false,
		reportLoading: false,
	},
});

export default function listReducer(state = initState, action) {

	switch (action.type) {

		case actions.USERS_MASS_BONUS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.USERS_MASS_BONUS_LIST_SET_VALUE_UI: {
			const { valueName, value } = action.data;
			const UI                   = new Map(state.get('UI')).toObject();
			UI[valueName]              = value;

			return state.set('UI', UI);
		}

		case actions.USERS_MASS_BONUS_LIST_SORTING_REFRESH: {
			const target = state.get('sorting');
			const result = fill(action.data, target);
			return state.set('sorting', result);
		}

		case actions.USERS_MASS_BONUS_LIST_UI_REFRESH: {
			const UI  =  action.data;
			const target = state.get('UI');
			const result = fill(UI, target);
			return state.set('UI', result);
		}
		case actions.USERS_MASS_BONUS_REPORT_DATA_FIELDS_REFRESH: {
			return state.set('reportFields', action.reportFields);
		}
		default:
			return state;
	}
}
