import { Map } from 'immutable';
import { createFilter, fill, filtersURL } from '../../../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({
	list    : [],
	baseData: {},
	filter  : createFilter(filtersURL.massBonus, initFilterTypes),

	UI: { 
		loading : false,
		editMode: false,
	},
});

export default function listReducer(state = initState, action) {

	switch (action.type) {

		case actions.MASS_BONUS_LIST_REFRESH: {
			return state.set('list', action.data);
		}

		case actions.MASS_BONUS_BASE_DATA_REFRESH: {
			return state.set('baseData', action.data);
		}

		case actions.MASS_BONUS_UI_REFRESH: {
			const UI  =  action.data;
			const target = state.get('UI');
			const result = fill(UI, target, true);
			return state.set('UI', result);
		}

		case actions.MASS_BONUS_FILTER_REFRESH: {
			const { filter } = action;
			const target = state.get('filter');
			const result = fill(filter, target, true);
			return state.set('filter', result);
		}

		case actions.MASS_BONUS_DATA_RESET: {
			return state.set('UI', initState.get('UI'));
		}

		case actions.MASS_BONUS_FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
