import { Col, Switch } from 'antd';
import IntlMessages from '../utility/intlMessages';
import FormMultiSelect from '../FormControls/FormMultiSelect';
import { StyledCol, StyledRow } from './CountriesAllowReject.style';
import { actions as countriesActions } from '../../redux/partner/partners/modal/countries/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/storeHooks';
const lang = {
	countries: <IntlMessages id = "common.countries" />,
	allow    : <IntlMessages id = "bonuses.countries.allow" />,
	reject   : <IntlMessages id = "bonuses.countries.reject" />,
};

export const CountriesAllowReject = ({ disabled, data, onChange, onSelect, onDeselect, isFilter = false, labelWidth, separateSpanCountries = null, separateSpanSwitch = null, span = 12, gutter = 16 }) => {
	const dispatch		= useDispatch();
	let { countriesList }	= useAppSelector(state => state.Partner.Partner.Modal.Countries.get('baseData'));

	if (isFilter) {
		countriesList = countriesList.map(country => ({ id: country.countriesCode, name: country.name }));
	}
	useEffect(() => {
		dispatch(countriesActions.baseDataReload());
	}, []);
	const countriesSpan	= separateSpanCountries ? separateSpanCountries : span;
	const switchSpan	= separateSpanSwitch ? separateSpanSwitch : span;
	const width			= separateSpanCountries ? labelWidth : 25;
	return (
		<StyledRow gutter={gutter}>
			<Col span={countriesSpan}>
				<FormMultiSelect 
					label={!isFilter && lang.countries}
					labelWidth={isFilter ? 0 : width}
					optionsList={countriesList}
					value={data.countriesIDs}
					disabled={disabled}
					onSelect={onSelect}
					onDeselect={onDeselect}
				/>
			</Col>
			<StyledCol style={{ marginBottom: !isFilter && '8px' }} span={isFilter ? 8 : switchSpan}>
				<Switch
					disabled={disabled}
					checkedChildren={lang.allow}
					unCheckedChildren={lang.reject}
					onChange={onChange}
					value={data.countriesAllow}
					defaultChecked
				/>
			</StyledCol>
		</StyledRow>
	);
};
