import { all, fork } from 'redux-saga/effects';
import sportRiskListSaga from './list/saga';
import sportRiskModalSaga from './modal/saga';
import sportRiskManagementHistorySaga from './history/saga';

export default function* sportRiskSaga() {
	yield all([
		fork(sportRiskListSaga),
		fork(sportRiskModalSaga),
		fork(sportRiskManagementHistorySaga),
	]);
}