import { IOddPriceUpdateData } from './types';

const prefix = 'SPORT_RISK_BETS_MODAL_';
const actionsSportRiskModal = {
	SPORT_RISK_BETS_MODAL_UPDATE          : `${prefix}UPDATE`,
	SPORT_RISK_BETS_MODAL_SUSPEND_UPDATE  : `${prefix}SUSPEND_UPDATE`,
	SPORT_RISK_BETS_MODAL_MARGIN_UPDATE   : `${prefix}MARGIN_UPDATE`,
	SPORT_RISK_BETS_MODAL_ODD_PRICE_UPDATE: `${prefix}ODD_PRICE_UPDATE`,

	updateData: (closeModal: boolean) => ({
		type: actionsSportRiskModal.SPORT_RISK_BETS_MODAL_UPDATE,
		closeModal,
	}),
	saveSuspend: (suspend: number, marketID: string, reason: string) => ({
		type: actionsSportRiskModal.SPORT_RISK_BETS_MODAL_SUSPEND_UPDATE,
		suspend,
		marketID,
		reason,
	}),
	saveMargin: (marketID: string, margin: string, betID: number) => ({
		type: actionsSportRiskModal.SPORT_RISK_BETS_MODAL_MARGIN_UPDATE,
		marketID,
		betID,
		margin,
	}),
	saveODDPrice: (data: IOddPriceUpdateData) => ({
		type: actionsSportRiskModal.SPORT_RISK_BETS_MODAL_ODD_PRICE_UPDATE,
		data,
	}),
};

export default actionsSportRiskModal;
