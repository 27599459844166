import { Component } from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../utility/intlMessages';
import Button from '../../uielements/button';

import SettingControl from './SettingsControl';
import { Wrapper } from './ReportBar.style';
import { Icons } from '../../uielements/AntIcons/AntIcons';
import { IconButton } from '../../uielements/styles/button.style';

const lang = {
	download       : <IntlMessages id = "reportToolbal.download" />,
	recalculate    : <IntlMessages id = "reportToolbal.recalculate" />,
	infoDownload   : <IntlMessages id = "reportToolbal.infoDownload" />,
	infoRecalculate: <IntlMessages id = "reportToolbal.infoRecalculate" />,
};

class ReportBar extends Component {

	static propTypes = {
		id          : PropTypes.string.isRequired,
		reportFields: PropTypes.arrayOf(PropTypes.shape({
			id     : PropTypes.string,
			intlID : PropTypes.string,
			checked: PropTypes.bool,
		})),

		loading        : PropTypes.bool,
		disabled       : PropTypes.bool,
		showRecalculate: PropTypes.bool,
		onDownload     : PropTypes.func.isRequired,
		onChangeFields : PropTypes.func.isRequired,
		onRecalculate  : PropTypes.func,
	};

	static defaultProps = {
		reportFields   : [],
		loading        : false,
		disabled       : false,
		showRecalculate: false,
		onRecalculate  : null,
	};

	constructor(props) {
		super(props);
		this.onClickDownload    = this.onClickDownload.bind(this);
		this.onClickRecalculate = this.onClickRecalculate.bind(this);
	}

	// Events ---------------------------------------------------------------------------------------
	onClickDownload() {
		const { onDownload } = this.props;
		onDownload();
	}

	onClickRecalculate() {
		const { onRecalculate } = this.props;
		if (onRecalculate) {
			onRecalculate();
		}
	}

	// Renders --------------------------------------------------------------------------------------
	render() {
		const { id, reportFields, loading, disabled, showRecalculate, onChangeFields } = this.props;


		return (
			<Wrapper>
				<IconButton
					className="download"
					type="primary"
					icon={!loading && Icons.downLoad}
					loading={loading}
					disabled={disabled || loading}
					onClick={this.onClickDownload}
				>
					{lang.download}
				</IconButton>
				{showRecalculate && (
					<Button
						className="recalculate"
						type="default"
						icon={loading ? 'loading' : 'reload'}
						disabled={disabled || loading}
						onClick={this.onClickRecalculate}
					>
						{lang.recalculate}
					</Button>
				)}
				<SettingControl
					id={id}
					reportFields={reportFields}
					onChange={onChangeFields}
					disabled={disabled || loading}
				/>
			</Wrapper>
		);
	}
}

export default ReportBar;
