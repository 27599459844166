import React from 'react';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';

import Intl from '../../components/utility/intlMessages';
import WebsiteSelect from '../../components/connected/WebsiteSelect';
import CurrencySelect from '../../components/connected/CurrencySelect';

import { fill, alphaOnly } from '../../helpers/utils';
import { FORMATS } from '../../helpers/commonConstants';
import { yesNoList } from '../../helpers/commonEnums';
import { ELEMENT_TYPES } from '../../constants/filters';

const lang = {
	filter  : <Intl id = "common.blockFilter" />,
	advanced: <Intl id = "common.advancedFilter" />,
	search  : <Intl id = "common.search" />,
	reset   : <Intl id = "common.reset" />,
	website : <Intl id = "common.website" />,
	currency: <Intl id = "common.currency" />,
};

class FilterBuilder {

	constructor(defaultProps) {
		const { onClickSearch, onClickReset, onChangeValue, filter, disabledFields } = defaultProps;
		const emptyHandler = () => {};

		this.settings = {
			onClickSearch: onClickSearch || emptyHandler,
			onClickReset : onClickReset  || emptyHandler,
			onChangeValue: onChangeValue || emptyHandler,

			columnsCount           : 3,
			advancedColumnsCount   : 3,
			blockTitle             : lang.filter,
			buttonSearchTitle      : lang.search,
			advancedFiltersTitle   : lang.advanced,
			buttonResetTitle       : lang.reset,
			disabled               : false,
			separateDisabledButtons: [],
		};

		this.filter = filter;

		this.disabledFields = disabledFields ||  [];

		this.init               	= this.init.bind(this);
		this.addDisabled        	= this.addDisabled.bind(this);
		this.isDisabled         	= this.isDisabled.bind(this);
		this.build              	= this.build.bind(this);

		this.Input              	= this.Input.bind(this);
		this.InputNumberTypeText	= this.InputNumberTypeText.bind(this);
		this.InputNumber        	= this.InputNumber.bind(this);
		this.InputNumberFinance 	= this.InputNumberFinance.bind(this);
		this.NumberRange        	= this.NumberRange.bind(this);
		this.NumberRangeFinance 	= this.NumberRangeFinance.bind(this);
		this.Checkbox           	= this.Checkbox.bind(this);
		this.InputAutoComplete  	= this.InputAutoComplete.bind(this);

		this.Select             	= this.Select.bind(this);
		this.SelectSearch       	= this.SelectSearch.bind(this);
		this.Multiselect        	= this.Multiselect.bind(this);
		this.YesNoSelect        	= this.YesNoSelect.bind(this);

		this.Date               	= this.Date.bind(this);
		this.DateRange          	= this.DateRange.bind(this);
		this.TimeRange          	= this.TimeRange.bind(this);
		this.Component          	= this.Component.bind(this);
	}

	// Service --------------------------------------------------------------------------------------
	init(customSettings = {}) {
		this.settings = fill(customSettings, this.settings);
	}

	addDisabled(name, condition = null) {
		const needToAdd = condition !== null && Boolean(condition);
		if (needToAdd) {
			this.disabledFields.push(name);
		}
	}

	isDisabled(name) {
		return this.disabledFields.includes(name);
	}

	build(primary, advanced = []) {
		const { settings } = this;

		return {
			...settings,
			elements        : primary,
			advancedElements: advanced,
		};
	}

	// Creating Filter fields -----------------------------------------------------------------------

	// Inputs ---------------------------------------------------------------------------------------
	Input(name, label = null, onChangeHandler = null, widthLabel, widthInput) {
		const { filter, settings } = this;
		const value = (filter[name] === undefined || filter[name] === null) ? '' : decodeURIComponent(filter[name]);
		return {
			name,
			label,
			value,
			widthLabel,
			widthInput,
			type     : ELEMENT_TYPES.Input,
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
		};
	}

	InputNumberTypeText(name, label = null, onChangeHandler = null, validationParams) {
		const { filter, settings } = this;
		const value = filter[name];
		return {
			name,
			label,
			value,
			type     : ELEMENT_TYPES.InputNumberTypeText,
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
			validationParams,
		};
	}

	InputNumber(name, label = null, onChangeHandler = null, minValue = 0, step = 1, isId = false ) {
		const { filter, settings } = this;
		const value = filter[name];

		return {
			name,
			label,
			value,
			minValue,
			step,
			type     : ELEMENT_TYPES.InputNumber,
			onChange : onChangeHandler || settings.onChangeValue,
			parser   : (value) => alphaOnly(value),
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
			isId,
		};
	}

	InputNumberFinance(name, label = null, onChangeHandler = null, minValue = 0) {
		return this.InputNumber(name, label, onChangeHandler, minValue, 0.01);
	}

	NumberRange(name, label = null, onChangeHandler = null, minValue = 0, step = 1) {
		const { filter, settings } = this;
		const rawValue = filter[name];
		const value = isArray(rawValue)
			? rawValue.map(item => (item ? String(item) : null))
			: [null, null];

		return {
			name,
			label,
			value,
			minValue,
			step,
			type     : ELEMENT_TYPES.NumberRange,
			separator: '/',
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
		};
	}

	NumberRangeFinance(name, label = null, onChangeHandler = null, minValue = 0) {
		return this.NumberRange(name, label, onChangeHandler, minValue, 0.01);
	}

	Checkbox(name, label = null, onChangeHandler = null) {
		const { filter, settings } = this;
		const value = filter[name];

		return {
			name,
			label,
			value,
			type     : ELEMENT_TYPES.Checkbox,
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
		};
	}

	InputAutoComplete(name, label = null, dataSource = [], onChangeHandler = null, widthLabel, widthInput) {
		const { filter, settings } = this;
		const value = (filter[name] === undefined || filter[name] === null) ? '' : decodeURIComponent(filter[name]);
		let dataId = '';
		switch (name) {
			case 'userName': dataId = 'userName';
				break;
			case 'userFirstName': dataId = 'first-name';
				break;
			case 'userLastName': dataId = 'last-name';
				break;
			case 'userEmail': dataId = 'e-mail';
				break;
			default: dataId = '';
		}

		return {
			name,
			dataId,
			label,
			value,
			dataSource,
			type     : ELEMENT_TYPES.InputAutoComplete,
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
			widthInput,
			widthLabel,
		};
	}

	// Selects --------------------------------------------------------------------------------------
	Select(name, label = null, list = [], onChangeHandler = null, widthLabel, widthInput) {
		const { filter, settings } = this;
		const rawValue = filter[name];
		const value    = rawValue ? String(rawValue) : null;
		this.addDisabled('depositSource', name === 'depositTypeID' && Number(value) === 5);

		return {
			name,
			label,
			value,
			type      : ELEMENT_TYPES.Select,
			optionList: list,
			onChange  : onChangeHandler || settings.onChangeValue,
			disabled  : this.isDisabled(name),
			showLabel : Boolean(label),
			widthLabel,
			widthInput,
		};
	}

	SelectSearch(name, label = null, list = [], onChangeHandler = null) {
		const { filter, settings } = this;
		const rawValue = filter[name];
		const value    = rawValue !== undefined && rawValue !== null ? String(rawValue) : null;
		return {
			name,
			label,
			value,
			type      : ELEMENT_TYPES.SelectSearch,
			optionList: list,
			onChange  : onChangeHandler || settings.onChangeValue,
			disabled  : this.isDisabled(name),
			showLabel : Boolean(label),
		};
	}

	Multiselect(name, label = null, list = [], onChangeHandler = null, skipSelectAll = false) {
		const { filter, settings } = this;
		const rawValue = filter[name];
		const value    = isArray(rawValue) ? rawValue.map(item => String(item)) : [];
		let dataId = '';

		if (name === 'validationStatus') {
			dataId = 'verification-methods';
		}
		return {
			name,
			label,
			dataId,
			value,
			skipSelectAll,
			type      : ELEMENT_TYPES.Multiselect,
			optionList: list,
			onChange  : onChangeHandler || settings.onChangeValue,
			disabled  : this.isDisabled(name),
			showLabel : Boolean(label),
		};
	}

	YesNoSelect(name, label = null, list = null, onChangeHandler = null) {
		const { filter, settings } = this;
		const value = filter[name];

		const yesNoOptionsList = list || yesNoList;

		return {
			name,
			label,
			value,
			type      : ELEMENT_TYPES.Select,
			optionList: yesNoOptionsList,
			onChange  : onChangeHandler || settings.onChangeValue,
			disabled  : this.isDisabled(name),
			showLabel : Boolean(label),
		};
	}

	// Dates ----------------------------------------------------------------------------------------
	Date(name, label = null, formats = {}, onChangeHandler = null) {
		const { filter, settings } = this;

		const value      = filter[name];
		const dateFormat = formats.date || FORMATS.date;
		const timeFormat = formats.time || null;

		return {
			name,
			label,
			value,
			dateFormat,
			timeFormat,
			type     : ELEMENT_TYPES.Date,
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
		};
	}

	DateRange(name, label = null, formats = {}, onChangeHandler = null) {
		const { filter, settings } = this;

		const rawValue   = filter[name];
		const value      = isArray(rawValue) ? rawValue.map(item => String(item)) : [];
		const dateFormat = formats.date || FORMATS.date;
		const timeFormat = formats.time || FORMATS.time;

		return {
			name,
			label,
			value,
			dateFormat,
			timeFormat,
			type     : ELEMENT_TYPES.DateRange,
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
		};
	}

	TimeRange(name, label = null, formats = {}, onChangeHandler = null) {
		const { filter, settings } = this;

		const rawValue   = filter[name];
		const value      = isArray(rawValue) ? rawValue.map(item => String(item)) : [];
		const dateFormat = formats.date || FORMATS.date;
		const timeFormat = formats.time || FORMATS.time;

		return {
			name,
			label,
			value,
			dateFormat,
			timeFormat,
			type     : ELEMENT_TYPES.TimeRange,
			onChange : onChangeHandler || settings.onChangeValue,
			disabled : this.isDisabled(name),
			showLabel: Boolean(label),
		};
	}

	// Others ---------------------------------------------------------------------------------------
	Component(name, label = null, component, value = null) {
		return {
			value,
			name,
			label,
			component,
			type     : ELEMENT_TYPES.Component,
			showLabel: Boolean(label),
		};
	}

	Website(name = 'websiteID', label = lang.website, onChangeHandler = null) {
		const { filter, settings } = this;
		const value    = toInteger(filter[name]) || null;
		const onChange = onChangeHandler || settings.onChangeValue;

		const component = (
			<WebsiteSelect
				noAutocomplete
				websiteID={value}
				onChange={websiteID => onChange(name, websiteID)}
			/>
		);

		return this.Component(name, label, component);
	}

	Currency(name = 'currencyID', label = lang.currency, onChangeHandler = null) {
		const { filter, settings } = this;
		const value    = toInteger(filter[name]) || null;
		const onChange = onChangeHandler || settings.onChangeValue;

		const component = (
			<CurrencySelect
				noAutocomplete
				currencyID={value}
				onChange={currencyID => onChange(name, currencyID)}
			/>
		);

		return this.Component(name, label, component);
	}
}

export default FilterBuilder;
