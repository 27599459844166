import { createSelector } from 'reselect';

const getPagination = (state, props) => {

	const { reduxPath, namePageSize, nameCurrent, nameTotal } = props;
	if (!reduxPath) return;
	const parts = reduxPath.split('.');
	const namePagination = parts.pop();

	let pagination = state;
	parts.forEach(key => {
		pagination = pagination[key];
	});
	pagination = pagination.get(namePagination);

	return {
		itemsPerPage: pagination[namePageSize] || 100,
		currentPage : pagination[nameCurrent]  || 1,
		totalCount  : pagination[nameTotal]    || 0,
	};
};

export const derivePagination = createSelector(
	[getPagination],
	(pagination) => pagination
);
