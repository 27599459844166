import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import actions from './actions';

import { uploadsAPI } from '../../../helpers/api/uploads';
import { showError, showSuccess, showWarning } from '../../../helpers/notifications';
import { logger } from '../../../helpers/logger';

const prefix = 'images.single';

const messages = {
	errorUpload  : `${prefix}.errorUpload`,
	successUpload: `${prefix}.successUpload`,
	warningNoFile: `${prefix}.warningNoFile`,
};


function getStoreData({ App, Images: { Multiple } }) {

	return {
		websiteID: App.get('websiteID'),
		uploads  : Multiple.get('uploaders'),
	};
}
function* uploadFile() {

	yield takeEvery(actions.IMAGES_MULTIPLE_UPLOAD_FILES, function* (action) {

		const { id, files, cbSuccess, cbError, onProgress } = action.data;
		const { websiteID, uploads } = yield select(getStoreData);

		if (!files.length) {
			showWarning(messages.warningNoFile);
			if (cbError) {
				cbError(new Error(messages.warningNoFile));
			}
			return;
		}

		yield put(actions.uiRefresh(id, { loading: true }));

		try {
			const params = { website_id: websiteID, return_info: true };
			const res = yield call(uploadsAPI.uploadMultipleFiles, files, onProgress, params);
			if (res && res.status === 200) {

				const filesList = res.data.data;
				if (isArray(filesList) && !isEmpty(filesList)) {

					const uploadedFiles = uploads[id].files;
					const mergedFiles = uploadedFiles.concat(filesList);
					yield put(actions.init(id, mergedFiles));

					showSuccess(messages.successUpload);

					if (cbSuccess) {
						cbSuccess(mergedFiles);
					}

				} else {
					yield put(actions.init());
				}
			} else {
				yield put(actions.init());
			}

		} catch (error) {

			yield put(actions.uploadFilesError(id));

			showError(messages.errorUpload, error);
			logger.log(error);

			if (cbError) {
				cbError(error);
			}
		}

		yield put(actions.uiRefresh(id, { loading: false }));
	});
}

export default function* imageMultipleSaga() {
	yield all([
		fork(uploadFile),
	]);
}
