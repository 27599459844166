import toInteger from 'lodash/toInteger';
import { isID } from '../../../../../helpers/utils';
import { createListAdapter, fields } from '../list/utils';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../utility';

const listAdapter = createListAdapter();
const revisionAdapter = createRevisionAdapter();
const promotionAdapter = createPromotionAdapter();

// Adapt ------------------------------------------------------------------------------------------

export function adaptPromotion(rawData = {}) {
	promotionAdapter.clearExcludes();

	return promotionAdapter.adapt(rawData);
}

export function adaptRevisionsList(rawData = []) {
	let activeRevisionID = null;
	revisionAdapter.clearExcludes();
	const adaptedData = revisionAdapter.adaptList(rawData);
	listAdapter.addExcludeField(fields.promotionName);
	adaptedData.forEach(revision => {
		revision.promotion.id = rawData.promotionID;
		revision.promotion.lang_id = rawData.langID;
		revision.promotion.channel_id = rawData.channelID;
		revision.promotion.website_id = rawData.websiteID;
		revision.promotion.alias = revision.alias;
		revision.promotion.order_id = revision.orderID;
		revision.promotion =  listAdapter.adapt(revision.promotion);
		revision.promotion.games = revision.promotion.games?.map(item => item.id);
		if (revision.active) {
			activeRevisionID = revision.id;
		}
	});

	const revisionsEntities = createEntities(adaptedData);

	return {
		revisionsEntities,
		activeRevisionID,
	};
}

// Prepare ----------------------------------------------------------------------------------------

export function preparePromotion(baseData, langID, channelID) {
	listAdapter.clearExcludes();
	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('createdAt');
	listAdapter.addExcludeField('updatedAt');

	const preparedData = listAdapter.prepare(baseData);

	preparedData[fields.langID] = langID;
	preparedData[fields.channelID] = channelID;
	if (isID(baseData.id)) {
		preparedData[fields.id] = toInteger(baseData.id);
	}

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------

function createRevisionAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'promotionID', fields.promotionID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.number, 'orderID', fields.orderID);

	adapter.addField(rules.bool, 'active', fields.active);
	adapter.addField(rules.fullDate, 'date', fields.date);
	adapter.addField(rules.noCondition, 'promotion', fields.promotion);

	return adapter;
}

function createPromotionAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'channelID', fields.channelID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.fullDate, 'createdAt', fields.createdAt);
	adapter.addField(rules.id, 'statusID', fields.statusID);

	adapter.addField(rules.string, 'promotionName', fields.name);
	adapter.addField(rules.string, 'alias', fields.alias);
	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'subtitle', fields.subtitle);
	adapter.addField(rules.wholeNumberOrNull, 'orderID', fields.orderID);
	adapter.addField(rules.arrayID, 'gameIDs', fields.gameIDs);

	return adapter;
}


export function mergePromotionRevisions(promotionData, revisionsData = {
	activeRevisionID : null,
	revisionsEntities: {},
}) {
	const activeRevision = Boolean(revisionsData.activeRevisionID) && revisionsData.revisionsEntities[revisionsData.activeRevisionID];
	if (activeRevision) {
		const res = {
			...promotionData,
			...activeRevision.promotion,
			statusID: activeRevision.statusID,
		};
		res.gameIDs = activeRevision.promotion.gameIDs;
		return res;
	}

	return promotionData;
}