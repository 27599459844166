import React from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import isArray from 'lodash/isArray';

import MultiSelect from '../../../components/uielements/MultiSelect';
import FormControl from '../../../components/uielements/FormControl';
import Utils from '../FilterUtils';

// how much items multiselect can have to show "Select/Unselect All"
const maxItemsMultiselect = 15;

const Multiselect = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, disabled, optionList, skipSelectAll, dataId } = filterItem;
	const resOptionList = isArray(optionList) ? optionList : [];
	const itemsCount = resOptionList.length;
	let showSelectAll = itemsCount <= maxItemsMultiselect;
	if (skipSelectAll) {
		showSelectAll = false;
	}

	const heightProps = {};
	if (isArray(value) && value.length > 1) {
		heightProps.height = 'auto';
	}

	const control = (
		<MultiSelect
			options={resOptionList}
			value={value}
			dataId = {dataId}
			name={name}
			disabled={disabled}
			showSelectAll={showSelectAll}
			onChange={value => onChange(name, value)}
			wrapperID={uuid()}
		/>
	);

	return (
		<FormControl
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			control={control}
			{...heightProps}
		/>
	);
};

Multiselect.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default Multiselect;
