import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';

export const fields = {
	id               : 'id',
	name             : 'name',
	logoUrl          : 'logo_url',
	withdrawalAllowed: 'withdrawal_allowed',
	depositAllowed   : 'deposit_allowed',
	depositCount     : 'deposit_count',
	langId           : 'lang_id',
	description      : 'description',
	orderId          : 'order_id',
	paymentId        : 'payment_id',

};

const listAdapter = createListAdapter();

// Params -----------------------------------------------------------------------------------------

export function getData(rawData = {}) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addField(rules.isString, 'amount', 'amount');
	builder.addField(rules.isString, 'commentForAdmin', 'comment_admin');
	builder.addField(rules.isString, 'transactionID', 'transaction_id');


	return builder.biuldParams(rawData);
}

// Adapting ---------------------------------------------------------------------------------------

export function adaptWebsiteDepositList(rawDepositList = []) {
	listAdapter.clearExcludes();

	return rawDepositList.reduce((acc, cur) => {
		if (cur.currency && cur.currency.id) {
			acc[cur.currency.id] = listAdapter.adaptList(cur.payments);
			return acc;
		}
		return acc;
	}, {});
}

// Adapters ---------------------------------------------------------------------------------------

function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.bool, 'withdrawalAllowed', fields.withdrawalAllowed);
	adapter.addField(rules.bool, 'depositAllowed', fields.depositAllowed);
	adapter.addField(rules.number, 'depositCount', fields.depositCount);
	adapter.addField(rules.id, 'langId', fields.langId);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.id, 'orderId', fields.orderId);
	adapter.addField(rules.id, 'paymentId', fields.paymentId);

	return adapter;
}
