import styled from 'styled-components';
import { Col, Row } from 'antd';

export const StyledRow = styled(Row)`
	flex: 1;
`;
export const StyledCol = styled(Col)`
	align-items: center;
	display: flex;
`;

