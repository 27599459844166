import { HTTPStatusCodes } from '../constants/HTTPStatusCodes';
import actions from './app/actions';
import authActions from './auth/actions';
import store from './store';
import notifications from '../components/feedback/notification';
import { logger } from '../helpers/logger';

// Error handling ------------------
export const ERROR_STATUSES = {
	USER_NOT_FOUND      : 'USER_NOT_FOUND',
	PASSWORD_EXPIRED    : 'PASSWORD_EXPIRED',
	INVALID_OLD_PASSWORD: 'INVALID_OLD_PASSWORD',
};

export const messages = {
	INVALID_OLD_PASSWORD: 'Old password is incorrect',
};


const getDispatch = () => {
	return store.dispatch;
};

// ------   handle errors   ------

export const handleError = (error) => {
	if (!error.response) return;
	const user = localStorage.getItem('user');
	const dispatch = getDispatch();
	// there are 2 cases of error messages.
	// 1. error message is in error.response.data.text
	// 2. error message is in error.response.data.error

	if (error.response.data.code === HTTPStatusCodes.Unauthorized && user ) {
		dispatch(authActions.logout());
		return;
	}
	// in case if error code is in response.data.error
	if (error.response.data) {
		switch (error.response.data.error) {
			case ERROR_STATUSES.PASSWORD_EXPIRED: {
				store.dispatch(actions.passwordExpiredModalRefresh({ visible: true }));
				return;
			}
			case ERROR_STATUSES.INVALID_OLD_PASSWORD: {
				showSingleError(messages[ERROR_STATUSES.INVALID_OLD_PASSWORD]);
				return;
			}

			default: {
				logger.error('Error');
			}
		}

	}

};

// ------   handle error messages ------


export function showSingleError(description) {


	try {
		notifications.error({
			message : 'Error',
			description,
			duration: 20,
		});
	} catch (err) {
		logger.error(description);
	}
}

