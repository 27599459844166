import { Map } from 'immutable';
import actions from './actions';
import { fill } from '../../helpers/utils';

const initState = new Map({
	list                   : [],
	limits                 : {},
	autoWithdrawMethods    : {},
	autoWithdrawMethodsTemp: {},
	UI                     : {
		loading: false,
	},
});

export default function autoWithdrawSettingsReducer(state = initState, action) {
	switch (action.type) {
		case actions.WEBSITE_AUTOWITHDRAW_LIST_REFRESH: {
			return state.set('list', action.data);
		}
		case actions.WEBSITE_AUTOWITHDRAW_LIMITS_REFRESH: {
			return state.set('limits', action.data);
		}
		case actions.WEBSITE_AUTOWITHDRAW_ADD: {
			const newItems = [...state.get('list'), action.data];
			return state.set('list', newItems);
		}
		case actions.AUTOWITHDROWAL_LIMIT_ADD: {
			const newLimits = { ...state.get('limits'), ...action.data };
			return state.set('limits', newLimits);
		}
		case actions.AUTOWITHDROWAL_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}
		case actions.AUTO_WITHDRAWAL_RESET: {
			return  initState;
		}
		case actions.AUTO_WITHDRAWAL_SOURCE_REFRESH: {
			return state.set('autoWithdrawMethods', action.data);
		}
		case actions.AUTO_WITHDRAWAL_SOURCE_TEMP_REFRESH: {
			return state.set('autoWithdrawMethodsTemp', action.data);
		}
		default:
			return state;
	}
}
