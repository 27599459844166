import React from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';

import FormDateRangePicker from '../../../components/FormControls/FormDateRangePicker';
import Utils from '../FilterUtils';

const fullWidth = { width: '100%' };

const DateRange = ({ filterItem, onChange, skippedPeriods }) => {
	const { name, value, label, showLabel, placeholder, defaultValue, disabled, dateFormat, timeFormat } = filterItem;

	const { format, showTime } = Utils.getDateRangeFormat(dateFormat, timeFormat);
	const resPlaceholder = placeholder ? [`${placeholder} from`, `${placeholder} to`] : ['', ''];

	let resDefaultValue = [null, null];
	if (Utils.isValidRange(defaultValue)) {
		const valueFrom = defaultValue[0] ? dayjs(defaultValue[0]) : null;
		const valueTo   = defaultValue[1] ? dayjs(defaultValue[1]) : null;
		resDefaultValue = [valueFrom, valueTo];
	}

	let resValue = [null, null];
	if (Utils.isValidRange(value)) {
		const valueFrom = value[0] ? dayjs(value[0]) : null;
		const valueTo   = value[1] ? dayjs(value[1]) : null;
		resValue = [valueFrom, valueTo];
	}

	const wrapperID = uuid();

	return (
		<FormDateRangePicker
			id={wrapperID}
			name={name}
			showPeriods
			skippedPeriods={skippedPeriods}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			format={format}
			showTime={showTime}
			placeholder={resPlaceholder}
			defaultValue={resDefaultValue}
			value={resValue}
			style={fullWidth}
			disabled={disabled}
			wrapperID={wrapperID}
			onChange={value => onChange(name, value, format)}
		/>
	);
};

DateRange.propTypes = {
	filterItem    : PropTypes.object.isRequired,
	onChange      : PropTypes.func.isRequired,
	skippedPeriods: PropTypes.array,
};

DateRange.defaultProps = {
	skippedPeriods: [],
};

export default DateRange;
