import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

const sportList     = (state) => state.Common.get('sportList');
const providerList  = (state) => state.Common.get('providerList');
const adminList     = (state) => state.Common.get('adminList');
const riskGroupList = (state) => state.Common.get('riskGroupList');
// Sports -----------------------------------------------------------------------------------------
export const deriveSportList = createSelector(
	[sportList],
	(sportList) => sortBy(sportList, ['order_id'])
);

export const deriveSportEntities = createSelector(
	[sportList],
	(sportList) => sportList.reduce((entities, item) => {
		const { id } = item;
		entities[id] = item;
		return entities;
	}, {})
);

// Providers --------------------------------------------------------------------------------------
export const deriveProviderList = createSelector(
	[providerList],
	(providerList) => sortBy(providerList, ['order_id', 'name', 'id'])
);

export const deriveProviderEntities = createSelector(
	[providerList],
	(providerList) => providerList.reduce((entities, item) => {
		const { id } = item;
		entities[id] = item;
		return entities;
	}, {})
);

// Admins -----------------------------------------------------------------------------------------
export const deriveAdminList = createSelector(
	[adminList],
	(adminList) => sortBy(adminList, ['name', 'id'])
);

export const deriveAdminEntities = createSelector(
	[adminList],
	(adminList) => adminList.reduce((entities, item) => {
		const { id } = item;
		entities[id] = item;
		return entities;
	}, {})
);

// Risk Groups ------------------------------------------------------------------------------------
export const deriveGroupList = createSelector(
	[riskGroupList],
	(riskGroupList) => sortBy(riskGroupList, ['id'])
);

export const deriveGroupEntities = createSelector(
	[riskGroupList],
	(riskGroupList) => riskGroupList.reduce((entities, item) => {
		const { id } = item;
		entities[id] = item;
		return entities;
	}, {})
);
