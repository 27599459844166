import EntityAdapter from '../../helpers/entityAdapter';


export const prefix = 'smsGateways';

export const messages = {
	errorSmsGatewaySave      : `${prefix}.errorSmsGatewaySave`,
	errorListReload          : `${prefix}.errorListReload`,
	errorDelete              : `${prefix}.errorDelete`,
	successSmsGatewaySave    : `${prefix}.successSmsGatewaySave`,
	successSmsGatewayDelete  : `${prefix}.successSmsGatewayDelete`,
	successSmsGatewayTestSent: `${prefix}.successSmsGatewaySendMessage`,
	errorSmsGatewayTestSent  : `${prefix}.sendTestMessageError`,
};

export const fields = {

	id: 'id',

	name : 'name',
	url  : 'url',
	token: 'token',

	postData      : 'post_data',
	providerType  : 'provider_type',
	sender        : 'sender',
	status        : 'status',
	defaultGateway: 'default',

	//Authorization header
	login    : 'login',
	password : 'password',
	userAgent: 'user_agent',
	content  : 'content',

	// test message
	number: 'number',
};
export const createSmsGatewaysListAdapter = () => {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	adapter.addField(rules.string,  'name', fields.name);
	adapter.addField(rules.string,  'url', fields.url);
	adapter.addField(rules.string,  'token', fields.token);

	adapter.addField(rules.string,  'postData', fields.postData);
	adapter.addField(rules.number,  'providerType', fields.providerType);
	adapter.addField(rules.string,  'sender', fields.sender);
	adapter.addField(rules.number,  'status', fields.status);
	adapter.addField(rules.bool,    'defaultGateway', fields.defaultGateway);

	//Authorization header
	adapter.addField(rules.string,  'login', fields.login);
	adapter.addField(rules.string,  'password', fields.password);
	adapter.addField(rules.string,  'userAgent', fields.userAgent);
	adapter.addField(rules.string,  'content', fields.content);

	return adapter;
};
export  const prepareSmsGateways = (sendData = {}) => {
	const listAdapter = createSmsGatewaysListAdapter();
	const preparedData = listAdapter.prepare(sendData);

	return preparedData;
};


export function createSmsGatewaysAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string,  'name', fields.name);
	adapter.addField(rules.string,  'url', fields.url);
	adapter.addField(rules.string,  'token', fields.token);

	adapter.addField(rules.string,  'postData', fields.postData);
	adapter.addField(rules.number,  'providerType', fields.providerType);
	adapter.addField(rules.string,  'sender', fields.sender);
	adapter.addField(rules.number,  'status', fields.status);
	adapter.addField(rules.bool,    'defaultGateway', fields.defaultGateway);

	//Authorization header
	adapter.addField(rules.string,  'login', fields.login);
	adapter.addField(rules.string,  'password', fields.password);
	adapter.addField(rules.string,  'userAgent', fields.userAgent);
	adapter.addField(rules.string,  'content', fields.content);

	return adapter;
}


const smsGatewaysListAdapter   = createSmsGatewaysAdapter();

export const adaptSmsGateways = (rawData) => {
	smsGatewaysListAdapter.clearExcludes();
	const adaptedData = smsGatewaysListAdapter.adaptList(rawData);

	return adaptedData.reduce((acc, item) => {
		acc[item.id] = item;
		return acc;
	}, {});

};

export const adaptDataSmsGateway = (rawData) => {
	smsGatewaysListAdapter.clearExcludes();
	const adaptedData = smsGatewaysListAdapter.adapt(rawData);

	return adaptedData;
};


// send Test Message

export const createSmsGatewaysTestMessageAdapter = () => {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	adapter.addField(rules.string,  'name', fields.name);
	adapter.addField(rules.string,  'url', fields.url);
	adapter.addField(rules.string,  'sender', fields.sender);
	adapter.addField(rules.string,  'login', fields.login);
	adapter.addField(rules.string,  'password', fields.password);
	adapter.addField(rules.string,  'userAgent', fields.userAgent);
	adapter.addField(rules.string,  'content', fields.content);
	adapter.addField(rules.string,  'number', fields.number);
	adapter.addField(rules.string,  'postData', fields.postData);
	adapter.addField(rules.string,  'token', fields.token);

	return adapter;
};

export  const prepareSmsGatewaysTest = (sendData = {}) => {
	const listAdapter = createSmsGatewaysTestMessageAdapter();
	return listAdapter.prepare(sendData);
};
