import { IRiskBetsItem, IRiskMarmketIDUpdate } from './types';

const actionsSportRiskList = {
	SPORT_RISK_BETS_LIST_RELOAD        : 'SPORT_RISK_BETS_LIST_RELOAD',
	SPORT_RISK_BETS_SOCKET_UPDATE_LIST : 'SPORT_RISK_BETS_SOCKET_UPDATE_LIST',
	SPORT_RISK_BETS_SOCKET_REFRESH_LIST: 'SPORT_RISK_BETS_SOCKET_REFRESH_LIST',

	SPORT_RISK_BETS_SOCKET_MARKET_IDS_UPDATE_LIST: 'SPORT_RISK_BETS_SOCKET_MARKET_IDS_UPDATE_LIST',
	SPORT_RISK_SOCKET_UPDATE_SELECTION_IDS_LIST  : 'SPORT_RISK_SOCKET_UPDATE_SELECTION_IDS_LIST',

	listReload: () => ({
		type: actionsSportRiskList.SPORT_RISK_BETS_LIST_RELOAD,
	}),

	socketUpdateData: (data: IRiskBetsItem) => ({
		type: actionsSportRiskList.SPORT_RISK_BETS_SOCKET_UPDATE_LIST,
		data,
	}),

	socketUpdateMarketIDs: (data: IRiskMarmketIDUpdate) => ({
		type: actionsSportRiskList.SPORT_RISK_BETS_SOCKET_MARKET_IDS_UPDATE_LIST,
		data,
	}),

	socketRefreshList: (data: IRiskBetsItem) => ({
		type: actionsSportRiskList.SPORT_RISK_BETS_SOCKET_REFRESH_LIST,
		data,
	}),

	socketUpdateSelectionIDs: (data: IRiskMarmketIDUpdate) => ({
		type: actionsSportRiskList.SPORT_RISK_SOCKET_UPDATE_SELECTION_IDS_LIST,
		data,
	}),
};

export default actionsSportRiskList;
