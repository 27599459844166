import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Wrapper } from './SelectAll.style';

const SelectAll = ({ name, selected }) => {

	const className = classnames({ selected });

	return (
		<Wrapper className={className}>
			{name}
		</Wrapper>
	);
};

SelectAll.propTypes = {
	name    : PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	selected: PropTypes.bool.isRequired,
};

export default SelectAll;
