import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Collapse } from 'antd';
import tagsAction from '../../../../../../redux/casino/tags/actions';

import { deriveCasinoTagsList } from '../../../../../../selectors/casino';
import CheckAll from '../Components/Checkbox';

import GamesList from '../Components/GamesList';
import { Label } from './components';

const { Panel } = Collapse;

class TabTags extends Component {

	static propTypes = {
		tagsList      : PropTypes.array.isRequired,
		tagsListReload: PropTypes.func.isRequired,
		uiRefresh     : PropTypes.func.isRequired,
		dataRefresh   : PropTypes.func.isRequired,
		bonusData     : PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);

		this.renderProviders  = this.renderProviders.bind(this);
	}

	componentDidMount() {
		const { tagsListReload, bonusData, singleGameMode } = this.props;
		const { currencyID,typeID } = bonusData;
		const type = singleGameMode && typeID;
		tagsListReload(currencyID, type);
	}

	// Renders --------------------------------------------------------------------------------------

	renderProviders() {
		const { tagsList, uiRefresh, dataRefresh, bonusData, readOnly, singleGameMode } = this.props;
		return tagsList.map(tag => {
			return (
				<Panel
					header={tag.name}
					key={tag.id}
					extra = {!singleGameMode && (
						<Label>
							<CheckAll
								disabled={readOnly}
								checkAllInto={readOnly} // TODO NOT true implementation
								data = {tag}
								uiRefresh = {uiRefresh}
								dataRefresh = {dataRefresh}
								bonusData   = {bonusData}
								sourceID = {tag.id}
								isTagPage
							/>
						</Label>
					)}
				>
					<GamesList
						data = {tag}
						uiRefresh = {uiRefresh}
						dataRefresh = {dataRefresh}
						bonusData   = {bonusData}
						sourceID = {tag.id}
						isTagPage
						singleGameMode={singleGameMode}
					/>
				</Panel>
			);
		});
	}

	render() {
		const providerPanels = this.renderProviders();
		return (
			<Collapse>
				{providerPanels}
			</Collapse>
		);
	}
}

export default connect(state => {
	return ({
		tagsList: deriveCasinoTagsList(state),
	});
}, {
	tagsListReload: tagsAction.listReload,
})(TabTags);
