import { EBonusTemplateTypeIDs } from './types';

const actions = {
	BONUS_TEMPLATES_RELOAD: 'BONUS_TEMPLATES_RELOAD',
	BONUS_TEMPLATE_GET    : 'BONUS_TEMPLATE_GET',
	BONUS_TEMPLATES_SAVE  : 'BONUS_TEMPLATES_SAVE',

	BONUS_TEMPLATE_ARCHIVE: 'BONUS_TEMPLATE_ARCHIVE',

	listReload: () => ({
		type: actions.BONUS_TEMPLATES_RELOAD,
	}),

	getTemplate: (id: number) => ({
		type: actions.BONUS_TEMPLATE_GET,
		data: id,
	}),

	onSave: (typeID: EBonusTemplateTypeIDs, editMode: boolean) => ({
		type: actions.BONUS_TEMPLATES_SAVE,
		data: {
			typeID,
			editMode,
		},
	}),

	archiveTemplate: (id: number) => ({
		type: actions.BONUS_TEMPLATE_ARCHIVE,
		data: id,
	}),
};


export { actions as bonusTemplatesActions };