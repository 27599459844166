import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { fill } from '../../../../helpers/utils';
import { CASINO_GAMES_CATEGORY, CHANNEL_TYPES, CASINO_GAMES_GRID_MODE } from '../../../../helpers/commonConstants';
import actions from './actions';
import { createNewGamesList, createNewLayouts, createSearchTargetGrid } from './utils';

const initState = new Map({

	defaultGrid : [],
	gamesList   : [],
	categoryList: [],

	searchResultGames: [],
	searchTargetGames: [],
	
	gridLayouts: {
		[CASINO_GAMES_CATEGORY.all]: [], // 0 - is an ID for category 'Popular (all)' - predefined game category
	},

	changedLayouts: [],

	draftGames  		: [],
	draftGamesList: [],

	draftUI: {
		dragGameID: null,
		isOpen    : false,
	},
	UI: {
		loading        : false,
		channelID      : CHANNEL_TYPES.web,
		categoryID     : CASINO_GAMES_CATEGORY.all,
		gridMode       : CASINO_GAMES_GRID_MODE.byDefault,
		isGridsChanged : false,
		gridLoaded     : false,
		gridInitialized: false,
		visible        : false,

		casinoGameID      : null,
		casinoGameName    : '',
		closeModal        : false,
		langID            : null,
		isBaseChanged     : false,
		isNamesChanged    : false,
		tags              : [],
		page              : 1,
		hasMore           : false,
		pageLoading       : false,
		lastPart          : false,
		totalCount        : 0,
		lastPageExists    : false,
		lastPageLoading   : false,
		lastGridPageCount : 0,
		overallPageCount  : 0,
		overallGridPageNo : 0,
		showLastPage      : false,
		actualGamesCount  : 0,
		searchGamesVisible: false,
		isSearchChanged   : false,
		searchLoad        : false,
		targetKeys        : [],
	},
});

export default function gamesGridsReducer(state = initState, action) {

	switch (action.type) {

		case actions.GAMES_GRIDS_DEFAULT_GRID_REFRESH: {
			const { defaultGrid } = action.data;
			return state.set('defaultGrid', defaultGrid);
		}

		case actions.GAMES_GRIDS_GAMES_LIST_SET: {
			return state.set('gamesList', action.data);
		}

		case actions.GAMES_GRIDS_GAMES_LIST_MERGE: {
			const { gamesList } = action.data;
			const existingList = state.get('gamesList');
			const result = uniqBy([...existingList, ...gamesList], 'id');

			return state.set('gamesList', result);
		}

		case actions.GAMES_GRIDS_GAMES_LIST_CLEAR: {
			return state.set('gamesList', []);
		}

		case actions.GAMES_GRIDS_CATEGORY_LIST_REFRESH: {
			const { categoryList } = action.data;
			return state.set('categoryList', categoryList);
		}

		case actions.GAMES_DRAFT_LIST_REFRESH: {
			return state.set('draftGames', action.data);
		}


		case actions.GAMES_SEARCH_RESULT_LIST_REFRESH: {
			const searchResultGames = state.get('searchResultGames');
			const targetKeys		= state.get('UI').targetKeys;
			const filterdList		= createNewGamesList(searchResultGames, targetKeys);
			const newSearchResult	= uniqBy([...action.data, ...filterdList], 'id');
			return state.set('searchResultGames', newSearchResult);
		}

		case actions.GAMES_SEARCH_RESULT_LIST_RESET: {
			return state.set('searchResultGames', []);
		}
		case actions.GAMES_SEARCH_TARGET_LIST_RESET: {
			return state.set('searchTargetGames', []);
		}
		case actions.GAMES_SEARCH_TARGET_TO_DRAFT: {
			const searchTargetGames					= state.get('searchTargetGames');
			const draftGamesList					= state.get('draftGamesList');
			const draftGridList						= state.get('draftGames');
			const gridLayouts       				= state.get('gridLayouts');
			const gamesList							= state.get('gamesList');
			const searchTargetGrid					= createSearchTargetGrid(searchTargetGames);
			const { newGridLayout, newGridGames }	= createNewLayouts(searchTargetGames, gridLayouts[action.categoryID], gamesList);
			gridLayouts[action.categoryID] = newGridLayout;

			return state
				.set('draftGamesList', uniqBy([...searchTargetGames, ...draftGamesList], 'id'))
				.set('draftGames', uniqBy([...searchTargetGrid, ...draftGridList], 'i'))
				.set('gridLayouts', gridLayouts)
				.set('gamesList', newGridGames)
				.set('searchTargetGames', []);
		}
		
		case actions.GAMES_SEARCH_TARGET_LIST_REFRESH: {
			const searchTargetGames = cloneDeep(state.get('searchTargetGames'));
			searchTargetGames.push(action.data);
			return state.set('searchTargetGames', searchTargetGames)
				.setIn(['UI', 'isSearchChanged'], true);
		}
		case actions.GAMES_SEARCH_TARGET_LIST_SET: {
			return state.set('searchTargetGames', action.data)
				.setIn(['UI', 'isSearchChanged'], true);
		}

		case actions.GAMES_GRIDS_DRAFT_LIST_REFRESH: {
			const targetDraftGames = cloneDeep(state.get('draftGames'));
			targetDraftGames.unshift(action.data);
			return state.set('draftGames', targetDraftGames);
		}

		case actions.GAMES_DRAFT_LIST_ENTITIES_REFRESH: {
			const targetDraftGames = cloneDeep(state.get('draftGamesList'));
			targetDraftGames.push(action.data);
			return state.set('draftGamesList', targetDraftGames);
		}

		case actions.GAMES_GRIDS_GRID_LAYOUT_SET: {
			const { categoryID, gridLayout } = action.data;
			const gridLayouts       = cloneDeep(state.get('gridLayouts'));
			gridLayouts[categoryID] = gridLayout;

			return state.set('gridLayouts', gridLayouts);
		}

		case actions.GAMES_GRIDS_GRID_LAYOUTS_SET: {
			const { gridLayouts } = action.data;
			return state.set('gridLayouts', gridLayouts);
		}

		case actions.GAMES_GRIDS_GRID_LAYOUTS_CLEAR: {
			const gridLayouts = state.get('gridLayouts');
			const result = {
				[CASINO_GAMES_CATEGORY.all]: gridLayouts[CASINO_GAMES_CATEGORY.all],
			};

			return state.set('gridLayouts', result);
		}

		case actions.GAMES_GRIDS_UI_REFRESH: {
			const target = state.get('UI');
			const result = fill(action.data, target, true);
			return state.set('UI', result);
		}

		case actions.GAMES_GRIDS_DRAFT_UI_REFRESH: {
			const target = state.get('draftUI');
			const result = fill(action.data, target, true);
			return state.set('draftUI', result);
		}

		case actions.GAMES_GRIDS_ADD_TO_CHANGED_LAYOUTS: {
			const { categoryID } = action.data;
			const changedLayouts = state.get('changedLayouts');
			if (changedLayouts.includes(categoryID)) {
				return state.set('changedLayouts', changedLayouts);
			}

			changedLayouts.push(categoryID);
			return state.set('changedLayouts', cloneDeep(changedLayouts));
		}

		case actions.GAMES_GRIDS_CLEAR_CHANGED_LAYOUTS: {
			return state.set('changedLayouts', []);
		}

		case actions.REMOVE_LAST_PAGE_DATA: {
			const categoryID = action.data.categoryID;
			if (categoryID === CASINO_GAMES_CATEGORY.all) {
				return state;
			}
			const filteredGamesList = state.get('gamesList').filter(item => !item.lastPage);
			const layout = state.get('gridLayouts')[categoryID].filter(item => filteredGamesList.some(game => String(game.id) === item.i));
			return state.set('gamesList', filteredGamesList)
				.setIn(['gridLayouts', String(categoryID)], layout)
				.setIn(['UI', 'lastPageExists'], false)
				.setIn(['UI', 'lastGridPageCount'], 0);
		}

		case actions.DATA_RESET: {
			return initState;
		}

		default:
			return state;
	}
}
