import styled from 'styled-components';


export const RowWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  width: max-content!important;
`;

export const RowContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding-bottom: 12px;
  cursor: pointer;
  width: max-content!important;
`;
