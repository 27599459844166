import React from 'react';
import PropTypes from 'prop-types';

import RadioBox, { RadioGroup } from '../../../../../../components/uielements/radio';
import { Wrapper } from './BetRadios.style';

const BetRadios = ({ bets, betID, onChange, readOnly }) => {

	const radios = bets.map(bet => {
		const name = `Bet per line: ${bet.betPerLine}, lines: ${bet.lines}`;
		const checked = (bet.betID === betID);

		return (
			<RadioBox
				key={bet.betID}
				value={bet.betID}
				checked={checked}
				disabled={readOnly}
			>
				{name}
			</RadioBox>
		);
	});

	return (
		<Wrapper>
			<RadioGroup
				disabled={readOnly}
				value={betID}
				onChange={({ target }) => onChange(target.value)}
			>
				{radios}
			</RadioGroup>
		</Wrapper>
	);
};

BetRadios.propTypes = {
	bets    : PropTypes.array.isRequired,
	betID   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
};

BetRadios.defaultProps = {
	betID: null,
};

export default BetRadios;
