import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormControl from '../../uielements/FormControl';
import Input from '../../uielements/input';

const validationPatterns = {
	onlyNumbers	: /^[0-9]+$/,
	onlyDots    : /^\d*[.]?\d*$/,
	onlyMinus			: /^-?\d*$/,
	minusAndDots: /^-?\d*[.]?\d*$/,
};
class FormInputNumberTextType extends Component {

	static propTypes = {
		validationParams: PropTypes.object,
		onChange        : PropTypes.func.isRequired,
		label           : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth      : PropTypes.number,
		height          : PropTypes.string,
		name            : PropTypes.string,
		value           : PropTypes.string,
	};

	static defaultProps = {
		label           : '',
		labelWidth      : 25, // in percent
		height          : '36px',
		name            : '',
		value           : '',
		validationParams: null,
	};

	constructor(props) {
		super(props);
		this.onHandleChange   = this.onHandleChange.bind(this);
		this.onInputKeyPress  = this.onInputKeyPress.bind(this);
	}

	onHandleChange({ target : { value } }) {
		const { validationParams, onChange, name } = this.props;
		let pattern;

		if (!validationParams) {
			pattern = validationPatterns.onlyNumbers;
		} else if (validationParams.withMinusAndDots) {
			pattern =  validationPatterns.minusAndDots;
		} else if (validationParams.withMinus) {
			pattern = validationPatterns.onlyMinus;
		} else if (validationParams.withDots) {
			pattern =  validationPatterns.onlyDots;
		}

		if (value === '' || pattern.test(value)) {
			onChange(name,  value);
		}
	}

	onInputKeyPress(e) {
		const { onChange, name, value } = this.props;
		if (e.keyCode === 8 || e.keyCode === 46) onChange(name,  value);
	}

	render() {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { label, labelWidth, height, name, onChange, ...restProps } = this.props;
		const isLabel = (Boolean(labelWidth) || labelWidth === 0);
		const lw = isLabel ? labelWidth : 25;
		const mb = (labelWidth === 0) ? 0 : undefined;
		const h  = height ? { height } : {};


		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				{...h}
				control={(
					<Input
						name={name}
						onChange={this.onHandleChange}
						onKeyDown={this.onInputKeyPress}
						{...restProps}
					/>
				)}
			/>
		);
	}
}

export default FormInputNumberTextType;
