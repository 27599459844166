import React from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import FormSelect from '../../../components/FormControls/FormSelect';
import Utils from '../FilterUtils';

const fullWidth = { width: '100%' };

const Select = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, placeholder, defaultValue, disabled, optionList, widthLabel, widthInput } = filterItem;
	return (
		<FormSelect
			name={name}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel, widthLabel)}
			optionsList={optionList || []}
			placeholder={placeholder}
			defaultValue={defaultValue || null}
			value={value}
			style={fullWidth}
			disabled={disabled}
			wrapperID={uuid()}
			onChange={value => onChange(name, value)}
			widthInput={widthInput}
		/>
	);
};

Select.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default Select;
