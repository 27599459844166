import React from 'react';
import IntlMessage from '../../../../components/utility/intlMessages';

export const lang = {
	titleFreeSpin: <IntlMessage id = "userBonus.title.freeSpin" />,
	titleFreeBet : <IntlMessage id = "userBonus.title.freeBet" />,
	bonusType    : <IntlMessage id = "userBonus.bonusType" />,

	usedTemplate      : <IntlMessage id = "userBonus.freeSpin.usedTemplate"/>,
	name           	  : <IntlMessage id = "userBonus.freeSpin.name" />,
	spinType          : <IntlMessage id = "userBonus.freeSpin.type" />,
	betAmount         : <IntlMessage id = "userBonus.freeSpin.betAmount" />,
	spinsTotal        : <IntlMessage id = "userBonus.freeSpin.spinsTotal" />,
	casinoGame        : <IntlMessage id = "userBonus.freeSpin.casinoGame" />,
	limits            : <IntlMessage id = "userBonus.freeSpin.limits" />,
	bets              : <IntlMessage id = "userBonus.freeSpin.bets" />,
	denomination      : <IntlMessage id = "userBonus.freeSpin.denomination" />,
	daysToExpireBonus : <IntlMessage id = "userBonus.freeSpin.daysToExpireBonus" />,
	daysToExpireWallet: <IntlMessage id = "userBonus.freeSpin.daysToExpireWallet" />,
	wageringTurnover  : <IntlMessage id = "userBonus.freeSpin.wageringTurnover" />,
	noteForAdmin      : <IntlMessage id = "userBonus.freeSpin.noteForAdmin" />,
	currency          : <IntlMessage id = "common.currency" />,

	lines               : <IntlMessage id = "userBonus.freeSpin.lines" />,
	betPerLine          : <IntlMessage id = "userBonus.freeSpin.betPerLine" />,
	minPerLineAndMinLine: <IntlMessage id = "userBonus.freeSpin.minPerLineAndMinLine" />,

	market            : <IntlMessage id = "userBonus.freeBet.market" />,
	maxBonusAmount    : <IntlMessage id = "userBonus.freeBet.maxBonusAmount" />,
	minWageringOdd    : <IntlMessage id = "userBonus.freeBet.minWageringOdd" />,
	sport             : <IntlMessage id = "userBonus.freeBet.sport" />,
	tradingMode       : <IntlMessage id = "userBonus.freeBet.tradingMode" />,
	forced            : <IntlMessage id = "bonuses.forced" />,
	eligibility       : <IntlMessage id = "bonuses.bonuses.eligibility" />,
	tabMain           : <IntlMessage id = "bonuses.bonuses.tabMain.titile" />,
	logo              : <IntlMessage id = "bonuses.logo" />,
	title             : <IntlMessage id = "bonuses.tab.title" />,
	affiliateReference: <IntlMessage id = "bonuses.bonuses.affiliateReference" />,
	overlap           : <IntlMessage id = "bonuses.overlap" />,
	manualBonus       : <IntlMessage id = "userBonus.types.bonusMoney" />,

	givenDate : <IntlMessage id = "userBonus.freeSpin.givenDate" />,
	claimDate : <IntlMessage id = "userBonus.freeSpin.claimDate" />,
	expireDate: <IntlMessage id = "userBonus.freeSpin.expireDate" />,
	leftSpins : <IntlMessage id = "userBonus.freeSpin.leftSpins" />,
	statusID  : <IntlMessage id = "userBonus.freeSpin.status" />,
	wonAmount : <IntlMessage id = "userBonus.freeSpin.wonAmount" />,

	createBonus: <IntlMessage id="common.createBonus" />,
};
