import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './ResizablePanels.style';

class ResizablePanels extends Component {
	eventHandler = null;

	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.arrayOf(PropTypes.array),
		]).isRequired,
		panels: PropTypes.array.isRequired,
		resize: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isDragging: false,
		};
	}

	componentDidMount() {
		document.addEventListener('mousemove', this.resizePanel);
		document.addEventListener('mouseup', this.stopResize);
		document.addEventListener('mouseleave', this.stopResize);
	}

	startResize = (event, index) => {
		event.preventDefault();
		this.setState({
			isDragging  : true,
			currentPanel: index,
			initialPos  : event.clientX,
		});
	};

	stopResize = () => {
		const { resize, panels } = this.props;
		const { isDragging, currentPanel, delta } = this.state;
		if (isDragging) {
			resize(currentPanel - 1, Number(panels[currentPanel - 1] || 0) + delta);
			this.setState({
				isDragging  : false,
				delta       : 0,
				currentPanel: null,
			});
		}
	};

	resizePanel = (event) => {
		const { isDragging, initialPos } = this.state;
		if (isDragging) {
			const delta = event.clientX - initialPos;
			this.setState({ delta });
		}
	};

	render() {
		const { children, panels } = this.props;
		const { currentPanel, delta } = this.state;
		const rest = children.slice(1);
		return (
			<Wrapper className="panel-container" onMouseUp={() => this.stopResize()}>
				<div className="panel" style={{ width: `${panels[0]}px` }}>
					{children[0]}
				</div>
				{[].concat(...rest.map((child) => {
					const i = rest.indexOf(child);
					return [
						<div
							onMouseDown={(e) => this.startResize(e, i + 1)}
							key={`resizer_${i}`}
							style={currentPanel === i + 1 ? { left: delta } : {}}
							className="resizer"
						/>,
						<div key={`panel_${i}`} className="panel" style={{ width: `${panels[i + 1]}px` }}>
							{child}
						</div>,
					];
				}))}
			</Wrapper>
		);
	}
}

export default ResizablePanels;
