import { IDataSaveDeposit } from './types';

const manualDepositActions = {
	MANUAL_DEPOSIT_BALANCE_SAVE: 'MANUAL_DEPOSIT_BALANCE_SAVE',
	RELOAD_DEPOSIT_METHODS     : 'RELOAD_DEPOSIT_METHODS',

	saveManualDeposit: (data:IDataSaveDeposit) => ({
		type: manualDepositActions.MANUAL_DEPOSIT_BALANCE_SAVE,
		data,
	}),

	reloadDepositMethods: () => ({
		type: manualDepositActions.RELOAD_DEPOSIT_METHODS,
	}),
};

export default manualDepositActions;
