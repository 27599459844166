import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FormControl from '../../uielements/FormControl';
import Checkbox from '../../uielements/checkbox';

import { ExtraContainer } from './FormCheckbox.style';

class FormCheckbox extends Component {

	static propTypes = {
		label       : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		labelWidth  : PropTypes.number,
		height      : PropTypes.string,
		extraContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		dataMarketID: PropTypes.number,
	};

	static defaultProps = {
		label       : '',
		labelWidth  : 25, // in percent
		height      : '36px',
		extraContent: '',
		dataMarketID: null,
	};

	render() {
		const { label, labelWidth, height, extraContent, dataMarketID, labelRight, ...restProps } = this.props;

		const lw = (Boolean(labelWidth) || labelWidth === 0) ? labelWidth : 25;
		const h  = height ? { height }     : {};
		const mb = (labelWidth === 0) ? 0 : undefined;

		const control = extraContent
			? (
				<Fragment>
					<Checkbox {...restProps} />
					<ExtraContainer>
						{extraContent}
					</ExtraContainer>
				</Fragment>
			)
			: (<Checkbox data-marketid={dataMarketID} {...restProps} />);

		return (
			<FormControl
				label={label}
				labelWidth={lw}
				marginBottom={mb}
				labelRight={labelRight}
				{...h}
				control={control}
			/>
		);
	}
}

export default FormCheckbox;
