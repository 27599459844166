import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Pagination } from 'antd';
import { RightCol, LeftCol } from './PaginationRow.style';

class PaginationRow extends Component {

	static propTypes = {
		preContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	};

	static defaultProps = {
		preContent: '',
	};

	render() {
		const { preContent, ...restProps } = this.props;

		if (preContent) {
			return (
				<Row>
					<LeftCol span={12}>{preContent}</LeftCol>
					<RightCol span={12}>
						<Pagination {...restProps} />
					</RightCol>
				</Row>
			);
		}

		return (
			<Row>
				<RightCol span={24}>
					<Pagination {...restProps} />
				</RightCol>
			</Row>
		);
	}
}

export default PaginationRow;
