import React from 'react';
import * as PropTypes from 'prop-types';

import FormControl from '../../../components/uielements/FormControl';
import FormAutoComplete from '../../../components/uielements/autocomplete';
import Utils from '../FilterUtils';

const AutoComplete = ({ filterItem, onChange }) => {
	const { name, label, showLabel, placeholder, defaultValue, disabled, dataSource, dataId, widthLabel, widthInput, value } = filterItem;
	const control = (
		<FormAutoComplete
			data-qa-id = {dataId}
			placeholder={placeholder || ''}
			defaultValue={defaultValue || null}
			name={name}
			dataSource={dataSource}
			disabled={disabled}
			filterOption={Utils.onFilterOptios}
			onChange={value => onChange(name, value)}
			value={value}
		/>
	);

	return (
		<FormControl
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel, widthLabel)}
			control={control}
			widthInput = {widthInput}
		/>
	);
};

AutoComplete.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default AutoComplete;
