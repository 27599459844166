const actions = {

	IMAGES_MULTIPLE_INIT      : 'IMAGES_MULTIPLE_INIT',
	IMAGES_MULTIPLE_UI_REFRESH: 'IMAGES_MULTIPLE_UI_REFRESH',

	IMAGES_MULTIPLE_SET_FILES   : 'IMAGES_MULTIPLE_SET_FILES',
	IMAGES_MULTIPLE_UPLOAD_FILES: 'IMAGES_MULTIPLE_UPLOAD_FILES',

	IMAGES_MULTIPLE_UPLOAD_FILES_SUCCESS: 'IMAGES_MULTIPLE_UPLOAD_FILES_SUCCESS',
	IMAGES_MULTIPLE_UPLOAD_FILES_ERROR  : 'IMAGES_MULTIPLE_UPLOAD_FILES_ERROR',

	init: (id, files) => ({
		type: actions.IMAGES_MULTIPLE_INIT,
		data: {
			id,
			files,
		},
	}),
	setFiles: (id, files) => ({
		type: actions.IMAGES_MULTIPLE_SET_FILES,
		data: {
			id,
			files,
		},
	}),
	uploadFiles: (id, files, cbSuccess = null, cbError = null, onProgress = null, minImageHeight = null, minImageWidth = null, profileAvatar = null) => ({
		type: actions.IMAGES_MULTIPLE_UPLOAD_FILES,
		data: {
			id,
			files,
			cbSuccess,
			cbError,
			onProgress,
			minImageHeight,
			minImageWidth,
			profileAvatar,
		},
	}),
	uploadFilesSuccess: (id, files) => ({
		type: actions.IMAGES_MULTIPLE_UPLOAD_FILES_SUCCESS,
		data: {
			id,
			files,
		},
	}),
	uploadFilesError: (id) => ({
		type: actions.IMAGES_MULTIPLE_UPLOAD_FILES_ERROR,
		data: {
			id,
		},
	}),

	uiRefresh: (id, uiData) => ({
		type: actions.IMAGES_MULTIPLE_UI_REFRESH,
		data: {
			id,
			uiData,
		},
	}),

};

export default actions;
