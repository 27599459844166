import { FC, Suspense, lazy } from 'react';
import { Routes } from './routesData';
import MainLayout from '../layouts/MainLayout/MainLayout';
import Spinner from '../components/Spinner/Spinner';
import { DashboardContainer } from '../layouts/DashboardContainer';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
const NotFound = lazy(() => import('../containers/Page/404'));

const ServerError = lazy(() => import('../containers/Page/forgotPassword'));
const ResetPass = lazy(() => import('../containers/Page/resetPassword'));
const SetPartnerPass = lazy(() => import('../containers/Page/setPartnerPassword'));
const SignIn = lazy(() => import('../containers/Page/signin'));
const SignUp = lazy(() => import('../containers/Page/signup'));
const PartnerPassHash = lazy(() => import('../containers/Page/setPartnerPassword'));
const PartnersWebsiteInner = lazy(() => import('../containers/Partner/Websites/Inner'));
const PartnersWebsite = lazy(() => import('../containers/Partner/Websites/List'));
const PartnersPartners = lazy(() => import('../containers/Partner/Partners'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const RTMBets = lazy(() => import('../containers/RealTimeMonitor/Bets'));
const RTMDeposits = lazy(() => import('../containers/RealTimeMonitor/Deposits'));
const RTMCasino = lazy(() => import('../containers/RealTimeMonitor/Casino'));
const RTMWithdrawal = lazy(() => import('../containers/RealTimeMonitor/Withdrawals'));
const RTMDocuments = lazy(() => import('../containers/RealTimeMonitor/Documents'));
const RiskSportBets = lazy(() => import('../containers/SportRiskManagement/SportBets/RiskSportBets'));
const RTMRisk = lazy(() => import('../containers/RealTimeMonitor/RiskManagement'));
const StaffPersonnel = lazy(() => import('../containers/StaffManagement/List'));
const PersonnelInner = lazy(() => import('../containers/CustomerManagement/UserInfo'));
const StaffRoles = lazy(() => import('../containers/StaffManagement/Roles'));
const UsersList = lazy(() => import('../containers/CustomerManagement'));
const UserInner = lazy(() => import('../containers/CustomerManagement/UserInfo'));
const UserGroups = lazy(() => import('../containers/CustomerManagement/UserGroups'));
const Bonuses = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/Bonuses'));
const ConditionalBonusDetails = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/Bonuses/BonusDetails'));
const BonusTemplates = lazy(() => import('../containers/LoyaltySystem/BonusTemplates'));
const TemplateDetails = lazy(() => import('../containers/LoyaltySystem/BonusTemplates/TemplateDetails'));
const FreeSpins = lazy(() => import('../containers/LoyaltySystem/BonusinhHistory/FreeSpins'));
const FreeBets = lazy(() => import('../containers/LoyaltySystem/BonusinhHistory/FreeBets'));
const MassBonus = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/MassBonus'));
const MassBonusCreate = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/MassBonus/CreateMassBonus'));
const WelcomeBonus = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/WelcomeBonus'));
const WelcomeBonusDetails = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/WelcomeBonus/BonusDetails'));
const CashbackUsers = lazy(() => import('../containers/LoyaltySystem/BonusinhHistory/CashbackUsers'));
const CasinoCashback = lazy(() => import('../containers/LoyaltySystem/BonusingSystem/CasinoCashback'));
const Tournaments = lazy(() => import('../containers/LoyaltySystem/Tournaments')); // commented in stage  (dev)
const CasinoList = lazy(() => import('../containers/Casino/List'));
const GameCategories = lazy(() => import('../containers/Casino/GameCategories'));
const CustomProviders = lazy(() => import('../containers/Casino/CustomProviders'));
const CasinoGames = lazy(() => import('../containers/Casino/Games'));
const CasinoIntegrators = lazy(() => import('../containers/Casino/Integrators'));
const SportCategories = lazy(() => import('../containers/Sport/Categories/Sports/Tree'));
const SportEvents = lazy(() => import('../containers/Sport/Events'));
const EventDetails = lazy(() => import('../containers/Sport/Events/EventDetails'));
const SportResults = lazy(() => import('../containers/Sport/Results'));
const ResultDetails = lazy(() => import('../containers/Sport/Results/ResultDetails'));
const SportLiveStatisticTypes = lazy(() => import('../containers/Sport/LiveStatisticTypes'));
const SportMarketTemplate = lazy(() => import('../containers/Sport/Markets'));
const SportMarketsGroups = lazy(() => import('../containers/Sport/Markets/MarketsGroups'));
const SportMarketsStatisticTypes = lazy(() => import('../containers/Sport/Markets/StatisticTypes/StatisticTypesList'));
const SportMarketsDisplayTypes = lazy(() => import('../containers/Sport/Markets/MarketsDisplayTypes'));
const SportParticipants = lazy(() => import('../containers/Sport/Participants'));
const SportParticipantsAges = lazy(() => import('../containers/Sport/Participants/ParticipantAges'));
const SportBettingRules = lazy(() => import('../containers/Sport/Settings/BettingRules/List'));
const SportScopes = lazy(() => import('../containers/Sport/Settings/Periods/List'));
const Ladders = lazy(() => import('../containers/Sport/Markets/MarketsLadders'));
const SidebarEditor = lazy(() => import('../containers/Settings/SidebarEditor'));
const SMSGateways = lazy(() => import('../containers/Settings/SMS/SMSGateways'));
const SMSTemplates = lazy(() => import('../containers/Settings/SMS/SMSTemplates'));
const Currencies = lazy(() => import('../containers/Settings/Currencies/List'));
const PaymentsList = lazy(() => import('../containers/Settings/Payments/List'));
const SecurityQuestions = lazy(() => import('../containers/Settings/Security/Questions/List'));
const BlockedIps = lazy(() => import('../containers/Settings/Security/BlockedIps'));
const Whitelist = lazy(() => import('../containers/Settings/Security/Whitelist'));
const CmsHeader = lazy(() => import('../containers/Cms/Menu/Header'));
const CmsFooter = lazy(() => import('../containers/Cms/Menu/Footer'));
const CmsArticles = lazy(() => import('../containers/Cms/Menu/Articles'));
const CmsSportsPriorities = lazy(() => import('../containers/Cms/LandingPage/SportsPriorities'));
const CmsSpecialOffers = lazy(() => import('../containers/Cms/LandingPage/SpecialOffers'));
const CmsSpecialOffer = lazy(() => import('../containers/Cms/LandingPage/SpecialOffers/Offers'));
const CmsUpcomingEvents = lazy(() => import('../containers/Cms/LandingPage/UpcomingEvents'));
const CmsMatchesOfDay = lazy(() => import('../containers/Cms/LandingPage/MatchesOfDay'));
const CmsGameGrid = lazy(() => import('../containers/Cms/LandingPage/GameGrid'));
const CmsInfoPages = lazy(() => import('../containers/Cms/Pages/InfoPages/InfoPagesList'));
const CmsInfoPage = lazy(() => import('../containers/Cms/Pages/InfoPages/InfoPage'));
const CmsNewsList = lazy(() => import('../containers/Cms/Pages/News/NewsList'));
const NewsArticle = lazy(() => import('../containers/Cms/Pages/News/NewsArticle'));
const CmsPromotionList = lazy(() => import('../containers/Cms/Pages/Promotions/PromotionList'));
const CmsPromotion = lazy(() => import('../containers/Cms/Pages/Promotions/PromotionPage'));
const CmsHelpCenter = lazy(() => import('../containers/Cms/Pages/HelpCenter'));
const CmsBannersList = lazy(() => import('../containers/Cms/Banners/List'));
const CmsBannersGroups = lazy(() => import('../containers/Cms/Banners/Groups/List'));
const SportsPriorities = lazy(() => import('../containers/Cms/LandingPage/SportsPriorities/Events'));
const ReportByPlayer = lazy(() => import('../containers/Reports/ReportByPlayer'));
const ReportByGame = lazy(() => import('../containers/Reports/ReportByGame'));
const ReportByProvider = lazy(() => import('../containers/Reports/ReportByProvider'));
const ReportByIntegrator = lazy(() => import('../containers/Reports/ReportByIntegrator'));
const Transactions = lazy(() => import('../containers/Reports/Transactions'));
const NotificationsPush = lazy(() => import('../containers/NotificationCenter/Notifications'));
const NotificationsMessages = lazy(() => import('../containers/NotificationCenter/Messages'));
const SMSListing = lazy(() => import('../containers/NotificationCenter/SMSListing/List'));
const EmailList = lazy(() => import('../containers/NotificationCenter/Emails/Emails/List'));
const EmailTemplates = lazy(() => import('../containers/NotificationCenter/Emails/EmailTemplates/List'));
const Affiliates = lazy(() => import('../containers/Affiliates'));

interface AsyncPrivateProps {
	route: Routes;
}

export const AsyncPublic: FC<AsyncPrivateProps> = (props) => {
	const { route } = props;

	switch (route) {
		case Routes.notFound: {
			return (
				<Suspense fallback={<Spinner size="medium" />}>
					<NotFound />
				</Suspense>
			);
		}
		case Routes.serverError: {
			return (
				<Suspense fallback={<Spinner size="medium" />}>
					<ServerError />
				</Suspense>
			);
		}
		case Routes.resetPassword: {
			return (
				<Suspense fallback={<Spinner size="medium" />}>
					<ResetPass />
				</Suspense>
			);
		}
		case Routes.setPartnerPassword: {
			return (
				<Suspense fallback={<Spinner size="medium" />}>
					<SetPartnerPass />
				</Suspense>
			);
		}
		case Routes.signup: {
			return (
				<Suspense fallback={<Spinner size="medium" />}>
					<SignUp />
				</Suspense>
			);
		}
		case Routes.signin: {
			return (
				<Suspense fallback={<Spinner size="medium" />}>
					<SignIn />
				</Suspense>
			);
		}
		case Routes.partnerHash: {
			return (
				<Suspense fallback={<Spinner size="medium" />}>
					<PartnerPassHash />
				</Suspense>
			);
		}

		default: {
			return <div />;
		}
	}
};

export const AsyncPrivate = (props) => {
	const { route } = props;
	const renderContent = (route) => {
		switch (route) {
			case Routes.dashboard: {
				return (
					<DashboardContainer >
						<Suspense fallback={<Spinner size="medium" />}>
							<Dashboard />
						</Suspense>
					</DashboardContainer>
				);
			}
			case Routes.partnersWebsiteInner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<PartnersWebsiteInner />
					</Suspense>
				);
			}
			case Routes.partnersWebsite: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<PartnersWebsite />
					</Suspense>
				);
			}
			case Routes.partnersPartner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<PartnersPartners />
					</Suspense>
				);
			}
			case Routes.rtmBets: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<RTMBets />
					</Suspense>
				);
			}
			case Routes.rtmDeposits: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<RTMDeposits />
					</Suspense>
				);
			}
			case Routes.rtmWithdrawals: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<RTMWithdrawal />
					</Suspense>
				);
			}
			case Routes.rtmCasino: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<RTMCasino />
					</Suspense>
				);
			}
			case Routes.rtmDocuments: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<RTMDocuments />
					</Suspense>
				);
			}
			case Routes.rtmRiskAlerts: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<RTMRisk />
					</Suspense>
				);
			}
			case Routes.riskManagementBets: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<RiskSportBets />
					</Suspense>
				);
			}
			case Routes.staffPersonnel: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<StaffPersonnel />
					</Suspense>
				);
			}
			case Routes.staffInner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<PersonnelInner />
					</Suspense>
				);
			}
			case Routes.staffRoles: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<StaffRoles />
					</Suspense>
				);
			}
			case Routes.usersList: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<UsersList />
					</Suspense>
				);
			}
			case Routes.userInner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<UserInner />
					</Suspense>
				);
			}
			case Routes.usersGroups: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<UserGroups />
					</Suspense>
				);
			}
			case Routes.bonuses: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<Bonuses />
					</Suspense>
				);
			}
			case Routes.conditionalBonusCreate: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<ConditionalBonusDetails />
					</Suspense>
				);
			}
			case Routes.conditionalBonusEdit: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<ConditionalBonusDetails />
					</Suspense>
				);
			}
			case Routes.welcomeBonusCreate: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<WelcomeBonusDetails />
					</Suspense>
				);
			}
			case Routes.welcomeBonusEdit: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<WelcomeBonusDetails />
					</Suspense>
				);
			}
			
			case Routes.bonusTemplates: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<BonusTemplates />
					</Suspense>
				);
			}
			case Routes.bonusTemplateCreate: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<TemplateDetails />
					</Suspense>
				);
			}
			case Routes.bonusTemplateDetails: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<TemplateDetails />
					</Suspense>
				);
			}


			case Routes.freeSpins: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<FreeSpins />
					</Suspense>
				);
			}
			case Routes.freeBets: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<FreeBets />
					</Suspense>
				);
			}
			case Routes.massBonus: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<MassBonus />
					</Suspense>
				);
			}
			case Routes.massBonusCreate: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<MassBonusCreate />
					</Suspense>
				);
			}
			case Routes.welcome: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<WelcomeBonus />
					</Suspense>
				);
			}
			case Routes.cashback: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CasinoCashback />
					</Suspense>
				);
			}
			case Routes.usersWithCashback: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CashbackUsers />
					</Suspense>
				);
			}
			case Routes.tournaments: { // commented in stage  (dev)
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<Tournaments />
					</Suspense>
				);
			}
			case Routes.casinoList: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CasinoList />
					</Suspense>
				);
			}
			case Routes.casinoGameCategories: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<GameCategories />
					</Suspense>
				);
			}
			case Routes.casinoCustomProviders: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CustomProviders />
					</Suspense>
				);
			}
			case Routes.casinoGames: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CasinoGames />
					</Suspense>
				);
			}
			case Routes.casinoIntegrators: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CasinoIntegrators />
					</Suspense>
				);
			}
			case Routes.sportCategories: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportCategories />
					</Suspense>
				);
			}
			case Routes.sportEvents: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportEvents />
					</Suspense>
				);
			}
			case Routes.sportEventsInner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<EventDetails />
					</Suspense>
				);
			}
			case Routes.sportResults: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportResults />
					</Suspense>
				);
			}
			case Routes.sportResultsInner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<ResultDetails />
					</Suspense>
				);
			}
			case Routes.sportLiveStatisticTypes: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportLiveStatisticTypes />
					</Suspense>
				);
			}
			case Routes.sportTemplates: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportMarketTemplate />
					</Suspense>
				);
			}
			case Routes.sportGroups: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportMarketsGroups />
					</Suspense>
				);
			}
			case Routes.sportMarketsStatisticTypes: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportMarketsStatisticTypes />
					</Suspense>
				);
			}
			case Routes.sportDisplayTypes: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportMarketsDisplayTypes />
					</Suspense>
				);
			}
			case Routes.sportParticipants: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportParticipants />
					</Suspense>
				);
			}
			case Routes.sportAges: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportParticipantsAges />
					</Suspense>
				);
			}
			case Routes.sportBettingRules: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportBettingRules />
					</Suspense>
				);
			}
			case Routes.sportScopes: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportScopes />
					</Suspense>
				);
			}
			case Routes.sportLadders: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<Ladders />
					</Suspense>
				);
			}
			case Routes.settingsEditor: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SidebarEditor />
					</Suspense>
				);
			}
			case Routes.settingsSmsGateway: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SMSGateways />
					</Suspense>
				);
			}
			case Routes.settingsTemplates: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SMSTemplates />
					</Suspense>
				);
			}
			case Routes.settingsCurrencies: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<Currencies />
					</Suspense>
				);
			}
			case Routes.settingsPayments: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<PaymentsList />
					</Suspense>
				);
			}

			case Routes.settingsQuestions: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SecurityQuestions />
					</Suspense>
				);
			}
			case Routes.settingsBlockedIPs: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<BlockedIps />
					</Suspense>
				);
			}
			case Routes.settingsWhitelist: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<Whitelist />
					</Suspense>
				);
			}
			case Routes.cmsHeader: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsHeader />
					</Suspense>
				);
			}
			case Routes.cmsFooter: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsFooter />
					</Suspense>
				);
			}
			case Routes.cmsArticles: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsArticles />
					</Suspense>
				);
			}
			case Routes.cmsPriorities: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsSportsPriorities />
					</Suspense>
				);
			}
			case Routes.cmsSpecialOffers: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsSpecialOffers />
					</Suspense>
				);
			}
			case Routes.cmsSpecialOffer: {
				return (
					<Suspense fallback={<Spinner size="large" />}>
						<CmsSpecialOffer />
					</Suspense>
				);
			}
			case Routes.cmsUpcoming: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsUpcomingEvents />
					</Suspense>
				);
			}
			case Routes.cmsMatchesOfDay: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsMatchesOfDay />
					</Suspense>
				);
			}
			case Routes.cmsLandingPageGameGrid: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsGameGrid />
					</Suspense>
				);
			}
			case Routes.cmsInfoPages: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsInfoPages />
					</Suspense>
				);
			}
			case Routes.cmsInfoPagesInner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsInfoPage />
					</Suspense>
				);
			}
			case Routes.cmsNews: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsNewsList />
					</Suspense>
				);
			}
			case Routes.newsArticlePage: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<NewsArticle />
					</Suspense>
				);
			}
			case Routes.cmsPromotions: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsPromotionList />
					</Suspense>
				);
			}
			case Routes.cmsPromotionsInner: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsPromotion />
					</Suspense>
				);
			}
			case Routes.cmsPromotionsAddNew: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsPromotion />
					</Suspense>
				);
			}
			case Routes.cmsHelpCenter: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsHelpCenter />
					</Suspense>
				);
			}
			case Routes.cmsBanners: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsBannersList />
					</Suspense>
				);
			}
			case Routes.cmsBannersGroup: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<CmsBannersGroups />
					</Suspense>
				);
			}
			case Routes.cmsSportPriorities: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SportsPriorities />
					</Suspense>
				);
			}
			case Routes.reportsByPlayer: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<ReportByPlayer />
					</Suspense>
				);
			}
			case Routes.reportsByGame: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<ReportByGame />
					</Suspense>
				);
			}
			case Routes.reportsByProvider: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<ReportByProvider />
					</Suspense>
				);
			}
			case Routes.reportsByIntegrator: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<ReportByIntegrator />
					</Suspense>
				);
			}
			case Routes.reportsTransactions: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<Transactions />
					</Suspense>
				);
			}
			case Routes.notificationPush: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<NotificationsPush />
					</Suspense>
				);
			}
			case Routes.notificationMessages: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<NotificationsMessages />
					</Suspense>
				);
			}
			case Routes.notificationSMS: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<SMSListing />
					</Suspense>
				);
			}
			case Routes.notificationEmails: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<EmailList />
					</Suspense>
				);
			}
			case Routes.notificationTemplates: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<EmailTemplates />
					</Suspense>
				);
			}
			case Routes.affiliates: {
				return (
					<Suspense fallback={<Spinner size="medium" />}>
						<Affiliates />
					</Suspense>
				);
			}

			default: {
				return <div />;
			}
		}
	};

	return (
		<MainLayout>
			<ErrorBoundary key={route} id={route}>
				{renderContent(route)}
			</ErrorBoundary>
		</MainLayout>
	);
};

