import styled, { keyframes } from 'styled-components';

const highlight = keyframes`
 0% { background-color: transparent; }
 50% { background-color: #B2C889; }
 100% { background-color: transparent; }
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;

  .ant-table {
    min-height: 400px;
    width: auto;
    overflow: visible;
    min-height : 400px;
  }

  .table-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    &:nth-child(2n+1) {
      background-color: #f9f9f9;
    }
  }

    .table-row-socket-update {
        animation-name: ${highlight};
        animation-duration: 1s;
        animation-iteration-count: 2;
    }

  .table-body:hover .table-cell{
    background-color: #f9f9f9;
  }

  .table-cell {
    padding: 0;
    overflow: visible !important;
    position: relative !important;

    & > div {
      padding: 2px 6px;
      overflow: hidden;
    }
  }

  .table-body .table-cell {
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
    border-right: 1px solid #f7f7f7;

    &.sorted {
      background-color: #f7f7f7;
      border-bottom: 1px solid #ffffff;
    }

    &.droppingCol {
      background: #eee;
    }

    & > div {
      height: auto;
    }
    svg {
      color: gray;
    }
  }

  .cell-cut {
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0;
    & > * {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  
  .cell-wrap {
    word-break: break-word;
    hyphens: auto;
  }

  .fix-head #head-row {
    position: fixed;
    top: 106px;
    width: 100%;
    overflow: auto;
    z-index: 1;
  }

  .table-head .table-cell {
    word-wrap: normal;
    white-space: normal;
    word-break: normal;
    background-color: #f7f7f7;
    height: 100%;
    cursor: grab;

    &.sorted {
      color: #4482FF;
    }
    &.droppingCol {
      background: #eee;
    }
  }

  .anticon-caret-up,
  .anticon-caret-down {
    font-size: 18px;
  }

  .ant-table + div {
    background-color: #f7f7f7;
    padding: 16px 8px;
    border-top: none;
    box-sizing: border-box;
  }

  .table-expanded {
    background-color: #eee !important;
    &:hover .table-cell{
      background-color: #c3c0c0 !important;
    }
  }

  .last {
    border-bottom: 1px solid #c3c0c0;
  }

  .skelton {
    margin-top: -16px;
    margin-bottom: -0.5em;
  }
`;

export const Empty = styled.h4`
  padding: 20px 0;
  text-align: center;
`;
