const actions = {

	WEBSITE_AUTOWITHDRAW_RELOAD        : 'WEBSITE_AUTOWITHDRAW_RELOAD',
	WEBSITE_AUTOWITHDRAW_LIST_REFRESH  : 'WEBSITE_AUTOWITHDRAW_LIST_REFRESH',
	WEBSITE_AUTOWITHDRAW_ADD           : 'WEBSITE_AUTOWITHDRAW_ADD',
	WEBSITE_AUTOWITHDRAW_SAVE          : 'WEBSITE_AUTOWITHDRAW_SAVE',
	WEBSITE_AUTOWITHDRAW_DELETE        : 'WEBSITE_AUTOWITHDRAW_DELETE',
	AUTOWITHDROWAL_UI_REFRESH          : 'AUTOWITHDROWAL_UI_REFRESH',
	WEBSITE_AUTOWITHDRAW_LIMITS_REFRESH: 'WEBSITE_AUTOWITHDRAW_LIMITS_REFRESH',
	AUTOWITHDROWAL_LIMIT_ADD           : 'AUTOWITHDROWAL_LIMIT_ADD',
	AUTO_WITHDRAWAL_RESET              : 'AUTO_WITHDRAWAL_RESET',
	MADE_AUTO_WITHDRAW_SOURCE          : 'MADE_AUTO_WITHDRAW_SOURCE',
	AUTO_WITHDRAWAL_SOURCE_REFRESH     : 'AUTO_WITHDRAWAL_SOURCE_REFRESH',
	AUTO_WITHDRAWAL_SOURCE_TEMP_REFRESH: 'AUTO_WITHDRAWAL_SOURCE_TEMP_REFRESH',
	// For Settings List
	listReload                         : websiteID => ({
		type: actions.WEBSITE_AUTOWITHDRAW_RELOAD,
		data: {
			websiteID,
		},
	}),
	listResresh: entities => ({
		type: actions.WEBSITE_AUTOWITHDRAW_LIST_REFRESH,
		data: entities,
	}),
	listDelete: (id) => ({
		type: actions.WEBSITE_AUTOWITHDRAW_DELETE,
		data: {
			id,
		},
	}),
	listAdd: data => ({
		type: actions.WEBSITE_AUTOWITHDRAW_ADD,
		data,
	}),
	// For Settings Limit
	limitsAdd: data => ({
		type: actions.AUTOWITHDROWAL_LIMIT_ADD,
		data,
	}),
	limitsResresh: (entities) => ({
		type: actions.WEBSITE_AUTOWITHDRAW_LIMITS_REFRESH,
		data: entities,
	}),
	autoWithdrawalSave: data => ({
		type: actions.WEBSITE_AUTOWITHDRAW_SAVE,
		data,
	}),
	madeAutoWithdrawSources: (data) => ({
		type: actions.MADE_AUTO_WITHDRAW_SOURCE,
		data,
	}),
	refreshAutoWithdrawSources: (data) => ({
		type: actions.AUTO_WITHDRAWAL_SOURCE_REFRESH,
		data,
	}),
	refreshAutoWithdrawTempSources: (data) => ({
		type: actions.AUTO_WITHDRAWAL_SOURCE_TEMP_REFRESH,
		data,
	}),
	uiRefresh: data => ({
		type: actions.AUTOWITHDROWAL_UI_REFRESH,
		data,
	}),
	dataReset: () => ({
		type: actions.AUTO_WITHDRAWAL_RESET,
	}),
};

export default actions;
