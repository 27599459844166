import styled from 'styled-components';
import { palette } from 'styled-theme';

export const StaticField = styled.span`
    font-weight: bold;
`;

export const TabsWrapper = styled.div`
  .ant-tabs {
    min-height: 50vh;
  }
`;
export const LayoutContentStyle = styled.div`
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    min-height: 140px ;
    margin-top: 60px;
`;
