import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, Table, Checkbox } from 'antd';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import IntlMessages from '../../../utility/intlMessages';
import notifications from '../../../../helpers/notifications';

import { DropdownContainer, Header, Body, Footer, FooterLink, IconWrapper } from './SettingsControl.style';

const lang = {
	title  : <IntlMessages id = "common.tableSettings.title" />,
	save   : <IntlMessages id = "common.save" />,
	default: <IntlMessages id = "common.default" />,
};

class SettingsControl extends Component {

	static propTypes = {
		id            : PropTypes.string.isRequired,
		columns       : PropTypes.arrayOf(PropTypes.object).isRequired,
		visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
		savedColumns  : PropTypes.arrayOf(PropTypes.object),
		onSave        : PropTypes.func,
		onDefault     : PropTypes.func,
	};

	static defaultProps = {
		onSave      : null,
		onDefault   : null,
		savedColumns: [],
	};

	constructor(props) {
		super(props);

		this.ownColumns = [
			{ title    : '', dataIndex: 'visible', key      : 'visible',
				render   : (text, record, index) => this.renderCellVisible(record, index),
			},
			{ title: 'Column', dataIndex: 'column', key: 'column' },
		];

		this.state = this.initState(props);

		this.initState             = this.initState.bind(this);
		this.validateSettings      = this.validateSettings.bind(this);
		this.getPopupContainer     = this.getPopupContainer.bind(this);

		this.onVisibleChange       = this.onVisibleChange.bind(this);
		this.hide                  = this.hide.bind(this);
		this.onClickSave           = this.onClickSave.bind(this);
		this.onClickDefault        = this.onClickDefault.bind(this);
		this.onChangeVisible       = this.onChangeVisible.bind(this);

		this.renderCellVisible     = this.renderCellVisible.bind(this);
		this.renderDropdownContent = this.renderDropdownContent.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { columns, visibleColumns } = this.props;
		const newColumns        = nextProps.columns;
		const newVisibleColumns = nextProps.visibleColumns;

		if (!isEqual(columns, newColumns) || !isEqual(visibleColumns, newVisibleColumns)) {
			const newState = this.initState(nextProps);
			this.setState(newState);
		}
	}

	// Service --------------------------------------------------------------------------------------

	initState(props) {
		const { columns, visibleColumns } = props;
		if (!isArray(visibleColumns) || isEmpty(visibleColumns)) {
			return {
				ownDataSource  : [],
				dropdownVisible: false,
			};
		}

		const ownDataSource = columns.map( item => {

			const { key }       = item;
			const columnTitle   = item.title;
			const visibleItem   = visibleColumns.find( item => item.key === key );
			const columnVisible = Boolean(visibleItem);

			return {
				key,
				column : columnTitle,
				visible: columnVisible,
			};
		});

		return {
			ownDataSource,
			dropdownVisible: false,
		};
	}

	validateSettings(dataSource) {

		const isError = dataSource.every( item => !item.visible );
		if (isError) {
			notifications.showWarning('Unable turn off all columns.');
		}

		return isError;
	}

	getPopupContainer() {
		const { id } = this.props;
		return document.getElementById(id);
	}

	// Local events ---------------------------------------------------------------------------------

	hide() {
		this.setState({ dropdownVisible: false });
	}

	onVisibleChange() {
		const { dropdownVisible } = this.state;
		this.setState({ dropdownVisible: !dropdownVisible });
	}

	onClickSave() {
		const { onSave, savedColumns } = this.props;
		const { ownDataSource } = this.state;
		if (!onSave) {
			this.setState({ dropdownVisible: false });
			return;
		}

		const isError = this.validateSettings(ownDataSource);
		if (isError) {
			return;
		}

		const result = cloneDeep(savedColumns);
		ownDataSource.forEach( item => {
			if (!item.visible && isEmpty(savedColumns)) {
				return;
			}

			if (isEmpty(savedColumns)) {
				result.push({
					key: item.key,
				});
			} else {
				const columnMatched = result.find(col => col.key === item.key);
				if (!columnMatched && item.visible) {
					result.push({
						key: item.key,
					});
				}
				if (columnMatched && !item.visible) {
					const index = result.indexOf(columnMatched);
					result.splice(index, 1);
				}
			}
		});

		onSave(result);
		this.setState({ dropdownVisible: false });
	}

	onClickDefault() {
		const { onDefault } = this.props;
		onDefault();
	}

	onChangeVisible(value, rowIndex) {
		const { ownDataSource } = this.state;
		const newOwnDataSource  = cloneDeep(ownDataSource);
		const record            = newOwnDataSource[rowIndex];
		record.visible          = value;

		this.setState({ ownDataSource: newOwnDataSource });
	}

	// Renders --------------------------------------------------------------------------------------

	renderCellVisible(record, rowIndex) {

		return (
			<Checkbox
				checked={record.visible}
				onChange={event => this.onChangeVisible(event.target.checked, rowIndex)}
			/>
		);
	}

	renderDropdownContent() {
		const { ownDataSource } = this.state;

		return (
			<DropdownContainer>
				<Header>{lang.title}</Header>
				<Body className='tableSettingsBody'>
					<Table
						rowKey="key"
						columns={this.ownColumns}
						dataSource={ownDataSource}
						pagination={false}
					/>
				</Body>
				<Footer>
					<FooterLink onClick={this.onClickSave}>{lang.save}</FooterLink>
					<FooterLink onClick={this.onClickDefault}>{lang.default}</FooterLink>
				</Footer>
			</DropdownContainer>
		);
	}

	render() {
		const { dropdownVisible } = this.state;

		const dropdownContent = this.renderDropdownContent();

		return (
			<Popover
				content={dropdownContent}
				trigger="click"
				visible={dropdownVisible}
				onVisibleChange={this.onVisibleChange}
				placement="leftBottom"
				getPopupContainer={this.getPopupContainer}
			>
				<IconWrapper>
					<i className="ion-android-settings" title="Table Settings" />
				</IconWrapper>
			</Popover>
		);
	}
}

export default SettingsControl;
