import { all, takeEvery, put, fork, select, call, delay } from 'redux-saga/effects';
import actions from './actions';

import { showError } from '../../../../helpers/notifications';
import { CONTENT_GAMES_CATEGORY } from '../../../../helpers/commonConstants';

import {
	makeRequestDefaultGrid,
	makeRequestGamesList,
	makeRequestGridsSave,
} from './functions';
import {
	gridListLimit,
	createGamesGridLayout,
	prepareGridLayouts,
	validateDefaultGrid, concatGames,
} from './utils';
import { logger } from '../../../../helpers/logger';
import { cloneDeep, toInteger } from 'lodash';
import { casinoAPI } from '../../../../helpers/api/casino';
import { adaptGamesList } from '../../../casino/games/utils';

const prefix = 'landingpage.gamegrid';

export const messages = {
	errorGridsLoad : `${prefix}.loading.grid.failed`,
	errorGamesLoad : `${prefix}.loading.list.failed`,
	errorGridsSave : `${prefix}.saving.grid.failed`,
	successGridSave: `${prefix}.grids.saved.success`,
};

function getStoreData({ CMS, App, LanguageSwitcher }) {

	const GamesGrids = CMS.LandingPage.GameGrid;

	return {
		defaultGrid   : GamesGrids.get('defaultGrid'),
		gamesList     : GamesGrids.get('gamesList'),
		categoryList  : GamesGrids.get('categoryList'),
		gridLayouts   : GamesGrids.get('gridLayouts'),
		changedLayouts: GamesGrids.get('changedLayouts'),
		channelID     : GamesGrids.get('UI').channelID,
		categoryID    : GamesGrids.get('UI').categoryID,
		gridMode      : GamesGrids.get('UI').gridMode,
		page          : GamesGrids.get('UI').page,
		isGridsChanged: GamesGrids.get('UI').isGridsChanged,
		websiteID     : App.get('websiteID'),
		totalCount   	: GamesGrids.get('UI').totalCount,
		langID        : LanguageSwitcher.get('language').dataBaseLangID, 
		partnerID     : App.get('partnerID'),
	};
}

function* defaultGridReload() {

	yield takeEvery(actions.GAMES_GRIDS_DEFAULT_GRID_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { channelID } = yield select(getStoreData);
		try {
			const rawDefaultGrid = yield call(makeRequestDefaultGrid, channelID);
			const { defaultGrid, defaultGridLayout } = yield call(validateDefaultGrid, rawDefaultGrid, channelID);

			yield put(actions.defaultGridRefresh(defaultGrid));
			yield put(actions.gridLayoutSet(CONTENT_GAMES_CATEGORY.all, defaultGridLayout));
			yield put(actions.clearChangedLayouts());
			yield put(actions.uiRefresh({
				isGridsChanged: false,
				gridLoaded    : true,
			}));

		} catch (error) {
			showError(messages.errorGridsLoad);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}
function* searchListReload() {

	yield takeEvery(actions.GAMES_SEARCH_LIST_RELOAD, function* ({ name }) {

		yield put(actions.uiRefresh({ searchLoad: true }));

		const { channelID, websiteID, categoryID, langID, partnerID } = yield select(getStoreData);
		
		const params = {
			name,
			page      : 1,
			limit     : 100,
			channel_id: channelID,
			lang_id   : langID,
			website_id: websiteID,
			partner_id: partnerID,
		};
		if (!isNaN(toInteger(categoryID)) && toInteger(categoryID > 0)) {
			params.category = categoryID;
		}
		try {
			const res = yield call(casinoAPI.gamesList, websiteID, params);
			if (res && res.status === 200) {
				const { adaptedData } = adaptGamesList(res.data.data);

				yield put(actions.searchResultGames(adaptedData.map(game => ({ ...game, key: String(game.id), searchResult: true }))));

			}
		} catch (error) {
			showError(messages.errorGridsLoad);
		}

		yield put(actions.uiRefresh({ searchLoad: false }));
	});
}

function* gamesListReload() {

	yield takeEvery(actions.GAMES_GRIDS_GAMES_LIST_RELOAD, function* (action) {
		const { gamesList } = yield select(getStoreData);
		const { websiteID, categoryID, channelID, page } = action.data;
		yield put(actions.uiRefresh(
			page > 1 
				? { pageLoading: true } 
				: { loading: true }));
		
		const params = {
			page,	
			channelID,
			categoryID,
		};
		if (categoryID) {
			try {
				const clonedGamesList = cloneDeep(gamesList);
				const { resualt: { adaptedData }, totalCount }  = yield call(makeRequestGamesList, websiteID, params);
				const currentListLength = clonedGamesList.length + adaptedData.length;

				const hasMore		= (totalCount - currentListLength > 0);
				const mergedData	= concatGames(clonedGamesList, adaptedData);
				const gridLayout	= yield call(createGamesGridLayout, mergedData);
				const lastPart		= (totalCount - currentListLength <= gridListLimit);

				const overallPageCount = Math.ceil(totalCount / gridListLimit);
				yield put(actions.uiRefresh({
					hasMore,
					lastPart,
					totalCount,
					overallPageCount,
					actualGamesCount: currentListLength,
				}));
				yield put(actions.gamesListMerge(mergedData));
				yield put(actions.gridLayoutSet(categoryID, gridLayout));

			} catch (error) {
				showError(messages.errorGamesLoad);
			}
		}

		yield put(actions.uiRefresh({ loading: false, pageLoading: false }));
	});
}

function* lastPageGamesListReload() {
	yield takeEvery(actions.LAST_PAGE_PAGE_RELOAD, function* () {
		const { totalCount, channelID, categoryID, websiteID, gamesList } = yield select(getStoreData);

		yield put(actions.uiRefresh({ pageLoading: true, lastPageLoading: true }));
		const params = {
			page    : Math.ceil(totalCount / gridListLimit),
			limit   : gridListLimit, // TODO: calculate limit
			channelID,
			categoryID,
			lastPage: true,
		};

		if (categoryID) {
			try {
				const { resualt: { adaptedData } } = yield call(makeRequestGamesList, websiteID, params);
				const mergedData = gamesList.concat(adaptedData);

				if (adaptedData.length) {
					const firstElement = adaptedData[0];
					const lastElement = adaptedData[adaptedData.length - 1];

					if (firstElement && lastElement) {
						const overallGridPageNo = lastElement.page;
						if (gamesList.length) {
							// const lastGridPageCount = (lastElement.page - firstElement.page) + 1;
							yield put(actions.uiRefresh({ lastGridPageCount: /* lastGridPageCount */ 4, overallGridPageNo }));
						}
					}
				}

				const gridLayout = yield call(createGamesGridLayout, mergedData);
				yield put(actions.gamesListMerge(mergedData));
				yield put(actions.gridLayoutSet(categoryID, gridLayout));
				yield put(actions.uiRefresh({ lastPageExists: true }));

				yield delay(100);
				window.scrollTo({
					top     : document.body.scrollHeight,
					behavior: 'smooth',
				});

			} catch (error) {
				showError(messages.errorGamesLoad);
			}
		}

		yield put(actions.uiRefresh({ pageLoading: false, lastPageLoading: false }));
	});

}


function* gamesGridsSave() {

	yield takeEvery(actions.GAMES_GRIDS_SAVE, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const {
			gridLayouts,
			changedLayouts,
			websiteID,
			channelID,
			isGridsChanged,
			gamesList,
		} = yield select(getStoreData);

		if (!isGridsChanged) {
			return;
		}

		const nonLastPageGameIDs =  gamesList.filter(game => !game.lastPage).map(game => game.id);
		const preparedGrids = prepareGridLayouts(gridLayouts, changedLayouts, websiteID, channelID, nonLastPageGameIDs);
		try {
			for (let i = 0; i < preparedGrids.length; i++) {
				const preparedData = preparedGrids[i];
				yield call(makeRequestGridsSave, preparedData);
			}

			yield put(actions.clearChangedLayouts());
			yield put(actions.uiRefresh({ isGridsChanged: false }));

		} catch (error) {
			logger.log(error);
			showError(messages.errorGridsSave, error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* gamesGridSaga() {
	yield all([
		fork(defaultGridReload),
		fork(gamesListReload),
		fork(gamesGridsSave),
		fork(lastPageGamesListReload),
		fork(searchListReload),
	]);
}
