import React from 'react';
import * as PropTypes from 'prop-types';

import FormNumberRange from '../../../components/FormControls/FormNumberRange';
import Utils from '../FilterUtils';

const NumberRange = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, defaultValue, disabled, minValue, maxValue, step, separator } = filterItem;
	let resDefaultValue = [null, null];
	if (Utils.isValidRange(defaultValue)) {
		const valueFrom = defaultValue[0] || null;
		const valueTo   = defaultValue[1] || null;
		resDefaultValue = [valueFrom, valueTo];
	}

	let resValue = [null, null];
	if (Utils.isValidRange(value)) {
		const valueFrom = value[0] || null;
		const valueTo   = value[1] || null;
		resValue = [valueFrom, valueTo];
	}

	return (
		<FormNumberRange
			name={name}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			defaultValue={resDefaultValue || null}
			value={resValue}
			min={minValue || 0}
			max={maxValue || Number.MAX_SAFE_INTEGER}
			step={step || 1}
			separator={separator || null}
			className="double-number-box"
			disabled={disabled}
			onChange={value => onChange(name, value)}
		/>
	);
};

NumberRange.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default NumberRange;
