import { apiRequest } from './index';
import { PartialConfigs } from './types';

const getTemplates = async (params) => {
	const config = {
		method: 'GET',
		url   : '/bonus_templates',
		params,
	};

	return apiRequest(config);
};

const getTemplate= async (templateID: number) => {
	const config = {
		method: 'GET',
		url   : `/bonus_template/${templateID}`,
	};

	return apiRequest(config);
};
function saveTemplate(data, params = {}) {
	const req = {
		method: 'POST',
		url   : '/bonus_template',
		data,
		params,
	};

	return apiRequest(req);
}

const archiveTemplate = async (templateID: number) => {
	const config = {
		method: 'DELETE',
		url   : `/bonus_template/${templateID}`,
	};

	return apiRequest(config);
};

const updateTemplate = async (data,templateID: number) => {
	const config: PartialConfigs  = {
		method: 'PUT',
		url   : `/bonus_template/${templateID}`,
		data,
	};
	return apiRequest(config);

};


export const bonusTemplatesAPI = {
	getTemplates,
	getTemplate,
	saveTemplate,
	archiveTemplate,
	updateTemplate,
};