import React from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { FORMATS } from '../../../helpers/commonConstants';
import FormDatePicker from '../../../components/FormControls/FormDatePicker';
import Utils from '../FilterUtils';

const fullWidth = { width: '100%' };

const Date = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, placeholder, defaultValue, disabled, dateFormat, timeFormat } = filterItem;

	const originDateFormat = dateFormat || FORMATS.date;
	const originTimeFormat = timeFormat || '';

	const resFormat = originTimeFormat ? `${originDateFormat} ${originTimeFormat}` : originDateFormat;
	const showTime = originTimeFormat ? { format: originTimeFormat } : false;

	const wrapperID = uuid();

	return (
		<div id={wrapperID} style={{ width: '100%' }}>
			<FormDatePicker
				name={name}
				label={label}
				labelWidth={Utils.getLabelWidth(showLabel)}
				format={resFormat}
				showTime={showTime}
				placeholder={placeholder || ''}
				defaultValue={defaultValue || null}
				value={value}
				style={fullWidth}
				disabled={disabled}
				onChange={value => onChange(name, value, resFormat)}
				getCalendarContainer={() => document.getElementById(wrapperID)}
			/>
		</div>
	);
};

Date.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default Date;
