import React from 'react';
import * as PropTypes from 'prop-types';

import FormInput from '../../../components/FormControls/FormInput';
import Utils from '../FilterUtils';

const Input = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, placeholder, defaultValue, disabled, widthLabel, widthInput } = filterItem;

	return (
		<FormInput
			name={name}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel, widthLabel)}
			placeholder={placeholder || ''}
			defaultValue={defaultValue || null}
			value={value}
			disabled={disabled}
			className="single-input-box"
			onChange={({ target }) => onChange(name, target.value)}
			widthInput={widthInput}
		/>
	);
};

Input.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default Input;
