import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
	position: relative;
		
	.ant-table-row {
		td {
			padding: 8px;
		} 
	}
`;

export const IconWrapper = styled.div`
  width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 30px;
	i {
		font-size: 40px;
	}
`;
