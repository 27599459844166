export interface IWebsiteCurrency {
    currencyID : number;
    description: string;
    isExists   : boolean;
    name       : string;
    orderID    : number;
    websiteID  : number;
}

export interface IWebsiteCountry {
    id       : number;
    countryID: number;
    code     : string;
    name     : string;
    isExist  : boolean;
}

export interface IWebsiteCountryEntities {
  [key:string]: IWebsiteCountry;
}

export interface IUsersRegField {
    id      : number;
    key     : string;
    name    : string;
    show    : boolean;
    required: boolean;
    disabled: boolean;
}

export enum  WebsiteUserShowRequiredFields { // WebsiteRequiredFields
    USERNAME = 'userName',
    EMAIL = 'email',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    GENDER = 'gender',
    CURRENCY_ID = 'currencyID',
    BIRTH = 'birth',
    PHONE = 'phone',
    COUNTRY = 'country',
    CITY = 'city',
    ADDRESS_1 = 'address1',
    ADDRESS_2 = 'address2',
    NATIONAL_ID = 'nationalID',
    PASSWORD = 'password',
    PASSWORD_CONFIRMATION = 'passwordConfirmation',

    // for create user
    LANGUAGE_ID = 'languageID',
    STATUS_ID = 'statusID',
    AFFILIATE_REFERENCE = 'affiliateReference',
}