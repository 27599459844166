import defaultSidebar from '../config/defaultSidebar';
import { getPermissions } from '../helpers/utility';
import { COMPONENTS } from './components';
import { canUse } from '../helpers/permissions/utils';
import { SIDEBAR_PERMISSIONS } from '../helpers/permissions/constants';
/**
 * Additional keys for independent pages: User Info, Event, Info Page and so on
 */


export const additionalKeys = [
	'users/info',             					// 0: User Info
	'results/info',           					// 1: Result Info
	'events/info',            					// 2: Event Info
	'content/page',           					// 3: Info Page
	'content/news/article',   					// 4: News Article Page
	'payment/payways',        					// 5: Payways
	'priorities/sport',       					// 6: ResponsibleGambling Priority Events
	'specialOffers/sports',   					// 7: Special Offers Events
	'content/newPromotion',   					// 8: Promotion
	'personal/info',										// 9: staff inner
	'partners/websitesInfo',  					// 10: website inner
	'loyalty/bonusingSystem/massbonus', // 11: mass bonus create
	'loyalty/templates/create', 			  // 12: bonus template create
	'loyalty/templates/view', 					// 13: bonus template view
	'loyalty/bonusingSystem/bonuses/create',	// 14: bonus Create after refactor to wizard
	'loyalty/bonusingSystem/bonuses/edit',	// 15: bonus Edit after refactor to wizard
	'loyalty/bonusingSystem/welcome/create',	// 16: Welcome bonus Create after refactor to wizard
	'loyalty/bonusingSystem/welcome/edit',	// 17: Welcome bonus Edit after refactor to wizard

];


export const APP_TABS = {
	dashboard                    : 'dashboard',
	userInfo                     : additionalKeys[0],
	resultInfo                   : additionalKeys[1],
	eventInfo                    : additionalKeys[2],
	infoPage                     : additionalKeys[3],
	newsArticlePage              : additionalKeys[4],
	paymentPayways               : additionalKeys[5],
	sportPriorities              : additionalKeys[6],
	specialOffers                : additionalKeys[7],
	promotion                    : additionalKeys[8],
	personalInfo                 : additionalKeys[9],
	websiteInner                 : additionalKeys[10],
	createMassBonus              : additionalKeys[11],
	bonusTemplate                : additionalKeys[12],
	bonusTemplateView            : additionalKeys[13],
	bonusesConditionalBonusCreate: additionalKeys[14],
	bonusesConditionalBonusEdit  : additionalKeys[15],
	bonusesWelcomeBonusCreate    : additionalKeys[16],
	bonusesWelcomeBonusEdit      : additionalKeys[17],
};

export const DEFAULT_TABS = [{
	id            : APP_TABS.dashboard,
	titleID       : 'sidebar.dashboard',
	componentName : COMPONENTS.dashboard,
	componentProps: {},
	location      : '/dashboard',
	search        : '',
}];


export const permissions = getPermissions();
export const SIDEBAR_KEYS = createSidebarKeys(defaultSidebar, permissions, [], {});

export const ADDITIONAL_TABS = {
	userInfo: {
		id           : APP_TABS.userInfo,
		titleID      : 'userInfo.title',
		componentName: COMPONENTS.userInfo,
	},
	resultInfo: {
		id           : APP_TABS.resultInfo,
		titleID      : 'results.resultInfo.title',
		componentName: COMPONENTS.resultInfo,
	},
	eventInfo: {
		id           : APP_TABS.eventInfo,
		titleID      : 'events.details.title',
		componentName: COMPONENTS.eventInfo,
	},
	infoPage: {
		id           : APP_TABS.infoPage,
		titleID      : 'infoPages.newTitle',
		componentName: COMPONENTS.contentInfoPage,
	},
	newsArticlePage: {
		id           : APP_TABS.newsArticlePage,
		titleID      : 'news.article.newTitle',
		componentName: COMPONENTS.newsArticlePage,
	},
	paymentPayways: {
		id           : APP_TABS.paymentPayways,
		titleID      : 'payways.title',
		componentName: COMPONENTS.paymentPayways,
	},
	sportPriorities: {
		id           : APP_TABS.sportPriorities,
		titleID      : 'priorities.eventTitle',
		componentName: COMPONENTS.sportPriorities,
	},
	specialOffers: {
		id           : APP_TABS.specialOffers,
		titleID      : 'specialOffers.offers.tabTitle',
		componentName: COMPONENTS.specialOffers,
	},
	promotions: {
		id           : APP_TABS.promotion,
		titleID      : 'promotion.newTitle',
		componentName: COMPONENTS.contentPromotion,
	},
	promotionsEdit: {
		id           : APP_TABS.promotion,
		titleID      : 'promotion.editTitle',
		componentName: COMPONENTS.contentPromotion,
	},

	reportByProvider: {
		id           : APP_TABS.reportByProvider,
		titleID      : 'reportByProvider.Title',
		componentName: COMPONENTS.reportByProvider,
	},
	personalInfo: {
		id           : APP_TABS.personalInfo,
		titleID      : 'personalInfo.title',
		componentName: COMPONENTS.userInfo,
	},
	websiteInner: {
		id           : APP_TABS.websiteInner,
		titleID      : 'websiteInner.title',
		componentName: COMPONENTS.websiteInner,
	},
	createMassBonus: {
		id           : APP_TABS.createMassBonus,
		titleID      : 'createMassBonus.title',
		componentName: COMPONENTS.createMassBonus,
	},
	bonusesConditionalBonusCreate: {
		id           : APP_TABS.bonusesConditionalBonusCreate,
		titleID      : 'loyalty/bonusingSystem/bonuses.createTitle',
		componentName: COMPONENTS.bonusesConditionalBonusCreate,
	},
	bonusesConditionalBonusEdit: {
		id           : APP_TABS.bonusesConditionalBonusEdit,
		titleID      : 'loyalty/bonusingSystem/bonuses.editTitle',
		componentName: COMPONENTS.bonusesConditionalBonusCreate,
	},
	bonusesWelcomeBonusCreate: {
		id           : APP_TABS.bonusesWelcomeBonusCreate,
		titleID      : 'loyalty/bonusingSystem/bonuses.createWelcomeTitle',
		componentName: COMPONENTS.bonusesWelcomeBonusCreate,
	},
	bonusesWelcomeBonusEdit: {
		id           : APP_TABS.bonusesWelcomeBonusEdit,
		titleID      : 'loyalty/bonusingSystem/bonuses.editWelcomeTitle',
		componentName: COMPONENTS.bonusesWelcomeBonusCreate,
	},


};

// Utils ------------------------------------------------------------------------------------------
export function createSidebarKeys(sidebar = defaultSidebar, permissions, result) {
	for (let i = 0; i < sidebar.length; i++) {
		const rootMenu = sidebar[i];
		const hasChildren = (rootMenu.menuItems && rootMenu.menuItems.length > 0);

		if (!hasChildren) {
			if (rootMenu.component) {
				const roleName = SIDEBAR_PERMISSIONS[rootMenu.key];
				const permitted = canUse(roleName, null, permissions);
				if (permitted) {
					result.push(rootMenu.key);
				}
			}
		} else {
			createSidebarKeys(rootMenu.menuItems, permissions, result);
		}
	}
	return result;
}

export function createNextedKeys(sidebar = defaultSidebar, nextedKeys, latestCloseKey, parentKey = '') {
	sidebar.forEach(item => {
		if (item.menuItems && item.menuItems.length) {
			item.menuItems.forEach(childItem => {
				if (childItem.menuItems && childItem.menuItems.length) {
					nextedKeys.push(childItem.key);
				}
				if (childItem.key === latestCloseKey) {
					parentKey = item.key;
				}
			});
		}
	});
	return {
		nextedKeys,
		parentKey,
	};
}
export const defineOpenKeysInDidMount = (menu, activeTabID, keysArr = []) => {
	for (let i = 0; i < menu.length; i++) {
		const rootMenu = menu[i];
		const hasChildren = (rootMenu.menuItems && rootMenu.menuItems.length > 0);
		if (!hasChildren) {
			if (rootMenu.url === activeTabID) {
				const keys = rootMenu.key.split('/');
				if (keys.length > 2) {
					keysArr.push(...keys.slice(0, keys.length - 1));
				} else {
					keysArr.push(keys[0]);
				}
			}
		} else {
			defineOpenKeysInDidMount(rootMenu.menuItems, activeTabID, keysArr);
		}
	}
	return keysArr;
};
