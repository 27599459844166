import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

export const currencyEntities = (state) => state.Settings.CurrencyModule.get('entities');
export const currencyWithIDEntities = (state) => state.Settings.CurrencyModule.get('entitiesWithCurrID');

export const deriveCurrencyEntities = createSelector(
	[currencyEntities],
	(currencyEntities) => currencyEntities
);

export const deriveCurrencyList = createSelector(
	[currencyEntities],
	(currencyEntities) => sortBy( values(currencyEntities), ['name'])
);

export const deriveCurrencyWithCurrencyID = createSelector(
	[currencyEntities],
	(currencyEntities) => sortBy( values(currencyEntities).map(currency => ({ ...currency, id: currency.currencyID })), ['name'])
);

export const deriveCurrencyListWithCurrID = createSelector(
	[currencyWithIDEntities],
	(currencyEntities) => sortBy( values(currencyEntities), ['name'])
);
