import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex !important;
    min-height: auto;
  
  .panel {
    background: white;
    border: none;
  }
  .panel:first-child {
    border-left: none;
  }
  
  .resizer {
    width: 1px;
    z-index: 2;
    position: relative;
    cursor: col-resize;
    flex-shrink: 0;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */ 
  }
  
  .resizer::after,
  .resizer::before {
    content: "";
    border-left: 1px solid darkGray;
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    right: 0;
    display: inline-block;
    height: 100%;
    margin: 0 1px;
  }
  .resizer::before {
    left: 0;
  }
`;
