import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

export const Link = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  width: calc(100% - 42px);
  height: 34px;
  margin-right: 8px;
  box-sizing: border-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .anticon-loading {
    margin-right: 8px;
  }
`;

export const Button = styled.button`
  background-color: #d77031;
  border: none;
  color: #fff;
  text-align: center;
  cursor: pointer;
  height: 34px;
  width: 34px;
  border-radius: 4px;
  box-sizing: border-box;
  &.disabled {
    background-color: #e9e9e9;
		cursor: not-allowed;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: ${({ disabled }) => disabled ? '#e9e9e9' : 'rgb(233, 233, 233)'};
	opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
  color: #666666;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;
