import { all, fork } from 'redux-saga/effects';

import userMassBonusFilter from './filter/saga';
import userMassBonusList from './list/saga';


export default function* bonusesRootSaga() {
	yield all([
		fork(userMassBonusList),
		fork(userMassBonusFilter),
	]);
}