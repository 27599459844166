import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import isArray from 'lodash/isArray';
import Intl from '../../utility/intlMessages';

import TimeRangeWrapper from './timeRange.style';

const lang = {
	hours  : <Intl id = "rtm.bets.filter.settlementSpeed.hours" />,
	minutes: <Intl id = "rtm.bets.filter.settlementSpeed.minutes" />,
	seconds: <Intl id = "rtm.bets.filter.settlementSpeed.seconds" />,
};

function isValidFilterArray(list) {
	return (Boolean(list) && isArray(list) && list.length === 2);
}

class TimeRange extends Component {

	static propTypes = {
		value       : PropTypes.arrayOf(PropTypes.string),
		defaultValue: PropTypes.arrayOf(PropTypes.string),
		format      : PropTypes.string,
		separator   : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		onChange    : PropTypes.func,
	};

	static defaultProps = {
		value       : [],
		defaultValue: [],
		format      : 'HH : mm : ss',
		separator   : '',
		onChange    : null,
	};

	constructor(props) {
		super(props);
		this.changeFormField = this.changeFormField.bind(this);
		this.state = {
			valueFrom: '',
			valueTo  : '',
		};
	}

	componentDidMount() {
		const { value, defaultValue } = this.props;

		// defined Value
		if (isValidFilterArray(value)) {
			const valueFrom = value[0];
			const valueTo   = value[1];
			this.setState({ valueFrom, valueTo });

			// defined DefaultValue
		} else if (isValidFilterArray(defaultValue)) {
			const valueFrom = defaultValue[0];
			const valueTo   = defaultValue[1];
			this.setState({ valueFrom, valueTo });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { value } = nextProps;

		if (isValidFilterArray(value)) {
			const valueFrom = value[0];
			const valueTo   = value[1];
			this.setState({ valueFrom, valueTo });
		}
	}

	changeFormField(fieldName, time) {
		const { format, onChange } = this.props;

		const fieldValue = time ? time.format(format) : '';

		this.setState({ [fieldName]: fieldValue }, () => {
			const { valueFrom, valueTo } = this.state;
			const values = [valueFrom, valueTo];
			if (onChange) {
				onChange(values);
			}
		});
	}

	render() {
		const { separator, format } = this.props;
		const { valueFrom, valueTo } = this.state;

		const resValueFrom = valueFrom ? dayjs(valueFrom, format) : null;
		const resValueTo   = valueTo   ? dayjs(valueTo,   format) : null;

		return (
			<TimeRangeWrapper>

				<TimePicker
					value={resValueFrom}
					format={format}
					className="number-box"
					onChange={time => this.changeFormField('valueFrom', time)}
					addon={() => (
						<>
							<span>{lang.hours}</span>
							<span>{lang.minutes}</span>
							<span>{lang.seconds}</span>
						</>
					)}
					popupClassName="time-range"
					getPopupContainer={triggerNode => triggerNode.parentNode}
				/>

				{separator && <div>{separator}</div>}

				<TimePicker
					value={resValueTo}
					format={format}
					className="number-box"
					onChange={time => this.changeFormField('valueTo', time)}
					addon={() => (
						<>
							<span>{lang.hours}</span>
							<span>{lang.minutes}</span>
							<span>{lang.seconds}</span>
						</>
					)}
					popupClassName="time-range"
					getPopupContainer={triggerNode => triggerNode.parentNode}
				/>

			</TimeRangeWrapper>
		);
	}
}

export default TimeRange;
