import React from 'react';
import * as PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import FormSelect from '../../../components/FormControls/FormSelect';
import Utils from '../FilterUtils';

const fullWidth = { width: '100%' };

const SelectSearch = ({ filterItem, onChange }) => {
	const { name, value, label, showLabel, placeholder, defaultValue, disabled, optionList } = filterItem;

	return (
		<FormSelect
			showSearch
			optionFilterProp="children"
			name={name}
			label={label}
			labelWidth={Utils.getLabelWidth(showLabel)}
			optionsList={optionList || []}
			placeholder={placeholder}
			defaultValue={defaultValue || null}
			value={value}
			style={fullWidth}
			disabled={disabled}
			wrapperID={uuid()}
			filterOption={Utils.onFilterOptios}
			onChange={value => onChange(name, value)}
		/>
	);
};

SelectSearch.propTypes = {
	filterItem: PropTypes.object.isRequired,
	onChange  : PropTypes.func.isRequired,
};

export default SelectSearch;
