import styled from 'styled-components';

export const Wrapper = styled.div`
  & .langSwitcher {
    margin-bottom: 20px;

    .ant-select {
      left: 10px;
    }
  }
		
	.title-length,
	.description-length {
		display: flex;
		justify-content: flex-end;
			
		&-red {
				color: red;
		}	
	}
`;
