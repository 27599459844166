import { Col, Row } from 'antd';
import FormInputNumber from '../FormControls/FormInputNumber';
import IntlMessages from '../utility/intlMessages';

const lang = {
	maxPayoutAmount : <IntlMessages id = "bonuses.bonuses.modal.maxPayoutAmount" />,
	maxPayoutPercent: <IntlMessages id = "bonuses.bonuses.modal.maxPayoutPercent" />,
};

export const MaxPayout = ({ 
	disabled, 
	onChange, 
	bonusData, 
	changedFieldAmount = 'maxPayoutAmount', 
	changedFieldPercent = 'maxPayoutPercent', 
	span = 24, 
	gutter = 16, 
	showPercent = false, 
	style = {},  
}) => {
	const { maxPayoutAmount, maxPayoutPercent } = bonusData;

	return (
		<Row style={style} gutter={gutter}>
			<Col span={span}>
				<FormInputNumber
					label={lang.maxPayoutAmount}
					value={maxPayoutAmount || null}
					disabled={disabled}
					min={0}
					onChange={value => onChange(value || null, changedFieldAmount)}
				/>
			</Col>
			{showPercent && <Col span={span}>
				<FormInputNumber
					label={lang.maxPayoutPercent}
					value={maxPayoutPercent || null}
					min={0}
					disabled={disabled}
					onChange={value => onChange(value || null, changedFieldPercent)}
				/>
			</Col>}
		</Row>
	);
};