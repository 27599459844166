export const ELEMENT_TYPES = {
	Input             	: 'Input',
	InputNumberTypeText: 'InputNumberTypeText',
	InputNumber       	: 'InputNumber',
	Select            	: 'Select',
	SelectSearch      	: 'SelectSearch',
	Multiselect       	: 'Multiselect',
	Date              	: 'Date',
	DateRange         	: 'DateRange',
	NumberRange       	: 'NumberRange',
	Checkbox          	: 'Checkbox',
	TimeRange         	: 'TimeRange',
	InputAutoComplete 	: 'InputAutoComplete',
	Component         	: 'Component',

};

export const EMPTY_VALUES = {
	[ELEMENT_TYPES.Input]             	: null,
	[ELEMENT_TYPES.InputNumberTypeText]: null,
	[ELEMENT_TYPES.InputNumber]       	: null,
	[ELEMENT_TYPES.Select]            	: null,
	[ELEMENT_TYPES.SelectSearch]      	: null,
	[ELEMENT_TYPES.Multiselect]       	: [],
	[ELEMENT_TYPES.Date]              	: null,
	[ELEMENT_TYPES.DateRange]         	: [],
	[ELEMENT_TYPES.NumberRange]       	: [],
	[ELEMENT_TYPES.Checkbox]          	: null,
	[ELEMENT_TYPES.TimeRange]         	: [],
	[ELEMENT_TYPES.InputAutoComplete] 	: null,
	[ELEMENT_TYPES.Component]         	: null,
};
